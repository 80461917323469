import "./WizardForm.css";
import Container from "react-bootstrap/Container";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Col from "react-bootstrap/Col";
import icon1 from "../../../../images/shopCards/Icon1Active.svg";
import icon2 from "../../../../images/shopCards/Icon2.svg";
import icon2Active from "../../../../images/shopCards/Icon2Active.svg";
import icon3 from "../../../../images/shopCards/Icon3.svg";
import icon3Active from "../../../../images/shopCards/Icon3Active.svg";
import icon4 from "../../../../images/shopCards/Icon4.svg";
import icon4Active from "../../../../images/shopCards/Icon4Active.svg";
import icon5 from "../../../../images/shopCards/Icon5.svg";
import icon5Active from "../../../../images/shopCards/Icon5Active.svg";
import icon6 from "../../../../images/shopCards/Icon6.svg";
import icon6Active from "../../../../images/shopCards/Icon6Active.svg";
import Step1 from "../Step1/Step1";
import Step2 from "../Step2/Step2";
import Button from "react-bootstrap/Button";
import backArrow from "../../../../images/shopCards/backArrow.svg";
import nextArrow from "../../../../images/shopCards/nextArrow.svg";
import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { RemoveScrollBar } from "react-remove-scroll-bar";
//redux
import { useDispatch, useSelector } from "react-redux";
import { checkOutActions } from "../../../../store/CheckOut-slice";
import { stylesActions } from "../../../../store/Styles-slice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const WizardForm = () => {
  const notify = (msg) => toast(msg);
  const history = useHistory();
  const dispatch = useDispatch();
  const showCheckOutModalHandler = () => {
    dispatch(checkOutActions.setInsertCard());
  };
  const [activeIcon2, setActiveIcon2] = useState(false);
  const [activeIcon3, setActiveIcon3] = useState(false);
  const [activeIcon4, setActiveIcon4] = useState(false);
  const [activeIcon5, setActiveIcon5] = useState(false);
  const [activeIcon6, setActiveIcon6] = useState(false);
  const button1 = (
    <div className="flex text-[#fff]">
      <img src={icon1} alt="..." />
      <p className="m-0 font-medium sm:!text-xs md:!text-base lg:!px-3 text-left">
        Card Style
        <br /> and Size
      </p>
    </div>
  );
  const button2 = (
    <div className="flex !text-left">
      <img src={activeIcon2 ? icon2Active : icon2} alt="..." />
      <p
        className={
          activeIcon2
            ? "!text-[#ffffff] m-0 font-medium sm:!text-xs md:!text-base lg:!px-3 text-left"
            : " !text-[#999999] m-0 font-medium sm:!text-xs md:!text-base lg:!px-3 text-left"
        }
      >
        Card
        <br />
        Design
      </p>
    </div>
  );
  const button3 = (
    <div className="flex !text-left">
      <img src={activeIcon3 ? icon3Active : icon3} alt="..." />
      <p
        className={
          activeIcon3
            ? "!text-[#ffffff] m-0 font-medium sm:!text-xs md:!text-base lg:!px-3 text-left"
            : " !text-[#999999] m-0 font-medium sm:!text-xs md:!text-base lg:!px-3 text-left"
        }
      >
        Edit&nbsp;Your
        <br />
        Card
      </p>
    </div>
  );
  const button4 = (
    <div className="flex !text-left">
      <img src={activeIcon4 ? icon4Active : icon4} alt="..." />
      <p
        className={
          activeIcon4
            ? "!text-[#ffffff] m-0 font-medium sm:!text-xs md:!text-base lg:!px-3 text-left"
            : " !text-[#999999] m-0 font-medium sm:!text-xs md:!text-base lg:!px-3 text-left"
        }
      >
        Choose&nbsp;Club
        <br />
        and&nbsp;Country
      </p>
    </div>
  );
  const button5 = (
    <div className="flex !text-left">
      <img src={activeIcon5 ? icon5Active : icon5} alt="..." />
      <p
        className={
          activeIcon5
            ? "!text-[#ffffff] m-0 font-medium sm:!text-xs md:!text-base lg:!px-3 text-left"
            : " !text-[#999999] m-0 font-medium sm:!text-xs md:!text-base lg:!px-3 text-left"
        }
      >
        Choose
        <br />
        Attributes
      </p>
    </div>
  );
  const button6 = (
    <div className="flex !text-left">
      <img src={activeIcon6 ? icon6Active : icon6} alt="..." />
      <p
        className={
          activeIcon6
            ? "!text-[#ffffff] m-0 font-medium sm:!text-xs md:!text-base lg:!px-3 text-left"
            : " !text-[#999999] m-0 font-medium sm:!text-xs md:!text-base lg:!px-3 text-left"
        }
      >
        Choose
        <br />
        Extras
      </p>
    </div>
  );
  const [backHome, setBackHome] = useState(true);
  const [hideNext, setHideNext] = useState(false);
  const [activeKey, setActiveKey] = useState("step1");
  const [lightStep2, setLightStep2] = useState(false);
  const [lightStep3, setLightStep3] = useState(false);
  const [lightStep4, setLightStep4] = useState(false);
  const [lightStep5, setLightStep5] = useState(false);
  const [lightStep6, setLightStep6] = useState(false);
  const [activeColumn, setActiveColumn] = useState("");
  const activeNextKeyHandler = (e) => {
    if (btnNextStatus) {
      notify("Please Fill Out All Required Fields!");
    } else {
      dispatch(stylesActions.disableBtnNext());
      setBackHome(false);
      if (activeKey === "step1") {
        setActiveIcon2(true);
        setLightStep2(true);
        setActiveColumn("col1");
        setActiveKey("step2");
      } else if (activeKey === "step2" && lightStep2 && !lightStep3) {
        setActiveIcon3(true);
        setLightStep3(true);
        setActiveKey("step2");
        setActiveColumn("col2");
      } else if (activeKey === "step2" && lightStep3 && !lightStep4) {
        setActiveColumn("col3");
        setActiveIcon4(true);
        setActiveKey("step2");
        setLightStep4(true);
      } else if (
        activeKey === "step2" &&
        lightStep3 &&
        lightStep4 &&
        !lightStep5
      ) {
        setActiveColumn("col4");
        setActiveIcon5(true);
        setActiveKey("step2");
        setLightStep5(true);
      } else if (
        activeKey === "step2" &&
        lightStep3 &&
        lightStep4 &&
        lightStep5 &&
        !lightStep6
      ) {
        setActiveColumn("col5");
        setActiveIcon6(true);
        setActiveKey("step2");
        setLightStep6(true);
        setHideNext(true);
      }
    }
  };
  const activeBackKeyHandler = () => {
    setHideNext(false);
    if (activeKey === "step2" && activeColumn === "col1") {
      dispatch(stylesActions.enableBtnNext());
      setActiveColumn("");
      setActiveKey("step1");
      setBackHome(true);
      setActiveIcon2(false);
    } else if (activeKey === "step2" && activeColumn === "col2") {
      dispatch(stylesActions.enableBtnNext());
      setActiveColumn("col1");
      setLightStep3(false);
      setActiveIcon3(false);
    } else if (activeKey === "step2" && activeColumn === "col3") {
      setActiveColumn("col2");
      setLightStep4(false);
      setActiveIcon4(false);
    } else if (activeKey === "step2" && activeColumn === "col4") {
      setActiveColumn("col3");
      setLightStep5(false);
      setActiveIcon5(false);
    } else if (activeKey === "step2" && activeColumn === "col5") {
      setActiveColumn("col4");
      setLightStep6(false);
      setActiveIcon6(false);
    }
  };
  const onCustomizeClick = () => {
    setActiveKey("step2");
    setActiveColumn("col1");
    setActiveIcon2(true);
    setBackHome(false);
    setLightStep2(true);
    setHideNext(false);
  };
  const continueShoppingHandler = () => {
    setLightStep2(false);
    setLightStep3(false);
    setLightStep4(false);
    setLightStep5(false);
    setLightStep6(false);
    setActiveIcon2(false);
    setActiveIcon3(false);
    setActiveIcon4(false);
    setActiveIcon5(false);
    setActiveIcon6(false);
    setActiveKey("step1");
    setBackHome(true);
    setHideNext(false);
  };
  useEffect(() => {
    if (
      !backHome &&
      document.querySelector('li[class="nav-item"]:nth-child(1)')
    ) {
      document.querySelector(
        'li[class="nav-item"]:nth-child(1)'
      ).style.background = "#ecccbe";
      document.querySelector(
        'li[class="nav-item"]:nth-child(1)'
      ).style.borderTopLeftRadius = "20px";
    } else {
      document.querySelector(
        'li[class="nav-item"]:nth-child(1)'
      ).style.background = "initial";
    }
  }, [backHome]);
  useEffect(() => {
    if (
      activeIcon3 &&
      document.querySelector('li[class="nav-item"]:nth-child(2)')
    ) {
      document.querySelector(
        'li[class="nav-item"]:nth-child(2)'
      ).style.background = "#ecccbe";
    } else {
      document.querySelector(
        'li[class="nav-item"]:nth-child(2)'
      ).style.background = "initial";
    }
  }, [activeIcon3]);
  useEffect(() => {
    if (
      lightStep4 &&
      document.querySelector('li[class="nav-item"]:nth-child(3)')
    ) {
      document.querySelector(
        'li[class="nav-item"]:nth-child(3)'
      ).style.background = "#ecccbe";
    } else {
      document.querySelector(
        'li[class="nav-item"]:nth-child(3)'
      ).style.background = "initial";
    }
  }, [lightStep4]);
  useEffect(() => {
    if (
      lightStep5 &&
      document.querySelector('li[class="nav-item"]:nth-child(4)')
    ) {
      document.querySelector(
        'li[class="nav-item"]:nth-child(4)'
      ).style.background = "#ecccbe";
    } else {
      document.querySelector(
        'li[class="nav-item"]:nth-child(4)'
      ).style.background = "initial";
    }
  }, [lightStep5]);
  useEffect(() => {
    if (
      lightStep6 &&
      document.querySelector('li[class="nav-item"]:nth-child(5)')
    ) {
      document.querySelector(
        'li[class="nav-item"]:nth-child(5)'
      ).style.background = "#ecccbe";
    } else {
      document.querySelector(
        'li[class="nav-item"]:nth-child(5)'
      ).style.background = "initial";
    }
  }, [lightStep6]);
  //
  const btnNextStatus = useSelector(
    (state) => state.styles.wizardBtnNextDisabled
  );
  const insert = useSelector((state) => state.checkOut.startInsert);
  const cardClickHandler = () => {
    setActiveColumn("col2");
    setActiveIcon3(true);
    setBackHome(false);
    setLightStep3(true);
    setHideNext(false);
    dispatch(stylesActions.disableBtnNext());
  };
  const backHomeHandler = () => {
    history.push("/home");
  };
  return (
    <section className="shopCardsSection !h-[100vh] !pt-7">
      <ToastContainer autoClose={3000} />
      <button
        className="absolute top-10 px-3 text-[#FF733A] font-bold flex items-center"
        onClick={backHomeHandler}
      >
        <img src={backArrow} alt="Back" className="!pr-2" />
        Home
      </button>
      <RemoveScrollBar />
      <Container className="wizardCont !p-0 lg:!w-[80%] !h-[85%]">
        <Col>
          <Tabs
            defaultActiveKey="step1"
            activeKey={activeKey}
            id="fill-tab-example"
            className="mb-3 bg-[#DEDDEB] !rounded-t-[20px]"
            fill
          >
            <Tab eventKey="step1" title={button1} tabClassName="step1" disabled>
              <Step1
                hanldeCustomizeButton={onCustomizeClick}
                activeKey={activeKey}
              />
            </Tab>
            <Tab
              eventKey="step2"
              title={button2}
              tabClassName={activeIcon2 ? "step2" : ""}
              disabled
            >
              <Step2
                activeCol={activeColumn}
                continueShoppingHandler={continueShoppingHandler}
                cardClickHandler={cardClickHandler}
              />
            </Tab>
            <Tab
              eventKey="step3"
              title={button3}
              tabClassName={lightStep3 ? "step3" : ""}
              disabled
            />
            <Tab
              eventKey="step4"
              title={button4}
              tabClassName={activeIcon4 ? "step4" : ""}
              disabled
            />
            <Tab
              eventKey="step5"
              title={button5}
              tabClassName={lightStep5 ? "step5" : ""}
              disabled
            />
            <Tab
              eventKey="step6"
              title={button6}
              tabClassName={lightStep6 ? "step6" : ""}
              disabled
            />
          </Tabs>
        </Col>
      </Container>
      <footer className="!fixed !bottom-0 !left-0 !right-0 !bg-[#fff] !flex !justify-between !py-6 !px-32 shadow-lg">
        <>
          {!backHome && (
            <Button
              className="!rounded-[60px] !py-2 !px-8 !flex !items-center !justify-between !bg-[#DEDDEB] !border-transparent !text-[#000]"
              onClick={activeBackKeyHandler}
            >
              <img src={backArrow} alt="Back" className="!pr-2" />
              Back
            </Button>
          )}
          {backHome && (
            <Link
              to="/home"
              className="!rounded-[60px] !py-2 !px-8 !flex !items-center !justify-between !bg-[#DEDDEB] !border-transparent !text-[#000]"
            >
              <img src={backArrow} alt="Back" className="!pr-2" />
              Return to home page
            </Link>
          )}
          {!hideNext && (
            <Button
              className={
                btnNextStatus
                  ? "!rounded-[60px] !py-2 !px-8 !flex !items-center !justify-between !bg-slate-500 !border-transparent !text-[#fff]"
                  : "!rounded-[60px] !py-2 !px-8 !flex !items-center !justify-between !bg-[#FF733A] !border-transparent !text-[#fff]"
              }
              onClick={activeNextKeyHandler}
              // disabled={btnNextStatus}
            >
              Next
              <img src={nextArrow} alt="next" className="!pl-2" />
            </Button>
          )}
          {hideNext && (
            <Button
              className="!rounded-[60px] !py-2 !px-8 !flex !items-center !justify-between !bg-[#FF733A] !border-transparent !text-[#fff] disabled:!cursor-progress"
              onClick={showCheckOutModalHandler}
              disabled={insert}
            >
              {insert ? "Loading..." : "Add to cart"}
              <img src={nextArrow} alt="next" className="!pl-2" />
            </Button>
          )}
        </>
      </footer>
    </section>
  );
};

export default WizardForm;
