import BestSelling from "../components/ui/home/BestSelling";
import BigOccasion from "../components/ui/home/BigOccasion";
import CustomerCarousel from "../components/ui/home/CustomerCarousel";
import FrequentlyAsked from "../components/ui/home/FrequentlyAsked";
import HowToChoose from "../components/ui/home/HowToChoose";
import IntroSwiper from "../components/ui/home/IntroSwiper";
import MetalCards from "../components/ui/home/MetalCards";
import QualityTrust from "../components/ui/home/QualityTrust";
import Section2 from "../components/ui/home/Section2";
import StandardCard from "../components/ui/home/StandardCards";
import TrustedFans from "../components/ui/home/TrustedFans";
import WhyChoose from "../components/ui/home/WhyChoose";
import HomeLoader from "../components/ui/Loader/HomeLoader";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import BigOccasionSmall from "../components/ui/home/BigOccasionSmall";
//
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import register from "../images/home/signup.svg";
//
const Home = (props) => {
  const history = useHistory();
  const isTabletOrMobile = useMediaQuery({ query: "(min-width: 768px)" });
  //
  const content1 = useSelector((state) => state.home.headerSlider);
  const content2 = useSelector((state) => state.home.ourCustomerSays);
  const content3 = useSelector((state) => state.home.howToChoose);
  const content4 = useSelector((state) => state.home.bestSelling);
  const content5 = useSelector((state) => state.home.cardType1);
  const content6 = useSelector((state) => state.home.cardType2);
  const content7 = useSelector((state) => state.home.qualityTrust);
  const content8 = useSelector((state) => state.home.whyChoose);
  const content9 = useSelector((state) => state.home.trustedFans);
  const content10 = useSelector((state) => state.home.bigOccasion);
  const content11 = useSelector((state) => state.home.customerCarousel);
  //Registraion modal
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("token") == null) {
      setTimeout(() => {
        setShowModal(true);
      }, 7000);
    } else {
      setShowModal(false);
    }
  }, []);
  return (
    <>
      {props.loading ? (
        <HomeLoader />
      ) : (
        <>
          <IntroSwiper />
          {content1.content && !content1.isLoading && <Section2 />}
          {content2.content && content2.contentBg && !content2.isLoading && (
            <HowToChoose />
          )}
          {content3.content && !content3.isLoading && <BestSelling />}
          {content4.content && !content4.isLoading && <MetalCards />}
          {content5.content && !content5.isLoading && <StandardCard />}
          {content6.content && !content6.isLoading && <QualityTrust />}
          {content7.content && !content7.isLoading && <WhyChoose />}
          {content8.content1 && content8.content2 && !content8.isLoading && (
            <TrustedFans />
          )}
          {content9.content && !content9.isLoading && isTabletOrMobile && (
            <BigOccasion />
          )}
          {content9.content && !content9.isLoading && !isTabletOrMobile && (
            <BigOccasionSmall />
          )}
          {content10.content && !content10.isLoading && <CustomerCarousel />}
          {content11.content && !content11.isLoading && <FrequentlyAsked />}
        </>
      )}
    </>
  );
};

export default Home;
