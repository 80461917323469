import PrivacyPolicyForm from "../components/ui/privacyPolicy/PrivacyPolicyForm";
const PrivacyPolicy = () => {
  return (
    <section className="AU1">
      <PrivacyPolicyForm />
    </section>
  );
};

export default PrivacyPolicy;
