import "./StandardCards.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Placeholder from "react-bootstrap/Placeholder";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazy-load";
import ProgressiveImage from "react-progressive-image";
import { useState, useEffect } from "react";
import Loader from "../Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import { getCardTypes } from "../../../store/data/Home-slice";
import { getStepOne } from "../../../store/data/Step1-slice";
const StandardCard = () => {
  const dispatch = useDispatch();
  const { isLoading, content } = useSelector((state) => state.home.cardType2);
  useEffect(() => {
    if (content === null) {
      dispatch(getCardTypes());
    } else {
      return;
    }
    dispatch(getStepOne());
  }, []);
  const [imageLoaded, setImageLoaded] = useState(false);
  const stepOneContentStyles = useSelector(
    (state) => state.stepOne.stepOne.stepOneContentStyles
  );
  return (
    <Container className="!relative mt-28 xsm:mt-28 sm:mt-32 md:mt-32 lg:mt-32">
      {/* <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 500"
        id="blobSvg"
        filter="blur(1.6px)"
        style={{ opacity: "1" }}
        transform="rotate(179)"
        className="!absolute w-[25%] top-32 right-0"
      >
        {" "}
        <defs>
          {" "}
          <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            {" "}
            <stop
              offset="0%"
              style={{ stopColor: "rgb(255, 152, 87)" }}
            ></stop>{" "}
            <stop
              offset="100%"
              style={{ stopColor: "rgb(255, 152, 87)" }}
            ></stop>{" "}
          </linearGradient>{" "}
        </defs>{" "}
        <path id="blob" fill="url(#gradient)" style={{opacity: "0.56"}}>
          <animate
            attributeName="d"
            dur="4.1s"
            repeatCount="indefinite"
            values="M428.88107,298.41731Q382.87896,346.83463,343.6411,381.94757Q304.40324,417.06052,249.73188,417.66327Q195.06052,418.26602,121.90324,407.33463Q48.74595,396.40324,42.90922,323.20162Q37.07249,250,50.04223,182.03624Q63.01197,114.07249,129.2076,98.49191Q195.40324,82.91133,251.0746,78.83673Q306.74595,74.76214,356.50598,105.64321Q406.26602,136.52427,440.5746,193.26214Q474.88318,250,428.88107,298.41731Z;M410.95278,296.31131Q377.83016,342.62262,346.59668,396.40799Q315.3632,450.19336,253.66275,438.70512Q191.9623,427.21687,143.72172,394.79004Q95.48115,362.3632,86.24057,306.1816Q77,250,74.51885,185.3632Q72.0377,120.72639,123.0377,68.91975Q174.0377,17.1131,239.88914,47.47163Q305.74057,77.83016,366.40332,99.75233Q427.06607,121.67451,435.57074,185.83725Q444.0754,250,410.95278,296.31131Z;M423.38828,307.00416Q406.39243,364.00831,353.55217,384.57202Q300.71191,405.13573,242.06787,430.50416Q183.42382,455.87258,116.5554,425.63666Q49.68697,395.40075,58.87581,322.70037Q68.06464,250,86.66067,197.31625Q105.2567,144.63251,148.86843,108.53648Q192.48015,72.44045,252.10019,66.13989Q311.72022,59.83933,378.54063,84.21929Q445.36105,108.59925,442.87258,179.29963Q440.38412,250,423.38828,307.00416Z;M428.88107,298.41731Q382.87896,346.83463,343.6411,381.94757Q304.40324,417.06052,249.73188,417.66327Q195.06052,418.26602,121.90324,407.33463Q48.74595,396.40324,42.90922,323.20162Q37.07249,250,50.04223,182.03624Q63.01197,114.07249,129.2076,98.49191Q195.40324,82.91133,251.0746,78.83673Q306.74595,74.76214,356.50598,105.64321Q406.26602,136.52427,440.5746,193.26214Q474.88318,250,428.88107,298.41731Z"
          ></animate>
        </path>
      </svg> */}
      {!isLoading && content && (
        <Row>
          <Col>
            {content && (
              <Row>
                <LazyLoad offset={500}>
                  <ProgressiveImage
                    src={`${content?.path}${content?.file_name}`}
                    placeholder={`${content?.path}thumbnails/${content?.file_name}`}
                  >
                    {(src) => (
                      <img
                        src={src}
                        alt="Plexi Cards"
                        loading="lazy"
                        onLoad={() => {
                          setImageLoaded(true);
                        }}
                      />
                    )}
                  </ProgressiveImage>
                </LazyLoad>
              </Row>
            )}
            <Row>
              {imageLoaded && (
                <Link
                  to={`/${content && content?.button_link}`}
                  className="text-[#FFFFFF] bg-[#FF733A] rounded-full no-underline font-bold
              xsm:!text-[7px] xsm:ml-8 text-[10px] md:!py-3 md:!px-12 !py-1
              sm:text-[15px] md:!ml-20 sm:!mt-10 uppercase !w-auto"
                >
                  {content && content?.button_text}
                </Link>
              )}
            </Row>
          </Col>
          {imageLoaded && (
            <Col>
              <header className="text-left">
                {!isLoading && stepOneContentStyles && (
                  <h1 className="uppercase text-[#000000] font-black lg:text-7xl md:text-5xl sm:text-4xl text-3xl xsm:text-2xl">
                    {stepOneContentStyles[1]?.name}{" "}
                    <span className="block xsm:pl-16 pl-20">cards</span>
                  </h1>
                )}
                {isLoading && !stepOneContentStyles && (
                  <Placeholder as="h1" animation="glow">
                    <Placeholder xs={6} style={{ backgroundColor: "red" }} />
                  </Placeholder>
                )}
              </header>
              <div className="flex flex-col text-[#A04723]">
                <div
                  className="txts1 sm:translate-y-11 sm:-translate-x-5
            md:translate-y-20 md:-translate-x-5"
                >
                  <h1 className="xsm:text-[12px] sm:text-[20px] text-[16px] md:text-[25px] font-bold lg:text-[35px]">
                    {content && content?.option_one}
                  </h1>
                </div>
                <div
                  className="txts2 sm:translate-y-12 sm:-translate-x-6
            md:translate-y-24 md:-translate-x-7"
                >
                  <h1 className="xsm:text-[12px] sm:text-[20px] text-[16px] md:text-[25px] font-bold lg:text-[35px]">
                    {content && content?.option_two}
                  </h1>
                </div>
                <div
                  className="txts3 sm:translate-y-14 sm:-translate-x-10
            md:translate-y-28 md:-translate-x-12"
                >
                  <h1 className="xsm:text-[12px] sm:text-[20px] text-[16px] md:text-[23px] font-bold lg:text-[35px]">
                    {content && content?.option_three}
                  </h1>
                </div>
                <div
                  className="txts4 sm:translate-y-14 sm:-translate-x-16
            md:translate-y-28 md:-translate-x-24"
                >
                  <h1 className="xsm:text-[12px] sm:text-[20px] text-[16px] md:text-[25px] font-bold lg:text-[35px]">
                    {content && content?.option_four}
                  </h1>
                </div>
              </div>
            </Col>
          )}
        </Row>
      )}
      {isLoading && <Loader />}
    </Container>
  );
};

export default StandardCard;
