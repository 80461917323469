import "./MetalCards.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Placeholder from "react-bootstrap/Placeholder";
import { Link } from "react-router-dom";
import cube1 from "../../../images/home/BestSelling/cube1.png";
import cube2 from "../../../images/home/BestSelling/cube2.png";
import LazyLoad from "react-lazy-load";
import ProgressiveImage from "react-progressive-image";
import { useState, useEffect } from "react";
import Loader from "../Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import { getCardTypes } from "../../../store/data/Home-slice";
import { getStepOne } from "../../../store/data/Step1-slice";
const MetalCards = () => {
  const dispatch = useDispatch();
  const { isLoading, content } = useSelector((state) => state.home.cardType1);
  useEffect(() => {
    if (content === null) {
      dispatch(getCardTypes());
    } else {
      return;
    }
    dispatch(getStepOne());
  }, []);
  const [imageLoaded, setImageLoaded] = useState(false);
  const stepOneContentStyles = useSelector(
    (state) => state.stepOne.stepOne.stepOneContentStyles
  );
  return (
    <div className="mcards">
      <Container className="!relative !mt-28 xsm:!mt-14 sm:!mt-32 md:!mt-32 lg:!mt-32">
        <Col>
          <Row>
            <main className="text-center">
              <header className="flex flex-row items-center justify-center">
                <img src={cube1} alt="Cube 1" className="p-1 xsm:h-8" />
                <h1 className="text-[#000000] font-bold xsm:text-[20px] !pt-2 md:!pt-0">
                  {!isLoading && content && "Unique Cards Styles"}
                  {isLoading && (
                    <Placeholder animation="glow">
                      <Placeholder xs={6} style={{ backgroundColor: "red" }} />
                    </Placeholder>
                  )}
                </h1>
                <img src={cube2} alt="Cube 2" className="p-1 xsm:h-8" />
              </header>
            </main>
          </Row>
          {!isLoading && content && (
            <>
              <Row className="!mt-10">
                <header className="lg:ml-16 md:ml-16 sm:ml-16 ml-16 xsm:ml-5 lg:mb-[-10rem] xsm:mb-[-3rem] sm:mb-[-3rem] md:mb-[-5rem] mb-[-4rem]">
                  {imageLoaded && stepOneContentStyles && (
                    <h1 className="uppercase text-[#000000] font-black lg:text-7xl md:text-5xl sm:text-4xl text-3xl xsm:text-2xl">
                      {stepOneContentStyles[0]?.name}
                      <span className="block pl-12">cards</span>
                    </h1>
                  )}
                </header>
              </Row>
              <Row>
                {imageLoaded && (
                  <Col className="flex flex-col text-[#A04723] items-end justify-center">
                    <div
                      className="txt1 md:translate-y-[4.9rem] md:translate-x-[1rem]
                      sm:translate-y-[4rem] sm:translate-x-[1rem]"
                    >
                      <h1 className="xsm:text-[12px] sm:text-[20px] text-[16px] md:text-[24px] font-bold lg:text-[35px]">
                        {content && content?.option_one}
                      </h1>
                    </div>
                    <div
                      className="txt2 md:translate-y-[5.9rem] md:translate-x-[2rem]
                      sm:translate-y-[4.4rem] sm:translate-x-[1.5rem]"
                    >
                      <h1 className="xsm:text-[12px] sm:text-[20px] text-[16px] md:text-[24px] font-bold lg:text-[35px]">
                        {content && content?.option_two}
                      </h1>
                    </div>
                    <div
                      className="txt3 md:translate-y-[6.6rem] md:translate-x-[3rem]
                      sm:translate-y-[4.5rem] sm:translate-x-[2.5rem]"
                    >
                      <h1 className="xsm:text-[12px] sm:text-[20px] text-[16px] md:text-[23px] font-bold lg:text-[35px]">
                        {content && content?.option_three}
                      </h1>
                    </div>
                    <div
                      className="txt4 md:translate-y-[6.6rem] md:translate-x-[5.6rem]
                      sm:translate-y-[4.5rem] sm:translate-x-[4rem]"
                    >
                      <h1 className="xsm:text-[12px] sm:text-[20px] text-[16px] md:text-[24px] font-bold lg:text-[35px]">
                        {content && content?.option_four}
                      </h1>
                    </div>
                  </Col>
                )}
                <Col>
                  <LazyLoad offset={500}>
                    <ProgressiveImage
                      src={`${content?.path}${content?.file_name}`}
                      placeholder={`${content?.path}thumbnails/${content?.file_name}`}
                    >
                      {(src) => (
                        <img
                          src={src}
                          alt="Wood Cards"
                          loading="lazy"
                          onLoad={() => {
                            setImageLoaded(true);
                          }}
                        />
                      )}
                    </ProgressiveImage>
                  </LazyLoad>
                </Col>
              </Row>
              {imageLoaded && (
                <Link
                  to={`/${content?.button_link}`}
                  className="text-[#FFFFFF] float-right bg-[#FF733A] rounded-full no-underline font-bold
              md:!py-3 md:!px-12 !py-1 !px-2 lg:mr-44 xsm:!text-[7px] xsm:mr-10 xsm:mt-5
              text-[10px] mr-10 mt-7 sm:text-[15px] md:mr-20 uppercase"
                >
                  {content && content?.button_text}
                </Link>
              )}
            </>
          )}
        </Col>
        {isLoading && <Loader />}
      </Container>
    </div>
  );
};

export default MetalCards;
