import "./CartItem.css";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import basket from "../../../images/cart/deleteOrder.svg";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import localforage from "localforage";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
const CartItem = (props) => {
  const url = useSelector((state) => state.api.domain);
  const [usersCardId, setUsersCardId] = useState(null);
  const [items, setItems] = useState([]);
  const [loggedIn, setIsloggedIn] = useState(false);
  useEffect(() => {
    localforage.getItem("user").then((user) => {
      if (user !== null) {
        setIsloggedIn(true);
      } else {
        setIsloggedIn(false);
      }
    });
  }, []);
  useEffect(() => {
    setItems(props.cardsItems);
  }, [props.cardsItems]);
  const itemDeleteHandler = (id) => {
    props.deleteItem(id);
  };
  const itemChangeQuantityHandler = (id, type) => {
    setUsersCardId(id);
    let formData = new FormData();
    formData.append("user_card_id", id);
    formData.append("is_logged_in", loggedIn);
    formData.append("is_increment", type);
    formData.append("token", localStorage.getItem("token"));
    formData.append("session_id", localStorage.getItem("session_id"));
    const url1 = `${url}/wizard/change-card-quantity`;
    axios
      .post(url1, formData)
      .then((res) => {
        if (res.data.succeeded) {
          const updatedItems = items.map((item) => {
            if (item.id === id) {
              return { ...item, quantity: res.data.message };
            }
            return item;
          });
          setItems(updatedItems);
          setUsersCardId(null);
        }
      })
      .catch((err) => console.log(err));
  };
  function calculateTotal(items) {
    if (items.length === 0) return 0;
    let total_card = 0;
    items.forEach((item) => {
      var card_total =
        parseFloat(item.price) +
        item.extras.reduce((acx, extra) => {
          return acx + parseFloat(extra.extra_price) * parseInt(extra.quantity);
        }, 0);
      total_card += card_total * parseInt(item.quantity);
    });
    return total_card;
  }
  useEffect(() => {
    props.setSubTotal(calculateTotal(items));
  }, [items]);
  function roundNumber(num) {
    if (num % 1 !== 0 && num.toString().split(".")[1].length >= 3) {
      return num.toFixed(2);
    } else {
      return num;
    }
  }
  return (
    <div>
      {items?.map((item) => (
        <Card
          className="single-cart !px-0 md:!flex-row xsm:justify-evenly md:!justify-between !rounded-[30px] !border-0 !my-5"
          key={item.id}
        >
          <Col lg={2} md={2}>
            <Card.Img
              src={item.main_image}
              alt={item.card_name}
              className="!mt-[20%]"
            />
          </Col>
          <Card.Body>
            <Row>
              <div className="!flex">
                <Card.Title className="!text-[#000000] !font-bold !whitespace-nowrap">
                  {item.card_name}
                </Card.Title>
                <Card.Title className="!text-[#000000] !font-bold !whitespace-nowrap !ml-7">
                  ${roundNumber(item.price)}
                </Card.Title>
              </div>
              <div>
                <ul className="!list-goup list1">
                  <li>{item.style}</li>
                  <li>{item.size}</li>
                </ul>
              </div>
            </Row>
            {item.extras.length > 0 && (
              <Row>
                <p className="!pb-0 !mb-0">Extras : </p>
                <div className="!flex !flex-col">
                  <Table size="sm" className="!w-[60%]">
                    <tbody>
                      {item.extras.map((extra, index) => (
                        <tr key={index}>
                          <th className="!whitespace-nowrap">
                            {extra.extra_name} x {extra.quantity}
                          </th>
                          <th>${extra.extra_price}</th>
                          <th>${extra.extra_price * extra.quantity}</th>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Row>
            )}
            <Row>
              <p className="!font-bold !text-lg">
                Package price: $
                {roundNumber(
                  parseFloat(item.price) +
                    item.extras.reduce((acx, extra) => {
                      return (
                        acx +
                        parseFloat(extra.extra_price) * parseInt(extra.quantity)
                      );
                    }, 0)
                )}
              </p>
            </Row>
          </Card.Body>
          <Card.Body className="!flex !items-center !justify-center">
            <Col className="!flex !items-center">
              <div className="!max-w-[40px] !min-w-[40px]">
                <h3 className="font-semibold text-[#000000]">
                  {item.quantity}
                </h3>
              </div>
              <div className="!flex !flex-col">
                <button
                  className="transition-all-03 !px-[10px] !py-[3px] !rounded-[50%] !bg-[#FF733A] !border-0 m-2 text-[#FFFFFF]"
                  onClick={() => {
                    itemChangeQuantityHandler(item.id, "increment");
                  }}
                  disabled={item.id === usersCardId}
                >
                  +
                </button>
                <Button
                  className="transition-all-03 !px-[10px] !py-[3px] !rounded-[50%] !bg-[#FF733A] !border-0 m-2 text-[#FFFFFF]"
                  onClick={() => {
                    itemChangeQuantityHandler(item.id, "decrement");
                  }}
                  disabled={item.id === usersCardId || item.quantity == 1}
                >
                  -
                </Button>
              </div>
            </Col>
            <Col md={8}>
              <p className="!font-bold !text-lg !whitespace-nowrap !max-w-[212px] !min-w-[212px]">
                Total Card price: $
                {item.quantity *
                  roundNumber(
                    parseFloat(item.price) +
                      item.extras.reduce((acx, extra) => {
                        return (
                          acx +
                          parseFloat(extra.extra_price) *
                            parseInt(extra.quantity)
                        );
                      }, 0)
                  )}
              </p>
            </Col>
          </Card.Body>
          <Button
            className="!bg-[#D30000] border-0 !rounded-b-[30px] md:!rounded-r-[30px] md:!rounded-l-none hover:!bg-[#B00000]"
            onClick={() => {
              itemDeleteHandler(item.id);
            }}
          >
            <img src={basket} alt="Delete Order" className="!m-auto" />
          </Button>
        </Card>
      ))}
    </div>
  );
};

export default CartItem;
