import loader from "../../../images/home/Loader/loader.gif";
import classes from "./HomeLoader.module.css";
const HomeLoader = () => {
  return (
    <div className={classes.loaderContainer}>
      <div className={classes.loaderOverlay}>
        <img src={loader} alt="Loading..."/>
      </div>
    </div>
  );
};

export default HomeLoader;
