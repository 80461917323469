import "./FinalVersionForm.css";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import { fabric } from "fabric";
import Loader from "../Loader/Loader";
import test from "../../../images/yas.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const FinalVersionForm = () => {
  const { cardId, orderId } = useParams();
  const url = useSelector((state) => state.api.domain);

  const [orderDetails, setOrderDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [exported, setExported] = useState(false);
  const [isLoadingP, setIsLoadingP] = useState(true);

  const [fontColor, setFontColor] = useState("#000");
  const [nameFont, setNameFont] = useState("");
  const [nameTop, setNameTop] = useState("");
  const [positionLeft, setPositionLeft] = useState();

  const [trait1V, setTrait1V] = useState("");
  const [trait2V, setTrait2V] = useState("");
  const [trait3V, setTrait3V] = useState("");
  const [trait4V, setTrait4V] = useState("");
  const [trait5V, setTrait5V] = useState("");
  const [trait6V, setTrait6V] = useState("");

  const notify = (msg) => toast(msg);

  //   function applyFont(fontFileName) {
  //     const style = document.createElement("style");
  //     style.type = "text/css";
  //     style.innerHTML = `
  //   @font-face {
  //     font-family: 'Yellow Flower';
  //     src: url(${fontFileName});
  //   }
  // `;
  //     document.head.appendChild(style);
  //     setFontFamily("Yellow Flower");
  //   }

  const [chemistryConverted, setChemistryConverted] = useState(null);

  function convertToPath(imagePath) {
    fetch(imagePath)
      .then((response) => response.text())
      .then((svgText) => {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgText, "image/svg+xml");
        const paths = svgDoc.querySelectorAll("path");
        const pathData = Array.from(paths).map((path) =>
          path.getAttribute("d")
        );
        setChemistryConverted(pathData.join(" "));
      })
      .catch((error) => {
        console.error("Error loading SVG file:", error);
      });
  }

  useEffect(() => {
    setIsLoading(true);
    getOrderDetails();
  }, []);

  const [factorHeight, setFactorHeight] = useState(0);
  const [factorWidth, setfactorWidth] = useState(0);

  const [factorHeightScale, setFactorHeightScale] = useState(0);
  const [factorWidthScale, setFactorWidthScale] = useState(0);

  const largeCanvasEl = useRef(null);

  useEffect(() => {
    setFactorWidthScale(factorWidth / 350);
    setFactorHeightScale(factorHeight / 490);
  }, [factorHeight, factorWidth]);

  const getMeta = async (url, cb) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };

  function getOrderDetails() {
    let formData = new FormData();
    formData.append("card_id", cardId);
    formData.append("order_id", orderId);
    const url1 = `${url}/wizard/get-order-details`;
    axios
      .post(url1, formData)
      .then((res) => {
        if (res.data.succeeded) {
          setOrderDetails(res.data);
          // if (res.data.card_image.font) {
          //   applyFont(res.data.card_image.font);
          // } else {
          //   setFontFamily("Inter");
          // }
          if (res.data.order_details.client_name) {
            if (res.data.order_details.client_name.length <= 24) {
              setNameFont(29);
              setNameTop(262);
            } else if (
              res.data.order_details.client_name.length > 24 &&
              res.data.order_details.client_name.length < 30
            ) {
              setNameFont(22);
              setNameTop(272);
            } else if (res.data.order_details.client_name.length > 30) {
              setNameFont(17);
              setNameTop(276);
            }
          }
          if (res.data.order_details.position) {
            if (res.data.order_details.position.length <= 1) {
              setPositionLeft(97);
            } else if (res.data.order_details.position.length === 2) {
              setPositionLeft(88);
            } else if (res.data.order_details.position.length === 3) {
              setPositionLeft(82);
            } else if (res.data.order_details.position.length > 3) {
              setPositionLeft(82);
            }
          }
          setFontColor(res.data.card_image.font_color);
          setTrait1V(
            res.data.order_details?.attribute_position
              .split("},{")[0]
              .split(",")[1]
              .split(":")[1]
              .replace(/"/g, "")
          );
          setTrait2V(
            res.data.order_details?.attribute_position
              .split("},{")[1]
              .split(",")[1]
              .split(":")[1]
              .replace(/"/g, "")
          );
          setTrait3V(
            res.data.order_details?.attribute_position
              .split("},{")[2]
              .split(",")[1]
              .split(":")[1]
              .replace(/"/g, "")
          );
          setTrait4V(
            res.data.order_details?.attribute_position
              .split("},{")[3]
              .split(",")[1]
              .split(":")[1]
              .replace(/"/g, "")
          );
          setTrait5V(
            res.data.order_details?.attribute_position
              .split("},{")[4]
              .split(",")[1]
              .split(":")[1]
              .replace(/"/g, "")
          );
          setTrait6V(
            res.data.order_details?.attribute_position
              .split("},{")[5]
              .split(",")[1]
              .split(":")[1]
              .split("}")[0]
              .replace(/"/g, "")
          );
          if (res.data.order_details.chemistry_style) {
            convertToPath(res.data.order_details.chemistry_style);
          }
          getMeta(res.data.card_image.file_path, (err, img) => {
            setfactorWidth(img.naturalWidth);
            setFactorHeight(img.naturalHeight);
          });
        } else {
          setOrderDetails(null);
        }
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }

  const downloadImage = (image) => {
    fetch(image)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "image.png");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };

  const canvasRef = useRef();

  const canvasEl = useRef(null);

  useEffect(() => {
    if (!isLoading && orderDetails && chemistryConverted) {
      var canvas = new fabric.Canvas(canvasRef.current, {
        width: 350,
        height: 490,
        preserveObjectStacking: true,
      });

      fabric.Image.fromURL(
        orderDetails?.card_image?.file_path,
        function (img) {
          // window.location.reload();
          canvas.width = img.width * 3.125; // 3.125 = 300 / 96 = img dpi / canvas dpi
          canvas.height = img.height * 3.125; // 3.125 = 300 / 96 = img dpi / canvas dpi

          img.scaleX = 350 / img.width;
          img.scaleY = 490 / img.height;
          img.originX = "left";
          img.originY = "top";
          img.selectable = false;
          img.evented = false;
          canvas.add(img);
          canvas.sendToBack(img);
          img.moveTo(0);
        },
        { crossOrigin: "anonymous" }
      );

      fabric.Image.fromURL(
        orderDetails?.order_details?.cropped_image,
        function (img) {
          img.top = 34;
          img.left = 131;
          img.scaleX = canvas.width / (img.width * 2.03);
          img.scaleY = canvas.height / (img.height * 2.23);
          img.selectable = false;
          img.evented = false;
          canvas.add(img);
        },
        { crossOrigin: "anonymous" }
      );
      var playerPosition = new fabric.Textbox("", {
        fontFamily: "den-pro",
        originX: "left",
        originY: "top",
        editable: false,
        selectable: false,
        evented: true,
      });
      playerPosition.set({
        fill: fontColor,
        text: orderDetails?.order_details?.position.toUpperCase(),
        fontSize: 28,
        left: positionLeft,
        top: 123,
      });

      canvas.add(playerPosition).renderAll();

      var playerName = new fabric.Textbox(
        String(orderDetails?.order_details?.client_name.toUpperCase()),
        {
          stroke: fontColor,
          strokeWidth: 0.1,
          fill: fontColor,
          fontSize: nameFont,
          fontFamily: "den-pro",
          fontWeight: 600,
          textAlign: "center",
          left: -6.4,
          top: nameTop,
          width: 365,
          selectable: false,
          evented: false,
        }
      );
      canvas.add(playerName).renderAll();

      var playerTrait1 = new fabric.Textbox(
        String(
          orderDetails?.order_details?.attribute_position
            .split("},{")[0]
            .split(":")[1]
            .split(",")[0]
            .replace(/"/g, "")
        ),
        {
          originX: "left",
          originY: "top",
          editable: false,
          selectable: false,
          evented: false,
          fontFamily: "den-pro",
          fill: fontColor,
          fontSize: 26,
          left: 115,
          top: 305,
        }
      );
      canvas.add(playerTrait1);

      var playerTrait2 = new fabric.Textbox(
        String(
          orderDetails?.order_details?.attribute_position
            .split("},{")[1]
            .split(":")[1]
            .split(",")[0]
            .replace(/"/g, "")
        ),
        {
          originX: "left",
          originY: "top",
          editable: false,
          selectable: false,
          evented: false,
          fontFamily: "den-pro",
          fill: fontColor,
          fontSize: 26,
          left: 115,
          top: 340,
        }
      );
      canvas.add(playerTrait2);

      var playerTrait3 = new fabric.Textbox(
        String(
          orderDetails?.order_details?.attribute_position
            .split("},{")[2]
            .split(":")[1]
            .split(",")[0]
            .replace(/"/g, "")
        ),
        {
          originX: "left",
          originY: "top",
          editable: false,
          selectable: false,
          evented: false,
          fontFamily: "den-pro",
          fill: fontColor,
          fontSize: 26,
          left: 115,
          top: 375,
        }
      );
      canvas.add(playerTrait3);

      var playerTrait4 = new fabric.Textbox(
        String(
          orderDetails?.order_details?.attribute_position
            .split("},{")[3]
            .split(":")[1]
            .split(",")[0]
            .replace(/"/g, "")
        ),
        {
          originX: "left",
          originY: "top",
          editable: false,
          selectable: false,
          evented: false,
          fontFamily: "den-pro",
          fill: fontColor,
          fontSize: 26,
          left: 225,
          top: 305,
        }
      );
      canvas.add(playerTrait4);

      var playerTrait5 = new fabric.Textbox(
        String(
          orderDetails?.order_details?.attribute_position
            .split("},{")[4]
            .split(":")[1]
            .split(",")[0]
            .replace(/"/g, "")
        ),
        {
          originX: "left",
          originY: "top",
          editable: false,
          selectable: false,
          evented: false,
          fontFamily: "den-pro",
          fill: fontColor,
          fontSize: 26,
          left: 225,
          top: 340,
        }
      );
      canvas.add(playerTrait5);

      var playerTrait6 = new fabric.Textbox(
        String(
          orderDetails?.order_details?.attribute_position
            .split("},{")[5]
            .split(":")[1]
            .split(",")[0]
            .replace(/"/g, "")
        ),
        {
          originX: "left",
          originY: "top",
          editable: false,
          selectable: false,
          evented: false,
          fontFamily: "den-pro",
          fill: fontColor,
          fontSize: 26,
          left: 225,
          top: 375,
        }
      );
      canvas.add(playerTrait6);

      var playerTrait1V = new fabric.Textbox(
        String(
          orderDetails?.order_details?.attribute_position
            .split("},{")[0]
            .split(",")[1]
            .split(":")[1]
            .replace(/"/g, "")
        ),
        {
          originX: "left",
          originY: "top",
          editable: false,
          selectable: false,
          evented: false,
          fontFamily: "den-pro",
          fill: fontColor,
          fontSize: 26,
          fontWeight: 600,
          left: 85,
          top: 305,
          width: 20,
          textAlign: "center",
        }
      );
      canvas.add(playerTrait1V);

      var playerTrait2V = new fabric.Textbox(
        String(
          orderDetails?.order_details?.attribute_position
            .split("},{")[1]
            .split(",")[1]
            .split(":")[1]
            .replace(/"/g, "")
        ),
        {
          originX: "left",
          originY: "top",
          editable: false,
          selectable: false,
          evented: false,
          fontFamily: "den-pro",
          fill: fontColor,
          fontSize: 26,
          fontWeight: 600,
          left: 85,
          top: 340,
          width: 20,
          textAlign: "center",
        }
      );
      canvas.add(playerTrait2V);

      var playerTrait3V = new fabric.Textbox(
        String(
          orderDetails?.order_details?.attribute_position
            .split("},{")[2]
            .split(",")[1]
            .split(":")[1]
            .replace(/"/g, "")
        ),
        {
          originX: "left",
          originY: "top",
          editable: false,
          selectable: false,
          evented: false,
          fontFamily: "den-pro",
          fill: fontColor,
          fontSize: 26,
          fontWeight: 600,
          left: 85,
          top: 375,
          width: 20,
          textAlign: "center",
        }
      );
      canvas.add(playerTrait3V);

      var playerTrait4V = new fabric.Textbox(
        String(
          orderDetails?.order_details?.attribute_position
            .split("},{")[3]
            .split(",")[1]
            .split(":")[1]
            .replace(/"/g, "")
        ),
        {
          originX: "left",
          originY: "top",
          editable: false,
          selectable: false,
          evented: false,
          fontFamily: "den-pro",
          fill: fontColor,
          fontSize: 26,
          fontWeight: 600,
          left: 195,
          top: 305,
          width: 20,
          textAlign: "center",
        }
      );
      canvas.add(playerTrait4V);

      var playerTrait5V = new fabric.Textbox(
        String(
          orderDetails?.order_details?.attribute_position
            .split("},{")[4]
            .split(",")[1]
            .split(":")[1]
            .replace(/"/g, "")
        ),
        {
          originX: "left",
          originY: "top",
          editable: false,
          selectable: false,
          evented: false,
          fontFamily: "den-pro",
          fill: fontColor,
          fontSize: 26,
          fontWeight: 600,
          left: 195,
          top: 340,
          width: 20,
          textAlign: "center",
        }
      );
      canvas.add(playerTrait5V);

      var playerTrait6V = new fabric.Textbox(
        String(
          orderDetails?.order_details?.attribute_position
            .split("},{")[5]
            .split(",")[1]
            .split(":")[1]
            .split("}")[0]
            .replace(/"/g, "")
        ),
        {
          originX: "left",
          originY: "top",
          editable: false,
          selectable: false,
          evented: false,
          fontFamily: "den-pro",
          fill: fontColor,
          fontSize: 26,
          fontWeight: 600,
          left: 195,
          top: 375,
          width: 20,
          textAlign: "center",
        }
      );
      canvas.add(playerTrait6V);

      var overallRating = new fabric.Textbox(
        String(
          Math.round(
            +trait1V + +trait2V + +trait3V + +trait4V + +trait5V + +trait6V
          ) / 6
        ).split(".")[0],
        {
          fontFamily: "den-pro",
          originX: "left",
          originY: "top",
          editable: false,
          selectable: false,
          evented: false,
          fontSize: 49,
          left:
            String(
              Math.round(
                +trait1V + +trait2V + +trait3V + +trait4V + +trait5V + +trait6V
              ) / 6
            ).split(".")[0] === "87"
              ? 84
              : 81,
          top: 70,
          fill: fontColor,
          textAlign: "center",
        }
      );

      canvas.add(overallRating);

      fabric.Image.fromURL(
        orderDetails?.order_details?.country,
        function (img) {
          var scale = Math.min(35, 32) / Math.max(img.width, img.height);
          img.scaleX = scale * 1.4;
          img.scaleY = scale * 1.4;
          img.originX = "left";
          img.originY = "top";
          img.top = 157.5;
          img.left = 79;
          img.selectable = false;
          img.evented = false;
          canvas.add(img);
        },
        { crossOrigin: "anonymous" }
      );

      fabric.Image.fromURL(
        orderDetails?.order_details?.club,
        function (img) {
          var scale = Math.min(35, 32) / Math.max(img.width, img.height);
          img.scaleX = scale * 1.4;
          img.scaleY = scale * 1.4;
          img.originX = "left";
          img.originY = "top";
          img.top = 200;
          img.left = 79;
          img.selectable = false;
          img.evented = false;
          canvas.add(img);
        },
        { crossOrigin: "anonymous" }
      );

      var path = new fabric.Path(chemistryConverted, {
        selectable: false,
        editable: false,
        originX: "left",
        originY: "top",
        fill: fontColor,
        left: 5,
        top: 200,
        scaleX: -0.025,
        scaleY: 0.025,
        angle: 180,
        // rotate
      });

      if (path.height > 800) {
        path.set({
          top: 438,
        });
      } else if (path.height <= 800) {
        path.set({
          top: 430,
        });
      }
      canvas.add(path).centerObjectH(path);
    }

    canvasEl.current = canvas;
  }, [isLoading, orderDetails, "den-pro", chemistryConverted]);

  useEffect(() => {
    if (
      !isLoading &&
      orderDetails &&
      chemistryConverted &&
      factorHeightScale &&
      factorWidthScale
    ) {
      const hiddenCanvas = document.createElement("canvas");
      hiddenCanvas.width = factorWidth;
      hiddenCanvas.height = factorHeight;
      var canvas2 = new fabric.Canvas(hiddenCanvas, {
        width: factorWidth,
        height: factorHeight,
        preserveObjectStacking: true,
      });

      fabric.Image.fromURL(
        orderDetails?.card_image?.file_path,
        function (img) {
          img.scaleX = 1;
          img.scaleY = 1;
          img.originX = "left";
          img.originY = "top";
          img.selectable = false;
          img.evented = false;
          canvas2.add(img);
          canvas2.sendToBack(img);
          img.moveTo(0);
        },
        { crossOrigin: "anonymous" }
      );

      fabric.Image.fromURL(
        orderDetails?.order_details?.cropped_image,
        function (img) {
          img.top = 34 * factorHeightScale;
          img.left = 131 * factorWidthScale;
          img.scaleX = canvas2.width / (img.width * 2.03);
          img.scaleY = canvas2.height / (img.height * 2.23);
          img.selectable = false;
          img.evented = false;
          canvas2.add(img);
        },
        { crossOrigin: "anonymous" }
      );

      var playerName2 = new fabric.Textbox(
        String(orderDetails?.order_details?.client_name.toUpperCase()),
        {
          fontFamily: "den-pro",
          stroke: fontColor,
          strokeWidth: 0.1,
          fill: fontColor,
          fontSize: nameFont * factorWidthScale,
          fontWeight: 600,
          top: nameTop * factorHeightScale,
          textAlign: "center",
          left: 0,
          width: 2576,
          selectable: false,
          evented: false,
        }
      );
      canvas2.add(playerName2);

      var playerPosition = new fabric.Textbox("", {
        fontFamily: "den-pro",
        originX: "left",
        originY: "top",
        editable: false,
        selectable: false,
        evented: true,
      });
      playerPosition.set({
        fill: fontColor,
        text: orderDetails?.order_details?.position.toUpperCase(),
        fontSize: 28 * factorWidthScale,
        left: positionLeft * factorWidthScale,
        top: 123 * factorHeightScale,
        fontFamily: "den-pro",
      });

      canvas2.add(playerPosition).renderAll();

      var playerTrait1 = new fabric.Textbox(
        String(
          orderDetails?.order_details?.attribute_position
            .split("},{")[0]
            .split(":")[1]
            .split(",")[0]
            .replace(/"/g, "")
        ),
        {
          originX: "left",
          originY: "top",
          editable: false,
          selectable: false,
          evented: false,
          fontFamily: "den-pro",
          fill: fontColor,
          fontSize: 26 * factorWidthScale,
          left: 115 * factorWidthScale,
          top: 305 * factorHeightScale,
        }
      );
      canvas2.add(playerTrait1);

      var playerTrait2 = new fabric.Textbox(
        String(
          orderDetails?.order_details?.attribute_position
            .split("},{")[1]
            .split(":")[1]
            .split(",")[0]
            .replace(/"/g, "")
        ),
        {
          originX: "left",
          originY: "top",
          editable: false,
          selectable: false,
          evented: false,
          fontFamily: "den-pro",
          fill: fontColor,
          fontSize: 26 * factorWidthScale,
          left: 115 * factorWidthScale,
          top: 340 * factorHeightScale,
        }
      );
      canvas2.add(playerTrait2);

      var playerTrait3 = new fabric.Textbox(
        String(
          orderDetails?.order_details?.attribute_position
            .split("},{")[2]
            .split(":")[1]
            .split(",")[0]
            .replace(/"/g, "")
        ),
        {
          originX: "left",
          originY: "top",
          editable: false,
          selectable: false,
          evented: false,
          fontFamily: "den-pro",
          fill: fontColor,
          fontSize: 26 * factorWidthScale,
          left: 115 * factorWidthScale,
          top: 375 * factorHeightScale,
        }
      );
      canvas2.add(playerTrait3);

      var playerTrait4 = new fabric.Textbox(
        String(
          orderDetails?.order_details?.attribute_position
            .split("},{")[3]
            .split(":")[1]
            .split(",")[0]
            .replace(/"/g, "")
        ),
        {
          originX: "left",
          originY: "top",
          editable: false,
          selectable: false,
          evented: false,
          fontFamily: "den-pro",
          fill: fontColor,
          fontSize: 26 * factorWidthScale,
          left: 225 * factorWidthScale,
          top: 305 * factorHeightScale,
        }
      );
      canvas2.add(playerTrait4);

      var playerTrait5 = new fabric.Textbox(
        String(
          orderDetails?.order_details?.attribute_position
            .split("},{")[4]
            .split(":")[1]
            .split(",")[0]
            .replace(/"/g, "")
        ),
        {
          originX: "left",
          originY: "top",
          editable: false,
          selectable: false,
          evented: false,
          fontFamily: "den-pro",
          fill: fontColor,
          fontSize: 26 * factorWidthScale,
          left: 225 * factorWidthScale,
          top: 340 * factorHeightScale,
        }
      );
      canvas2.add(playerTrait5);

      var playerTrait6 = new fabric.Textbox(
        String(
          orderDetails?.order_details?.attribute_position
            .split("},{")[5]
            .split(":")[1]
            .split(",")[0]
            .replace(/"/g, "")
        ),
        {
          originX: "left",
          originY: "top",
          editable: false,
          selectable: false,
          evented: false,
          fontFamily: "den-pro",
          fill: fontColor,
          fontSize: 26 * factorWidthScale,
          left: 225 * factorWidthScale,
          top: 375 * factorHeightScale,
        }
      );
      canvas2.add(playerTrait6);

      var playerTrait1V = new fabric.Textbox(
        String(
          orderDetails?.order_details?.attribute_position
            .split("},{")[0]
            .split(",")[1]
            .split(":")[1]
            .replace(/"/g, "")
        ),
        {
          originX: "left",
          originY: "top",
          editable: false,
          selectable: false,
          evented: false,
          fontFamily: "den-pro",
          fill: fontColor,
          fontSize: 26 * factorWidthScale,
          fontWeight: 600,
          left: 85 * factorWidthScale,
          top: 305 * factorHeightScale,
          width: 20,
          textAlign: "center",
        }
      );
      canvas2.add(playerTrait1V);

      var playerTrait2V = new fabric.Textbox(
        String(
          orderDetails?.order_details?.attribute_position
            .split("},{")[1]
            .split(",")[1]
            .split(":")[1]
            .replace(/"/g, "")
        ),
        {
          originX: "left",
          originY: "top",
          editable: false,
          selectable: false,
          evented: false,
          fontFamily: "den-pro",
          fill: fontColor,
          fontSize: 26 * factorWidthScale,
          fontWeight: 600,
          left: 85 * factorWidthScale,
          top: 340 * factorHeightScale,
          width: 20,
          textAlign: "center",
        }
      );
      canvas2.add(playerTrait2V);

      var playerTrait3V = new fabric.Textbox(
        String(
          orderDetails?.order_details?.attribute_position
            .split("},{")[2]
            .split(",")[1]
            .split(":")[1]
            .replace(/"/g, "")
        ),
        {
          originX: "left",
          originY: "top",
          editable: false,
          selectable: false,
          evented: false,
          fontFamily: "den-pro",
          fill: fontColor,
          fontSize: 26 * factorWidthScale,
          fontWeight: 600,
          left: 85 * factorWidthScale,
          top: 375 * factorHeightScale,
          width: 20,
          textAlign: "center",
        }
      );
      canvas2.add(playerTrait3V);

      var playerTrait4V = new fabric.Textbox(
        String(
          orderDetails?.order_details?.attribute_position
            .split("},{")[3]
            .split(",")[1]
            .split(":")[1]
            .replace(/"/g, "")
        ),
        {
          originX: "left",
          originY: "top",
          editable: false,
          selectable: false,
          evented: false,
          fontFamily: "den-pro",
          fill: fontColor,
          fontSize: 26 * factorWidthScale,
          fontWeight: 600,
          left: 195 * factorWidthScale,
          top: 305 * factorHeightScale,
          width: 20,
          textAlign: "center",
        }
      );
      canvas2.add(playerTrait4V);

      var playerTrait5V = new fabric.Textbox(
        String(
          orderDetails?.order_details?.attribute_position
            .split("},{")[4]
            .split(",")[1]
            .split(":")[1]
            .replace(/"/g, "")
        ),
        {
          originX: "left",
          originY: "top",
          editable: false,
          selectable: false,
          evented: false,
          fontFamily: "den-pro",
          fill: fontColor,
          fontSize: 26 * factorWidthScale,
          fontWeight: 600,
          left: 195 * factorWidthScale,
          top: 340 * factorHeightScale,
          width: 20,
          textAlign: "center",
        }
      );
      canvas2.add(playerTrait5V);

      var playerTrait6V = new fabric.Textbox(
        String(
          orderDetails?.order_details?.attribute_position
            .split("},{")[5]
            .split(",")[1]
            .split(":")[1]
            .split("}")[0]
            .replace(/"/g, "")
        ),
        {
          originX: "left",
          originY: "top",
          editable: false,
          selectable: false,
          evented: false,
          fontFamily: "den-pro",
          fill: fontColor,
          fontSize: 26 * factorWidthScale,
          fontWeight: 600,
          left: 195 * factorWidthScale,
          top: 375 * factorHeightScale,
          width: 20,
          textAlign: "center",
        }
      );
      canvas2.add(playerTrait6V);

      var overallRating = new fabric.Textbox(
        String(
          Math.round(
            +trait1V + +trait2V + +trait3V + +trait4V + +trait5V + +trait6V
          ) / 6
        ).split(".")[0],
        {
          fontFamily: "den-pro",
          originX: "left",
          originY: "top",
          editable: false,
          selectable: false,
          evented: false,
          fontSize: 49 * factorWidthScale,
          left:
            String(
              Math.round(
                +trait1V + +trait2V + +trait3V + +trait4V + +trait5V + +trait6V
              ) / 6
            ).split(".")[0] === "87"
              ? 84 * factorWidthScale
              : 81 * factorWidthScale,
          top: 70 * factorHeightScale,
          fill: fontColor,
          textAlign: "center",
        }
      );

      canvas2.add(overallRating);

      fabric.Image.fromURL(
        orderDetails?.order_details?.country,
        function (img) {
          var scale = Math.min(35, 32) / Math.max(img.width, img.height);
          img.scaleX = scale * 1.4 * factorWidthScale;
          img.scaleY = scale * 1.4 * factorHeightScale;
          img.originX = "left";
          img.originY = "top";
          img.top = 157.5 * factorHeightScale;
          img.left = 79 * factorWidthScale;
          img.selectable = false;
          img.evented = false;
          canvas2.add(img);
        },
        { crossOrigin: "anonymous" }
      );

      fabric.Image.fromURL(
        orderDetails?.order_details?.club,
        function (img) {
          var scale = Math.min(35, 32) / Math.max(img.width, img.height);
          img.scaleX = scale * 1.4 * factorWidthScale;
          img.scaleY = scale * 1.4 * factorHeightScale;
          img.originX = "left";
          img.originY = "top";
          img.top = 200 * factorHeightScale;
          img.left = 79 * factorWidthScale;
          img.selectable = false;
          img.evented = false;
          canvas2.add(img);
        },
        { crossOrigin: "anonymous" }
      );

      var path = new fabric.Path(chemistryConverted, {
        selectable: false,
        editable: false,
        originX: "left",
        originY: "top",
        fill: fontColor,
        left: 5 * factorWidthScale,
        top: 200 * factorHeightScale,
        scaleX: -0.025 * factorWidthScale,
        scaleY: 0.025 * factorHeightScale,
        angle: 180,
        // rotate
      });

      if (path.height > 800) {
        path.set({
          top: 438 * factorHeightScale,
        });
      } else if (path.height <= 800) {
        path.set({
          top: 430 * factorHeightScale,
        });
      }
      canvas2.add(path).centerObjectH(path);
      largeCanvasEl.current = canvas2;
      setIsLoadingP(false);
    }
  }, [
    orderDetails,
    chemistryConverted,
    factorHeight,
    factorHeightScale,
    factorWidth,
    factorWidthScale,
  ]);

  const handleExportCanvas = () => {
    setIsLoadingP(true);
    var imageData = largeCanvasEl.current.toDataURL();
    const url1 = `${url}/wizard/upload-hd-copy`;
    let formData = new FormData();
    formData.append("image", imageData);
    formData.append("card_id", cardId);
    formData.append("order_id", orderId);
    axios.post(url1, formData).then((res) => {
      if (res.data.succeeded) {
        notify(res.data.message);
        downloadImage(res.data.url);
        setExported(true);
        setIsLoadingP(false);
      } else {
        notify(res.data.message);
      }
    });
  };

  return (
    <Container className="fv !grid !place-items-center pt-16">
      <ToastContainer />
      {isLoading && (
        <div className="!my-40 xl:!my-60 2xl:!my-96">
          <Loader />
        </div>
      )}
      {orderDetails && (
        <>
          <canvas ref={canvasRef}></canvas>
          <canvas className="!hidden" style={{ visibility: "hidden" }}></canvas>
          <button
            className="!relative !z-[999] btn--ripple !inline-block !px-8 !py-2 !text-white !bg-[#FF733A] !rounded-full !overflow-hidden disabled:!bg-stone-500 hover:!bg-amber-900"
            disabled={isLoading || exported || isLoadingP}
            onClick={handleExportCanvas}
          >
            {exported ? "Exported" : isLoadingP ? "Loading" : "Export"}
          </button>
        </>
      )}
      {!isLoading && !orderDetails && (
        <div className="!my-60 xl:!my-60 2xl:!my-96">
          <h1>Empty Order</h1>
        </div>
      )}
    </Container>
  );
};

export default FinalVersionForm;
