import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  headerSlider: {
    isLoading: true,
    content: null,
    error: null,
    isDataFetched: false
  },
  ourCustomerSays: {
    isLoading: true,
    content: null,
    contentBg: null,
    error: null,
  },
  howToChoose: {
    isLoading: true,
    content: null,
    error: null,
  },
  bestSelling: {
    isLoading: true,
    content: null,
    error: null,
  },
  cardType1: {
    isLoading: true,
    content: null,
    error: null,
  },
  cardType2: {
    isLoading: true,
    content: null,
    error: null,
  },
  qualityTrust: {
    isLoading: true,
    content: null,
    error: null,
  },
  whyChoose: {
    isLoading: true,
    content1: null,
    content2: null,
    error: null,
  },
  trustedFans: {
    isLoading: true,
    content: null,
    error: null,
  },
  bigOccasion: {
    isLoading: true,
    content: null,
    error: null,
  },
  customerCarousel: {
    isLoading: true,
    content: null,
    error: null,
  },
  frequentlyAsked: {
    isLoading: true,
    content: null,
    error: null,
  },
};

const url = "https://propackstore.org/propack/web/API";

export const getHeaderSlider = createAsyncThunk(
  "home/getHeaderSlider",
  async () => {
    const response = await axios.get(`${url}/wizard/get-image-slider`);
    return response.data;
  }
);

export const getOurCustomerSays = createAsyncThunk(
  "home/getOurCustomerSays",
  async () => {
    const response = await axios.get(`${url}/wizard/get-section-one`);
    return response.data;
  }
);

export const getHowToChoose = createAsyncThunk(
  "home/getHowToChoose",
  async () => {
    const response = await axios.get(`${url}/wizard/get-section-two`);
    return response.data;
  }
);

export const getBestSelling = createAsyncThunk(
  "home/getBestSelling",
  async () => {
    const response = await axios.get(`${url}/wizard/get-section-three`);
    return response.data;
  }
);

export const getCardTypes = createAsyncThunk("home/getCardTypes", async () => {
  const response = await axios.get(`${url}/wizard/get-section-four`);
  return response.data;
});

export const getQualityTrust = createAsyncThunk(
  "home/getQualityTrust",
  async () => {
    const response = await axios.get(`${url}/wizard/get-section-five`);
    return response.data;
  }
);

export const getWhyChoose = createAsyncThunk("home/getWhyChoose", async () => {
  const response = await axios.get(`${url}/wizard/get-section-six`);
  return response.data;
});

export const getTrustedFans = createAsyncThunk(
  "home/getTrustedFans",
  async () => {
    const response = await axios.get(`${url}/wizard/get-section-seven`);
    return response.data;
  }
);

export const getBigOccasion = createAsyncThunk(
  "home/getBigOccasion",
  async () => {
    const response = await axios.get(`${url}/wizard/get-section-eight`);
    return response.data;
  }
);

export const getFAQ = createAsyncThunk("home/getFAQ", async () => {
  const response = await axios.get(`${url}/wizard/get-section-nine`);
  return response.data;
});

export const getCustomerCarousel = createAsyncThunk(
  "home/getCustomerCarousel",
  async () => {
    const response = await axios.get(`${url}/wizard/get-section-ten`);
    return response.data;
  }
);

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHeaderSlider.pending, (state) => {
        state.headerSlider.isLoading = true;
        state.headerSlider.content = null;
      })
      .addCase(getHeaderSlider.fulfilled, (state, action) => {
        state.headerSlider.isLoading = false;
        state.headerSlider.content = action.payload;
        state.headerSlider.isDataFetched = true;
      });
    //
    builder
      .addCase(getOurCustomerSays.pending, (state) => {
        state.ourCustomerSays.isLoading = true;
        state.ourCustomerSays.content = null;
        state.ourCustomerSays.contentBg = null;
      })
      .addCase(getOurCustomerSays.fulfilled, (state, action) => {
        state.ourCustomerSays.isLoading = false;
        state.ourCustomerSays.content = action.payload.content;
        state.ourCustomerSays.contentBg = action.payload.bg;
      });
    //
    builder
      .addCase(getHowToChoose.pending, (state) => {
        state.howToChoose.isLoading = true;
        state.howToChoose.content = null;
      })
      .addCase(getHowToChoose.fulfilled, (state, action) => {
        state.howToChoose.isLoading = false;
        state.howToChoose.content = action.payload;
      });
    //
    builder
      .addCase(getBestSelling.pending, (state) => {
        state.bestSelling.isLoading = true;
        state.bestSelling.content = null;
      })
      .addCase(getBestSelling.fulfilled, (state, action) => {
        state.bestSelling.isLoading = false;
        state.bestSelling.content = action.payload;
      });
    //
    builder
      .addCase(getCardTypes.pending, (state) => {
        state.cardType1.isLoading = true;
        state.cardType1.content = null;
        state.cardType2.isLoading = true;
        state.cardType2.content = null;
      })
      .addCase(getCardTypes.fulfilled, (state, action) => {
        state.cardType1.isLoading = false;
        state.cardType1.content = action.payload[0];
        state.cardType2.isLoading = false;
        state.cardType2.content = action.payload[1];
      });
    //
    builder
      .addCase(getQualityTrust.pending, (state) => {
        state.qualityTrust.isLoading = true;
        state.qualityTrust.content = null;
      })
      .addCase(getQualityTrust.fulfilled, (state, action) => {
        state.qualityTrust.isLoading = false;
        state.qualityTrust.content = action.payload;
      });
    //
    builder
      .addCase(getWhyChoose.pending, (state) => {
        state.whyChoose.isLoading = true;
        state.whyChoose.content1 = null;
        state.whyChoose.content2 = null;
      })
      .addCase(getWhyChoose.fulfilled, (state, action) => {
        state.whyChoose.isLoading = false;
        state.whyChoose.content1 = action.payload.sectionSix;
        state.whyChoose.content2 = action.payload.sectionSixFeatures;
      });
    //
    builder
      .addCase(getTrustedFans.pending, (state) => {
        state.trustedFans.isLoading = true;
        state.trustedFans.content = null;
      })
      .addCase(getTrustedFans.fulfilled, (state, action) => {
        state.trustedFans.isLoading = false;
        state.trustedFans.content = action.payload;
      });
    //
    builder
      .addCase(getBigOccasion.pending, (state) => {
        state.bigOccasion.isLoading = true;
        state.bigOccasion.content = null;
      })
      .addCase(getBigOccasion.fulfilled, (state, action) => {
        state.bigOccasion.isLoading = false;
        state.bigOccasion.content = action.payload;
      });
    //
    builder
      .addCase(getFAQ.pending, (state) => {
        state.frequentlyAsked.isLoading = true;
        state.frequentlyAsked.content = null;
      })
      .addCase(getFAQ.fulfilled, (state, action) => {
        state.frequentlyAsked.isLoading = false;
        state.frequentlyAsked.content = action.payload;
      });
    //
    builder
      .addCase(getCustomerCarousel.pending, (state) => {
        state.customerCarousel.isLoading = true;
        state.customerCarousel.content = null;
      })
      .addCase(getCustomerCarousel.fulfilled, (state, action) => {
        state.customerCarousel.isLoading = false;
        state.customerCarousel.content = action.payload;
      });
  },
});

export default homeSlice;
