import { Fragment } from "react";
import CartMain from "../components/ui/cart/CartMain";

const Cart = () => {
  return (
    <Fragment>
      <div className="contAll !bg-[#F5F5F5]">
        <CartMain />
      </div>
    </Fragment>
  );
};

export default Cart;
