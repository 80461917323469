import "./IntroSwiper.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation, EffectCreative } from "swiper";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import "swiper/css/effect-creative";
import ProgressiveImage from "react-progressive-image";
import LazyLoad from "react-lazy-load";
import { Container } from "react-bootstrap";
import Loader from "../Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import { getHeaderSlider } from "../../../store/data/Home-slice";
const IntroSwiper = () => {
  const dispatch = useDispatch();
  const [textChange, setTextChange] = useState(true);
  useEffect(() => {
    if (content.content === null) {
      dispatch(getHeaderSlider());
    } else {
      return;
    }
  }, [dispatch]);
  const content = useSelector((state) => state.home.headerSlider);
  return (
    <Container
      border="0"
      className="xsm:mt-[50px] mt-[50px] md:mt-0 !p-0 !mx-0 !rounded-none !relative"
      fluid
    >
      {!content.isDataFetched && (
        <div className="!mt-64 sm:!mt-32">
          <Loader />
        </div>
      )}
      {content.isDataFetched && content.content && (
        <Swiper
          spaceBetween={0}
          loop={true}
          effect={"creative"}
          onSlideChangeTransitionEnd={() => {
            setTextChange(true);
          }}
          onSlideChangeTransitionStart={() => {
            setTextChange(false);
          }}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: [0, 0, -400],
            },
            next: {
              translate: ["100%", 0, 0],
            },
          }}
          centeredSlides={true}
          autoplay={{
            delay: 3500,
            // disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation, EffectCreative]}
          className="mySwiper lg:!h-[80vh]"
          // /!w-[85vw] xsm:!w-[100vw]
        >
          {content.content?.map(
            (slide, index) =>
              slide.file_name && (
                <SwiperSlide
                  key={index}
                  className="!bg-[#eda786] md:!mt-16 !m-auto"
                >
                  <Card className="!border-none !rounded-none">
                    <LazyLoad offset={300}>
                      <ProgressiveImage
                        src={slide.main_image}
                        placeholder={`${slide.path}blures/${slide.file_name}`}
                      >
                        {(src) => (
                          <Card.Img
                            src={src}
                            className="!m-auto !h-[25vh] sm:!h-auto !w-[85vw] xsm:!w-[100vw]"
                          />
                        )}
                      </ProgressiveImage>
                    </LazyLoad>
                    <Card.ImgOverlay>
                      <Card.Body>
                        <Row>
                          <Col className="text-left">
                            <div className="!absolute !top-[25%] sm:!top[30%] xl:!top-[25%] 2xl:!top-[20%] !left-[10%]">
                              <div
                                className={
                                  textChange ? "text-effect1" : "no-effect"
                                }
                              >
                                <p>
                                  <span
                                    className="normal-case text-wrapper xsm:!max-w-[150px] !max-w-[200px] lg:!max-w-sm xsm:!text-xs md:!text-md lg:!text-2xl 2xl:!text-5xl"
                                    style={{ color: `${slide.font_color_title_1 ? slide.font_color_title_1 : "#fff"}` }}
                                  >
                                    {slide.title}
                                  </span>
                                  <span
                                    className="!max-w-[100%] normal-case xsm:m-0 xsm:!text-[8px] text-[12px] sm:text-[14px] md:text-[16px] lg:text-[26px] !w-[40%]"
                                    style={{ color: `${slide.font_color_title_2 ? slide.font_color_title_2 : "#A04723"}` }}
                                  >
                                    {slide.short_description}
                                  </span>
                                </p>
                              </div>
                              <Link
                                className="uppercase no-underline text-[white] bg-[#FF733A] xsm:text-[7px] xsm:p-[7px] text-[7px] p-[7px]
                              sm:text-[9px] sm:p-[7px] md:text-[11px] md:p-[7px] lg:text-[20px] lg:!p-[12px] 2xl:text-[23px] 2xl:p-[14px] rounded-full hover:!text-[#A04723]"
                                to={`/${slide.button_link}`}
                              >
                                {slide.button_text}
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card.ImgOverlay>
                  </Card>
                </SwiperSlide>
              )
          )}
        </Swiper>
      )}
    </Container>
  );
};

export default IntroSwiper;
