import "./BestSelling.css";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import cube1 from "../../../images/home/BestSelling/cube1.png";
import cube2 from "../../../images/home/BestSelling/cube2.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazy-load";
import ProgressiveImage from "react-progressive-image";
import { useSelector, useDispatch } from "react-redux";
import { getBestSelling } from "../../../store/data/Home-slice";
import { useEffect } from "react";
const BestSelling = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (content === null) {
      dispatch(getBestSelling());
    } else {
      return;
    }
  }, []);
  const content = useSelector((state) => state.home.bestSelling.content);
  return (
    <Container className="mt-20">
      {content && (
        <Row>
          <main className="text-center">
            <header className="flex flex-row items-center justify-center">
              <img src={cube1} alt="Cube 1" className="p-1 xsm:h-8" />
              <h1 className="text-[#000000] font-bold xsm:text-[20px]">
                Best Selling Cards
              </h1>
              <img src={cube2} alt="Cube 2" className="p-1 xsm:h-8" />
            </header>
          </main>
        </Row>
      )}
      <Swiper
        slidesPerView={2}
        slidesPerGroup={2}
        spaceBetween={10}
        loopFillGroupWithBlank={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        breakpoints={{
          460: {
            slidesPerView: 2,
            spaceBetween: 0,
            slidesPerGroup: 2,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
            slidesPerGroup: 2,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: -50,
            slidesPerGroup: 2,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: -10,
            slidesPerGroup: 3,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: -100,
            slidesPerGroup: 3,
          },
          1400: {
            slidesPerView: 3,
            // spaceBetween: -170,
            spaceBetween: -150,
            slidesPerGroup: 3,
          },
        }}
        loop={true}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper2"
      >
        {content &&
          content?.map((card, index) => (
            <SwiperSlide className="bg-transparent !my-10" key={index}>
              <Card
                style={{ width: "18rem" }}
                className="card1 !m-auto !bg-[#FFF1EB] !rounded-[30px] !w-[10rem] md:!w-[18rem] !min-h-[400px]"
                border="0"
              >
                <LazyLoad offset={300}>
                  <ProgressiveImage
                    src={`${card.path}thumbnails/${card.file_name}`}
                    placeholder={`${card.path}blures/${card.file_name}`}
                  >
                    {(src) => (
                      <Card.Img
                        src={src}
                        alt={card.title}
                        variant="top"
                        loading="lazy"
                        className="md:!h-[402px]"
                      />
                    )}
                  </ProgressiveImage>
                </LazyLoad>
                <Card.Body className="!text-[#000000] !text-center">
                  <Card.Title className="!font-extrabold">
                    {card.title}
                  </Card.Title>
                  <Card.Text className="!font-medium">
                    {card.brief_description}
                  </Card.Text>
                </Card.Body>
                <Card.Link
                  as={Link}
                  className="bg-[#ff733a] rounded-full !px-7 !py-2 no-underline text-[#FFFFFF] m-auto translate-y-3"
                  to={`/${card.button_link}`}
                >
                  {card.button_text}
                </Card.Link>
              </Card>
            </SwiperSlide>
          ))}
      </Swiper>
    </Container>
  );
};

export default BestSelling;
