import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ToggleButton from "react-bootstrap/ToggleButton";
import Modal from "react-bootstrap/Modal";
import Placeholder from "react-bootstrap/Placeholder";
import customizeIcon from "../../../../images/shopCards/Step1/customizeButtonIcon.svg";
import checkIcon from "../../../../images/shopCards/Step1/checkIcon.svg";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cardActions } from "../../../../store/CardDesign-slice";
import { stylesActions } from "../../../../store/Styles-slice";
import LazyLoad from "react-lazy-load";
import ProgressiveImage from "react-progressive-image";
import { getStepOne } from "../../../../store/data/Step1-slice";
import icon2 from "../../../../images/shopCards/Step3/InfoSquare.svg";
import classes from "../../privacyPolicy/PrivacyPolicyForm.module.css";
import Loader from "../../Loader/Loader";
import AOS from "aos";
import "aos/dist/aos.css";
const Step1 = (props) => {
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const [styleSelected, setStyleSelected] = useState(null);
  const [sizeSelected, setSizeSelected] = useState(null);
  const [price, setPrice] = useState(0);
  const [imagesToDisplay, setImagesToDisplay] = useState([]);
  const customizeHandleButton = (e) => {
    e.preventDefault();
    props.hanldeCustomizeButton();
  };
  const cardStyleModal = useSelector((state) => state.card.cardStyle);
  const cardSizeModal = useSelector((state) => state.card.cardSize);
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
    });
  }, []);
  useEffect(() => {
    if (!cardStyleModal && !cardSizeModal) {
      setSizeSelected(null);
      setStyleSelected(null);
    }
  }, [cardStyleModal, cardSizeModal]);
  useEffect(() => {
    if (styleSelected) {
      dispatch(cardActions.setCardStyleId(styleSelected));
      const styleName = stepOneContentStyles.filter(
        (style) => style.id === styleSelected
      );
      const name = styleName[0].name;
      dispatch(cardActions.setCardStyleName(name));
    }
  }, [styleSelected]);
  useEffect(() => {
    if (sizeSelected) {
      dispatch(cardActions.setCardSizeId(sizeSelected));
      const sizeName = stepOneContentSizes.filter(
        (size) => size.id === sizeSelected
      );
      const name = sizeName[0].size;
      dispatch(cardActions.setCardSizeName(name));
      setImagesToDisplay(
        stepOneContentImages.filter((image) => image.r_size_id === sizeSelected)
      );
    }
  }, [sizeSelected]);
  useEffect(() => {
    if (styleSelected && sizeSelected) {
      dispatch(stylesActions.enableBtnNext());
      const selectedItem = stepOneContentPrices.filter(
        (item) =>
          item.r_style_id === styleSelected && item.r_size_id === sizeSelected
      );
      const price = selectedItem[0].price;
      setPrice(price);
      dispatch(cardActions.setCardPrice(price));
    } else {
      dispatch(stylesActions.disableBtnNext());
    }
  }, [styleSelected, sizeSelected]);
  const btnNextStatus = useSelector(
    (state) => state.styles.wizardBtnNextDisabled
  );
  //fetch
  useEffect(() => {
    if (
      stepOneContentStyles === null ||
      stepOneContentSizes === null ||
      stepOneContentPrices === null ||
      stepOneContentImages === null
    ) {
      dispatch(getStepOne());
    } else {
      return;
    }
  }, [dispatch]);
  const {
    isLoading,
    stepOneContentStyles,
    stepOneContentSizes,
    stepOneContentPrices,
    stepOneContentImages,
    stepOneContentList,
    stepOneContentStylesGuide,
    error,
  } = useSelector((state) => state.stepOne.stepOne);
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (stepOneContentSizes) {
      setSizeSelected(stepOneContentSizes[stepOneContentSizes.length - 1]?.id);
    }
  }, [stepOneContentSizes]);
  return (
    <Container ref={containerRef} className="!overflow-y-scroll !h-[68vh]">
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        size="lg"
        centered
        scrollable
      >
        <Modal.Header className="!border-b-0" closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="!text-[#000] !font-extrabold"
          >
            Propack Style Guide
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={`${classes.pp} show-grid`}>
          <Container>
            <Row>
              <Col className="!text-[#000] font-semibold">
                {stepOneContentStylesGuide && (
                  <Row
                    dangerouslySetInnerHTML={{
                      __html: stepOneContentStylesGuide[0]?.text,
                    }}
                  ></Row>
                )}
              </Col>
              <Col>
                <div className="flex flex-col justify-center items-center">
                  {stepOneContentStylesGuide && (
                    <LazyLoad offset={0}>
                      <ProgressiveImage
                        src={`${stepOneContentStylesGuide[0]?.path}${stepOneContentStylesGuide[0]?.file_name}`}
                        placeholder={`${stepOneContentStylesGuide[0]?.path}blures/${stepOneContentStylesGuide[0]?.file_name}`}
                      >
                        {(src) => (
                          <Card.Img
                            src={src}
                            variant="top"
                            className="ImgClass !rounded-t-[16px] !rounded-b-[16px]"
                          />
                        )}
                      </ProgressiveImage>
                    </LazyLoad>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      <Row>
        <Col md={12} lg={6} xl={5} xxl={6} className="!px-5">
          <Row>
            <div className="flex flex-col">
              <div className="flex items-center">
                <h5 className="text-[#000000] !font-bold">(A) Select Style</h5>
                <label
                  className="!cursor-pointer !mb-[0.5rem] !mx-2"
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  <img src={icon2} alt=".." id="imgGuide" />
                </label>
              </div>
              {!isLoading && stepOneContentStyles && (
                <div className="flex">
                  {stepOneContentStyles?.map((style, index) => (
                    <ToggleButton
                      key={index}
                      className={
                        styleSelected === style.id
                          ? "!bg-[#FF733A] !shadow-lg !focus:outline-none !border-2 !text-[#fff] !border-[#FF733A] !px-20 lg:!px-[4.6rem] !py-1 !font-semibold !mx-4"
                          : "!bg-[#FAF9F6] !shadow-md !focus:outline-none !border-2 !text-[#FF733A] !border-[#FF733A] !px-20 lg:!px-[4.6rem] !py-1 !font-semibold !mx-4"
                      }
                      checked={styleSelected === style.id}
                      value={style.name}
                      onClick={() => {
                        setStyleSelected(style.id);
                      }}
                      type="checkbox"
                      name={style.name}
                    >
                      {style.name}
                    </ToggleButton>
                  ))}
                </div>
              )}
              {isLoading && (
                <Placeholder animation="glow ">
                  <Placeholder xs={6} className="!bg-red-500" />
                </Placeholder>
              )}
            </div>
          </Row>
          <Row className="mt-2">
            <div className="flex flex-col">
              <div>
                <h5 className="text-[#000000] !font-bold">(B) Select Size</h5>
              </div>
            </div>
            <Row>
              {!isLoading &&
                stepOneContentSizes &&
                stepOneContentSizes?.map((size, index) => (
                  <Card
                    key={index}
                    className={
                      size.id === sizeSelected
                        ? "!border-[#FF733A] !bg-[#FAF9F6] ml-3 !pt-1 cursor-pointer !w-[9rem] md:!w-[10rem] lg:!w-[7rem] xl:!w-[10rem]"
                        : "!bg-[#FAF9F6] ml-3 !pt-1 cursor-pointer !w-[9rem] md:!w-[10rem] lg:!w-[7rem] xl:!w-[10rem]"
                    }
                    onClick={() => {
                      setSizeSelected(size.id);
                    }}
                  >
                    <LazyLoad offset={300}>
                      <ProgressiveImage
                        src={`${size.path}thumbnails/${size.file_name}`}
                        placeholder={`${size.path}blures/${size.file_name}`}
                      >
                        {(src) => (
                          <Card.Img
                            src={src}
                            alt={size.size}
                            loading="lazy"
                            // className="!h-28 !w-[95px] !m-auto"
                            className="!h-44 !object-contain"
                            variant="top"
                          />
                        )}
                      </ProgressiveImage>
                    </LazyLoad>
                    <Card.Body className="!text-center">
                      <Card.Title className="!text-[#000] !font-semibold lg:text-sm whitespace-nowrap">
                        {size.size}
                      </Card.Title>
                      <Card.Subtitle className="!text-[#000] lg:!text-xs whitespace-nowrap">
                        {size.size_short_description}
                      </Card.Subtitle>
                    </Card.Body>
                  </Card>
                ))}
              {isLoading && <Loader />}
            </Row>
          </Row>
          <Row>
            <Button
              className="!mx-auto !mt-5 !bg-[#FF733A] !text-[#fff] !flex !justify-center !rounded-[10px] !py-2 
              !border-transparent !font-semibold hover:!bg-[#A04723] !text-lg"
              onClick={customizeHandleButton}
              disabled={btnNextStatus}
            >
              <img src={customizeIcon} alt="..." className="px-1" />
              Customize {price > 0 && `--  $${price}`}
            </Button>
          </Row>
          <Row className="mt-3">
            <Col>
              <ul className="list-group">
                {!isLoading &&
                  stepOneContentList &&
                  stepOneContentList?.map((list, index) =>
                    index < stepOneContentList.length / 2 ? (
                      <span className="!flex" key={list.id}>
                        <img src={checkIcon} alt=".." />
                        <li className="!text-[#000] !font-semibold !pl-2 whitespace-nowrap">
                          {list.name}
                        </li>
                      </span>
                    ) : (
                      ""
                    )
                  )}
              </ul>
            </Col>
            <Col>
              <ul className="list-group">
                {!isLoading &&
                  stepOneContentList &&
                  stepOneContentList?.map((list, index) =>
                    index > stepOneContentList.length / 2 - 1 ? (
                      <span className="!flex" key={list.id}>
                        <img src={checkIcon} alt=".." />
                        <li className="!text-[#000] !font-semibold !pl-2 whitespace-nowrap">
                          {list.name}
                        </li>
                      </span>
                    ) : (
                      ""
                    )
                  )}
              </ul>
            </Col>
          </Row>
          {isLoading && <Loader />}
        </Col>
        <Col>
          {!isLoading && imagesToDisplay && (
            <Col
              className="!grid 2xl:!grid-cols-2 !gap-1"
            >
              {imagesToDisplay.length > 0 && (
                <LazyLoad offset={0} className="!rounded-[20px] !col-span-2">
                  <ProgressiveImage
                    src={`${imagesToDisplay[2]?.path}${imagesToDisplay[2]?.file_name}`}
                    placeholder={`${imagesToDisplay[2]?.path}blures/${imagesToDisplay[2]?.file_name}`}
                  >
                    {(src) => (
                      <img
                        src={src}
                        alt="..."
                        loading="lazy"
                        className="!rounded-[20px] img1Step1"
                        data-aos="fade-up"
                        data-aos-once="false"
                      />
                    )}
                  </ProgressiveImage>
                </LazyLoad>
              )}
            </Col>
          )}
          {isLoading && <Loader />}
        </Col>
      </Row>
      <Row>
        {!isLoading && imagesToDisplay && (
          <div className="!grid !grid-cols-4 !mt-6 !gap-2">
            <LazyLoad>
              <ProgressiveImage
                src={`${imagesToDisplay[0]?.path}${imagesToDisplay[0]?.file_name}`}
                placeholder={`${imagesToDisplay[0]?.path}blures/${imagesToDisplay[0]?.file_name}`}
              >
                {(src) => (
                  <img
                    src={src}
                    alt="..."
                    loading="lazy"
                    className="!rounded-[20px] !w-[270px] !h-[270px] !object-cover"
                  />
                )}
              </ProgressiveImage>
            </LazyLoad>
            <LazyLoad>
              <ProgressiveImage
                src={`${imagesToDisplay[1]?.path}${imagesToDisplay[1]?.file_name}`}
                placeholder={`${imagesToDisplay[1]?.path}blures/${imagesToDisplay[1]?.file_name}`}
              >
                {(src) => (
                  <img
                    src={src}
                    alt="..."
                    loading="lazy"
                    className="!rounded-[20px] !w-[270px] !h-[270px] !object-cover"
                  />
                )}
              </ProgressiveImage>
            </LazyLoad>
            <LazyLoad>
              <ProgressiveImage
                src={`${imagesToDisplay[3]?.path}${imagesToDisplay[3]?.file_name}`}
                placeholder={`${imagesToDisplay[3]?.path}blures/${imagesToDisplay[3]?.file_name}`}
              >
                {(src) => (
                  <img
                    src={src}
                    alt="..."
                    loading="lazy"
                    className="!rounded-[20px] !w-[270px] !h-[270px] !object-cover"
                  />
                )}
              </ProgressiveImage>
            </LazyLoad>
            <LazyLoad>
              <ProgressiveImage
                src={`${imagesToDisplay[4]?.path}${imagesToDisplay[4]?.file_name}`}
                placeholder={`${imagesToDisplay[4]?.path}blures/${imagesToDisplay[4]?.file_name}`}
              >
                {(src) => (
                  <img
                    src={src}
                    alt="..."
                    loading="lazy"
                    className="!rounded-[20px] !w-[270px] !h-[270px] !object-cover"
                  />
                )}
              </ProgressiveImage>
            </LazyLoad>
          </div>
        )}
      </Row>
    </Container>
  );
};

export default Step1;
