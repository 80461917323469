import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import "./App.css";
import Footer from "./components/ui/footer/Footer";
import NavBar from "./components/ui/navbar/NavBar";
import AboutUs from "./pages/AboutUs";
import Cart from "./pages/Cart";
import Checkout from "./pages/CheckOut";
import Home from "./pages/Home";
import ContactUs from "./pages/ContactUs";
import NotFound from "./pages/NotFound";
import ShopCards from "./pages/ShopCards";
import TermsAndConditions from "./pages/TermsAndConditions";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import Step2 from "./components/ui/wizard-small/Step2";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import DhlResponse from "./pages/DhlResponse";
import FinalVersoin from "./pages/FinalVersion";
import { useEffect, useState } from "react";
import axios from "axios";
import localforage from "localforage";
import ScrollToTop from "./components/ui/scroll/ScrollToTop";
import { useSelector, useDispatch } from "react-redux";
import { checkOutActions } from "./store/CheckOut-slice";
import { stylesActions } from "./store/Styles-slice";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import register from "./images/home/signup.svg"
function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const url = useSelector((state) => state.api.domain);
  const location = useLocation();
  const isShopCards = location.pathname.split("/")[1] === "shopcards";
  const isCardDesignSmall = location.pathname.split("/")[1] === "cardesign";
  const isHome = location.pathname.split("/")[1] === "home";
  const [showNavs, setShowNavs] = useState(true);
  //loader
  const [loading, setIsLoading] = useState(true);
  useEffect(() => {
    if (isShopCards || isCardDesignSmall || (loading && isHome)) {
      setShowNavs(false);
    } else {
      setShowNavs(true);
    }
  }, [isShopCards, isCardDesignSmall, loading]);
  //Auto Login
  function autoLogin() {
    let formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("session_id", localStorage.getItem("session_id"));
    const url1 = `${url}/wizard/auto-login`;
    axios
      .post(url1, formData)
      .then((res) => {
        if (res.data.succeeded) {
          localforage.setItem("user", res.data.user);
          dispatch(stylesActions.trueLoggedIn());
        } else {
          localStorage.setItem("token", null);
          localforage.setItem("user", null);
          dispatch(stylesActions.falseLoggedIn());
          setTimeout(() => {
            setShowModal(true);
          }, 7000);
        }
        dispatch(
          checkOutActions.setCardsCount({
            type: "update",
            count: res.data.cards_count,
          })
        );
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 6000);
    autoLogin();
  }, []);
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="App selection:text-orange-400 selection:bg-purple-900">
      {showNavs && (
        <header>
          <NavBar />
        </header>
      )}
      <main>
        <ScrollToTop />
        <Switch>
          <Route path="/" exact>
            <Redirect to="/home" />
          </Route>
          <Route path="/home" exact>
            <Home loading={loading} />
          </Route>
          <Route path="/cart" exact>
            <Cart />
          </Route>
          <Route path="/checkout" exact>
            <Checkout />
          </Route>
          <Route path="/aboutus" exact>
            <AboutUs />
          </Route>
          <Route path="/contactus" exact>
            <ContactUs />
          </Route>
          <Route path="/shopcards" exact>
            <ShopCards />
          </Route>
          <Route path="/account/signin" exact>
            <SignIn />
          </Route>
          <Route path="/account/signup" exact>
            <SignUp />
          </Route>
          <Route path="/termsAndConditions" exact>
            <TermsAndConditions />
          </Route>
          <Route path="/cardesign" exact>
            <Step2 />
          </Route>
          <Route path="/privacypolicy" exact>
            <PrivacyPolicy />
          </Route>
          <Route path="/trackorder" exact>
            <DhlResponse />
          </Route>
          <Route path="/finalVersion/:orderId/:cardId" exact>
            <FinalVersoin />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </main>
      {showNavs && (
        <footer>
          <Footer />
        </footer>
      )}

      <Modal
        show={showModal && isHome}
        onHide={() => setShowModal(false)}
        size="sm"
        centered
      >
        <Modal.Header className="!border-b-0" closeButton />
        <Modal.Body className="!flex !flex-col !justify-center !items-center !px-0">
          <img
            src={register}
            alt="..."
            className="!w-44 !h-44 !object-contain !m-auto"
          />
          <p className="!text-base !text-center !text-black !font-bold !mt-2">
            Subscribe to our newsletter <br />
            Stay updated with our latest designs, discounts and special offers.
          </p>
          <Button
            className="!bg-[#FF733A] !border-transparent !text-[#fff] !rounded-[60px] !font-semibold !text-center !px-3 !py-1 hover:!text-[#A04723] !whitespace-nowrap"
            onClick={() => history.push("/account/signup")}
          >
            Register
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default App;
