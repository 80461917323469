import WizardForm from "../components/ui/wizard/wizardForm/WizardForm";
import { useMediaQuery } from "react-responsive";
import  Wizrad_small from "../components/ui/wizard-small/Wizrad_small";
const ShopCards = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 600px)" });
  return (
    <div>
      {!isTabletOrMobile && <WizardForm />}
      {isTabletOrMobile && <Wizrad_small />}
    </div>
  );
};

export default ShopCards;
