import { useState, useEffect, useRef } from "react";
import "../Step3/Step3.css";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import icon1 from "../../../../images/shopCards/Step3/nb1.svg";
import icon2 from "../../../../images/shopCards/Step3/InfoSquare.svg";
import icon3 from "../../../../images/shopCards/Step3/galleryIcon.svg";
import icon5 from "../../../../images/shopCards/Step3/nb2.svg";
//
import classes from "../../privacyPolicy/PrivacyPolicyForm.module.css";
import icon14 from "../../../../images/shopCards/Step4/nb3.svg";
import icon24 from "../../../../images/shopCards/Step4/nb4.svg";
import icon34 from "../../../../images/shopCards/Step4/searchPlaceholder.svg";
import icon44 from "../../../../images/shopCards/Step3/galleryIcon.svg";
import icon54 from "../../../../images/shopCards/Step3/ringIcon.svg";
import icon64 from "../../../../images/shopCards/Step4/checkIcon.svg";
import kk from "../../../../images/262.svg";
//
import icon15 from "../../../../images/shopCards/Step5/nb5.svg";
import icon25 from "../../../../images/shopCards/Step5/nb6.svg";
//
import icon16 from "../../../../images/shopCards/step6/nb7.svg";
import icon17 from "../../../../images/shopCards/step6/nb8.svg";
import icon9 from "../../../../images/shopCards/Step3/deleteIcon.svg";
//
import image72 from "../../../../images/shopCards/step6/basketIcon.svg";
import image73 from "../../../../images/shopCards/step6/checkoutIcon.svg";
import image74 from "../../../../images/shopCards/step6/greenCheck.svg";
//
import image56 from "../../../../images/shopCards/doneIcon.svg";
import image57 from "../../../../images/shopCards/backArrow.svg";
//
import r1 from "../../../../images/shopCards/copper/rotateLeft.svg";
import r2 from "../../../../images/shopCards/copper/rotateRight.svg";
import m1 from "../../../../images/shopCards/copper/magnefier.svg";
import m2 from "../../../../images/shopCards/copper/minus.svg";
//
import Tab from "react-bootstrap/Tab";
import ListGroup from "react-bootstrap/ListGroup";
import { fabric } from "fabric";
//
import Lottie from "react-lottie";
import gif3 from "../../../../images/shopCards/Step4/gif4.json";
//redux
import { useDispatch, useSelector } from "react-redux";
import { checkOutActions } from "../../../../store/CheckOut-slice";
import localforage from "localforage";
//
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import { Navigation, Scrollbar } from "swiper";
import { Link } from "react-router-dom";
import axios from "axios";
import LazyLoad from "react-lazy-load";
import ProgressiveImage from "react-progressive-image";
import { CustomProgressiveImage } from "../../progressiveImage/ProgressiveImage";
import { cardActions } from "../../../../store/CardDesign-slice";
import LoaderImage from "../../../../images/home/Loader/loader.gif";
import { stylesActions } from "../../../../store/Styles-slice";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getExtrasGuide } from "../../../../store/data/Secondary-slice";
import Loader from "../../Loader/Loader";
import EXIF from "exif-js";
const Step2 = (props) => {
  const notify = (msg) => toast(msg);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: gif3,
    renderer: "svg",
  };
  const placeholder = (
    <div className="!flex !items-center !text-[#999999]">
      <img src={icon34} alt="..." className="!pr-1" /> Search country name
    </div>
  );
  const placeholder2 = (
    <div className="!flex !items-center !text-[#999999]">
      <img src={icon34} alt="..." className="!pr-1" /> Search club name
    </div>
  );
  const dispatch = useDispatch();
  const col1 = props.activeCol === "col1";
  const col2 = props.activeCol === "col2";
  const col3 = props.activeCol === "col3";
  const col4 = props.activeCol === "col4";
  const col5 = props.activeCol === "col5";
  const imageGuideModal = useSelector(
    (state) => state.styles.showImageGuideModal
  );
  const [modalShowCustomFlag, setModalShowCustomFlag] = useState(false);
  const [modalShowCustomBadge, setModalShowCustomBadge] = useState(false);
  const handleClose = () => {
    dispatch(stylesActions.showImageGuideModal());
  };
  const handleCloseCustomFlag = () => {
    setModalShowCustomFlag(false);
  };
  const handleCloseCustomBadge = () => {
    setModalShowCustomBadge(false);
  };
  //canvas modals
  const [showUploadedImageContent, setShowUploadedImageContent] =
    useState(false);
  //fetching
  const url = useSelector((state) => state.api.domain);
  const [countryClicked, setCountryClicked] = useState(null);
  const [clubClicked, setClubClicked] = useState(null);
  const [continentSelected, setContinentSelected] = useState(null);
  const [leagueSelected, setLeagueSelected] = useState(null);
  const [categorySelected, setCategorySelected] = useState(null);
  //card_categories
  const [categories, setCategories] = useState([]);
  async function getCategories() {
    try {
      const response = await axios.get(`${url}/wizard/get-card-categories`);
      setCategories(response.data);
      setCategorySelected(response.data[0].id);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getCategories();
  }, []);
  const [cardStyle, setCardStyle] = useState([]);
  const [cardStyleLoading, setCardStyleLoading] = useState(true);
  function getCardStyle() {
    setCardStyleLoading(true);
    let formData = new FormData();
    formData.append("id", categorySelected);
    const url1 = `${url}/wizard/get-cards`;
    axios
      .post(url1, formData)
      .then((res) => {
        setCardStyle(res.data);
        setCardStyleLoading(false);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    getCardStyle();
  }, [categorySelected]);
  const [sportC, setSportC] = useState([]);
  const [sportS, setSportS] = useState([]);
  const [firstSport, setFirstSport] = useState("");
  async function getAllSportsAttr() {
    try {
      const response = await axios.get(`${url}/wizard/get-sports-attributes`);
      setSportC(response.data.categories);
      setSportS(response.data.sports);
      setFirstSport(response.data.sports[0].sport_name);
      setSport(response.data.sports[0].id);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getAllSportsAttr();
    dispatch(getExtrasGuide());
  }, []);
  //continents
  const [continents, setContinents] = useState([]);
  async function getContinents() {
    try {
      const response = await axios.get(`${url}/wizard/get-continents`);
      setContinents(response.data);
      setContinentSelected(response.data[0].id);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getContinents();
  }, []);
  //countries
  const [countries, setCountries] = useState([]);
  function getCountry() {
    let formData = new FormData();
    formData.append("continent_id", continentSelected);
    const url1 = `${url}/wizard/get-countries`;
    axios
      .post(url1, formData)
      .then((res) => {
        setCountries(res.data);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    getCountry();
  }, [continentSelected]);
  //leagues
  const [leagues, setLeagues] = useState([]);
  async function getLeagues() {
    try {
      const response = await axios.get(`${url}/wizard/get-leagues`);
      setLeagues(response.data);
      setLeagueSelected(response.data[0].id);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getLeagues();
  }, []);
  //clubs
  const [clubs, setClubs] = useState([]);
  function getClubs() {
    let formData = new FormData();
    formData.append("league_id", leagueSelected);
    const url1 = `${url}/wizard/get-clubs`;
    axios
      .post(url1, formData)
      .then((res) => {
        setClubs(res.data);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    getClubs();
  }, [leagueSelected]);
  // extras
  const [extras, setExtras] = useState([]);
  const [extraBundles, setExtraBundles] = useState([]);
  async function getExtras() {
    try {
      const response = await axios.get(`${url}/wizard/get-extras`);
      setExtras(response.data.extras);
      setExtraBundles(response.data.bundles);
      dispatch(checkOutActions.setInitialExtras(response.data.extras));
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getExtras();
  }, []);
  //chemistry
  const [chemistryStyle, setChemistryStyle] = useState([]);
  const [chemistryClicked, setChemistryClicked] = useState();
  const [chemistryClickedImage, setChemistryClickedImage] = useState(null);
  const [chemistryName, setChemistryName] = useState("");

  async function getChemistryStyle() {
    try {
      const response = await axios.get(`${url}/wizard/get-chemistry-styles`);
      setChemistryStyle(response.data);
      const id = response.data.filter(
        (chem) => chem.name.toLowerCase() === "propack"
      );
      if (id) {
        setChemistryClicked(id[0].id);
        convertToPath(id[0].main_image);
        setChemistryName(id[0].name);
      } else {
        setChemistryClicked(null);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getChemistryStyle();
  }, []);
  //image guide
  const [imageGuide, setImageFGuide] = useState([]);
  async function getImageGuide() {
    try {
      const response = await axios.get(`${url}/wizard/get-image-guide`);
      setImageFGuide(response.data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getImageGuide();
  }, []);
  //cards fonts
  const [cardsFonts, setCardsFonts] = useState([]);
  async function getCardsFonts() {
    try {
      const response = await axios.get(`${url}/wizard/get-cards-fonts`);
      setCardsFonts(response.data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getCardsFonts();
  }, []);
  //user customized card
  const [cardName, setCardName] = useState("");
  const [cardId, setCardId] = useState(null);
  const [clientName, setClientName] = useState("");
  const [imageProperties, setImageProperties] = useState({
    src: "",
    top: 34,
    left: 127,
    width: 352,
    height: 640,
  });
  const [sport, setSport] = useState(null);
  const [position, setPosition] = useState("");
  const [club, setClub] = useState("");
  const [country, setCountry] = useState();
  const [chemistry, setChemistry] = useState("");
  //canvas
  const canvasRef = useRef(null);
  const canvasEl = useRef(null);
  const cropperCanvas = useRef(null);
  const previewCanvas = useRef(null);
  const previewCanvas2 = useRef(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [bgImage, setBgImage] = useState("");
  const [fontColor, setFontColor] = useState("");
  const [fontFamily, setFontFamily] = useState("");
  const [fontLoaded, setFontLoaded] = useState(false);
  const [nameFont, setNameFont] = useState("");
  const [nameTop, setNameTop] = useState("");
  const [positionLeft, setPositionLeft] = useState();
  const playerImageRef = useRef();
  function applyFont(fontFileName) {
    const fontUrl = cardsFonts.filter((font) => font.name === fontFileName);
    if (fontUrl) {
      if (fontUrl[0].font_path) {
        const fontFace = new FontFace(
          "customCardFont",
          `url(${fontUrl[0].font_path})`
        );
        fontFace.load().then(() => {
          document.fonts.add(fontFace);
          setFontFamily("customCardFont");
          setFontLoaded(true);
        });
      } else {
        setFontFamily("Inter");
        setFontLoaded(true);
      }
    } else {
      setFontFamily("Inter");
      setFontLoaded(true);
    }
  }
  const imageChangeHandler = async (
    cardUrl,
    cardName,
    cardFontColor,
    cardIdP,
    cardFontName
  ) => {
    if (cardFontName) {
      applyFont(cardFontName);
    } else {
      setFontFamily("Inter");
      setFontLoaded(true);
    }
    var image = new Image();
    image.src = cardUrl;
    setBgImage(image.src);
    setCardName(cardName);
    setFontColor(cardFontColor);
    setCardId(cardIdP);

    if (cardId === cardIdP) {
      props.cardClickHandler();
    }
  };
  function nameChangeHandler(event) {
    if (event.target.value.length === 0) {
      dispatch(stylesActions.disableBtnNext());
    } else if (event.target.value.length <= 24) {
      setNameFont(29);
      setNameTop(262);
    } else if (
      event.target.value.length > 24 &&
      event.target.value.length < 30
    ) {
      setNameFont(22);
      setNameTop(272);
    } else if (event.target.value.length > 30) {
      setNameFont(17);
      setNameTop(276);
    }
    setClientName(event.target.value);
  }
  function playerImageInputHandler() {
    playerImageRef.current.click();
  }
  function updateImageProperties(newProperties) {
    setImageProperties((prevProperties) => ({
      ...prevProperties,
      ...newProperties,
    }));
  }
  const [isImageProcessing, setIsImageProcessing] = useState(false);
  function playerImageHandler(event) {
    setIsImageProcessing(true);
    event.preventDefault();
    let formData = new FormData();
    const api = "https://trans.smcare.net/rest.php";
    const url1 = `${url}/wizard/save-uploaded-image`;
    var reader = new FileReader();
    reader.onload = function (e) {
      formData.append("is_logged_in", loggedIn);
      formData.append("token", localStorage.getItem("token"));
      formData.append("main_image", e.target.result);
      axios.post(url1, formData).then((res) => {
        if (res.data.succeeded) {
          const apiUrlWithImageUrl = `${api}?image_url=${res.data.url}`;
          axios.post(apiUrlWithImageUrl).then((response) => {
            updateImageProperties({
              src: response.data.new_image_url,
            });
            setIsImageProcessing(false);
            setCanvasModalShow(true);
          });
        }
      });
    };
    reader.readAsDataURL(event.target.files[0]);
  }
  function clearUploadedImage() {
    updateImageProperties({
      src: "",
    });
    setCroppedImage(null);
    setShowUploadedImageContent(false);
    playerImageRef.current.value = "";
    dispatch(stylesActions.disableBtnNext());
  }
  const [trait1, setTrait1] = useState("");
  const [trait2, setTrait2] = useState("");
  const [trait3, setTrait3] = useState("");
  const [trait4, setTrait4] = useState("");
  const [trait5, setTrait5] = useState("");
  const [trait6, setTrait6] = useState("");
  const [trait1V, setTrait1V] = useState("");
  const [trait2V, setTrait2V] = useState("");
  const [trait3V, setTrait3V] = useState("");
  const [trait4V, setTrait4V] = useState("");
  const [trait5V, setTrait5V] = useState("");
  const [trait6V, setTrait6V] = useState("");
  //suggestions and overaLL rating
  const [overAllRating, setOverAllRating] = useState(0);
  //
  function positionChangeHandler(event, catId) {
    if (event.target.value.length <= 1) {
      setPosition(event.target.value);
      setPositionLeft(97);
    } else if (event.target.value.length === 2) {
      setPosition(event.target.value);
      setPositionLeft(88);
    } else if (event.target.value.length === 3) {
      setPosition(event.target.value);
      setPositionLeft(82);
    } else if (event.target.value.length > 3) {
      setPosition(event.target.value);
      setPositionLeft(82);
    }
    setTrait1(sportC[catId][0]);
    setTrait2(sportC[catId][1]);
    setTrait3(sportC[catId][2]);
    setTrait4(sportC[catId][3]);
    setTrait5(sportC[catId][4]);
    setTrait6(sportC[catId][5]);
  }
  function clubChangeHandler(clubId, clubImage) {
    setClubClicked(clubId);
    setClub(clubImage);
  }
  function countryChangeHandler(countryId, countryImage) {
    setCountryClicked(countryId);
    setCountry(countryImage);
  }
  //custom
  const flagInputRef = useRef();
  const clubInputRef = useRef();
  function customFlagModal() {
    setModalShowCustomFlag(true);
  }
  function customFlagInputhandler() {
    flagInputRef.current.click();
  }
  function customFlagHandler(event) {
    var reader = new FileReader();
    reader.onload = function (e) {
      var imageCustomFlag = new Image();
      imageCustomFlag.src = e.target.result;
      imageCustomFlag.onload = function () {
        setCountry(imageCustomFlag.src);
        setCountryClicked(null);
      };
    };
    reader.readAsDataURL(event.target.files[0]);
    setModalShowCustomFlag(false);
  }
  //
  function customClubModal() {
    setModalShowCustomBadge(true);
  }
  function closeClubModalHandler() {
    setModalShowCustomBadge(false);
  }
  function customClubInputhandler() {
    clubInputRef.current.click();
  }
  function customClubHandler(event) {
    var reader = new FileReader();
    reader.onload = function (e) {
      var imageCustomClub = new Image();
      imageCustomClub.src = e.target.result;
      imageCustomClub.onload = function () {
        setClub(imageCustomClub.src);
        setClubClicked(null);
      };
    };
    reader.readAsDataURL(event.target.files[0]);
    closeClubModalHandler();
  }
  //chemistry
  function convertToPath(imagePath) {
    // load the SVG file using fetch or axisetChemistryos
    fetch(imagePath)
      .then((response) => response.text())
      .then((svgText) => {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgText, "image/svg+xml");
        const paths = svgDoc.querySelectorAll("path");
        const pathData = Array.from(paths).map((path) =>
          path.getAttribute("d")
        );
        setChemistry(pathData.join(" "));
      })
      .catch((error) => {
        console.error("Error loading SVG file:", error);
      });
  }

  function chemistryStyleHandler(id, image, name) {
    setChemistryClicked(id);
    convertToPath(image);
    setChemistryClickedImage(image);
    setChemistryName(name);
  }
  //attribute titles handlers
  function trait1THandler(e) {
    // if (e.target.value.length <= 3) {
    setTrait1(e.target.value.toUpperCase());
    // } else {
    //   return;
    // }
  }
  function trait2THandler(e) {
    // if (e.target.value.length <= 3) {
    setTrait2(e.target.value.toUpperCase());
    // } else {
    //   return;
    // }
  }
  function trait3THandler(e) {
    // if (e.target.value.length <= 3) {
    setTrait3(e.target.value.toUpperCase());
    // } else {
    //   return;
    // }
  }
  function trait4THandler(e) {
    // if (e.target.value.length <= 3) {
    setTrait4(e.target.value.toUpperCase());
    // } else {
    //   return;
    // }
  }
  function trait5THandler(e) {
    // if (e.target.value.length <= 3) {
    setTrait5(e.target.value.toUpperCase());
    // } else {
    //   return;
    // }
  }
  function trait6THandler(e) {
    // if (e.target.value.length <= 3) {
    setTrait6(e.target.value.toUpperCase());
    // } else {
    //   return;
    // }
  }
  //atribute values handlers
  function trait1VHandler(e) {
    if (e.target.value.length <= 3 && e.target.value <= 99) {
      setTrait1V(e.target.value);
    } else if (e.target.value > 99) {
      e.target.value = 99;
      setTrait1V(e.target.value);
    }
  }
  function trait2VHandler(e) {
    if (e.target.value.length <= 3 && e.target.value <= 99) {
      setTrait2V(e.target.value);
    } else if (e.target.value > 99) {
      e.target.value = 99;
      setTrait2V(e.target.value);
    }
  }
  function trait3VHandler(e) {
    if (e.target.value.length <= 3 && e.target.value <= 99) {
      setTrait3V(e.target.value);
    } else if (e.target.value > 99) {
      e.target.value = 99;
      setTrait3V(e.target.value);
    }
  }
  function trait4VHandler(e) {
    if (e.target.value.length <= 3 && e.target.value <= 99) {
      setTrait4V(e.target.value);
    } else if (e.target.value > 99) {
      e.target.value = 99;
      setTrait4V(e.target.value);
    }
  }
  function trait5VHandler(e) {
    if (e.target.value.length <= 3 && e.target.value <= 99) {
      setTrait5V(e.target.value);
    } else if (e.target.value > 99) {
      e.target.value = 99;
      setTrait5V(e.target.value);
    }
  }
  function trait6VHandler(e) {
    if (e.target.value.length <= 3 && e.target.value <= 99) {
      setTrait6V(e.target.value);
    } else if (e.target.value > 99) {
      e.target.value = 99;
      setTrait6V(e.target.value);
    }
  }
  //ratings
  useEffect(() => {
    const sugg1 =
      (+trait1V + +trait2V + +trait3V + +trait4V + +trait5V + +trait6V) / 6;
    if (sugg1 > 99) {
      setOverAllRating("99");
    } else {
      setOverAllRating(String(Math.round(sugg1)));
    }
  }, [trait1V, trait2V, trait3V, trait4V, trait5V, trait6V]);
  //suggestion clicks
  function generateRandomNumbers(num) {
    // Set the target average

    // Set the range for the random numbers
    const min = 50;
    const max = 99;

    // Generate six random numbers between min and max
    const numbers = [];
    for (let i = 0; i < 6; i++) {
      numbers.push(Math.floor(Math.random() * (max - min + 1)) + min);
    }

    // Calculate the current average
    let currentAvg =
      numbers.reduce((sum, num) => sum + num, 0) / numbers.length;

    // Adjust the numbers until the average matches the target average
    while (Math.abs(currentAvg - num) > 0.0001) {
      // Calculate the difference between the current average and the target average
      const diff = num - currentAvg;

      // Find a random index in the array of numbers
      const index = Math.floor(Math.random() * numbers.length);

      // Add the difference to the number at the random index
      var temp = numbers[index] + diff;
      if (temp < 50) {
        temp = 50;
      } else if (temp > 99) {
        temp = 99;
      }
      numbers[index] = temp;

      // Recalculate the average
      currentAvg = numbers.reduce((sum, num) => sum + num, 0) / numbers.length;
    }
    return numbers;
  }

  function sugg1(e) {
    const randomNumbers = generateRandomNumbers(e.target.value);
    setTrait1V(String(Math.round(randomNumbers[0])));
    setTrait2V(String(Math.round(randomNumbers[1])));
    setTrait3V(String(Math.round(randomNumbers[2])));
    setTrait4V(String(Math.round(randomNumbers[3])));
    setTrait5V(String(Math.round(randomNumbers[4])));
    setTrait6V(String(Math.round(randomNumbers[5])));
    setOverAllRating(e.target.value);
  }
  function sugg2(e) {
    const randomNumbers = generateRandomNumbers(e.target.value);
    setTrait1V(String(Math.round(randomNumbers[0])));
    setTrait2V(String(Math.round(randomNumbers[1])));
    setTrait3V(String(Math.round(randomNumbers[2])));
    setTrait4V(String(Math.round(randomNumbers[3])));
    setTrait5V(String(Math.round(randomNumbers[4])));
    setTrait6V(String(Math.round(randomNumbers[5])));
    setOverAllRating(e.target.value);
  }
  function sugg3(e) {
    const randomNumbers = generateRandomNumbers(e.target.value);
    setTrait1V(String(Math.round(randomNumbers[0])));
    setTrait2V(String(Math.round(randomNumbers[1])));
    setTrait3V(String(Math.round(randomNumbers[2])));
    setTrait4V(String(Math.round(randomNumbers[3])));
    setTrait5V(String(Math.round(randomNumbers[4])));
    setTrait6V(String(Math.round(randomNumbers[5])));
    setOverAllRating(e.target.value);
  }
  function overAllRatingHandler(e) {
    if (e.target.value.length <= 3 && e.target.value <= 99) {
      setOverAllRating(e.target.value);
    } else if (e.target.value > 99) {
      e.target.value = 99;
      setOverAllRating(e.target.value);
    }
  }
  useEffect(() => {
    var canvas = new fabric.Canvas(canvasRef.current, {
      width: 350,
      height: 490,
      preserveObjectStacking: true,
    });
    fabric.Image.fromURL(
      bgImage,
      function (img) {
        img.scaleX = canvas.width / img.width;
        img.scaleY = canvas.height / img.height;
        img.originX = "left";
        img.originY = "top";
        img.selectable = false;
        img.evented = false;
        canvas.add(img);
        canvas.sendToBack(img);
        img.moveTo(0);
      },
      { crossOrigin: "anonymous" }
    );
    //name
    var playerName = new fabric.Textbox("", {
      originX: "left",
      originY: "top",
      editable: false,
      selectable: false,
      evented: false,
    });

    playerName.set({
      stroke: fontColor,
      strokeWidth: 0.1,
      fill: fontColor,
      fontSize: nameFont,
      fontFamily: fontLoaded ? fontFamily : "sans-serif",
      fontWeight: 600,
      text: clientName.toUpperCase(),
      textAlign: "center",
      left: -6.4,
      top: nameTop,
      width: 365,
      selectable: false,
      evented: false,
    });

    var playerImage = new fabric.Image("", {
      selectable: false,
      cornerColor: "blue",
      cornerStyle: "circle",
      transparentCorners: false,
    });
    var pImage = new Image();
    pImage.src = croppedImage;
    pImage.onload = function () {
      playerImage.setSrc(pImage.src, function (plImage) {
        plImage.set({
          left: 131,
          top: imageProperties.top,
          scaleX: canvas.width / (plImage.width * 2.03),
          scaleY: canvas.height / (plImage.height * 2.23),
        });
        canvas.add(plImage).renderAll();
        setShowUploadedImageContent(true);
      });
    };
    playerImage.setControlsVisibility({
      bl: true,
      br: true,
      tl: true,
      tr: true,
      mb: false,
      mt: false,
      ml: false,
      mr: false,
    });
    //position
    var playerPosition = new fabric.Textbox("", {
      originX: "left",
      originY: "top",
      editable: false,
      selectable: false,
      evented: true,
      fontFamily: fontLoaded ? fontFamily : "sans-serif",
    });
    playerPosition.set({
      fill: fontColor,
      text: position.toUpperCase(),
      fontSize: 28,
      left: positionLeft,
      top: 123,
    });
    //club
    var clubImage = new fabric.Image("", {
      editable: false,
      selectable: false,
      evented: false,
      top: 200,
      left: 79,
      // width : 35,
      // height : 32
    });
    var imgClub = new Image();
    imgClub.src = club;
    imgClub.onload = function () {
      clubImage.setSrc(
        imgClub.src,
        function (clubObj1) {
          var scale =
            Math.min(35, 32) / Math.max(clubObj1.width, clubObj1.height);
          clubObj1.set({
            scaleX: scale * 1.4,
            scaleY: scale * 1.4,
          });
          canvas.add(clubObj1).renderAll();
        },
        {
          crossOrigin: "anonymous",
        }
      );
    };
    //country
    var countryImage = new fabric.Image("", {
      top: 157.5,
      left: 79,
      editable: false,
      selectable: false,
      evented: false,
    });
    var imgCountry = new Image();
    imgCountry.src = country;
    imgCountry.onload = function () {
      countryImage.setSrc(
        imgCountry.src,
        function (countryObj) {
          var scale =
            Math.min(35, 32) / Math.max(countryObj.width, countryObj.height);
          countryObj.set({
            scaleX: scale * 1.4,
            scaleY: scale * 1.4,
          });
          canvas.add(countryObj).renderAll();
        },
        {
          crossOrigin: "anonymous",
        }
      );
    };
    //traits
    var playerTrait1 = new fabric.Textbox("", {
      originX: "left",
      originY: "top",
      editable: false,
      selectable: false,
      evented: false,
      fontFamily: fontLoaded ? fontFamily : "sans-serif",
    });
    var playerTrait2 = new fabric.Textbox("", {
      originX: "left",
      originY: "top",
      editable: false,
      selectable: false,
      evented: false,
      fontFamily: fontLoaded ? fontFamily : "sans-serif",
    });
    var playerTrait3 = new fabric.Textbox("", {
      originX: "left",
      originY: "top",
      editable: false,
      selectable: false,
      evented: false,
      fontFamily: fontLoaded ? fontFamily : "sans-serif",
    });
    var playerTrait4 = new fabric.Textbox("", {
      originX: "left",
      originY: "top",
      editable: false,
      selectable: false,
      evented: false,
      fontFamily: fontLoaded ? fontFamily : "sans-serif",
    });
    var playerTrait5 = new fabric.Textbox("", {
      originX: "left",
      originY: "top",
      editable: false,
      selectable: false,
      evented: false,
      fontFamily: fontLoaded ? fontFamily : "sans-serif",
    });
    var playerTrait6 = new fabric.Textbox("", {
      originX: "left",
      originY: "top",
      editable: false,
      selectable: false,
      evented: false,
      fontFamily: fontLoaded ? fontFamily : "sans-serif",
    });
    function writeTrait1() {
      playerTrait1.set({
        fill: fontColor,
        text: trait1,
        fontSize: 26,
        left: 115,
        top: 305,
      });
      canvas.add(playerTrait1);
      canvas.renderAll();
    }
    function writeTrait2() {
      playerTrait2.set({
        text: trait2,
        fill: fontColor,
        fontSize: 26,
        left: 115,
        top: 340,
      });
      canvas.add(playerTrait2);
      canvas.renderAll();
    }
    function writeTrait3() {
      playerTrait3.set({
        text: trait3,
        fill: fontColor,
        fontSize: 26,
        left: 115,
        top: 375,
      });
      canvas.add(playerTrait3);
      canvas.renderAll();
    }
    function writeTrait4() {
      playerTrait4.set({
        text: trait4,
        fill: fontColor,
        fontSize: 26,
        left: 225,
        top: 305,
      });
      canvas.add(playerTrait4);
      canvas.renderAll();
    }
    function writeTrait5() {
      playerTrait5.set({
        text: trait5,
        visible: true,
        fill: fontColor,
        fontSize: 26,
        left: 225,
        top: 340,
      });
      canvas.add(playerTrait5);
      canvas.renderAll();
    }
    function writeTrait6() {
      playerTrait6.set({
        text: trait6,
        fill: fontColor,
        fontSize: 26,
        left: 225,
        top: 375,
      });
      canvas.add(playerTrait6);
      canvas.renderAll();
    }
    writeTrait1();
    writeTrait2();
    writeTrait3();
    writeTrait4();
    writeTrait5();
    writeTrait6();
    // attr values
    var playerTrait1V = new fabric.Textbox("", {
      originX: "left",
      originY: "top",
      editable: false,
      selectable: false,
      evented: false,
      fontFamily: fontLoaded ? fontFamily : "sans-serif",
    });
    var playerTrait2V = new fabric.Textbox("", {
      originX: "left",
      originY: "top",
      editable: false,
      selectable: false,
      evented: false,
      fontFamily: fontLoaded ? fontFamily : "sans-serif",
    });
    var playerTrait3V = new fabric.Textbox("", {
      originX: "left",
      originY: "top",
      editable: false,
      selectable: false,
      evented: false,
      fontFamily: fontLoaded ? fontFamily : "sans-serif",
    });
    var playerTrait4V = new fabric.Textbox("", {
      originX: "left",
      originY: "top",
      editable: false,
      selectable: false,
      evented: false,
      fontFamily: fontLoaded ? fontFamily : "sans-serif",
    });
    var playerTrait5V = new fabric.Textbox("", {
      originX: "left",
      originY: "top",
      editable: false,
      selectable: false,
      evented: false,
      fontFamily: fontLoaded ? fontFamily : "sans-serif",
    });
    var playerTrait6V = new fabric.Textbox("", {
      originX: "left",
      originY: "top",
      editable: false,
      selectable: false,
      evented: false,
      fontFamily: fontLoaded ? fontFamily : "sans-serif",
    });
    function writeTrait1V() {
      playerTrait1V.set({
        fill: fontColor,
        text: trait1V,
        fontSize: 26,
        fontWeight: 600,
        left: 85,
        top: 305,
        width: 20,
        textAlign: "center",
      });
      canvas.add(playerTrait1V);
      canvas.renderAll();
    }
    function writeTrait2V() {
      playerTrait2V.set({
        text: trait2V,
        fill: fontColor,
        fontSize: 26,
        fontWeight: 600,
        left: 85,
        top: 340,
        width: 20,
        textAlign: "center",
      });
      canvas.add(playerTrait2V);
      canvas.renderAll();
    }
    function writeTrait3V() {
      playerTrait3V.set({
        text: trait3V,
        fill: fontColor,
        fontSize: 26,
        fontWeight: 600,
        left: 85,
        top: 375,
        width: 20,
        textAlign: "center",
      });
      canvas.add(playerTrait3V);
      canvas.renderAll();
    }
    function writeTrait4V() {
      playerTrait4V.set({
        text: trait4V,
        fill: fontColor,
        fontSize: 26,
        fontWeight: 600,
        left: 195,
        top: 305,
        width: 20,
        textAlign: "center",
      });
      canvas.add(playerTrait4V);
      canvas.renderAll();
    }
    function writeTrait5V() {
      playerTrait5V.set({
        text: trait5V,
        visible: true,
        fill: fontColor,
        fontSize: 26,
        fontWeight: 600,
        left: 195,
        top: 340,
        width: 20,
        textAlign: "center",
      });
      canvas.add(playerTrait5V);
      canvas.renderAll();
    }
    function writeTrait6V() {
      playerTrait6V.set({
        text: trait6V,
        fill: fontColor,
        fontSize: 26,
        fontWeight: 600,
        left: 195,
        top: 375,
        width: 20,
        textAlign: "center",
      });
      canvas.add(playerTrait6V);
      canvas.renderAll();
    }
    writeTrait1V();
    writeTrait2V();
    writeTrait3V();
    writeTrait4V();
    writeTrait5V();
    writeTrait6V();
    // ratings
    var overallRating = new fabric.Textbox("", {
      originX: "left",
      originY: "top",
      editable: false,
      selectable: false,
      evented: false,
    });
    overallRating.set({
      fill: fontColor,
      text: overAllRating === "0" ? "" : String(overAllRating),
      fontFamily: fontLoaded ? fontFamily : "sans-serif",
      fontSize: 49,
      left: overAllRating === "87" ? 84 : 81,
      top: 70,
      fill: fontColor,
      textAlign: "center",
    });
    var path = new fabric.Path(chemistry, {
      selectable: false,
      editable: false,
      originX: "left",
      originY: "top",
      fill: fontColor,
      left: 5,
      top: 427,
      scaleX: chemistryName.toLowerCase() === "propack" ? -0.3 : -0.025,
      scaleY: chemistryName.toLowerCase() === "propack" ? -0.3 : 0.025,
      angle: 180,
    });

    if (path.height > 800) {
      path.set({
        top: 438,
      });
    } else if (
      path.height <= 800 &&
      chemistryName.toLowerCase() === "propack"
    ) {
      path.set({
        top: 425,
      });
    } else if (
      path.height <= 800 &&
      !chemistryName.toLowerCase() === "propack"
    ) {
      path.set({
        top: 430,
      });
    }

    // add the path to the canvas
    canvas.add(playerName);
    canvas.add(playerPosition);
    canvas.add(overallRating);
    canvas.add(path).centerObjectH(path);
    canvasEl.current = canvas;
  }, [
    bgImage,
    clientName,
    croppedImage,
    position,
    club,
    country,
    chemistry,
    fontColor,
    fontFamily,
    overAllRating,
    trait1,
    trait2,
    trait3,
    trait4,
    trait5,
    trait6,
    trait1V,
    trait2V,
    trait3V,
    trait4V,
    trait5V,
    trait6V,
  ]);
  const btnApply = useRef(null);
  const rotate1 = useRef(null);
  const rotate2 = useRef(null);
  const magnefier = useRef(null);
  const minus = useRef(null);
  const [cropperFullyLoaded, setCropperFullyLoaded] = useState(false);
  // useEffect(() => {
  //   if (imageProperties.src && cropperCanvas.current) {

  //     cropperCanvas.current.src = imageProperties.src;
  //     var ctx2 = previewCanvas.current.getContext("2d");
  //     var ctx3 = previewCanvas2.current.getContext("2d");
  //     var image = new Image();
  //     var image2 = new Image();
  //     var image3 = new Image();
  //     image3.crossOrigin = "Anonymous";
  //     image.src = imageProperties.src;
  //     var cropper;
  //     image.onload = () => {
  //       cropper = new Cropper(cropperCanvas.current, {
  //         aspectRatio: 150 / 190,
  //         viewMode: 1,
  //         background: true,
  //         ready: () => {
  //           setCropperFullyLoaded(true);
  //           rotate1.current.onclick = () => {
  //             cropper.rotate(90);
  //           };
  //           rotate2.current.onclick = () => {
  //             cropper.rotate(-90);
  //           };
  //           magnefier.current.onclick = () => {
  //             cropper.zoom(0.1);
  //           };
  //           minus.current.onclick = () => {
  //             cropper.zoom(-0.1);
  //           };
  //         },
  //         crop: () => {
  //           var croppedCanvas = cropper.getCroppedCanvas();
  //           image3.src = croppedCanvas.toDataURL();
  //           image3.onload = () => {
  //             ctx2.clearRect(127, 30, 180, 230);
  //             ctx2.drawImage(image3, 127, 30, 180, 230);
  //           };
  //         },
  //       });
  //       image3.src = "";
  //       cropper.reset().clear();
  //       cropper.replace(image.src);
  //     };
  //     btnApply.current.addEventListener("click", () => {
  //       setCroppedImage(image3.src);
  //       canvasModalHide();
  //     });
  //     image2.src = canvasEl.current.toDataURL();
  //     image2.onload = () => {
  //       ctx3.drawImage(image2, 0, 0, 350, 490);
  //       ctx2.drawImage(image2, 0, 0, 350, 490);
  //     };
  //   }

  //   return () => {
  //     if (cropper) {
  //       cropper.destroy();
  //     }
  //   };
  // }, [imageProperties.src]);

  //extras
  useEffect(() => {
    if (imageProperties.src && cropperCanvas.current) {
      cropperCanvas.current.src = imageProperties.src;
      const ctx2 = previewCanvas.current.getContext("2d");
      const ctx3 = previewCanvas2.current.getContext("2d");
      const image = new Image();
      const image2 = new Image();
      const image3 = new Image();
      image3.crossOrigin = "Anonymous";
      image.src = imageProperties.src;
      var cropper;

      image.onload = () => {
        EXIF.getData(image, function () {
          const orientation = EXIF.getTag(this, "Orientation");

          let rotatedImage = image;
          if (orientation === 6) {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = image.width;
            canvas.height = image.height;
            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate(Math.PI / 2);
            ctx.drawImage(image, -image.width / 2, -image.height / 2);
            rotatedImage = new Image();
            rotatedImage.src = canvas.toDataURL();
          }

          cropper = new Cropper(cropperCanvas.current, {
            aspectRatio: 150 / 190,
            viewMode: 1,
            background: true,
            ready: () => {
              setCropperFullyLoaded(true);
              rotate1.current.onclick = () => {
                cropper.rotate(90);
              };
              rotate2.current.onclick = () => {
                cropper.rotate(-90);
              };
              magnefier.current.onclick = () => {
                cropper.zoom(0.1);
              };
              minus.current.onclick = () => {
                cropper.zoom(-0.1);
              };
            },
            crop: () => {
              const croppedCanvas = cropper.getCroppedCanvas();
              image3.src = croppedCanvas.toDataURL();
              image3.onload = () => {
                ctx2.clearRect(131, 30, 172, 230);
                ctx2.drawImage(image3, 131, 30, 172, 230);
              };
            },
          });

          image3.src = "";
          cropper.reset().clear();
          cropper.replace(rotatedImage.src);
        });
      };

      btnApply.current.addEventListener("click", () => {
        // Handle the cropped image
        setCroppedImage(image3.src);
        canvasModalHide();
      });

      image2.src = canvasEl.current.toDataURL();
      image2.onload = () => {
        ctx3.drawImage(image2, 0, 0, 350, 490);
        ctx2.drawImage(image2, 0, 0, 350, 490);
      };
    }

    return () => {
      if (cropper) {
        cropper.destroy();
      }
    };
  }, [imageProperties.src]);
  const getExtrasSelected = useSelector((state) => state.checkOut.extraList);
  const textAreaRef = useRef(null);
  const [isTextAreaVisible, setIsTextAreaVisible] = useState(false);
  const [extrasToDisable, setExtrasToDisable] = useState([]);

  function handleCheckExtra(event, id) {
    var hasNote = event.target.getAttribute("data-note") === "1";
    var hasBundle = event.target.getAttribute("data-bundle") === "1";
    if (event.target.checked) {
      if (hasBundle) {
        const toDisable = extraBundles.filter(
          (extra) => extra.r_bundle_id === id
        );
        toDisable.forEach((extra) => {
          setExtrasToDisable((prevState) => [
            ...prevState,
            extra.r_card_extra_id,
          ]);
          dispatch(
            checkOutActions.decrementExtra({
              type: "notChecked",
              id: extra.r_card_extra_id,
            })
          );
        });
      }
      if (hasNote) {
        setIsTextAreaVisible(true);
        const t = extras.filter((ex) => ex.id === id);
        setNoteImgSelected(t[0].extra_images[0].id);
        setNoteExtraSelected(id);
        dispatch(
          checkOutActions.setGiftNoteImage({
            extraId: id,
            imgId: t[0].extra_images[0].id,
          })
        );
      }
      dispatch(checkOutActions.incrementExtra({ id }));
    } else {
      if (hasBundle) {
        const toDisable = extraBundles.filter(
          (extra) => extra.r_bundle_id === id
        );
        toDisable.forEach((extra) => {
          setExtrasToDisable((prevState) =>
            prevState.filter((item) => item !== extra.r_card_extra_id)
          );
        });
      }
      setIsTextAreaVisible(false);
      dispatch(checkOutActions.decrementExtra({ type: "notChecked", id: id }));
      setNoteImgSelected(null);
    }
  }
  useEffect(() => {
    if (isTextAreaVisible && textAreaRef.current) {
      textAreaRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isTextAreaVisible]);
  const [noteImgSelected, setNoteImgSelected] = useState(null);
  const [noteExtraSelected, setNoteExtraSelected] = useState(null);
  function handleGiftNoteImage(idExtra, idImage) {
    setNoteImgSelected(idImage);
    setNoteExtraSelected(idExtra);
    dispatch(
      checkOutActions.setGiftNoteImage({ extraId: idExtra, imgId: idImage })
    );
  }
  const [textAreaChars, setTextAreaAchars] = useState("");
  function handleGiftNoteText(event) {
    if (event.target.value.length <= 120) {
      setTextAreaAchars(event.target.value);
      dispatch(
        checkOutActions.setGiftNoteText({
          extraId: noteExtraSelected,
          extraNote: event.target.value,
        })
      );
    } else {
      return;
    }
  }
  //search
  //country
  const [queryCountry, setQueryCountry] = useState("");
  function handleChangeCountry(event) {
    setQueryCountry(event.target.value);
  }
  function handleKeyUpCountry() {
    searchCountry();
  }
  async function searchCountry() {
    const response = await axios.get(`${url}/wizard/search-country`, {
      params: { queryCountry },
    });
    setCountries(response.data);
  }
  //club
  const [queryClub, setQueryClub] = useState("");
  function handleChangeClub(event) {
    setQueryClub(event.target.value);
  }
  function handleKeyUpClub() {
    searchClub();
  }
  async function searchClub() {
    const response = await axios.get(`${url}/wizard/search-club`, {
      params: { queryClub },
    });
    setClubs(response.data);
  }
  //button disabled
  useEffect(() => {
    if (col1 && cardName) {
      dispatch(stylesActions.enableBtnNext());
    }
  }, [col1, cardName]);
  useEffect(() => {
    if (col2 && clientName && croppedImage && position) {
      dispatch(stylesActions.enableBtnNext());
    }
  }, [col2, clientName, position, croppedImage]);
  useEffect(() => {
    if (col3 && country && club) {
      dispatch(stylesActions.enableBtnNext());
    }
  }, [col3, country, club]);
  useEffect(() => {
    if (col4 && chemistry) {
      dispatch(stylesActions.enableBtnNext());
    }
  }, [col4, chemistry]);
  //attr position
  const cardStyleModal = useSelector((state) => state.card.cardStyle);
  const cardSizeModal = useSelector((state) => state.card.cardSize);
  const cardSizeNameModal = useSelector((state) => state.card.cardSizeName);
  const cardStyleNameModal = useSelector((state) => state.card.cardStyleName);
  const cardPriceModal = useSelector((state) => state.card.cardPrice);
  const closeModalHandler = () => {
    setShowModalCheckOut(false);
  };
  const canvasImage = useSelector((state) => state.checkOut.finalCanvasImage);
  const [mappedExtras, setMappedExtras] = useState([]);
  //checkOutModal
  const [showModalCheckOut, setShowModalCheckOut] = useState(false);
  const [loggedIn, setIsloggedIn] = useState(false);
  useEffect(() => {
    localforage.getItem("user").then((user) => {
      if (user !== null) {
        setIsloggedIn(true);
      } else {
        setIsloggedIn(false);
      }
    });
  }, []);
  function emptyAll() {
    closeModalHandler();
    dispatch(cardActions.setCardStyleId(""));
    dispatch(cardActions.setCardSizeId(""));
    dispatch(cardActions.setCardStyleName(""));
    dispatch(cardActions.setCardSizeName(""));
    updateImageProperties({
      src: "",
    });
    setShowUploadedImageContent(false);
    setBgImage("");
    setCardName("");
    setFontColor("");
    setCardId("");
    setClientName("");
    setSport("");
    setPosition("");
    setCountry("");
    setClub("");
    setCroppedImage("");
    setTrait1("");
    setTrait1V("");
    setTrait2("");
    setTrait2V("");
    setTrait3("");
    setTrait3V("");
    setTrait4("");
    setTrait4V("");
    setTrait5("");
    setTrait5V("");
    setTrait6("");
    setTrait6V("");
    setCategorySelected(categories[0].id);
    setCountryClicked(null);
    setClubClicked(null);
    setChemistryClicked(null);
    setChemistry("");
    setOverAllRating("");
    getExtras();
    props.continueShoppingHandler();
  }
  const triggerChildFunction = useSelector(
    (state) => state.checkOut.startInsert
  );
  useEffect(() => {
    if (triggerChildFunction) {
      insertCard();
    }
  }, [triggerChildFunction]);
  const [subTotal, setSubTotal] = useState(0);
  function insertCard() {
    notify("Please wait");
    var imageData = canvasEl.current.toDataURL();
    dispatch(checkOutActions.setFinalCanvasImage(imageData));
    const extrasWithQuantity = getExtrasSelected.filter(
      (extra) => extra.quantity > 0
    );
    setMappedExtras(extrasWithQuantity);
    var attr = new Array();
    attr.push(
      { name: trait1, value: trait1V },
      { name: trait2, value: trait2V },
      { name: trait3, value: trait3V },
      { name: trait4, value: trait4V },
      { name: trait5, value: trait5V },
      { name: trait6, value: trait6V }
    );
    let formData = new FormData();
    if (
      localStorage.getItem("session_id") &&
      localStorage.getItem("session_id") != null &&
      localStorage.getItem("session_id") !== "null"
    ) {
      formData.append("session_id", localStorage.getItem("session_id"));
    }
    formData.append("card_name", cardName);
    formData.append("is_logged_in", loggedIn);
    formData.append("token", localStorage.getItem("token"));
    formData.append("card_id", cardId);
    formData.append("player_name", clientName);
    formData.append("card_style", cardStyleModal);
    formData.append("card_size", cardSizeModal);
    formData.append("sport", sport);
    formData.append("position", position);
    formData.append("attribute_positions", JSON.stringify(attr));
    formData.append("country", country);
    formData.append("club", club);
    formData.append("chemistry", chemistryClickedImage);
    formData.append("extras", JSON.stringify(getExtrasSelected));
    formData.append("init_player_image", imageProperties.src);
    formData.append("cropped_image", croppedImage);
    formData.append("main_image", imageData);
    const url1 = `${url}/wizard/set-users-customized-card`;
    axios
      .post(url1, formData)
      .then((res) => {
        if (res.data.succeeded) {
          setShowModalCheckOut(true);
          if (
            !localStorage.getItem("session_id") ||
            localStorage.getItem("session_id") == null ||
            localStorage.getItem("session_id") === "null"
          ) {
            localStorage.setItem("session_id", res.data.session_id);
          }
          dispatch(checkOutActions.setCardsCount({ type: "update", count: 1 }));
          var card_total =
            parseFloat(cardPriceModal) +
            extrasWithQuantity.reduce((acx, extra) => {
              return acx + parseFloat(extra.price) * parseInt(extra.quantity);
            }, 0);
          setSubTotal(card_total);
          notify(res.data.message);
        } else {
          notify(res.data.message);
        }
        dispatch(checkOutActions.setInsertCard());
      })
      .catch((err) => console.log(err));
  }
  const containerRef = useRef(null);
  useEffect(() => {
    containerRef.current.scrollTop = 0;
  }, [col1, col2, col3, col4]);
  const [canvasModalShow, setCanvasModalShow] = useState(false);
  const canvasModalHide = () => {
    setCanvasModalShow(false);
    playerImageRef.current.value = "";
    // updateImageProperties({
    //   src: "",
    // });
    setCropperFullyLoaded(false);
  };
  const handleImageDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    playerImageHandler(files);
  };
  // const [loadedCards, setLoadedCards] = useState([]);
  const categoryChangeHandler = (id) => {
    setCategorySelected(id);
    // setLoadedCards(null);
  };
  function roundNumber(num) {
    if (num % 1 !== 0 && num.toString().split(".")[1].length >= 3) {
      return num.toFixed(2);
    } else {
      return num;
    }
  }
  return (
    <section className="step2Cont">
      <Container
        className="!overflow-y-scroll !overflow-x-hidden !h-[68vh]"
        ref={containerRef}
      >
        <ToastContainer autoClose={3000} />
        {/* Loader modal */}
        <Modal
          show={isImageProcessing}
          onHide={() => {
            setIsImageProcessing(false);
          }}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Body>
            <Row>
              <img src={LoaderImage} alt="Processing..." />
            </Row>
            <Row className="!text-center !text-black !font-semibold">
              <p className="!text-sm !whitespace-nowrap">
                Please wait, your photo is being cropped.
              </p>
            </Row>
          </Modal.Body>
        </Modal>
        {/* custom flag modal */}
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          animation={true}
          show={modalShowCustomFlag}
          onHide={handleCloseCustomFlag}
          scrollable={true}
        >
          <Modal.Header closeButton className="!border-b-0">
            <Modal.Title
              id="contained-modal-title-vcenter"
              className="!text-[#000] !flex !flex-row !items-center"
            >
              <img src={icon14} alt="..." />
              <p className="!text-[#000] !m-0 !px-2">Upload custom flag</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
            <Form>
              <Row>
                <Form.Group
                  className="!border-dashed !border-[#FF733A] !border-2 !rounded-[15px] !py-4 !px-3"
                  onClick={customFlagInputhandler}
                >
                  <Form.Label className="!m-0 !flex !flex-col !items-center !justify-center !cursor-pointer !text-[#000] !font-bold">
                    <img src={icon44} alt="..." />
                    Upload custom flag
                  </Form.Label>
                  <Form.Control
                    type="file"
                    size="lg"
                    className="!hidden"
                    ref={flagInputRef}
                    onChange={customFlagHandler}
                  />
                </Form.Group>
              </Row>
            </Form>
            <Row className="!my-5">
              <div className="!flex !items-center ">
                <img src={icon54} alt="..." />
                <p className="!text-[#000] !font-medium !m-0 !px-2">
                  Uploaded flag will be fully edited by our design team.
                </p>
              </div>
            </Row>
          </Modal.Body>
        </Modal>
        {/* custom badge modal */}
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          animation={true}
          show={modalShowCustomBadge}
          onHide={handleCloseCustomBadge}
          scrollable={true}
        >
          <Modal.Header closeButton className="!border-b-0">
            <Modal.Title
              id="contained-modal-title-vcenter"
              className="!text-[#000] !flex !flex-row !items-center"
            >
              <img src={icon24} alt="..." />
              <p className="!text-[#000] !m-0 !px-2">Upload custom badge</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
            <Form>
              <Row>
                <Form.Group
                  className="!border-dashed !border-[#FF733A] !border-2 !rounded-[15px] !py-4 !px-3"
                  onClick={customClubInputhandler}
                >
                  <Form.Label className="!m-0 !flex !flex-col !items-center !justify-center !cursor-pointer !text-[#000] !font-bold">
                    <img src={icon44} alt="..." />
                    Upload custom badge
                  </Form.Label>
                  <Form.Control
                    type="file"
                    size="lg"
                    className="!hidden"
                    ref={clubInputRef}
                    onChange={customClubHandler}
                  />
                </Form.Group>
              </Row>
            </Form>
            <Row className="!my-5">
              <div className="!flex !items-center ">
                <img src={icon54} alt="..." />
                <p className="!text-[#000] !font-medium !m-0 !px-2">
                  Uploaded badge will be fully edited by our design team.
                </p>
              </div>
            </Row>
          </Modal.Body>
        </Modal>
        {/* image guide modal */}
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          animation={true}
          show={imageGuideModal}
          onHide={handleClose}
          scrollable
        >
          <Modal.Header closeButton className="!border-b-0">
            <Modal.Title
              id="contained-modal-title-vcenter"
              className="!text-[#000] !font-extrabold"
            >
              Propack image guide
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={`${classes.pp} show-grid`}>
            <Container>
              <Row>
                <Col className="!text-[#000] font-semibold">
                  <Row
                    dangerouslySetInnerHTML={{ __html: imageGuide[0]?.text }}
                  ></Row>
                </Col>
                <Col>
                  <div className="flex flex-col justify-center items-center">
                    {imageGuide && (
                      <LazyLoad>
                        <ProgressiveImage
                          src={imageGuide[0]?.main_image}
                          placeholder={`${imageGuide[0]?.path}blures/${imageGuide[0]?.file_name}`}
                        >
                          {(src) => (
                            <Card.Img
                              src={src}
                              variant="top"
                              className="ImgClass !rounded-t-[16px] !rounded-b-[16px]"
                            />
                          )}
                        </ProgressiveImage>
                      </LazyLoad>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
        {/* extras guide modal */}
        {/* <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          animation={true}
          show={extrasGuideModal}
          onHide={() => {
            setExtrasGuideModal(false);
          }}
          scrollable
        >
          <Modal.Header closeButton className="!border-b-0">
            <Modal.Title
              id="contained-modal-title-vcenter"
              className="!text-[#000] !font-extrabold"
            >
              Propack extras guide
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={`${classes.pp} show-grid`}>
            <Container>
              <Row>
                <Col className="!text-[#000] font-semibold">
                  {extrasGuide.content && (
                    <Row
                      dangerouslySetInnerHTML={{
                        __html: extrasGuide.content[0]?.text,
                      }}
                    />
                  )}
                </Col>
                <Col>
                  <div className="flex flex-col justify-center items-center">
                    {extrasGuide.content && (
                      <LazyLoad>
                        <ProgressiveImage
                          src={extrasGuide.content[0]?.main_image}
                          placeholder={`${extrasGuide.content[0]?.path}blures/${extrasGuide.content[0]?.file_name}`}
                        >
                          {(src) => (
                            <Card.Img
                              src={src}
                              variant="top"
                              className="ImgClass !rounded-t-[16px] !rounded-b-[16px]"
                            />
                          )}
                        </ProgressiveImage>
                      </LazyLoad>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal> */}
        {/* checkout modal */}
        {/* checkout modal */}
        <Modal
          show={showModalCheckOut}
          onHide={closeModalHandler}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="!border-b-0" closeButton>
            <Modal.Title
              id="contained-modal-title-vcenter"
              className="!ml-auto !flex !items-center"
            >
              <img src={image74} alt="succeeded" className="!mr-2 !h-10 !w-8" />
              Your order has been added to your cart
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="!pt-4">
              <Col className="border-r-4 !pl-0">
                <div className="flex flex-row items-center">
                  <div>
                    <img
                      src={canvasImage}
                      alt="..."
                      className="!h-72 !object-contain"
                    />
                  </div>
                  <div>
                    <ul className="!pl-0 capitalize whitespace-nowrap">
                      <li className="!text-base !text-[#000] !font-semibold !pt-1">
                        {cardName}
                      </li>
                      <li className="!text-base !text-[#000] !font-semibold !pt-1">
                        Style: {cardStyleNameModal}
                      </li>
                      <li className="!text-base !text-[#000] !font-semibold !pt-1">
                        Size: {cardSizeNameModal}
                      </li>
                      <li className="!text-[#000] !text-2xl font-extrabold !pt-1">
                        Card Price: ${cardPriceModal}
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col className="px-4">
                <Row className="!border-b-2">
                  <h4 className="text-[#000] font-bold">Shopping Cart</h4>
                  <ul className="!list-disc !px-9  whitespace-nowrap">
                    <li className="text-[#000] font-semibold capitalize">
                      <span className="inline-block">{cardName} </span>
                      <span className="inline-block float-right !font-bold">
                        ${roundNumber(cardPriceModal)}
                      </span>
                    </li>
                  </ul>
                  {mappedExtras.length > 0 && (
                    <Table size="sm">
                      <thead>
                        <tr>
                          <th className="!border-b-0">Extras:</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>Extra</th>
                          <th>Unit price</th>
                          <th className="!text-right">Total price</th>
                        </tr>
                        {mappedExtras.map((extra, index) => (
                          <tr key={index}>
                            <td>
                              {extra.option_name} x{extra.quantity}
                            </td>
                            <td className="!text-center">${extra.price}</td>
                            <td className="!text-right">
                              ${extra.price * extra.quantity}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </Row>
                <Row className="pt-2">
                  <div className="!flex !justify-between">
                    <p className="text-[#000] font-bold">Subtotal</p>
                    <p className="text-[#000] font-extrabold text-lg">
                      ${roundNumber(subTotal)}
                    </p>
                  </div>
                </Row>
                <Row>
                  <div className="flex items-center justify-center">
                    <Link
                      to="/cart"
                      className="!text-blue-500 !font-bold text-center px-3 py-2 hover:text-[#fff]"
                    >
                      View cart
                    </Link>
                  </div>
                </Row>
              </Col>
            </Row>
            <Row>
              <div className="flex justify-between !pt-7 px-5">
                <Button
                  className="!bg-[#E6E9EE] !text-[#000] !flex !items-center !justify-center !rounded-[60px] !border-none"
                  onClick={emptyAll}
                >
                  <img src={image72} alt="..." className="!pr-2" />
                  Continue shopping
                </Button>
                <Link
                  className="!bg-[#FF733A] !text-[#fff] !flex !items-center !justify-center !rounded-[60px] !border-none !px-3"
                  to="/checkout"
                >
                  <img src={image73} alt="..." className="!pr-2" />
                  Proceed to checkout
                </Link>
              </div>
            </Row>
          </Modal.Body>
        </Modal>
        {/* cropper modal */}
        <Modal
          show={canvasModalShow}
          onHide={canvasModalHide}
          aria-labelledby="contained-modal-title-vcenter"
          size={"lg"}
          className="!h-[100vh]"
          scrollable
        >
          <Modal.Header closeButton className="!border-none !mb-0 !pb-0">
            <Modal.Title
              id="contained-modal-title-vcenter"
              className="ml-auto !flex !items-center !justify-center !flex-col !text-lg"
            >
              <span>Edit image</span>
              <span>Please select your desired part of the photo</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid bg">
            <Container>
              <Row>
                <Col sm={12} md={12} lg={6}>
                  <img
                    ref={cropperCanvas}
                    className="!object-contain !min-h-[390px] !max-h-[390px]"
                    src=""
                    alt="Image to crop, Please wait."
                    width="100%"
                    height="490"
                  />
                </Col>
                <Col
                  className="!relative md:!h-[400px] sm:!h-[400px]"
                  sm={12}
                  md={12}
                  lg={6}
                >
                  <canvas
                    ref={previewCanvas}
                    width="350"
                    height="490"
                    className="!absolute !top-0 !left-10 !z-50"
                  />
                  <canvas
                    ref={previewCanvas2}
                    width="350"
                    height="490"
                    className="!absolute !top-0 !left-10"
                  />
                </Col>
              </Row>
              <Row className="!mt-16">
                <Col sm={6}>
                  <div className="!bg-[#E6E9EE] !rounded-3xl grid grid-cols-4 gap-0 mt-2 py-3 px-10 mx-auto">
                    <img
                      src={r1}
                      alt="rotate1"
                      className="!cursor-pointer"
                      ref={rotate1}
                    />
                    <img
                      src={r2}
                      alt="rotate2"
                      className="!cursor-pointer"
                      ref={rotate2}
                    />
                    <img
                      src={m1}
                      alt="mag1"
                      className="!cursor-pointer"
                      ref={magnefier}
                    />
                    <img
                      src={m2}
                      alt="mag2"
                      className="!cursor-pointer"
                      ref={minus}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="!flex !flex-row !items-center !justify-center !text-xs">
                    <img src={icon54} alt="ring" className="!pr-1" />
                    In case you couldn’t edit certain details in the photo, our
                    team can modify it while preparing your package.
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer className="!justify-center !border-none !mt-0 !pt-0">
            <Row>
              <Col>
                <Button
                  onClick={canvasModalHide}
                  className="!flex !items-center !justify-between !border-none !bg-[#bbb] !text-black !rounded-[20px] !px-20 !font-semibold"
                >
                  <img src={image57} alt="back" className="!px-1 !pt-2" />
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  ref={btnApply}
                  className="!flex !items-center !justify-between !border-none !bg-[#FF733A] !text-white !rounded-[20px] !whitespace-nowrap !px-20 !font-semibold"
                  disabled={!cropperFullyLoaded}
                >
                  {cropperFullyLoaded && (
                    <>
                      <img
                        src={image56}
                        alt="continue"
                        className="!px-1 !pt-2"
                      />{" "}
                      Apply
                    </>
                  )}
                  {!cropperFullyLoaded && (
                    <>
                      <Spinner animation="border" size="sm" />
                      Please wait
                    </>
                  )}
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
        {col1 && (
          <Col className="!relative">
            <div className="!fixed !left-5 !right-10 !z-50">
              <Swiper
                modules={[Scrollbar, Navigation]}
                scrollbar={{
                  hide: false,
                  draggable: true,
                  dragSize: 100,
                }}
                slidesPerView={8}
                className="swiperContainer !m-auto !bg-white !text-center"
                tag="ul"
                wrapperTag="ul"
              >
                {categories.map((card) => (
                  <SwiperSlide
                    tag="li"
                    key={card.id}
                    className="cursor-pointer !pt-2"
                    onClick={() => categoryChangeHandler(card.id)}
                  >
                    <h5
                      className={
                        categorySelected === card.id
                          ? "!text-[#FF733A] !font-semibold sm:!text-xs lg:!text-lg  hover:!text-[#ff5359d1] outline outline-offset-1 outline-1"
                          : "!text-[#A04723] !font-semibold sm:!text-xs lg:!text-lg  hover:!text-[#ff5359d1]"
                      }
                    >
                      {card.name}
                    </h5>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            {/* cards grid */}
            {!cardStyleLoading && cardStyle && (
              <Row className="!pt-16">
                <p className="!text-[#000] !font-bold">Select Card Design</p>
                <div className="grid grid-cols-9 xl:grid-cols-7 md:grid-cols-4 lg:grid-cols-6 sm:grid-cols-4 gap-1">
                  {cardStyle?.map((card) => (
                    <div key={card.id} className="cursor-pointer">
                      <Card
                        className={
                          cardId === card.id
                            ? "!w-32 !mb-4 !border-slate-400 !border-4"
                            : "!w-32 !mb-4 !border-none"
                        }
                      >
                        {card.card_label && (
                          <Card.Subtitle
                            className="!text-[13px] !absolute !top-1 !px-4 !right-0 !text-center !bg-[#00B67A] !text-white"
                            style={{ borderRadius: " 0px 7px 0px 7px" }}
                          >
                            {card.card_label}
                          </Card.Subtitle>
                        )}
                        <LazyLoad>
                          <ProgressiveImage
                            src={`${card.path}thumbnails/${card.file_name}`}
                            placeholder={`${card.path}blures/${card.file_name}`}
                          >
                            {(src) => (
                              <img
                                src={src}
                                // loading="lazy"
                                alt={card.cards_name}
                                className="!mt-3"
                                onClick={() => {
                                  imageChangeHandler(
                                    `${card.path}thumbnails/${card.file_name}`,
                                    card.cards_name,
                                    card.font_color,
                                    card.id,
                                    card.font_family
                                  );
                                }}
                                // onLoad={() => {setLoadedCards([...loadedCards,card.id])}}
                              />
                            )}
                          </ProgressiveImage>
                        </LazyLoad>
                        <Card.Body className="!text-center !pt-0">
                          <Card.Title className="!text-sm !text-[#000000] !font-black">
                            {card.cards_name}
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                </div>
              </Row>
            )}
            {!cardStyleLoading && !cardStyle && (
              <h1 className="!text-center !mt-[15%]">
                No Cards Found Here Yet
              </h1>
            )}
            {cardStyleLoading && (
              <div className="!pt-[10%]">
                <Loader />
              </div>
            )}
          </Col>
        )}
        <Row>
          {col2 && (
            <Col sm={6} md={6} lg={6}>
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <img src={icon1} alt=".." />
                  <p className="!font-bold !text-[#000] !m-0 !px-2 md:text-xs lg:text-lg">
                    Name & image upload
                  </p>
                </div>
                <div>
                  <label
                    htmlFor="imgGuide"
                    className="!text-[#999999] flex items-center justify-around cursor-pointer md:text-xs lg:text-lg"
                    onClick={() => {
                      dispatch(stylesActions.showImageGuideModal());
                    }}
                  >
                    Image Guide
                    <img className="!p-5" src={icon2} alt=".." />
                  </label>
                </div>
              </div>
              <Row>
                <InputGroup as={Col} controlid="formGridEmail" size="sm">
                  <FloatingLabel
                    controlid="floatingInput"
                    label="Enter Your Name"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Enter Your Name"
                      className="!rounded-[15px] !border-[#FF733A]"
                      onChange={nameChangeHandler}
                      value={clientName}
                    />
                  </FloatingLabel>
                </InputGroup>
              </Row>
              <Row className="!mx-0">
                {showUploadedImageContent && (
                  <Col
                    lg={2}
                    className="!flex !flex-col !items-center !justify-around"
                  >
                    {croppedImage && (
                      <img
                        src={croppedImage}
                        alt="Uploaded"
                        className="!h-[60px] !w-[79px] !rounded-[10px] !object-contain"
                      />
                    )}
                    <Button
                      className="!rounded-[20px] !bg-[#E6E9EE] !text-[#000] !px-4 !py-1 !border-none !flex !items-center"
                      onClick={clearUploadedImage}
                    >
                      <img src={icon9} alt="..." className="" />
                      Delete
                    </Button>
                  </Col>
                )}
                <Col className="!pl-0 !pr-0">
                  <Form.Group
                    className="!border-dashed !border-[#FF733A] !border-2 !rounded-[15px] !py-4 !px-3"
                    onClick={playerImageInputHandler}
                    onDrop={(e) => handleImageDrop(e)}
                    onDragOver={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <Form.Label className="!m-0 !flex !flex-col !items-center !justify-center !cursor-pointer !text-[#000] !font-bold">
                      {!showUploadedImageContent && (
                        <div className="flex flex-col items-center !justify-center">
                          <img src={icon3} alt="..." />
                          Drag or upload image
                        </div>
                      )}
                      {showUploadedImageContent && (
                        <div className="flex items-center !justify-center !py-1">
                          <h5 className="!mb-0 muted">Uploaded</h5>
                          <div className="!ml-2">
                            <img
                              src={image74}
                              alt="uploaded"
                              className="!h-6"
                            />
                          </div>
                        </div>
                      )}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      size="lg"
                      className="!hidden"
                      ref={playerImageRef}
                      onChange={playerImageHandler}
                      disabled={showUploadedImageContent}
                      accept="image/*"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="!my-2">
                <div className="flex items-center">
                  <img src={icon5} alt=".." />
                  <p className="!font-bold !text-[#000] !m-0 !px-2 md:text-xs lg:text-lg">
                    Choose a sport & position
                  </p>
                </div>
              </Row>
              <div className="!px-5 !bg-[#FFF1EB] !rounded-[20px]">
                <Tab.Container activeKey={`#${firstSport}`}>
                  <Row>
                    <Row sm={4} className="!overflow-x-scroll">
                      <ListGroup horizontal>
                        {sportS.map((sport, index) => (
                          <ListGroup.Item
                            key={index}
                            action
                            eventKey={`#${sport.sport_name}`}
                            className="bg-transparent !text-[#000] !font-semibold !border-x-0 !border-t-0 !rounded-none !pl-0 md:text-xs lg:text-lg"
                            onClick={() => {
                              setSport(sport.id);
                              setFirstSport(sport.sport_name);
                            }}
                          >
                            {sport.sport_name}
                          </ListGroup.Item>
                        ))}
                        <ListGroup.Item
                          action
                          eventKey="#custom"
                          className="bg-transparent !text-[#000] !font-semibold !border-x-0 !border-t-0 !rounded-none md:text-xs lg:text-lg"
                          onClick={() => {
                            setSport(0);
                            setFirstSport("custom");
                          }}
                        >
                          Custom
                        </ListGroup.Item>
                      </ListGroup>
                    </Row>
                    <Col className="!pl-0">
                      <Tab.Content className="!py-2">
                        {sportS.map((sport, index) => (
                          <Tab.Pane
                            eventKey={`#${sport.sport_name}`}
                            key={index}
                          >
                            <div>
                              {sport.positions.map((positionM, index) => (
                                <div className="!inline-block" key={index}>
                                  <label className="cursor-pointer" key={index}>
                                    <Form.Check
                                      inline
                                      className="!bg-white !pr-5 !pl-10 !py-1 !rounded-[10px] !border-[black] !border-[0.5px]"
                                      label={positionM.position}
                                      type="radio"
                                      id={positionM.position}
                                      name="rdbs"
                                      value={positionM.position}
                                      onClick={(event) =>
                                        positionChangeHandler(
                                          event,
                                          positionM.category_id
                                        )
                                      }
                                    />
                                  </label>
                                </div>
                              ))}
                            </div>
                          </Tab.Pane>
                        ))}
                        <Tab.Pane eventKey="#custom">
                          <Row>
                            <InputGroup
                              as={Col}
                              controlid="formGridEmail"
                              size="sm"
                            >
                              <FloatingLabel
                                controlid="floatingInput"
                                label="Enter Custom Position"
                                className="mb-3"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Custom Position"
                                  id="customPosition"
                                  className="!rounded-[15px] !border-[#FF733A]"
                                  onKeyUp={(event) =>
                                    positionChangeHandler(
                                      event,
                                      sportS[0].positions[0].category_id
                                    )
                                  }
                                />
                              </FloatingLabel>
                            </InputGroup>
                          </Row>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </Col>
          )}
          {col3 && (
            <Col sm={6} md={6}>
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <img src={icon14} alt=".." />
                  <p className="!font-bold !text-[#000] !m-0 !px-2">
                    Choose a country flag
                  </p>
                </div>
                <div>
                  <div onClick={customFlagModal}>
                    <Lottie options={defaultOptions} height={50} width={50} />
                  </div>
                </div>
              </div>
              <Row>
                <InputGroup
                  as={Col}
                  controlid="formGridSearchCountry"
                  size="sm"
                >
                  <FloatingLabel
                    controlid={placeholder}
                    label={placeholder}
                    className="mb-3"
                  >
                    <Form.Control
                      type="search"
                      onChange={handleChangeCountry}
                      onKeyUp={handleKeyUpCountry}
                      placeholder={placeholder}
                      className="!rounded-[15px] !border-[#FF733A]"
                    />
                  </FloatingLabel>
                </InputGroup>
              </Row>
              <Row className="">
                <Swiper
                  modules={[Scrollbar]}
                  scrollbar={{
                    hide: false,
                    draggable: true,
                    dragSize: 100,
                  }}
                  centeredSlides={false}
                  slidesPerView={"auto"}
                  slideToClickedSlide={false}
                  spaceBetween={12}
                  className="swiperContainer !ml-5"
                >
                  {continents.map((continent) => (
                    <SwiperSlide
                      className="swiperSlide"
                      key={continent.id}
                      value={continent.id}
                      onClick={() => {
                        setContinentSelected(continent.id);
                      }}
                    >
                      <p
                        className={
                          continentSelected === continent.id
                            ? "!text-[#FF733A] !font-semibold !cursor-pointer hover:!scale-110 hover:!text-[#FF533A] select-none"
                            : "!text-[#A04723] !font-semibold !cursor-pointer hover:!scale-110 hover:!text-[#A02723] select-none"
                        }
                      >
                        {continent.name}
                      </p>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Row>
              <Row className="">
                <Col className="rdbs">
                  <div className="!h-[20vh] !overflow-y-scroll !flex !flex-wrap !items-center !bg-[#FFF1EB] !rounded-[20px]">
                    {countries?.map((country) => (
                      <Button
                        key={country.id}
                        className={
                          countryClicked === country.id
                            ? "!m-1 !text-[#fff] !font-semibold !bg-[#FF733A] !border-2 !border-[#fff] !rounded-[20px] !flex !items-center"
                            : "!m-1 !text-[#000] !font-semibold !bg-transparent !border-2 !border-[#999] !rounded-[20px] !flex !items-center"
                        }
                        onClick={() => {
                          countryChangeHandler(country.id, country.main_image);
                        }}
                      >
                        <LazyLoad offset={300}>
                          <CustomProgressiveImage
                            src={country.main_image}
                            thumbnail={`${country.path}thumbnails/${country.file_name}`}
                            blur={`${country.path}blures/${country.file_name}`}
                            className="!pr-1 !h-4"
                          />
                        </LazyLoad>
                        {country.name}
                        <img
                          src={icon64}
                          alt="..."
                          className={
                            countryClicked === country.id
                              ? "!pt-1 !px-1"
                              : "!hidden"
                          }
                        />
                      </Button>
                    ))}
                  </div>
                </Col>
              </Row>
              <Row className="!mt-2">
                <div className="flex !justify-between !items-center">
                  <div className="flex items-center">
                    <img src={icon24} alt=".." />
                    <p className="!font-bold !text-[#000] !m-0 !px-2">
                      Choose club
                    </p>
                  </div>
                  <div>
                    <div onClick={customClubModal}>
                      <Lottie options={defaultOptions} height={50} width={50} />
                    </div>
                  </div>
                </div>
              </Row>
              <Row>
                <InputGroup as={Col} controlid="formGridEmail" size="sm">
                  <FloatingLabel
                    controlid={placeholder2}
                    label={placeholder2}
                    className="mb-3"
                  >
                    <Form.Control
                      type="search"
                      onChange={handleChangeClub}
                      onKeyUp={handleKeyUpClub}
                      placeholder={placeholder2}
                      className="!rounded-[15px] !border-[#FF733A]"
                    />
                  </FloatingLabel>
                </InputGroup>
                <Row className="">
                  <Swiper
                    modules={[Scrollbar]}
                    scrollbar={{
                      hide: false,
                      draggable: true,
                      dragSize: 100,
                    }}
                    centeredSlides={false}
                    slidesPerView={"auto"}
                    slideToClickedSlide={false}
                    spaceBetween={15}
                    className="swiperContainer !ml-5"
                  >
                    {leagues.map((league) => (
                      <SwiperSlide
                        className="swiperSlide"
                        key={league.id}
                        value={league.id}
                        onClick={() => {
                          setLeagueSelected(league.id);
                        }}
                      >
                        <p
                          className={
                            leagueSelected === league.id
                              ? "!text-[#FF733A] !font-semibold !cursor-pointer hover:!scale-105 hover:!text-[#FF533A] select-none"
                              : "!text-[#A04723] !font-semibold !cursor-pointer hover:!scale-105 hover:!text-[#A02723] select-none"
                          }
                        >
                          {league.league_name}
                        </p>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </Row>
                <Row className="!pt-1">
                  <Col className="rdbs">
                    <div className="!h-[20vh] !overflow-y-scroll !flex !flex-wrap !items-center !bg-[#FFF1EB] !rounded-[20px]">
                      {clubs?.map((club) => (
                        <Button
                          key={club.id}
                          className={
                            clubClicked === club.id
                              ? "!m-1 !text-[#fff] !font-semibold !bg-[#FF733A] !border-2 !border-[#fff] !rounded-[20px] !flex !items-center"
                              : "!m-1 !text-[#000] !font-semibold !bg-transparent !border-2 !border-[#999] !rounded-[20px] !flex !items-center"
                          }
                          onClick={() => {
                            clubChangeHandler(club.id, club.main_image);
                          }}
                        >
                          <LazyLoad offset={300}>
                            <ProgressiveImage
                              src={`${club.path}${club.file_name}`}
                              placeholder={`${club.path}blures/${club.file_name}`}
                            >
                              {(src) => (
                                <img
                                  src={src}
                                  alt="..."
                                  loading="lazy"
                                  className="!pr-1 !h-8"
                                />
                              )}
                            </ProgressiveImage>
                          </LazyLoad>
                          {club.club_name}
                          <img
                            src={icon64}
                            alt="..."
                            className={
                              clubClicked === club.id
                                ? "!pt-1 !px-1"
                                : "!hidden"
                            }
                          />
                        </Button>
                      ))}
                    </div>
                  </Col>
                </Row>
              </Row>
            </Col>
          )}
          {col4 && (
            <Col sm={6} md={6}>
              <Row>
                <div className="flex items-center">
                  <img src={icon15} alt=".." />
                  <p className="!font-bold !text-[#000] !m-0 !px-2">
                    Choose attributes
                  </p>
                </div>
              </Row>
              <Row className="!pl-3">
                <Col>
                  <Row className="my-2">
                    <Form.Control
                      min={0}
                      max={99}
                      size="lg"
                      type="number"
                      placeholder="99"
                      className="!w-[40%] !mr-2 !rounded-r-none !rounded-l-[14px] !border-[#FF733A]"
                      onChange={trait1VHandler}
                      value={trait1V}
                    />
                    <Form.Control
                      size="lg"
                      type="text"
                      className="!uppercase !w-[40%] !rounded-l-none !rounded-r-[14px] !border-[#FF733A] md:!text-sm lg:!text-base"
                      defaultValue={trait1}
                      onKeyUp={trait1THandler}
                      // maxLength={3}
                    />
                  </Row>
                  <Row className="my-2">
                    <Form.Control
                      size="lg"
                      type="number"
                      placeholder="99"
                      className="!w-[40%] !mr-2 !rounded-r-none !rounded-l-[14px] !border-[#FF733A]"
                      onChange={trait2VHandler}
                      value={trait2V}
                    />
                    <Form.Control
                      size="lg"
                      type="text"
                      className="!uppercase !w-[40%] !rounded-l-none !rounded-r-[14px] !border-[#FF733A] md:!text-sm lg:!text-base"
                      defaultValue={trait2}
                      onKeyUp={trait2THandler}
                      // maxLength={3}
                    />
                  </Row>
                  <Row className="my-2">
                    <Form.Control
                      min={0}
                      max={99}
                      size="lg"
                      type="number"
                      placeholder="99"
                      className="!w-[40%] !mr-2 !rounded-r-none !rounded-l-[14px] !border-[#FF733A]"
                      onChange={trait3VHandler}
                      value={trait3V}
                    />
                    <Form.Control
                      size="lg"
                      type="text"
                      className="!uppercase !w-[40%] !rounded-l-none !rounded-r-[14px] !border-[#FF733A] md:!text-sm lg:!text-base"
                      defaultValue={trait3}
                      onKeyUp={trait3THandler}
                      // maxLength={3}
                    />
                  </Row>
                </Col>
                <Col>
                  <Row className="my-2">
                    <Form.Control
                      size="lg"
                      type="number"
                      placeholder="99"
                      className="!w-[40%] !mr-2 !rounded-r-none !rounded-l-[14px] !border-[#FF733A]"
                      onChange={trait4VHandler}
                      value={trait4V}
                    />
                    <Form.Control
                      size="lg"
                      type="text"
                      className="!uppercase !w-[40%] !rounded-l-none !rounded-r-[14px] !border-[#FF733A] md:!text-sm lg:!text-base"
                      defaultValue={trait4}
                      onKeyUp={trait4THandler}
                      // maxLength={3}
                    />
                  </Row>
                  <Row className="my-2">
                    <Form.Control
                      min={0}
                      max={99}
                      size="lg"
                      type="number"
                      placeholder="99"
                      className="!w-[40%] !mr-2 !rounded-r-none !rounded-l-[14px] !border-[#FF733A]"
                      onChange={trait5VHandler}
                      value={trait5V}
                    />
                    <Form.Control
                      size="lg"
                      type="text"
                      className="!uppercase !w-[40%] !rounded-l-none !rounded-r-[14px] !border-[#FF733A] md:!text-sm lg:!text-base"
                      defaultValue={trait5}
                      onKeyUp={trait5THandler}
                      // maxLength={3}
                    />
                  </Row>
                  <Row className="my-2">
                    <Form.Control
                      min={0}
                      max={99}
                      size="lg"
                      type="number"
                      placeholder="99"
                      className="!w-[40%] !mr-2 !rounded-r-none !rounded-l-[14px] !border-[#FF733A]"
                      onChange={trait6VHandler}
                      value={trait6V}
                    />
                    <Form.Control
                      size="lg"
                      type="text"
                      className="!uppercase !w-[40%] !rounded-l-none !rounded-r-[14px] !border-[#FF733A] md:!text-sm lg:!text-base"
                      defaultValue={trait6}
                      onKeyUp={trait6THandler}
                      // maxLength={3}
                    />
                  </Row>
                </Col>
              </Row>
              <Row>
                <div className="flex items-center">
                  <img src={icon25} alt=".." />
                  <p className="!font-bold !text-[#000] !m-0 !px-2">
                    Overall rating
                  </p>
                </div>
              </Row>
              <Row>
                <Col className="!py-3">
                  <Form.Control
                    min={0}
                    max={99}
                    size="lg"
                    type="number"
                    placeholder="99"
                    className="!mr-2 !border-[#FF733A] !text-center !rounded-[14px] !text-4xl"
                    value={overAllRating}
                    onChange={overAllRatingHandler}
                  />
                </Col>
                <Col>
                  <div className="!text-center">
                    <p className="!mb-1 !font-black">Suggested ratings:</p>
                    <div className="!flex !flex-row !justify-between">
                      <Form.Control
                        readOnly
                        value="99"
                        onClick={sugg1}
                        size="md"
                        type="text"
                        placeholder="99"
                        className={
                          overAllRating === "99"
                            ? "!border-[#A04723] !border-2 !w-[30%] !rounded-[14px] !p-2 !text-center !cursor-pointer !text-[#000] !font-medium"
                            : "!border-[#FF733A] !w-[30%] !rounded-[14px] !p-2 !text-center !cursor-pointer !text-[#000] !font-medium"
                        }
                      />
                      <Form.Control
                        readOnly
                        value="93"
                        onClick={sugg2}
                        size="md"
                        type="text"
                        placeholder="99"
                        className={
                          overAllRating === "93"
                            ? "!border-[#A04723] !border-2 !w-[30%] !rounded-[14px] !p-2 !text-center !cursor-pointer !text-[#000] !font-medium"
                            : "!border-[#FF733A] !w-[30%] !rounded-[14px] !p-2 !text-center !cursor-pointer !text-[#000] !font-medium"
                        }
                      />
                      <Form.Control
                        readOnly
                        value="87"
                        onClick={sugg3}
                        size="md"
                        type="text"
                        placeholder="99"
                        className={
                          overAllRating === "87"
                            ? "!border-[#A04723] !border-2 !w-[30%] !rounded-[14px] !p-2 !text-center !cursor-pointer !text-[#000] !font-medium"
                            : "!border-[#FF733A] !w-[30%] !rounded-[14px] !p-2 !text-center !cursor-pointer !text-[#000] !font-medium"
                        }
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <div className="flex items-center">
                  <img src={icon16} alt=".." />
                  <p className="!font-bold !text-[#000] !m-0 !px-2">
                    Choose chemistry style
                  </p>
                </div>
              </Row>
              <Row className="!pt-1">
                <Col className="rdbs">
                  <div className="!h-[20vh] !overflow-y-scroll !flex !flex-wrap !items-center  !rounded-[20px]">
                    {chemistryStyle?.map((chemistry) => (
                      <div key={chemistry.id}>
                        <Button
                          className={
                            chemistryClicked === chemistry.id
                              ? ` ${
                                  chemistryName.toLowerCase() === "propack"
                                    ? "!bg-[#ece8e8]"
                                    : "!bg-[#FF733A]"
                                } !m-1 !text-[#fff] !font-semibold !border-2 !border-[#fff] !rounded-[20px] !flex !items-center`
                              : "!m-1 !text-[#000] !font-semibold !bg-transparent !border-2 !border-[#999] !rounded-[20px] !flex !items-center"
                          }
                          onClick={() =>
                            chemistryStyleHandler(
                              chemistry.id,
                              chemistry.main_image,
                              chemistry.name
                            )
                          }
                        >
                          <LazyLoad offset={300}>
                            <ProgressiveImage
                              src={`${chemistry.main_image}`}
                              placeholder={`${chemistry.path}thumbnails${chemistry.file_name}`}
                            >
                              {(src) => (
                                <img
                                  src={src}
                                  alt="..."
                                  loading="lazy"
                                  className="!pr-1 !w-10 !h-8 !object-contain"
                                  fill="#fff"
                                />
                              )}
                            </ProgressiveImage>
                          </LazyLoad>
                          {chemistry.name.toLowerCase() === "propack"
                            ? ""
                            : chemistry.name}
                          <img
                            src={icon64}
                            alt="..."
                            className={
                              chemistryClicked === chemistry.id
                                ? "!pt-1 !px-1"
                                : "!hidden"
                            }
                          />
                        </Button>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            </Col>
          )}
          {col5 && (
            <Col sm={6} md={6}>
              <Row>
                <div className="flex items-center">
                  <img src={icon17} alt=".." />
                  <p className="!font-bold !text-[#000] !m-0 !px-2 ">
                    Choose extras for your card
                  </p>
                  {/* <img
                    src={icon2}
                    alt="extras guide"
                    className="!cursor-pointer"
                    onClick={() => {
                      setExtrasGuideModal(true);
                    }}
                  /> */}
                </div>
              </Row>
              <Row className="!mt-4">
                {extras.map((extra, index) => (
                  <div>
                    <Row key={index}>
                      <label htmlFor={`${index}_${extra.id}`}>
                        <Card className="!flex-row !items-center !border-none">
                          <Form.Check
                            onChange={(event) => {
                              handleCheckExtra(
                                event,
                                getExtrasSelected[index].id
                              );
                            }}
                            checked={getExtrasSelected[index].quantity > 0}
                            data-note={extra.is_extra_note}
                            data-bundle={extra.is_bundle}
                            value={getExtrasSelected[index].id}
                            id={`${index}_${extra.id}`}
                            className="!text-[25px]"
                            disabled={extrasToDisable.includes(
                              getExtrasSelected[index].id
                            )}
                          />
                          <LazyLoad>
                            <ProgressiveImage
                              src={extra.file_path}
                              placeholder={`${extra.path}thumbnails/${extra.file_name}`}
                            >
                              {(src) => (
                                <Card.Img
                                  src={src}
                                  alt="..."
                                  loading="lazy"
                                  className="!rounded-t-sm !ml-3 !pl-1 !h-20 !w-20 !max-h-20 !max-w-20 !object-contain"
                                />
                              )}
                            </ProgressiveImage>
                          </LazyLoad>
                          <Card.Body className="!flex-col">
                            <Card.Title className="!text-black !font-bold">
                              {extra.option_name}
                            </Card.Title>
                            <Card.Subtitle className="!pt-2">
                              {extra.short_description}
                            </Card.Subtitle>
                            <Card.Text className="!pt-2">
                              <span
                                className={
                                  getExtrasSelected[index].quantity
                                    ? "!text-[#FF733A] !font-extrabold !text-2xl"
                                    : "!text-[#000] !font-extrabold !text-2xl"
                                }
                              >
                                ${extra.price}
                              </span>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </label>
                    </Row>
                    {extra.is_extra_note === "1" &&
                      getExtrasSelected[index].quantity > 0 && (
                        <>
                          <Row className="!grid !grid-cols-3">
                            {extra.extra_images?.map((img, index) => (
                              <LazyLoad offset={300} key={index}>
                                <CustomProgressiveImage
                                  src={img.file_path}
                                  thumbnail={img.file_path}
                                  blur={img.file_path}
                                  className={
                                    noteImgSelected === img.id
                                      ? "!border-orange-600 !border-4 !rounded-[20px] !h-32 !w-36 !cursor-pointer !mt-2"
                                      : "!h-32 !w-36 !rounded-[20px] !cursor-pointer !mt-2"
                                  }
                                  onClick={() => {
                                    handleGiftNoteImage(extra.id, img.id);
                                  }}
                                />
                              </LazyLoad>
                            ))}
                          </Row>
                          <Row className="mt-3">
                            <FloatingLabel
                              controlId="floatingTextarea2"
                              label="Write your message here or leave this space blank if you want to write it by hand."
                              className={"!text-[#999] !pl-2"}
                            >
                              <Form.Control
                                as="textarea"
                                placeholder="Write your message here or leave this space blank if you want to write it by hand."
                                style={{ height: "100px" }}
                                onChange={handleGiftNoteText}
                                maxLength={120}
                                className="!border-4 !border-orange-600 !relative"
                                ref={textAreaRef}
                                disabled={noteImgSelected === null}
                              />
                              {textAreaChars.length > 0 && (
                                <div className="!absolute !w-14 !h-5 !bottom-1 !right-5 bg-gray-200 !rounded-md text-sm !select-none">
                                  {120 - textAreaChars.length} / 120
                                </div>
                              )}
                            </FloatingLabel>
                          </Row>
                        </>
                      )}
                  </div>
                ))}
              </Row>
            </Col>
          )}
          {
            <Col
              sm={6}
              md={6}
              lg={5}
              xl={5}
              xxl={5}
              className={
                !col1
                  ? "2xl:!ml-24 md:!pl-10 lg:!ml-12 xl:!ml-20 !mt-0 !pt-0"
                  : "col1Hidden"
              }
            >
              <canvas ref={canvasRef} width="350" height="490" />
            </Col>
          }
        </Row>
      </Container>
    </section>
  );
};

export default Step2;
