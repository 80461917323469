import React, { useState, useEffect,useRef } from "react";
import { Col, Container } from "react-bootstrap";
import cube1 from "../../../images/home/BestSelling/cube1.png";
import cube2 from "../../../images/home/BestSelling/cube2.png";
import Row from "react-bootstrap/Row";
import Loader from "../Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import { getTermsAndConditions } from "../../../store/data/Secondary-slice";
const TermsandconditionsForm = () => {
  const dispatch = useDispatch();

  const { isLoading, content } = useSelector(
    (state) => state.secondary.termsAndConditions
  );

  const sectRef = useRef(null);

  useEffect(() => {
    if (content) {
      return;
    } else {
      dispatch(getTermsAndConditions());
    }
  }, []);
  useEffect(() => {
    if (content) {
      setOverViewContent(content[0].description);
      setOverViewTitle(content[0]?.title);
    } else {
      setOverViewContent("Loading...");
      setOverViewTitle("Loading...");
    }
  }, [content]);
  const [overViewContent, setOverViewContent] = useState();
  const [overViewTitle, setOverViewTitle] = useState();

  const termChangeHandler = (desc, title) => {
    setOverViewContent(desc);
    setOverViewTitle(title);
    if (window.innerWidth <= 768) {
      sectRef.current.scrollIntoView({ behavior: 'smooth',block:"start" })
    }else {
      window.scrollTo({top: 0,behavior:'smooth'})
    }
  };

  return (
    <section className="AU1">
      <Container className="md:!pt-[140px] !pt-[90px]">
        <Row>
          <header className="flex flex-col items-center justify-center mb-10">
            <div className="flex flex-row items-center justify-center">
              <img src={cube1} alt="Cube 1" className="p-2 xsm:h-8" />
              <h1 className="text-[#000000] font-extrabold xsm:text-[20px]">
                Terms & Conditions
              </h1>
              <img src={cube2} alt="Cube 2" className="p-2 xsm:h-8" />
            </div>
          </header>
        </Row>
        {!isLoading && content && (
          <div className="!mb-32 xl:!mb-44 2xl:!mb-60">
            {content && (
              <Row className="rowMain  !flex !flex-col md:!flex-row">
                <Col
                  md={6}
                  lg={4}
                  xl={4}
                  xxl={4}
                  className=" bg-[#FFF1EB] !pt-10 text-left flex flex-col !items-start"
                >
                  {content?.map((term, index) => (
                    <div
                      className="!pl-5 !flex !items-center mb-3 cursor-pointer"
                      key={index}
                      onClick={() =>
                        termChangeHandler(term.description, term.title)
                      }
                    >
                      <div
                        className={
                          term.title === overViewTitle
                            ? "bg-[#FF733A] text-white w-[25px] h-[25px] leading-[25px] text-center rounded-full float-left"
                            : "bg-[#FFAF8E] text-white w-[25px] h-[25px] leading-[25px] text-center rounded-full float-left"
                        }
                      >
                        {index + 1}
                      </div>
                      <p
                        className={
                          term.title === overViewTitle
                            ? "text-[#FF733A] float-right leading-[25px] ml-3 mb-0"
                            : "text-[#FFAF8E] float-right leading-[25px] ml-3 mb-0"
                        }
                      >
                        <b>{term.title}</b>
                      </p>
                    </div>
                  ))}
                </Col>
                <Col className="bg-transparent !px-5 !py-7" ref={sectRef}>
                  <h3>
                    <b>{overViewTitle}</b>
                  </h3>
                  <p>{overViewContent}</p>
                </Col>
              </Row>
            )}
          </div>
        )}
        {isLoading && (
          <div className="!my-32">
            <Loader />
          </div>
        )}
      </Container>
    </section>
  );
};

export default TermsandconditionsForm;
