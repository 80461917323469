import CheckoutForm from "../components/ui/CheckOut/CheckoutForm";
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// import CardPaymentInfo from "../components/ui/CheckOut/CardPaymentInfo";
// import { useState, useEffect } from "react";
// import { useSelector } from "react-redux";
// import axios from "axios";
const Checkout = () => {
  // const url = useSelector((state) => state.api.domain);
  // const [stripePromise, setStripePromise] = useState("null");
  // const [clientSecret, setClientSecret] = useState(null);
  //fetch publishable key
  //fetch client secret
  // async function getClientSecret() {
  //   try {
  //     const response = await axios.get(`${url}/wizard/get-secret-key`);
  //     setClientSecret(response.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  // useEffect(() => {
  //   setStripePromise(
  //     loadStripe(
  //       "pk_test_51MVeofKkZdQVdTykapOwPlw0NA6Oit6R9lz4E547LZajCUPfJLfRyKqHZXGfOh1zqLLOth2Gn5NfTSI6AoHy05cz00MuaMl2Ik"
  //     )
  //   );
  //   getClientSecret();
  // }, []);
  return (
    <div className="!bg-[#F5F5F5]">
      {/* {stripePromise && clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CardPaymentInfo />
        </Elements>
      )} */}
      <CheckoutForm />
    </div>
  );
};

export default Checkout;
