import classes from "./PrivacyPolicyForm.module.css";
import Container from "react-bootstrap/Container";
import Loader from "../Loader/Loader";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPrivacyPolicy } from "../../../store/data/Secondary-slice";
const PrivacyPolicyForm = () => {
  const dispatch = useDispatch();
  const { isLoading, content } = useSelector((state) => state.secondary.privacyPolicy);
  useEffect(() => {
    if (content === null) {
      dispatch(getPrivacyPolicy());
    } else {
      return;
    }
  }, []);
  return (
    <Container className={`${classes.pp} !mt-20`}>
      {!isLoading && content && (
        <div dangerouslySetInnerHTML={{ __html: content[0]?.text }} className="divTransition" />
      )}
      {isLoading && (
        <div className="!my-36">
          <Loader />
        </div>
      )}
    </Container>
  );
};

export default PrivacyPolicyForm;
