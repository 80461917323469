import "./QualityTrust.css";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import cube1 from "../../../images/home/QualityTrust/QTcube1.png";
import cube2 from "../../../images/home/QualityTrust/QTcube2.png";
import img2 from "../../../images/home/QualityTrust/halfStar.svg";
import LazyLoad from "react-lazy-load";
import ProgressiveImage from "react-progressive-image";
import Loader from "../Loader/Loader";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getQualityTrust } from "../../../store/data/Home-slice";
const QualityTrust = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (content === null) {
      dispatch(getQualityTrust());
    } else {
      return;
    }
  }, []);
  const { isLoading, content } = useSelector(
    (state) => state.home.qualityTrust
  );
  const [imageLoaded, setImageLoaded] = useState(false);
  return (
    <Container className="!px-0 !mt-4 md:!mt-12 !border-0" fluid>
      {!isLoading && content && (
        <Card
          className="!relative text-[#FFFFFF] !rounded-none !border-0"
          border="0"
        >
          <LazyLoad offset={500}>
            <ProgressiveImage
              src={`${content[0]?.path}${content[0]?.file_name}`}
              placeholder={`${content[0]?.path}blures/${content[0]?.file_name}`}
            >
              {(src) => (
                <img
                  src={src}
                  alt="..."
                  loading="lazy"
                  onLoad={() => {
                    setImageLoaded(true);
                  }}
                  className="!w-full"
                />
              )}
            </ProgressiveImage>
          </LazyLoad>
          <Card.ImgOverlay>
            {imageLoaded && (
              <Card.Body className="!absolute top-8 sm:top-20 md:top-32 left-[40%] xl:top-44">
                <Card.Title className="flex items-center xsm:!pb-0">
                  <img
                    src={cube1}
                    alt="cube1"
                    className="p-1 xsm:h-5 xsm:w-5 h-6 w-6 lg:w-7 lg:h-7"
                  />
                  <h1 className="2xl:!text-[35px] !font-extrabold xsm:text-[13px] text-[14px] xsm:pt-2 pt-2 sm:text-[20px] md:text-[24px] lg:!text-[26px] xl:!text-[29px]">
                    Quality&nbsp;you&nbsp;can&nbsp;trust
                  </h1>
                  <img
                    src={cube2}
                    alt="cube2"
                    className="p-1 xsm:h-5 xsm:w-5 h-6 w-6 lg:w-7 lg:h-7"
                  />
                </Card.Title>
                {/* !font-semibold 2xl:text-[30px] xsm:text-[6px] text-[13px] sm:text-[14px] md:text-[18px] lg:text-[18px] xl:text-[24px] xl:pt-10 */}
                <Card.Text className="xsm:text-[8px] text-[11px] sm:text-[15px] md:text-[20px] lg:text-[27px] xl:text-[30px] 2xl:text-[34px]">
                  {content[0]?.description}
                  <br />
                  Excellent
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-7 inline-block xsm:!h-3 xsm:w-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-7 inline-block xsm:!h-3 xsm:w-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-7 inline-block xsm:!h-3 xsm:w-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-7 inline-block xsm:!h-3 xsm:w-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                  <img
                    src={img2}
                    alt="preview"
                    className="h-4 w-6 inline-block pl-1 xsm:!h-2 xsm:w-3 xsm:pl-0"
                  />
                </Card.Text>
              </Card.Body>
            )}
          </Card.ImgOverlay>
        </Card>
      )}
      {isLoading && <Loader />}
    </Container>
  );
};

export default QualityTrust;
