import "./Footer.css";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../../images/home/Footer/FooterLogo.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSocialMedia } from "../../../store/data/Secondary-slice";
const Footer = () => {
  const dispatch = useDispatch();
  const url = useSelector((state) => state.api.domain);
  const [footerContent, setFooterContent] = useState([]);
  async function getSectionOneContent() {
    try {
      const response = await axios.get(`${url}/wizard/get-footer`);
      setFooterContent(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  function getCurrentYear() {
    const currentYear = new Date().getFullYear();
    return currentYear;
  }
  useEffect(() => {
    getSectionOneContent();
    dispatch(getSocialMedia());
  }, []);
  const { isLoading, content } = useSelector(
    (state) => state.secondary.socialMedia
  );
  return (
    <Container className="footerl" fluid>
      <Row className="md:!pt-24 md:!px-24 xsm:!pt-14 xsm:!px-6 sm:!pt-24 sm:!px-6 pt-20 px-18">
        <Col xs={5}>
          <div>
            <Link to="/">
              <img src={logo} alt="Propack" />
            </Link>
          </div>
          <div>
            <p
              className="pt-3 text-[#FFFFFF] xsm:!text-[7px] xsm:!mt-[-4px] sm:text-[12px]
             text-[10px] md:text-[12px] lg:text-[16px]"
            >
              {footerContent?.description}
            </p>
          </div>
        </Col>
        <Col xs={3}>
          <div className="flex flex-col text-justify xsm:text-[6px] xsm:!pl-2 md:!pl-9">
            <Link
              to="/contactus"
              className="!text-[#fff] no-underline font-extrabold md:text-[16px]
               xsm:text-[9px] text-[14px] hover:!text-[#000]"
            >
              Contact&nbsp;us
            </Link>
            <Link
              to="/aboutus"
              className="!text-[#fff] no-underline font-extrabold md:text-[16px] md:pt-2
               xsm:text-[9px] xsm:!pt-1 text-[14px] hover:!text-[#000]"
            >
              About us
            </Link>
            <Link
              to="/termsAndConditions"
              className="!text-[#fff] no-underline font-extrabold md:text-[16px] md:pt-2
               xsm:text-[9px] xsm:!pt-1 text-[14px] hover:!text-[#000]"
            >
              Terms&nbsp;&&nbsp;Conditions
            </Link>
            <Link
              to="/privacypolicy"
              className="!text-[#fff] no-underline font-extrabold md:text-[16px] md:pt-2
               xsm:text-[9px] xsm:!pt-1 text-[14px] hover:!text-[#000]"
            >
              Privacy&nbsp;Policy
            </Link>
          </div>
        </Col>
        <Col>
          <div className="flex flex-col !ml-auto xsm:!pl-5 md:!pl-10">
            <h3 className="text-[#000000] font-extrabold xsm:text-[12px]">
              Follow us
            </h3>
            <div className="grid grid-cols-4 gap-0">
              {/* <a href="https://facebook.com/propack.store" target="_blank">
                <div className="bg-contain bg-no-repeat icon iconF1 xsm:!h-6 xsm:!w-6"></div>
              </a>
              <a href="https://instagram.com/propack.store" target="_blank">
                <i
                  className="fab fa-facebook"
                  style={{ color: "#FF733A", fontSize: "40px" }}
                />
              </a> */}
              {content &&
                content?.map((s, index) => (
                  <a href={s.link} target="_blank" key={index}>
                    <i
                      className={`xsm:!text-4 !text-5 md:!text-[1.7rem] lg:!text-[2rem] xl:!text-[2.5rem] 2xl:!text-[2.5rem] ${s.icon}`}
                      style={{ color: "#fff" }}
                    />
                  </a>
                ))}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <div className="flex items-center justify-between md:!px-24 xsm:!px-6 px-10 md:pt-10">
          <p className="text-[#FFFFFF] xsm:text-[7px]">
            © {getCurrentYear()} Propack. All rights reserved.
          </p>
          <p className="text-[#FFFFFF] xsm:text-[7px]">
            Powered by
            <a
              target="_blank"
              className="uppercase text-[#ffcd00] hover:text-[#ffcd00] !px-2"
              href="https://web.brainkets.com"
            >
              <span className="text-[#000] inline">&#123;</span>BRAIN
              <span className="text-[#000] inline">KETS&#125;</span>
            </a>
          </p>
        </div>
      </Row>
    </Container>
  );
};

export default Footer;
