import "./FrequentlyAsked.css";
import Container from "react-bootstrap/Container";
import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import Placeholder from "react-bootstrap/Placeholder";
import "bootstrap/dist/css/bootstrap.min.css";
import cube1 from "../../../images/home/WhyChoose/cube1.svg";
import cube2 from "../../../images/home/WhyChoose/cube2.svg";
import { useEffect } from "react";
import { getFAQ } from "../../../store/data/Home-slice";
import { useSelector, useDispatch } from "react-redux";
const FrequentlyAsked = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (content === null) {
      dispatch(getFAQ());
    } else {
      return;
    }
  }, []);
  const { isLoading, content } = useSelector(
    (state) => state.home.frequentlyAsked
  );
  return (
    <Container className="faBg pb-2 my-20 md:!w-[80%] xsm:!w-[90%] !w-[80%] rounded-[20px]">
      <Row className="!pt-5">
        <div className="!flex !flex-row !items-center !justify-center text-[#000000]">
          <img
            src={cube1}
            alt="Propack Cube"
            className="p-2 xsm:p-0 xsm:h-8 h-10"
          />
          <h1 className="font-extrabold pt-2 xsm:text-[18px] md:text-2xl sm:text-xl text-lg">
            Frequently asked questions
          </h1>
          <img
            src={cube2}
            alt="Propack Cube"
            className="p-2 xsm:p-0 xsm:h-8 h-10"
          />
        </div>
      </Row>
      <Row className="md:!mt-6 xsm:!mt-3">
        {isLoading && (
          <Placeholder as={"div"} animation="glow" className="!ml-[25%]">
            <Placeholder xs={6} style={{ backgroundColor: "red" }} />
          </Placeholder>
        )}
        {!isLoading && content && (
          <Accordion>
            {content?.map((accordian, index) => (
              <Accordion.Item
                eventKey={index}
                style={{ border: "0" }}
                key={index}
              >
                <Accordion.Header className="">
                  <div className="flex items-center !text-[20px] sm:!text-[30px]">
                    <div
                      className="!bg-[#FF733A] !text-[#fff] !font-extrabold !text-center !rounded-full !px-4 !pt-1"
                      style={{
                        boxShadow:
                          "linear-gradient(269.93deg, #FFF1EB 0.07%, #FFC7B0 99.95%)",
                      }}
                    >
                      {index + 1}
                    </div>
                    <h3 className="font-extrabold pl-5 pt-2 xsm:text-[20px] text-[#000000]">
                      {accordian.title_question}
                    </h3>
                  </div>
                </Accordion.Header>
                <Accordion.Body
                  className="bg-[#FFE3D8] text-[#000000] font-bold rounded-[20px]"
                  style={{ border: "0" }}
                >
                  {accordian.question_content}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        )}
      </Row>
    </Container>
  );
};

export default FrequentlyAsked;
