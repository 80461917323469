import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  stepOne: {
    isLoading: true,
    stepOneContentSizes: null,
    stepOneContentStyles: null,
    stepOneContentImages: null,
    stepOneContentPrices: null,
    stepOneContentList: null,
    stepOneContentStylesGuide: null,
    error: null,
  },
};

const url = "https://propackstore.org/propack/web/API";

export const getStepOne = createAsyncThunk("stepOne/getStepOne", async () => {
  const response = await axios.get(`${url}/wizard/get-step-one`);
  return response.data;
});

const step1Slice = createSlice({
  name: "stepOne",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStepOne.pending, (state) => {
        state.stepOne.isLoading = true;
        state.stepOne.stepOneContentSizes = null;
        state.stepOne.stepOneContentStyles = null;
        state.stepOne.stepOneContentImages = null;
        state.stepOne.stepOneContentPrices = null;
        state.stepOne.stepOneContentList = null;
        state.stepOne.stepOneContentStylesGuide = null;
      })
      .addCase(getStepOne.fulfilled, (state, action) => {
        state.stepOne.isLoading = false;
        state.stepOne.stepOneContentSizes = action.payload.sizes;
        state.stepOne.stepOneContentStyles = action.payload.styles;
        state.stepOne.stepOneContentImages = action.payload.step_images;
        state.stepOne.stepOneContentPrices = action.payload.prices;
        state.stepOne.stepOneContentList = action.payload.list;
        state.stepOne.stepOneContentStylesGuide = action.payload.stylesGuide;
      });
  },
  //
});

export default step1Slice;
