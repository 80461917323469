import { createSlice } from "@reduxjs/toolkit";
const stylesSlice = createSlice({
  name: "styles",
  initialState: {
    wizardBtnNextDisabled: true,
    wizardSmallCustomizeButtonDisabled: true,
    loggedIn: false,
    loggedOut: false,
    showImageGuideModal: false,
    showImageGuideModalSmall: false,
  },
  reducers: {
    disableBtnNext(state) {
      state.wizardBtnNextDisabled = true;
    },
    enableBtnNext(state) {
      state.wizardBtnNextDisabled = false;
    },
    disableSmallBtnCustomize(state) {
      state.wizardSmallCustomizeButtonDisabled = true;
    },
    enableSmallBtnCustomize(state) {
      state.wizardSmallCustomizeButtonDisabled = false;
    },
    trueLoggedIn(state) {
      state.loggedIn = true;
    },
    falseLoggedIn(state) {
      state.loggedIn = false;
    },
    trueLoggedOut(state) {
      state.loggedIn = false;
      localStorage.setItem("token", null);
      localStorage.setItem("session_id", null);
    },
    showImageGuideModal(state) {
      state.showImageGuideModal = !state.showImageGuideModal;
    },
    showImageGuideModalSmall(state) {
      state.showImageGuideModalSmall = !state.showImageGuideModalSmall;
    },
  },
});

export const stylesActions = stylesSlice.actions;
export default stylesSlice;
