import "./TrustedFans.css";
import Slider from "react-slick";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import bgImage from "../../../images/home/TrustedCustomer/TrustedCard.png";
import cube1 from "../../../images/home/WhyChoose/cube1.svg";
import cube2 from "../../../images/home/WhyChoose/cube2.svg";
import halfStar from "../../../images/home/QualityTrust/halfStar.svg";
import LazyLoad from "react-lazy-load";
import ProgressiveImage from "react-progressive-image";
import Loader from "../Loader/Loader";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTrustedFans } from "../../../store/data/Home-slice";
const TrustedFans = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (content === null) {
      dispatch(getTrustedFans());
    } else {
      return;
    }
  }, []);
  const { content, isLoading } = useSelector((state) => state.home.trustedFans);
  const settings = {
    dots: true,
    infinite: true,
    centered: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1524,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 855,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Container className="lg:!w-[70%] mt-20 !w-[90%] md:!w-[70%]">
      {!isLoading && content && (
        <Row>
          <div className="!flex !flex-row !items-center !justify-center text-[#000000]">
            <img
              src={cube1}
              alt="Propack Cube"
              className="p-2 xsm:p-0 xsm:h-8 h-10"
            />
            <h1 className="font-extrabold pt-2 xsm:text-[20px] md:text-2xl sm:text-xl text-lg lg:text-4xl">
              Trusted&nbsp;by&nbsp;25000+&nbsp;fans
            </h1>
            <img
              src={cube2}
              alt="Propack Cube"
              className="p-2 xsm:p-0 xsm:h-8 h-10"
            />
          </div>
        </Row>
      )}
      <Row>
        {!isLoading && content && (
          <Slider {...settings} className="!mt-14">
            {content?.map((card, index) => (
              <div className="xsm:!ml-5 !ml-20 lg:!ml-0" key={index}>
                <Card style={{ width: "18rem" }} border="0">
                  <Card.Img src={bgImage} />
                  <Card.ImgOverlay>
                    <div className="h-[224px] w-[220px] rounded-[20px] ml-9">
                      <LazyLoad>
                        <ProgressiveImage
                          src={`${card.path}${card.file_name}`}
                          placeholder={`${card.path}blures/${card.file_name}`}
                        >
                          {(src) => (
                            <Card.Img
                              src={src}
                              alt="Fan Overview"
                              loading="lazy"
                              className="rounded-[30px] !rounded-l-3xl !rounded-r-3xl !h-[224px] !object-cover"
                            />
                          )}
                        </ProgressiveImage>
                      </LazyLoad>
                    </div>
                    <Card.Body>
                      <Card.Text
                        as="p"
                        className="text-[#FFFFFF] pt-1 !ml-[-16px]"
                      >
                        {card.description}
                        <br />
                        <br />
                        {card.fan_name}
                        <br />
                        <span className="flex items-center !ml-[-5px]">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-7 xsm:!h-3 xsm:w-4"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-7 xsm:!h-3 xsm:w-4"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-7 xsm:!h-3 xsm:w-4"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                          </svg>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-7 xsm:!h-3 xsm:w-4"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                          </svg>
                          <img
                            src={halfStar}
                            alt="preview"
                            className="h-4 w-6 pl-1 xsm:!h-2 xsm:w-3 xsm:pl-0"
                          />
                        </span>
                      </Card.Text>
                    </Card.Body>
                  </Card.ImgOverlay>
                </Card>
              </div>
            ))}
          </Slider>
        )}
        {isLoading && <Loader />}
      </Row>
    </Container>
  );
};

export default TrustedFans;
