import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  countryCode: {
    countryCodes: null,
  },
  aboutUs: {
    isLoading: true,
    content1: null,
    content2: null,
    content22: null,
    content3: null,
  },
  termsAndConditions: {
    isLoading: true,
    content: null,
  },
  privacyPolicy: {
    isLoading: true,
    content: null,
  },
  contactUs: {
    isLoading: true,
    content: null,
  },
  signup: {
    isLoading: true,
    content: null,
  },
  dhlCountries: {
    isLoading: true,
    content: null,
  },
  extrasGuide: {
    isLoading: true,
    content: null,
  },
  socialMedia: {
    isLoading: true,
    content: null,
  },
};

const url = "https://propackstore.org/propack/web/API";

export const getCountryCodes = createAsyncThunk(
  "secondary/getCountryCodes",
  async () => {
    const response = await axios.get(`${url}/wizard/get-country-codes`);
    return response.data;
  }
);

export const getAboutUs = createAsyncThunk("secondary/getAboutUs", async () => {
  const response = await axios.get(`${url}/wizard/get-about-us`);
  return response.data;
});

export const getTermsAndConditions = createAsyncThunk(
  "secondary/getTermsAndConditions",
  async () => {
    const response = await axios.get(`${url}/wizard/get-terms-and-conditions`);
    return response.data;
  }
);

export const getPrivacyPolicy = createAsyncThunk(
  "secondary/getPrivacyPolicy",
  async () => {
    const response = await axios.get(`${url}/wizard/get-privacy-policy`);
    return response.data;
  }
);

export const getContactUs = createAsyncThunk(
  "secondary/getContactUs",
  async () => {
    const response = await axios.get(`${url}/wizard/get-contact-us`);
    return response.data;
  }
);

export const getsignUp = createAsyncThunk("secondary/getsignUp", async () => {
  const response = await axios.get(`${url}/wizard/get-signup`);
  return response.data;
});

export const getDhlCountries = createAsyncThunk(
  "secondary/getDhlCountries",
  async () => {
    const response = await axios.get(`${url}/wizard/dhl-countries`);
    return response.data;
  }
);

export const getExtrasGuide = createAsyncThunk(
  "secondary/getExtrasGuide",
  async () => {
    const response = await axios.get(`${url}/wizard/get-extras-guide`);
    return response.data;
  }
);

export const getSocialMedia = createAsyncThunk(
  "secondary/getSocialMedia",
  async () => {
    const response = await axios.get(`${url}/wizard/get-social-media`);
    return response.data;
  }
);

const secondarySlice = createSlice({
  name: "secondary",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCountryCodes.pending, (state) => {
        state.countryCode.countryCodes = null;
      })
      .addCase(getCountryCodes.fulfilled, (state, action) => {
        state.countryCode.countryCodes = action.payload;
      });
    builder
      .addCase(getAboutUs.pending, (state) => {
        state.aboutUs.isLoading = true;
        state.aboutUs.content1 = null;
        state.aboutUs.content2 = null;
        state.aboutUs.content22 = null;
        state.aboutUs.content3 = null;
      })
      .addCase(getAboutUs.fulfilled, (state, action) => {
        state.aboutUs.isLoading = false;
        state.aboutUs.content1 = action.payload.aboutus_intro;
        state.aboutUs.content2 = action.payload.aboutus_second_section_data;
        state.aboutUs.content22 = action.payload.aboutus_second_section;
        state.aboutUs.content3 = action.payload.aboutus_second_section_detail;
      });
    builder
      .addCase(getTermsAndConditions.pending, (state) => {
        state.termsAndConditions.isLoading = true;
        state.termsAndConditions.content = null;
      })
      .addCase(getTermsAndConditions.fulfilled, (state, action) => {
        state.termsAndConditions.isLoading = false;
        state.termsAndConditions.content = action.payload;
      });
    builder
      .addCase(getPrivacyPolicy.pending, (state) => {
        state.privacyPolicy.isLoading = true;
        state.privacyPolicy.content = null;
      })
      .addCase(getPrivacyPolicy.fulfilled, (state, action) => {
        state.privacyPolicy.isLoading = false;
        state.privacyPolicy.content = action.payload;
      });
    builder
      .addCase(getContactUs.pending, (state) => {
        state.contactUs.isLoading = true;
        state.contactUs.content = null;
      })
      .addCase(getContactUs.fulfilled, (state, action) => {
        state.contactUs.isLoading = false;
        state.contactUs.content = action.payload;
      });
    builder
      .addCase(getsignUp.pending, (state) => {
        state.signup.isLoading = true;
        state.signup.content = null;
      })
      .addCase(getsignUp.fulfilled, (state, action) => {
        state.signup.isLoading = false;
        state.signup.content = action.payload;
      });
    builder
      .addCase(getDhlCountries.pending, (state) => {
        state.dhlCountries.isLoading = true;
        state.dhlCountries.content = null;
      })
      .addCase(getDhlCountries.fulfilled, (state, action) => {
        state.dhlCountries.isLoading = false;
        state.dhlCountries.content = action.payload;
      });
    builder
      .addCase(getExtrasGuide.pending, (state) => {
        state.extrasGuide.isLoading = true;
        state.extrasGuide.content = null;
      })
      .addCase(getExtrasGuide.fulfilled, (state, action) => {
        state.extrasGuide.isLoading = false;
        state.extrasGuide.content = action.payload;
      });
    builder
      .addCase(getSocialMedia.pending, (state) => {
        state.socialMedia.isLoading = true;
        state.socialMedia.content = null;
      })
      .addCase(getSocialMedia.fulfilled, (state, action) => {
        state.socialMedia.isLoading = false;
        state.socialMedia.content = action.payload;
      });
  },
  //
});

export default secondarySlice;
