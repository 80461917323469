import "./HowToChoose.css";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import cube1 from "../../../images/home/BestSelling/cube1.png";
import cube2 from "../../../images/home/BestSelling/cube2.png";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazy-load";
import ProgressiveImage from "react-progressive-image";
import { useEffect } from "react";
import Loader from "../Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import { getHowToChoose } from "../../../store/data/Home-slice";
const HowToChoose = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (content === null) {
      dispatch(getHowToChoose());
    } else {
      return;
    }
  }, []);
  const { isLoading, content } = useSelector((state) => state.home.howToChoose);
  return (
    <Container className="md:mt-10 lg:flex lg:!items-center lg:!justify-between md:flex md:!items-center md:!justify-between !text-center">
      <Col>
        <Row>
          <main className="text-center">
            <header className="flex flex-row items-center justify-center">
              <img src={cube1} alt="Cube 1" className="p-1 xsm:h-8" />
              <h1 className="text-[#000000] font-bold xsm:text-[20px] !pt-2 md:!pt-0">
                How it works
              </h1>
              <img src={cube2} alt="Cube 2" className="p-1 xsm:h-8" />
            </header>
          </main>
        </Row>
        {!isLoading && content && (
          <Row>
            {content?.map((card, index) => (
              <Card
                style={{ width: "20rem", height: "488px" }}
                border="0"
                className="m-auto"
                key={index}
              >
                <LazyLoad className="!min-h-[290px] !min-w-[290px]">
                  <ProgressiveImage
                    src={`${card.path}${card.file_name}`}
                    placeholder={`${card.path}blures/${card.file_name}`}
                    className="!min-h-[290px] !min-w-[290px]"
                  >
                    {(src) => (
                      <Card.Img
                        variant="top"
                        src={src}
                        alt="..."
                        loading="lazy"
                        className="!min-h-[290px] !min-w-[290px]"
                      />
                    )}
                  </ProgressiveImage>
                </LazyLoad>
                <Card.Body>
                  <Card.Title className="text-[#A04723] lg:!text-[32px] !font-extrabold xsm:!mt-[-2rem] !mt-[-2.5rem]">
                    {card.title}
                  </Card.Title>
                  <Card.Text className="text-[#A04723] lg:!text-[20px]">
                    {card.description}
                  </Card.Text>
                </Card.Body>
                <Link
                  to={`/${card.button_link}`}
                  className="!mx-auto !w-[70%] text-[#A04723] rounded-full no-underline border-[1px]
                    border-[#A04723] font-bold py-2 px-2 !transition !ease-in-out !delay-75 hover:!-translate-y-1 hover:!text-white hover:!bg-[#FF733A] !duration-300"
                >
                  {card.button_text}
                </Link>
              </Card>
            ))}
          </Row>
        )}
        {isLoading && !content && <Loader />}
      </Col>
    </Container>
  );
};

export default HowToChoose;
