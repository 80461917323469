import { createSlice } from "@reduxjs/toolkit";
const apiSlice = createSlice({
  name: "api",
  initialState: {
    domain: "https://propackstore.org/propack/web/API",
    // domain : "http://192.168.0.115/jmart/web/API",
  },
});

export const apiActions = apiSlice.actions;
export default apiSlice;
