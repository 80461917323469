import { createSlice } from "@reduxjs/toolkit";

const cardSlice = createSlice({
  name: "card",
  initialState: {
    cardStyle: "",
    cardSize: "",
    cardStyleName: "",
    cardSizeName: "",
    cardPrice: "",
  },
  reducers: {
    setCardStyleId(state, action) {
      const styleId = action.payload;
      state.cardStyle = styleId;
    },
    setCardSizeId(state, action) {
      const sizeId = action.payload;
      state.cardSize = sizeId;
    },
    setCardStyleName(state, action) {
      const styleName = action.payload;
      state.cardStyleName = styleName;
    },
    setCardSizeName(state, action) {
      const sizeName = action.payload;
      state.cardSizeName = sizeName;
    },
    setCardPrice(state, action) {
      const price = action.payload;
      state.cardPrice = price;
    },
  },
});

export const cardActions = cardSlice.actions;
export default cardSlice;
