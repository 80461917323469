import { useState, useEffect } from "react";

export const CustomProgressiveImage = ({
  src,
  blur,
  thumbnail,
  ...restProps
}) => {
  const [image, setImage] = useState(thumbnail);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const imgR = new window.Image();
    imgR.src = src;
    imgR.onload = function () {
      setLoading(false);
      setImage(src);
    };
  }, [src]);

  return (
    <div>
      {loading && <img src={blur} alt="Loading..." {...restProps}/>}
      {!loading && <img src={image} alt="Image" {...restProps} />}
    </div>
  );
};
