import "./CustomerCarousel.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";
import Placeholder from "react-bootstrap/Placeholder";
import Container from "react-bootstrap/Container";
import cube1 from "../../../images/home/BestSelling/cube1.png";
import cube2 from "../../../images/home/BestSelling/cube2.png";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import LazyLoad from "react-lazy-load";
import { CustomProgressiveImage } from "../progressiveImage/ProgressiveImage";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCustomerCarousel } from "../../../store/data/Home-slice";
import Loader from "../Loader/Loader";
const CustomerCarousel = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (content === null) {
      dispatch(getCustomerCarousel());
    } else {
      return;
    }
  }, [dispatch]);
  const { content, isLoading } = useSelector(
    (state) => state.home.customerCarousel
  );
  const [margin, setMargin] = useState(30);
  const handleInstagramClick = () => {
    window.open("https://instagram.com/propack.store");
  };
  return (
    <Container>
      <Col>
        <Row className="md:pb-5">
          <main className="text-center">
            <header className="flex flex-row items-center justify-center">
              <img src={cube1} alt="Cube 1" className="p-1 xsm:h-8" />
              {!isLoading && content && (
                <h1 className="text-[#000000] font-bold xsm:text-[20px] !pt-2 md:!pt-0">
                  {content[0]?.title}
                </h1>
              )}
              <img src={cube2} alt="Cube 2" className="p-1 xsm:h-8" />
            </header>
            {isLoading && (
              <Placeholder animation="glow">
                <Placeholder xs={6} className="!bg-red-500 !w-1/3" />
              </Placeholder>
            )}
          </main>
        </Row>
        <Row>
          <div className="slider">
            {!isLoading && content && (
              <OwlCarousel
                className="owl-theme"
                items="5"
                // autoplay
                loop
                dots
                center
                startPosition={Math.round(content[0].images.length / 2) - 1}
                responsive={{
                  0: {
                    items: 3,
                  },
                  1000: {
                    items: 5,
                  },
                }}
                margin={margin} // Set the margin dynamically based on the state
                onTranslated={(e) => {
                  const index = e.item.index;
                  const slideCount = e.item.count;
                  if (index === slideCount - 1) {
                    // Reached the last slide, set margin to 0
                    setMargin(0);
                  } else if (index === 0) {
                    // Reached the first slide again, set margin to original value
                    setMargin(30);
                  }
                }}
              >
                {content[0]?.images.map((carousel, index) => (
                  <div
                    className="slider-card !bg-transparent"
                    key={index}
                    onClick={handleInstagramClick}
                  >
                    <div className="flex justify-center ontent-center items-center mb-4 rounded-[20px] !cursor-pointer focus:!cursor-grabbing">
                      <LazyLoad offset={300}>
                        <CustomProgressiveImage
                          src={`${carousel.path}${carousel.file_name}`}
                          thumbnail={`${carousel.path}thumbnails/${carousel.file_name}`}
                          blur={`${carousel.path}blures/${carousel.file_name}`}
                          className="rounded-[20px] xsm:!h-[10rem] xsm:!w-[20rem] sm:!h-60 sm:!w-60 !object-cover"
                        />
                      </LazyLoad>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            )}
          </div>
          {isLoading && (
            <Loader />
          )}
        </Row>
      </Col>
    </Container>
  );
};

export default CustomerCarousel;
