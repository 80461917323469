import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import image74 from "../../../images/shopCards/step6/greenCheck.svg";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import backArrow from "../../../images/shopCards/backArrow.svg";
import nextArrow from "../../../images/shopCards/nextArrow.svg";
import icon3 from "../../../images/shopCards/Step3/galleryIcon.svg";
import icon1 from "../../../images/shopCards/Step3/nb1.svg";
//
import classes from "../privacyPolicy/PrivacyPolicyForm.module.css";
import nb8 from "../../../images/shopCards/step6/nb8.svg";
import nb9 from "../../../images/shopCards/step6/nb9.svg";
//
import icon9 from "../../../images/shopCards/Step3/deleteIcon.svg";
import icon2 from "../../../images/shopCards/Step3/InfoSquare.svg";
import icon5 from "../../../images/shopCards/Step3/nb2.svg";
import icon11 from "../../../images/shopCards/Step3/xIcon.svg";
import icon34 from "../../../images/shopCards/Step4/searchPlaceholder.svg";
import icon64 from "../../../images/shopCards/Step4/checkIcon.svg";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Tab from "react-bootstrap/Tab";
import ListGroup from "react-bootstrap/ListGroup";
import Lottie from "react-lottie";
import gif3 from "../../../images/shopCards/Step4/gif4.json";
import icon14 from "../../../images/shopCards/Step4/nb3.svg";
import icon13 from "../../../images/shopCards/Step4/nb4.svg";
import icon15 from "../../../images/shopCards/Step5/nb5.svg";
import icon25 from "../../../images/shopCards/Step5/nb6.svg";
import icon16 from "../../../images/shopCards/step6/nb7.svg";
import icon44 from "../../../images/shopCards/Step3/galleryIcon.svg";
import icon54 from "../../../images/shopCards/Step3/ringIcon.svg";
import LoaderImage from "../../../images/home/Loader/loader.gif";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import { Navigation, Scrollbar } from "swiper";
import { useEffect, useState, useRef } from "react";
import image72 from "../../../images/shopCards/step6/basketIcon.svg";
import image73 from "../../../images/shopCards/step6/checkoutIcon.svg";
import Offcanvas from "react-bootstrap/Offcanvas";
import { fabric } from "fabric";
import { useSelector } from "react-redux";
import { checkOutActions } from "../../../store/CheckOut-slice";
import { stylesActions } from "../../../store/Styles-slice";
import axios from "axios";
import LazyLoad from "react-lazy-load";
import ProgressiveImage from "react-progressive-image";
import { CustomProgressiveImage } from "../progressiveImage/ProgressiveImage";
import localforage from "localforage";
import { useHistory } from "react-router-dom";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
//
import r1 from "../../../images/shopCards/copper/rotateLeft.svg";
import r2 from "../../../images/shopCards/copper/rotateRight.svg";
import m1 from "../../../images/shopCards/copper/magnefier.svg";
import m2 from "../../../images/shopCards/copper/minus.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getExtrasGuide } from "../../../store/data/Secondary-slice";
import Loader from "../Loader/Loader";
import EXIF from 'exif-js';
const Step2 = () => {
  const notify = (msg) => toast(msg);
  const history = useHistory();
  const dispatch = useDispatch();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: gif3,
    renderer: "svg",
  };
  const [activeCol, setActiveCol] = useState("col1");
  const [activeRow, setActiveRow] = useState("");
  const [backToStep1, setBackToStep1] = useState(true);
  const [showCheckOutModal, setShowCheckOutModal] = useState(false);
  const [showCustomFlagCountryModal, setShowCustomFlagCountryModal] =
    useState(false);
  const [showCustomFlagClubModal, setShowCustomFlagClubModal] = useState(false);
  const activeBackColHandler = () => {
    setShowCheckOutModal(false);
    if (activeCol === "col2" && activeRow === "row1") {
      setBackToStep1(true);
      setActiveCol("col1");
    } else if (activeCol === "col2" && activeRow === "row3") {
      setBackToStep1(false);
      setActiveCol("col2");
      setActiveRow("row1");
    } else if (activeCol === "col2" && activeRow === "row4") {
      setBackToStep1(false);
      setActiveCol("col2");
      setActiveRow("row3");
    } else if (activeCol === "col2" && activeRow === "row6") {
      setBackToStep1(false);
      setActiveCol("col2");
      setActiveRow("row4");
    } else if (activeCol === "col2" && activeRow === "row9") {
      setBackToStep1(false);
      setActiveCol("col2");
      setActiveRow("row6");
      dispatch(stylesActions.enableBtnNext());
    } else if (activeCol === "col3") {
      setBackToStep1(false);
      setActiveCol("col2");
      setActiveRow("row9");
    }
  };
  const activeNextColHandler = () => {
    if (btnNextStatus) {
      notify("Please Fill Out All Required Fields");
    } else {
      setBackToStep1(false);
      if (activeCol === "col1") {
        setActiveCol("col2");
        setActiveRow("row1");
        dispatch(stylesActions.disableBtnNext());
      } else if (activeCol === "col2" && activeRow === "row1") {
        setActiveRow("row3");
        dispatch(stylesActions.disableBtnNext());
      } else if (activeCol === "col2" && activeRow === "row3") {
        setActiveRow("row4");
        dispatch(stylesActions.disableBtnNext());
      } else if (activeCol === "col2" && activeRow === "row4") {
        setActiveRow("row6");
      } else if (activeCol === "col2" && activeRow === "row6") {
        setActiveRow("row9");
        dispatch(stylesActions.disableBtnNext());
      } else if (activeCol === "col2" && activeRow === "row9") {
        setActiveCol("col3");
        setShowCheckOutModal(true);
      }
    }
  };
  const placeholder = (
    <div className="!flex !items-center !text-[#999999]">
      <img src={icon34} alt="..." className="!pr-1" /> Search country name
    </div>
  );
  const placeholder2 = (
    <div className="!flex !items-center !text-[#999999]">
      <img src={icon34} alt="..." className="!pr-1" /> Search club name
    </div>
  );
  const [countryClicked, setCountryClicked] = useState("");
  const [clubClicked, setClubClicked] = useState("");
  //
  const [imgUploaderContent, setImgUploaderContent] = useState(false);
  const [countryUploaderContent, setCountryUploaderContent] = useState(false);
  const [clubUploaderContent, setClubUploaderContent] = useState(false);
  const clearClubImage = () => {
    setClub("");
    setClubUploaderContent(false);
    setClubClicked(null);
    dispatch(stylesActions.disableBtnNext());
  };
  const clearCountryImage = () => {
    setCountry("");
    setCountryUploaderContent(false);
    setCountryClicked(null);
    dispatch(stylesActions.disableBtnNext());
  };
  const showImageGuide = useSelector(
    (state) => state.styles.showImageGuideModalSmall
  );
  const handleCloseImageGuide = () => {
    dispatch(stylesActions.showImageGuideModalSmall());
  };
  const playerImageRef = useRef();
  const [showImageCropper, setShowImageCropper] = useState(false);
  const handleCloseImageCropper = () => {
    setShowImageCropper(false);
    playerImageRef.current.value = "";
    setCropperFullyLoaded(false);
  };
  const [showCountries, setShowCountries] = useState(false);
  const handleCloseCountries = () => {
    setShowCountries(false);
  };
  const [showClubs, setShowClubs] = useState(false);
  const handleCloseClubs = () => {
    setShowClubs(false);
  };
  //
  const url = useSelector((state) => state.api.domain);
  const [continentSelected, setContinentSelected] = useState(null);
  const [leagueSelected, setLeagueSelected] = useState(null);
  const [categorySelected, setCategorySelected] = useState(null);
  const [categories, setCategories] = useState([]);
  const [cardStyleLoading, setCardStyleLoading] = useState(true);
  async function getCategories() {
    setCardStyleLoading(true);
    try {
      const response = await axios.get(`${url}/wizard/get-card-categories`);
      setCategories(response.data);
      setCategorySelected(response.data[0].id);
      setCardStyleLoading(false);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getCategories();
  }, []);
  const [cardStyle, setCardStyle] = useState([]);
  function getCardStyle() {
    let formData = new FormData();
    formData.append("id", categorySelected);
    const url1 = `${url}/wizard/get-cards`;
    axios
      .post(url1, formData)
      .then((res) => {
        setCardStyle(res.data);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    getCardStyle();
  }, [categorySelected]);
  //sports
  const [sportC, setSportC] = useState([]);
  const [sportS, setSportS] = useState([]);
  const [firstSport, setFirstSport] = useState("");
  async function getAllSportsAttr() {
    try {
      const response = await axios.get(`${url}/wizard/get-sports-attributes`);
      setSportC(response.data.categories);
      setSportS(response.data.sports);
      setFirstSport(response.data.sports[0].sport_name);
      setSport(response.data.sports[0].id);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getAllSportsAttr();
  }, []);
  //continents
  const [continents, setContinents] = useState([]);
  async function getContinents() {
    try {
      const response = await axios.get(`${url}/wizard/get-continents`);
      setContinents(response.data);
      setContinentSelected(response.data[0].id);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getContinents();
  }, []);
  //countries
  const [countries, setCountries] = useState([]);
  function getCountry() {
    let formData = new FormData();
    formData.append("continent_id", continentSelected);
    const url1 = `${url}/wizard/get-countries`;
    axios
      .post(url1, formData)
      .then((res) => {
        setCountries(res.data);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    getCountry();
  }, [continentSelected]);
  //leagues
  const [leagues, setLeagues] = useState([]);
  async function getLeagues() {
    try {
      const response = await axios.get(`${url}/wizard/get-leagues`);
      setLeagues(response.data);
      setLeagueSelected(response.data[0].id);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getLeagues();
  }, []);
  //clubs
  const [clubs, setClubs] = useState([]);
  function getClubs() {
    let formData = new FormData();
    formData.append("league_id", leagueSelected);
    const url1 = `${url}/wizard/get-clubs`;
    axios
      .post(url1, formData)
      .then((res) => {
        setClubs(res.data);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    getClubs();
  }, [leagueSelected]);
  // extras
  const [extras, setExtras] = useState([]);
  const [extraBundles, setExtraBundles] = useState([]);
  async function getExtras() {
    try {
      const response = await axios.get(`${url}/wizard/get-extras`);
      setExtras(response.data.extras);
      setExtraBundles(response.data.bundles);
      dispatch(checkOutActions.setInitialExtras(response.data.extras));
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getExtras();
  }, []);
  //chemistry
  const [chemistryStyle, setChemistryStyle] = useState([]);
  const [chemistryClicked, setChemistryClicked] = useState();
  const [chemistryClickedImage, setChemistryClickedImage] = useState(null);
  const [chemistryName, setChemistryName] = useState("");

  async function getChemistryStyle() {
    try {
      const response = await axios.get(`${url}/wizard/get-chemistry-styles`);
      setChemistryStyle(response.data);
      const id = response.data.filter((chem) => chem.name.toLowerCase() === "propack")
      if(id){
        setChemistryClicked(id[0].id)
        convertToPath(id[0].main_image)
        setChemistryName(id[0].name)
      }else {
        setChemistryClicked(null)
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getChemistryStyle();
  }, []);
  //image guide
  const [imageGuide, setImageFGuide] = useState([]);
  async function getImageGuide() {
    try {
      const response = await axios.get(`${url}/wizard/get-image-guide`);
      setImageFGuide(response.data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getImageGuide();
  }, []);
  //cards fonts
  const [cardsFonts, setCardsFonts] = useState([]);
  async function getCardsFonts() {
    try {
      const response = await axios.get(`${url}/wizard/get-cards-fonts`);
      setCardsFonts(response.data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getCardsFonts();
  }, []);
  //user customized card
  const [cardId, setCardId] = useState(null);
  const [clientName, setClientName] = useState("");
  const [imageProperties, setImageProperties] = useState({
    src: "",
    top: 33,
    left: 80,
    width: 352,
    height: 640,
  });
  const [sport, setSport] = useState("");
  const [position, setPosition] = useState("");
  const [club, setClub] = useState("");
  const [country, setCountry] = useState("");
  const [chemistry, setChemistry] = useState("");
  //canvas
  const [cardName, setCardName] = useState("");
  const canvasRef = useRef();
  const canvasEl = useRef(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [bgImage, setBgImage] = useState("");
  const [fontColor, setFontColor] = useState("");
  const [fontFamily, setFontFamily] = useState("");
  const [fontLoaded, setFontLoaded] = useState(false);
  const [nameFont, setNameFont] = useState();
  const [positionLeft, setPositionLeft] = useState();
  //canvas functions
  const cropperCanvasRef = useRef(null);
  function applyFont(fontFileName) {
    const fontUrl = cardsFonts.filter((font) => font.name === fontFileName);
    if (fontUrl) {
      if (fontUrl[0].font_path) {
        const fontFace = new FontFace(
          "customCardFont",
          `url(${fontUrl[0].font_path})`
        );
        fontFace.load().then(() => {
          document.fonts.add(fontFace);
          setFontFamily("customCardFont");
          setFontLoaded(true);
        });
      } else {
        setFontFamily("Inter");
        setFontLoaded(true);
      }
    } else {
      setFontFamily("Inter");
      setFontLoaded(true);
    }
  }
  function imageChangeHandler(
    cardUrl,
    cardName,
    cardFontColor,
    cardIdP,
    cardFontName
  ) {
    if (cardId === cardIdP) {
      setBackToStep1(false);
      setActiveCol("col2");
      setActiveRow("row1");
      dispatch(stylesActions.disableBtnNext());
    }
    if (cardFontName) {
      applyFont(cardFontName);
    } else {
      setFontLoaded(false);
    }
    setBgImage(cardUrl);
    setCardName(cardName);
    setFontColor(cardFontColor);
    setCardId(cardIdP);
  }
  function nameChangeHandler(event) {
    if (event.target.value.length === 0) {
      dispatch(stylesActions.disableBtnNext());
    } else if (event.target.value.length <= 22) {
      setNameFont(21);
    } else if (
      event.target.value.length > 22 &&
      event.target.value.length < 28
    ) {
      setNameFont(19);
    } else if (event.target.value.length > 28) {
      setNameFont(15);
    }
    setClientName(event.target.value);
  }
  function playerImageInputHandler() {
    playerImageRef.current.click();
  }
  function updateImageProperties(newProperties) {
    setImageProperties((prevProperties) => ({
      ...prevProperties,
      ...newProperties,
    }));
  }
  const [isImageProcessing, setIsImageProcessing] = useState(false);
  const playerImageHandler = async (event) => {
    setIsImageProcessing(true);
    let formData = new FormData();
    const api = "https://trans.smcare.net/rest.php";////www.chatgpts.org/transparent/rest.php
    const url1 = `${url}/wizard/save-uploaded-image`;
    try {
      var reader = new FileReader();
      reader.onload = function (e) {
        formData.append("is_logged_in", loggedIn);
        formData.append("token", localStorage.getItem("token"));
        formData.append("main_image", e.target.result);
        axios.post(url1, formData).then((res) => {
          if (res.data.succeeded) {
            const apiUrlWithImageUrl = `${api}?image_url=${res.data.url}`;
            axios.post(apiUrlWithImageUrl).then((response) => {
              updateImageProperties({
                src: response.data.new_image_url,
              });
              setShowImageCropper(true);
              setIsImageProcessing(false);
            });
          } else {
            alert("false");
          }
        });
      };
      reader.readAsDataURL(event.target.files[0]);
    } catch (err) {
      console.log(err);
    }
  };
  const btnApply = useRef(null);
  const rotate1 = useRef(null);
  const rotate2 = useRef(null);
  const magnefier = useRef(null);
  const minus = useRef(null);
  const previewCanvas = useRef(null);
  const previewCanvas2 = useRef(null);
  const [cropperFullyLoaded, setCropperFullyLoaded] = useState(false);

  useEffect(() => {
    if (imageProperties.src) {
      var image = new Image();
      var image2 = new Image();
      var image3 = new Image();
      image.src = imageProperties.src;
      image.onload = () => {
        cropperCanvasRef.current.src = imageProperties.src;

        var ctx2 = previewCanvas.current.getContext("2d");
        var ctx3 = previewCanvas2.current.getContext("2d");
        image3.src = canvasEl.current.toDataURL();
        image3.onload = () => {
          ctx3.drawImage(image3, 0, 0, 257, 360);
          ctx2.drawImage(image3, 0, 0, 257, 360);
        };
        const cropper = new Cropper(cropperCanvasRef.current, {
          // aspectRatio: image.naturalWidth / image.naturalHeight,
          aspectRatio: 150 / 190,
          viewMode: 1,
          autoCrop: true,
          background: true,
          ready: () => {
            setCropperFullyLoaded(true);
            rotate1.current.onclick = () => {
              cropper.rotate(90);
            };
            rotate2.current.onclick = () => {
              cropper.rotate(-90);
            };
            magnefier.current.onclick = () => {
              cropper.zoom(0.1);
            };
            minus.current.onclick = () => {
              cropper.zoom(-0.1);
            };
          },
          crop: () => {
            var croppedCanvas = cropper.getCroppedCanvas();
            image2.src = croppedCanvas.toDataURL();
            image2.onload = () => {
              ctx2.clearRect(96, 22, 128, 170);
              ctx2.drawImage(image2, 96, 22, 128, 170);
            };
          },
        });
        EXIF.getData(image, function () {
          var orientation = EXIF.getTag(this, "Orientation");
          if (orientation === 6) {
            cropper.rotate(90);
          }
        });
        btnApply.current.addEventListener("click", () => {
          handleCloseImageCropper();
          setCroppedImage(image2.src);
        });
      };
    }
  }, [imageProperties.src]);

  function clearUploadedImage() {
    updateImageProperties({
      src: "",
    });
    setCroppedImage(null);

    setImgUploaderContent(false);
    playerImageRef.current.value = "";
    document.getElementById("uploaded").src = "";
    document.getElementById("deleteUploaded").style.display = "none";
    dispatch(stylesActions.disableBtnNext());
  }
  const [trait1, setTrait1] = useState("");
  const [trait2, setTrait2] = useState("");
  const [trait3, setTrait3] = useState("");
  const [trait4, setTrait4] = useState("");
  const [trait5, setTrait5] = useState("");
  const [trait6, setTrait6] = useState("");
  const [trait1V, setTrait1V] = useState("");
  const [trait2V, setTrait2V] = useState("");
  const [trait3V, setTrait3V] = useState("");
  const [trait4V, setTrait4V] = useState("");
  const [trait5V, setTrait5V] = useState("");
  const [trait6V, setTrait6V] = useState("");
  //suggestions and overaLL rating
  const [overAllRating, setOverAllRating] = useState("");
  function positionChangeHandler(event, catId) {
    if (event.target.value.length == 0) {
      dispatch(stylesActions.disableBtnNext());
      return;
    } else if (event.target.value.length <= 1) {
      setPosition(event.target.value);
      setPositionLeft(70);
    } else if (event.target.value.length === 2) {
      setPosition(event.target.value);
      setPositionLeft(65);
    } else if (event.target.value.length === 3) {
      setPosition(event.target.value);
      setPositionLeft(60);
    } else if (event.target.value.length > 3) {
      setPosition(event.target.value);
      setPositionLeft(60);
    }
    setTrait1(sportC[catId][0]);
    setTrait2(sportC[catId][1]);
    setTrait3(sportC[catId][2]);
    setTrait4(sportC[catId][3]);
    setTrait5(sportC[catId][4]);
    setTrait6(sportC[catId][5]);
  }
  function clubChangeHandler(clubId, clubImage) {
    setClubClicked(clubId);
    setClub(clubImage);
  }
  function countryChangeHandler(countryId, countryImage) {
    setCountryClicked(countryId);
    setCountry(countryImage);
  }
  //custom
  const flagInputRef = useRef();
  const clubInputRef = useRef();
  function customFlagModal() {
    setShowCustomFlagCountryModal(true);
  }
  function closeFlagModalHandler() {
    setShowCustomFlagCountryModal(false);
  }
  function customFlagInputhandler() {
    flagInputRef.current.click();
  }
  function customFlagHandler(event) {
    var reader = new FileReader();
    reader.onload = function (e) {
      var imageCustomFlag = new Image();
      imageCustomFlag.src = e.target.result;
      imageCustomFlag.onload = function () {
        setCountry(imageCustomFlag.src);
      };
    };
    reader.readAsDataURL(event.target.files[0]);
    closeFlagModalHandler();
  }
  //
  function customClubModal() {
    setShowCustomFlagClubModal(true);
  }
  function closeClubModalHandler() {
    setShowCustomFlagClubModal(false);
  }
  function customClubInputhandler() {
    clubInputRef.current.click();
  }
  function customClubHandler(event) {
    var reader = new FileReader();
    reader.onload = function (e) {
      var imageCustomClub = new Image();
      imageCustomClub.src = e.target.result;
      imageCustomClub.onload = function () {
        setClub(imageCustomClub.src);
      };
    };
    reader.readAsDataURL(event.target.files[0]);
    closeClubModalHandler();
  }
  //chemistry
  function convertToPath(imagePath) {
    fetch(imagePath)
      .then((response) => response.text())
      .then((svgText) => {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgText, "image/svg+xml");
        const paths = svgDoc.querySelectorAll("path");
        const pathData = Array.from(paths).map((path) =>
          path.getAttribute("d")
        );
        setChemistry(pathData.join(" "));
      })
      .catch((error) => {
        console.error("Error loading SVG file:", error);
      });
  }

  function chemistryStyleHandler(id, image,name) {
    setChemistryClicked(id);
    convertToPath(image);
    setChemistryClickedImage(image)
    setChemistryName(name)
  }
  //attribute titles handlers
  function trait1THandler(e) {
    if (e.target.value.length <= 3) {
      setTrait1(e.target.value.toUpperCase());
    } else {
      return;
    }
  }
  function trait2THandler(e) {
    if (e.target.value.length <= 3) {
      setTrait2(e.target.value.toUpperCase());
    } else {
      return;
    }
  }
  function trait3THandler(e) {
    if (e.target.value.length <= 3) {
      setTrait3(e.target.value.toUpperCase());
    } else {
      return;
    }
  }
  function trait4THandler(e) {
    if (e.target.value.length <= 3) {
      setTrait4(e.target.value.toUpperCase());
    } else {
      return;
    }
  }
  function trait5THandler(e) {
    if (e.target.value.length <= 3) {
      setTrait5(e.target.value.toUpperCase());
    } else {
      return;
    }
  }
  function trait6THandler(e) {
    if (e.target.value.length <= 3) {
      setTrait6(e.target.value.toUpperCase());
    } else {
      return;
    }
  }
  //atribute values handlers
  function trait1VHandler(e) {
    if (e.target.value.length <= 3) {
      setTrait1V(e.target.value);
    } else {
      return;
    }
  }
  function trait2VHandler(e) {
    if (e.target.value.length <= 3) {
      setTrait2V(e.target.value);
    } else {
      return;
    }
  }
  function trait3VHandler(e) {
    if (e.target.value.length <= 3) {
      setTrait3V(e.target.value);
    } else {
      return;
    }
  }
  function trait4VHandler(e) {
    if (e.target.value.length <= 3) {
      setTrait4V(e.target.value);
    } else {
      return;
    }
  }
  function trait5VHandler(e) {
    if (e.target.value.length <= 3) {
      setTrait5V(e.target.value);
    } else {
      return;
    }
  }
  function trait6VHandler(e) {
    if (e.target.value.length <= 3) {
      setTrait6V(e.target.value);
    } else {
      return;
    }
  }
  //ratings
  useEffect(() => {
    const sugg1 =
      (+trait1V + +trait2V + +trait3V + +trait4V + +trait5V + +trait6V) / 6;
    if (sugg1 > 99) {
      setOverAllRating("99");
    } else {
      setOverAllRating(String(Math.round(sugg1)));
    }
  }, [trait1V, trait2V, trait3V, trait4V, trait5V, trait6V]);
  //suggestion clicks
  function generateRandomNumbers(num) {
    // Set the target average

    // Set the range for the random numbers
    const min = 50;
    const max = 99;

    // Generate six random numbers between min and max
    const numbers = [];
    for (let i = 0; i < 6; i++) {
      numbers.push(Math.floor(Math.random() * (max - min + 1)) + min);
    }

    // Calculate the current average
    let currentAvg =
      numbers.reduce((sum, num) => sum + num, 0) / numbers.length;

    // Adjust the numbers until the average matches the target average
    while (Math.abs(currentAvg - num) > 0.0001) {
      // Calculate the difference between the current average and the target average
      const diff = num - currentAvg;

      // Find a random index in the array of numbers
      const index = Math.floor(Math.random() * numbers.length);

      // Add the difference to the number at the random index
      var temp = numbers[index] + diff;
      if (temp < 50) {
        temp = 50;
      } else if (temp > 99) {
        temp = 99;
      }
      numbers[index] = temp;

      // Recalculate the average
      currentAvg = numbers.reduce((sum, num) => sum + num, 0) / numbers.length;
    }
    return numbers;
  }

  function sugg1(e) {
    const randomNumbers = generateRandomNumbers(e.target.value);
    setTrait1V(String(Math.round(randomNumbers[0])));
    setTrait2V(String(Math.round(randomNumbers[1])));
    setTrait3V(String(Math.round(randomNumbers[2])));
    setTrait4V(String(Math.round(randomNumbers[3])));
    setTrait5V(String(Math.round(randomNumbers[4])));
    setTrait6V(String(Math.round(randomNumbers[5])));
    setOverAllRating(e.target.value);
  }
  function sugg2(e) {
    const randomNumbers = generateRandomNumbers(e.target.value);
    setTrait1V(String(Math.round(randomNumbers[0])));
    setTrait2V(String(Math.round(randomNumbers[1])));
    setTrait3V(String(Math.round(randomNumbers[2])));
    setTrait4V(String(Math.round(randomNumbers[3])));
    setTrait5V(String(Math.round(randomNumbers[4])));
    setTrait6V(String(Math.round(randomNumbers[5])));
    setOverAllRating(e.target.value);
  }
  function sugg3(e) {
    const randomNumbers = generateRandomNumbers(e.target.value);
    setTrait1V(String(Math.round(randomNumbers[0])));
    setTrait2V(String(Math.round(randomNumbers[1])));
    setTrait3V(String(Math.round(randomNumbers[2])));
    setTrait4V(String(Math.round(randomNumbers[3])));
    setTrait5V(String(Math.round(randomNumbers[4])));
    setTrait6V(String(Math.round(randomNumbers[5])));
    setOverAllRating(e.target.value);
  }
  function overAllRatingHandler(e) {
    if (e.target.value.length === 0) {
      dispatch(stylesActions.disableBtnNext());
    } else if (e.target.value.length < 3) {
      setOverAllRating(e.target.value);
    } else if (e.target.value.length >= 3) {
      setOverAllRating(99);
      e.target.value = 99;
    }
  }
  useEffect(() => {
    var canvas = new fabric.Canvas(canvasRef.current, {
      width: 257,
      height: 360,
      preserveObjectStacking: true,
    });
    //backGround
    fabric.Image.fromURL(
      bgImage,
      function (img) {
        img.scaleX = canvas.width / img.width;
        img.scaleY = canvas.height / img.height;
        img.originX = "left";
        img.originY = "top";
        img.selectable = false;
        img.evented = false;
        canvas.add(img);
        canvas.sendToBack(img);
        img.moveTo(0);
      },
      { crossOrigin: "anonymous" }
    );
    //name
    var playerName = new fabric.Textbox("", {
      originX: "left",
      originY: "top",
      editable: false,
      selectable: false,
      evented: false,
      fontFamily: fontLoaded ? fontFamily : "sans-serif",
    });
    playerName.set({
      fill: fontColor,
      text: clientName.toUpperCase(),
      textAlign: "center",
      left: -0.9,
      top: 195,
      width: 260,
      fontWeight: 600,
      selectable: false,
      evented: false,
      fontSize: nameFont,
    });
    //player image
    var playerImage = new fabric.Image("", {
      editable: true,
      selectable: false,
      evented: true,
      cornerColor: "blue",
      cornerStyle: "circle",
      transparentCorners: false,
      crossOrigin: "anonymous",
      name: "playerImage",
    });
    var pImage = new Image();
    pImage.src = croppedImage;
    pImage.onload = function () {
      playerImage.setSrc(pImage.src, function (plImage) {
        plImage.set({
          left: 96,
          top: 25,
          scaleX: canvas.width / (plImage.width * 2.04),
          scaleY: canvas.height / (plImage.height * 2.2),
        });
        canvas.add(plImage).renderAll();
        setImgUploaderContent(true);
        document.getElementById("deleteUploaded").style.display = "flex";
        document.getElementById("uploaded").src = pImage.src;
      });
    };
    playerImage.setControlsVisibility({
      bl: true,
      br: true,
      tl: true,
      tr: true,
      mb: false,
      mt: false,
      ml: false,
      mr: false,
    });
    //position
    var playerPosition = new fabric.Textbox("", {
      originX: "left",
      originY: "top",
      editable: false,
      selectable: false,
      evented: true,
      fontFamily: fontLoaded ? fontFamily : "sans-serif",
    });
    playerPosition.set({
      fill: fontColor,
      text: position.toUpperCase(),
      fontSize: 18,
      left: positionLeft,
      top: 92,
    });
    //club
    var clubImage = new fabric.Image("", {
      top: 146,
      left: 60,
      editable: false,
      selectable: false,
      evented: false,
    });
    var imgClub = new Image();
    imgClub.src = club;
    imgClub.onload = function () {
      clubImage.setSrc(
        imgClub.src,
        function (clubObj1) {
          var scale =
            Math.min(25, 20) / Math.max(clubObj1.width, clubObj1.height);
          clubObj1.set({
            scaleX: scale * 1.5,
            scaleY: scale * 1.5,
          });
          canvas.add(clubObj1).renderAll();
          setClubUploaderContent(true);
        },
        {
          crossOrigin: "anonymous",
        }
      );
      setShowClubs(false);
    };
    //country
    var countryImage = new fabric.Image("", {
      top: 117,
      left: 58,
      editable: false,
      selectable: false,
      evented: false,
    });
    var imgCountry = new Image();
    imgCountry.src = country;
    imgCountry.onload = function () {
      countryImage.setSrc(
        imgCountry.src,
        function (countryObj) {
          var scale =
            Math.min(25, 20) / Math.max(countryObj.width, countryObj.height);
          countryObj.set({
            scaleX: scale * 1.6,
            scaleY: scale * 1.6,
          });
          canvas.add(countryObj).renderAll();
          setCountryUploaderContent(true);
        },
        {
          crossOrigin: "anonymous",
        }
      );
      setShowCountries(false);
    };
    //traits
    var playerTrait1 = new fabric.Textbox("", {
      originX: "left",
      originY: "top",
      editable: false,
      selectable: false,
      evented: false,
      fontFamily: fontLoaded ? fontFamily : "sans-serif",
    });
    var playerTrait2 = new fabric.Textbox("", {
      originX: "left",
      originY: "top",
      editable: false,
      selectable: false,
      evented: false,
      fontFamily: fontLoaded ? fontFamily : "sans-serif",
    });
    var playerTrait3 = new fabric.Textbox("", {
      originX: "left",
      originY: "top",
      editable: false,
      selectable: false,
      evented: false,
      fontFamily: fontLoaded ? fontFamily : "sans-serif",
    });
    var playerTrait4 = new fabric.Textbox("", {
      originX: "left",
      originY: "top",
      editable: false,
      selectable: false,
      evented: false,
      fontFamily: fontLoaded ? fontFamily : "sans-serif",
    });
    var playerTrait5 = new fabric.Textbox("", {
      originX: "left",
      originY: "top",
      editable: false,
      selectable: false,
      evented: false,
      fontFamily: fontLoaded ? fontFamily : "sans-serif",
    });
    var playerTrait6 = new fabric.Textbox("", {
      originX: "left",
      originY: "top",
      editable: false,
      selectable: false,
      evented: false,
      fontFamily: fontLoaded ? fontFamily : "sans-serif",
    });
    function writeTrait1() {
      playerTrait1.set({
        fill: fontColor,
        text: trait1,
        fontSize: 19,
        left: 90,
        top: 227,
      });
      canvas.add(playerTrait1);
      canvas.renderAll();
    }
    function writeTrait2() {
      playerTrait2.set({
        text: trait2,
        fill: fontColor,
        fontSize: 19,
        left: 90,
        top: 249,
      });
      canvas.add(playerTrait2);
      canvas.renderAll();
    }
    function writeTrait3() {
      playerTrait3.set({
        text: trait3,
        fill: fontColor,
        fontSize: 19,
        left: 90,
        top: 269,
      });
      canvas.add(playerTrait3);
      canvas.renderAll();
    }
    function writeTrait4() {
      playerTrait4.set({
        text: trait4,
        fill: fontColor,
        fontSize: 19,
        left: 157,
        top: 227,
      });
      canvas.add(playerTrait4);
      canvas.renderAll();
    }
    function writeTrait5() {
      playerTrait5.set({
        text: trait5,
        visible: true,
        fill: fontColor,
        fontSize: 19,
        left: 157,
        top: 249,
      });
      canvas.add(playerTrait5);
      canvas.renderAll();
    }
    function writeTrait6() {
      playerTrait6.set({
        text: trait6,
        fill: fontColor,
        fontSize: 19,
        left: 157,
        top: 269,
      });
      canvas.add(playerTrait6);
      canvas.renderAll();
    }
    writeTrait1();
    writeTrait2();
    writeTrait3();
    writeTrait4();
    writeTrait5();
    writeTrait6();
    // attr values
    var playerTrait1V = new fabric.Textbox("", {
      originX: "left",
      originY: "top",
      editable: false,
      selectable: false,
      evented: false,
      fontFamily: fontLoaded ? fontFamily : "sans-serif",
    });
    var playerTrait2V = new fabric.Textbox("", {
      originX: "left",
      originY: "top",
      editable: false,
      selectable: false,
      evented: false,
      fontFamily: fontLoaded ? fontFamily : "sans-serif",
    });
    var playerTrait3V = new fabric.Textbox("", {
      originX: "left",
      originY: "top",
      editable: false,
      selectable: false,
      evented: false,
      fontFamily: fontLoaded ? fontFamily : "sans-serif",
    });
    var playerTrait4V = new fabric.Textbox("", {
      originX: "left",
      originY: "top",
      editable: false,
      selectable: false,
      evented: false,
      fontFamily: fontLoaded ? fontFamily : "sans-serif",
    });
    var playerTrait5V = new fabric.Textbox("", {
      originX: "left",
      originY: "top",
      editable: false,
      selectable: false,
      evented: false,
      fontFamily: fontLoaded ? fontFamily : "sans-serif",
    });
    var playerTrait6V = new fabric.Textbox("", {
      originX: "left",
      originY: "top",
      editable: false,
      selectable: false,
      evented: false,
      fontFamily: fontLoaded ? fontFamily : "sans-serif",
    });
    function writeTrait1V() {
      playerTrait1V.set({
        fill: fontColor,
        text: trait1V,
        fontSize: 19,
        fontWeight: 600,
        left: 69,
        top: 227,
        textAlign: "center",
      });
      canvas.add(playerTrait1V);
      canvas.renderAll();
    }
    function writeTrait2V() {
      playerTrait2V.set({
        text: trait2V,
        fill: fontColor,
        fontSize: 19,
        fontWeight: 600,
        left: 69,
        top: 249,
        textAlign: "center",
      });
      canvas.add(playerTrait2V);
      canvas.renderAll();
    }
    function writeTrait3V() {
      playerTrait3V.set({
        text: trait3V,
        fill: fontColor,
        fontSize: 19,
        fontWeight: 600,
        left: 69,
        top: 269,
        textAlign: "center",
      });
      canvas.add(playerTrait3V);
      canvas.renderAll();
    }
    function writeTrait4V() {
      playerTrait4V.set({
        text: trait4V,
        fill: fontColor,
        fontSize: 19,
        fontWeight: 600,
        left: 136,
        top: 227,
        textAlign: "center",
      });
      canvas.add(playerTrait4V);
      canvas.renderAll();
    }
    function writeTrait5V() {
      playerTrait5V.set({
        text: trait5V,
        fill: fontColor,
        fontSize: 19,
        fontWeight: 600,
        left: 136,
        top: 249,
        textAlign: "center",
      });
      canvas.add(playerTrait5V);
      canvas.renderAll();
    }
    function writeTrait6V() {
      playerTrait6V.set({
        text: trait6V,
        fill: fontColor,
        fontSize: 19,
        fontWeight: 600,
        left: 136,
        top: 269,
        textAlign: "center",
      });
      canvas.add(playerTrait6V);
      canvas.renderAll();
    }
    writeTrait1V();
    writeTrait2V();
    writeTrait3V();
    writeTrait4V();
    writeTrait5V();
    writeTrait6V();
    //chemistry
    // var chemistryImage = new fabric.Image("", {
    //   top: 300,
    //   editable: false,
    //   selectable: false,
    //   evented: false,
    //   crossOrigin: "anonymous",
    // });
    // var imgChemistry = new Image();
    // imgChemistry.src = chemistry;
    // imgChemistry.onload = function () {
    //   chemistryImage.setSrc(
    //     imgChemistry.src,
    //     function (chemistryObj) {
    //       chemistryObj.scaleX = 0.13;
    //       chemistryObj.scaleY = 0.13;
    //       canvas.centerObjectH(chemistryObj);
    //       canvas.add(chemistryObj).renderAll();
    //     },
    //     {
    //       crossOrigin: "anonymous",
    //     }
    //   );
    // };
    var path = new fabric.Path(chemistry, {
      selectable: false,
      editable: false,
      originX: "left",
      originY: "top",
      fill: fontColor,
      left: 5,
      top: 315,
      scaleX: chemistryName.toLowerCase() === "propack" ? -0.2 : -0.02,
      scaleY: chemistryName.toLowerCase() === "propack" ? -0.2 : 0.02,
      angle: 180,
    });

    if (path.height > 800) {
      path.set({
        top: 324,
      });
    } else if (path.height <= 800 && chemistryName.toLowerCase() === "propack") {
      path.set({
        top: 311,
      });
    }else if (path.height <= 800 && !chemistryName.toLowerCase() === "propack") {
      path.set({
        top: 316,
      });
    }

    // ratings
    var overallRating = new fabric.Textbox("", {
      originX: "left",
      originY: "top",
      editable: false,
      selectable: false,
      evented: false,
    });
    overallRating.set({
      fill: fontColor,
      text: overAllRating === "0" ? "" : overAllRating,
      fontFamily: fontLoaded ? fontFamily : "sans-serif",
      fontSize: 25,
      left: overAllRating === "87" ? 63 : 62,
      top: 63,
      fill: fontColor,
      textAlign: "center",
    });
    canvas.add(playerName);
    canvas.add(playerPosition);
    canvas.add(overallRating);
    canvas.add(path).centerObjectH(path);
    canvasEl.current = canvas;
  }, [
    bgImage,
    clientName,
    croppedImage,
    position,
    club,
    country,
    chemistry,
    fontColor,
    fontFamily,
    overAllRating,
    trait1,
    trait2,
    trait3,
    trait4,
    trait5,
    trait6,
    trait1V,
    trait2V,
    trait3V,
    trait4V,
    trait5V,
    trait6V,
  ]);
  //extras
  const getExtrasSelected = useSelector((state) => state.checkOut.extraList);
  const textAreaRef = useRef(null);
  const [isTextAreaVisible, setIsTextAreaVisible] = useState(false);
  const [extrasToDisable, setExtrasToDisable] = useState([]);
  function handleCheckExtra(event, id) {
    var hasNote = event.target.getAttribute("data-note") === "1";
    var hasBundle = event.target.getAttribute("data-bundle") === "1";
    if (event.target.checked) {
      if (hasBundle) {
        const toDisable = extraBundles.filter(
          (extra) => extra.r_bundle_id === id
        );
        toDisable.forEach((extra) => {
          setExtrasToDisable((prevState) => [
            ...prevState,
            extra.r_card_extra_id,
          ]);
          dispatch(
            checkOutActions.decrementExtra({
              type: "notChecked",
              id: extra.r_card_extra_id,
            })
          );
        });
      }
      if (hasNote) {
        setIsTextAreaVisible(true);
          const t = extras.filter((ex) => ex.id === id);
          setNoteImgSelected(t[0].extra_images[0].id);
          setNoteExtraSelected(id);
          dispatch(
            checkOutActions.setGiftNoteImage({
              extraId: id,
              imgId: t[0].extra_images[0].id,
            })
          );
      }
      dispatch(checkOutActions.incrementExtra({ id }));
    } else {
      if (hasBundle) {
        const toDisable = extraBundles.filter(
          (extra) => extra.r_bundle_id === id
        );
        toDisable.forEach((extra) => {
          setExtrasToDisable((prevState) =>
            prevState.filter((item) => item !== extra.r_card_extra_id)
          );
        });
      }
      dispatch(checkOutActions.decrementExtra({ type: "notChecked", id: id }));
      if (hasNote) {
        setIsTextAreaVisible(false);
      }
      setNoteImgSelected(null);
    }
  }
  const [noteImgSelected, setNoteImgSelected] = useState(null);
  const [noteExtraSelected, setNoteExtraSelected] = useState(null);
  function handleGiftNoteImage(idExtra, idImage) {
    setNoteImgSelected(idImage);
    setNoteExtraSelected(idExtra);
    dispatch(
      checkOutActions.setGiftNoteImage({ extraId: idExtra, imgId: idImage })
    );
  }
  const [textAreaChars,setTextAreaAchars] = useState('')
  function handleGiftNoteText(event) {
    setTextAreaAchars(event.target.value)
    dispatch(
      checkOutActions.setGiftNoteText({
        extraId: noteExtraSelected,
        extraNote: event.target.value,
      })
    );
  }
  useEffect(() => {
    if (isTextAreaVisible && textAreaRef.current) {
      textAreaRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isTextAreaVisible]);
  //set users customized card
  const [loggedIn, setIsloggedIn] = useState(false);
  //checkout Modal
  const [showModal, setShowModal] = useState(false);
  const [cardStyleModal, setCardStyleModal] = useState(null);
  const [cardSizeModal, setCardSizeModal] = useState(null);
  const [cardSizeNameModal, setCardSizeNameModal] = useState(null);
  const [cardStyleNameModal, setCardStyleNameModal] = useState(null);
  const [cardPriceModal, setCardPriceModal] = useState(null);
  const closeModalHandler = () => {
    setShowModal(false);
  };
  const continueShoppingHandler = () => {
    history.push("/shopcards");
  };
  useEffect(() => {
    localforage.getItem("user").then((user) => {
      if (user !== null) {
        setIsloggedIn(true);
      } else {
        setIsloggedIn(false);
      }
    });
    localforage.getItem("st").then((value) => {
      setCardStyleModal(value);
    });
    localforage.getItem("si").then((value) => {
      setCardSizeModal(value);
    });
    localforage.getItem("stn").then((value) => {
      setCardStyleNameModal(value);
    });
    localforage.getItem("sin").then((value) => {
      setCardSizeNameModal(value);
    });
    localforage.getItem("cp").then((value) => {
      setCardPriceModal(value);
    });
    dispatch(getExtrasGuide());
  }, []);
  const canvasImage = useSelector((state) => state.checkOut.finalCanvasImage);
  const [mappedExtras, setMappedExtras] = useState([]);
  const [isAddToCard, setIsAaddToCard] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  function insertCard() {
    notify("Please wait");
    setIsAaddToCard(true);
    const extrasWithQuantity = getExtrasSelected.filter(
      (extra) => extra.quantity > 0
    );
    setMappedExtras(extrasWithQuantity);
    var imageData = canvasEl.current.toDataURL();
    dispatch(checkOutActions.setFinalCanvasImage(imageData));
    var attr = new Array();
    attr.push(
      { name: trait1, value: trait1V },
      { name: trait2, value: trait2V },
      { name: trait3, value: trait3V },
      { name: trait4, value: trait4V },
      { name: trait5, value: trait5V },
      { name: trait6, value: trait6V }
    );
    let formData = new FormData();
    if (
      localStorage.getItem("session_id") &&
      localStorage.getItem("session_id") != null &&
      localStorage.getItem("session_id") !== "null"
    ) {
      formData.append("session_id", localStorage.getItem("session_id"));
    }
    formData.append("card_name", cardName);
    formData.append("is_logged_in", loggedIn);
    formData.append("token", localStorage.getItem("token"));
    formData.append("card_id", cardId);
    formData.append("player_name", clientName);
    formData.append("card_style", cardStyleModal);
    formData.append("card_size", cardSizeModal);
    formData.append("sport", sport);
    formData.append("position", position);
    formData.append("attribute_positions", JSON.stringify(attr));
    formData.append("country", country);
    formData.append("club", club);
    formData.append("chemistry", chemistryClickedImage);
    formData.append("extras", JSON.stringify(getExtrasSelected));
    formData.append("init_player_image", imageProperties.src);
    formData.append("cropped_image", croppedImage);
    formData.append("main_image", imageData);
    const url1 = `${url}/wizard/set-users-customized-card`;
    axios
      .post(url1, formData)
      .then((res) => {
        if (res.data.succeeded) {
          setShowModal(true);
          if (
            !localStorage.getItem("session_id") ||
            localStorage.getItem("session_id") == null ||
            localStorage.getItem("session_id") === "null"
          ) {
            localStorage.setItem("session_id", res.data.session_id);
          }
          dispatch(checkOutActions.setCardsCount({ type: "update", count: 1 }));
          var card_total =
            parseFloat(cardPriceModal) +
            extrasWithQuantity.reduce((acx, extra) => {
              return acx + parseFloat(extra.price) * parseInt(extra.quantity);
            }, 0);
          setSubTotal(card_total);
          notify(res.data.message);
          localforage.removeItem("si");
          localforage.removeItem("st");
          localforage.removeItem("sin");
          localforage.removeItem("stn");
          localforage.removeItem("cp");
        } else {
          notify(res.data.message);
        }
        setIsAaddToCard(false);
      })
      .catch((err) => console.log(err));
  }
  //search - country
  const [queryCountry, setQueryCountry] = useState("");
  function handleChangeCountry(event) {
    setQueryCountry(event.target.value);
  }
  function handleKeyUpCountry() {
    searchCountry();
  }
  async function searchCountry() {
    const response = await axios.get(`${url}/wizard/search-country`, {
      params: { queryCountry },
    });
    setCountries(response.data);
  }
  //search - club
  const [queryClub, setQueryClub] = useState("");
  function handleChangeClub(event) {
    setQueryClub(event.target.value);
  }
  function handleKeyUpClub() {
    searchClub();
  }
  async function searchClub() {
    const response = await axios.get(`${url}/wizard/search-club`, {
      params: { queryClub },
    });
    setClubs(response.data);
  }
  //disable btn next
  const btnNextStatus = useSelector(
    (state) => state.styles.wizardBtnNextDisabled
  );
  useEffect(() => {
    dispatch(stylesActions.disableBtnNext());
  }, []);
  useEffect(() => {
    if (activeCol === "col1" && cardName) {
      dispatch(stylesActions.enableBtnNext());
    }
  }, [activeCol, cardName]);
  useEffect(() => {
    if (activeRow === "row1" && clientName && croppedImage) {
      dispatch(stylesActions.enableBtnNext());
    }
  }, [activeCol, activeRow, clientName, croppedImage]);
  useEffect(() => {
    if (activeRow === "row3" && position) {
      dispatch(stylesActions.enableBtnNext());
    }
  }, [activeCol, activeRow, position]);
  useEffect(() => {
    if (activeRow === "row4" && country && club) {
      dispatch(stylesActions.enableBtnNext());
    }
  }, [activeCol, activeRow, country, club]);
  useEffect(() => {
    if (activeRow === "row9" && chemistryClicked) {
      dispatch(stylesActions.enableBtnNext());
    }
  }, [activeCol, activeRow, chemistryClicked]);
  function roundNumber(num) {
    if (num % 1 !== 0 && num.toString().split(".")[1].length >= 3) {
      return num.toFixed(2);
    } else {
      return num;
    }
  }
  const backHomeHandler = () => {
    history.push("/home");
  };

  const [isScrolling,setIsScrolling] = useState(false);

  useEffect(() => {
    if(activeCol === "col1"){
      window.addEventListener("scroll", () => {
        if(window.scrollY > 0){
          setIsScrolling(true)
        }else {
          setIsScrolling(false)
        }
      })
    }
  },[activeCol,activeRow])
  return (
    <section className="shopCardsSection !pb-20">
      <ToastContainer autoClose={4000} />
      <Modal
        show={showModal}
        onHide={closeModalHandler}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="!border-b-0" closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="!ml-auto !text-sm"
          >
            Your order has been added to your cart
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col>
            <Row className="!border-b-2">
              <div className="!pt-0">
                <img
                  src={canvasImage}
                  alt="..."
                  className="!h-60 !m-auto !pt-0"
                />
              </div>
            </Row>
            <Row>
              <Col className="!border-r-2">
                <div>
                  <ul className="!pl-0 capitalize whitespace-nowrap">
                    <li className="!text-[#000] !font-semibold !pt-1 !text-sm">
                      {cardName}
                    </li>
                    <li className="!text-[#000] !font-semibold !pt-1 !text-sm">
                      Style : {cardStyleNameModal}
                    </li>
                    <li className="!text-[#000] !font-semibold !pt-1 !text-sm">
                      Size : {cardSizeNameModal}
                    </li>
                    <li className="!text-[#000] !text-base font-extrabold !pt-1">
                      Price : ${cardPriceModal}
                    </li>
                  </ul>
                </div>
              </Col>
              <Col>
                <Row className="!border-b-2">
                  <h4 className="text-[#000] font-bold !text-sm">
                    Shopping Cart
                  </h4>
                  <ul className="!list-disc !px-9  whitespace-nowrap">
                    <li className="text-[#000] font-semibold capitalize">
                      <span className="inline-block !text-sm">{cardName}</span>
                      <span className="inline-block float-right !font-bold !text-sm">
                        ${roundNumber(cardPriceModal)}
                      </span>
                    </li>
                  </ul>
                  {mappedExtras.length > 0 && (
                    <Table size="sm" className="!text-sm">
                      <thead>
                        <tr>
                          <th className="!border-b-0">Extras:</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>Extra</th>
                          <th>Unit price</th>
                          <th className="!text-right">Total price</th>
                        </tr>
                        {mappedExtras.map((extra, index) => (
                          <tr key={index}>
                            <td>
                              {extra.option_name} x{extra.quantity}
                            </td>
                            <td className="!text-center">${extra.price}</td>
                            <td className="!text-right">
                              ${extra.price * extra.quantity}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </Row>
                <Row className="pt-2">
                  <div className="!flex !justify-between">
                    <p className="text-[#000] font-bold !text-sm">Subtotal</p>
                    <p className="text-[#000] font-extrabold !text-base">
                      ${roundNumber(subTotal)}
                    </p>
                  </div>
                </Row>
                <Row>
                  <div className="flex items-center justify-center">
                    <Link
                      to="/cart"
                      className="!text-blue-500 !font-bold text-center hover:text-[#fff]"
                    >
                      View cart
                    </Link>
                  </div>
                </Row>
              </Col>
              <Col>
                <Row>
                  <div className="flex justify-between !pt-7">
                    <Button
                      className="!whitespace-nowrap !text-sm !bg-[#E6E9EE] !text-[#000] !flex !items-center !justify-center !rounded-[60px] !border-none"
                      onClick={continueShoppingHandler}
                    >
                      <img src={image72} alt="..." className="!pr-2" />
                      Continue shopping
                    </Button>
                    <Link
                      className="!whitespace-nowrap !text-sm !bg-[#FF733A] !text-[#fff] !flex !items-center !justify-center !rounded-[60px] !border-none !px-3"
                      to="/checkout"
                    >
                      <img src={image73} alt="..." className="!pr-2" />
                      Proceed to checkout
                    </Link>
                  </div>
                </Row>
              </Col>
            </Row>
          </Col>
        </Modal.Body>
      </Modal>
      <Offcanvas
        show={showImageCropper}
        onHide={handleCloseImageCropper}
        placement="bottom"
        className="!h-[100vh] !rounded-t-[20px]"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="!flex !flex-col">
            Please select your desired part of the photo
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="!pb-2 !px-0 !overflow-x-hidden">
          <Row className="!grid !place-items-center">
            <Col>
              <img
                ref={cropperCanvasRef}
                src=""
                alt="Image to crop. Please wait"
                className="!object-contain !h-[400px] !w-[100%] !min-w-[100%]"
              />
            </Col>
          </Row>
          {!isImageProcessing && (
            <Row className="!h-[360px] !min-h-[360px] !w-full !grid !place-items-center !m-0">
              <Col className="!relative !grid !place-items-center">
                <canvas
                  ref={previewCanvas}
                  width="257"
                  height="360"
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 !z-50"
                />
                <canvas
                  ref={previewCanvas2}
                  width="257"
                  height="360"
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                />
              </Col>
            </Row>
          )}
          <Row className="!mx-2 !grid !place-items-center">
            <div className="!bg-[#E6E9EE] !rounded-3xl !flex !items-center !justify-between !mt-2 !py-3">
              <img
                src={r1}
                alt="rotate1"
                className="!cursor-pointer"
                ref={rotate1}
              />
              <img
                src={r2}
                alt="rotate2"
                className="!cursor-pointer"
                ref={rotate2}
              />
              <img
                src={m1}
                alt="mag1"
                className="!cursor-pointer"
                ref={magnefier}
              />
              <img
                src={m2}
                alt="mag2"
                className="!cursor-pointer"
                ref={minus}
              />
            </div>
          </Row>
          <Row className="!mx-2 !grid !place-items-center">
            <div
              ref={btnApply}
              className={
                cropperFullyLoaded
                  ? "!bg-green-400 !rounded-3xl !text-xl !flex !items-center !justify-center !mt-2 !py-3"
                  : "!bg-gray-400 !pointer-events-none !rounded-3xl !text-xl !flex !items-center !justify-center !mt-2 !py-3"
              }
            >
              {cropperFullyLoaded ? (
                "Apply"
              ) : (
                <>
                  <Spinner animation="border" size="sm" />
                  Please wait
                </>
              )}
            </div>
          </Row>
          <Row>
            <div className="!flex !flex-row !items-center !justify-center !mt-3">
              <img src={icon54} alt="ring" className="!pr-1" />
              In case you couldn’t edit certain details in the photo, our team
              can modify it while preparing your package.
            </div>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
      <Modal
        show={isImageProcessing}
        onHide={() => {
          setIsImageProcessing(false);
        }}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <Row>
            <img src={LoaderImage} alt="Processing..." />
          </Row>
          <Row className="!text-center !text-black !font-semibold">
            <p>Please wait, your photo is being cropped.</p>
          </Row>
        </Modal.Body>
      </Modal>
      <Container>
        {
          <Col className={activeCol === "col1" ? "col1Show" : "col1Hidden"}>
            <Row>
              <div className="flex items-center justify-between py-3 text-[#fff] !bg-[#FF733A]">
                <div className="!flex !items-center" onClick={backHomeHandler}>
                  <img src={backArrow} alt="Back" className="!pr-2 !inline" />
                  <p className="!m-0">Home</p>
                </div>
                <div>
                  <p className="m-0 font-medium sm:!text-xs !px-3 text-left">
                  Card Design
                  </p>
                </div>
                <div>
                  <p className="!bg-white !text-[#FF733A] !rounded-lg !px-3 !m-0">
                  2 / 8
                  </p>
                </div>
              </div>
            </Row>
            <div className={isScrolling ? "!fixed !top-0 !left-0 !right-0 !z-50" : "!fixed !top-14 !left-0 !right-0 !z-50"}>
              <Swiper
                modules={[Scrollbar, Navigation]}
                scrollbar={{
                  hide: false,
                  draggable: true,
                  dragSize: 70,
                }}
                slidesPerView={5}
                spaceBetween={0}
                className="swiperContainer !h-[35px] !pb-0 !m-auto !w-[100%] !bg-white"
                tag="ul"
                wrapperTag="ul"
              >
                {categories.map((card, index) => (
                  <SwiperSlide
                    tag="li"
                    key={index}
                    className="cursor-pointer !pt-1"
                    onClick={() => setCategorySelected(card.id)}
                  >
                    <h5
                      className={
                        categorySelected === card.id
                          ? "!text-[#FF733A] !font-semibold !text-base"
                          : "!text-[#A04723] !font-semibold !text-sm"
                      }
                    >
                      {card.name}
                    </h5>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            {cardStyleLoading && (
              <div className="!mt-36">
                <Loader />
              </div>
            )}
            {cardStyle.length > 0 && !cardStyleLoading && (
              <Row className="!m-auto !mt-[90px]">
                <p className="!text-[#000] !font-bold">Select Card Design</p>
                <div className="grid grid-cols-3 gap-1">
                  {cardStyle.map((card) => (
                    <div
                      key={card.id}
                      className="cursor-pointer !flex !items-center !justify-center"
                    >
                      <Card
                        className={
                          cardName === card.cards_name
                            ? "!w-32 !border-slate-600 !relative"
                            : "!w-32 !border-none !relative"
                        }
                      >
                        {card.card_label && (
                          <Card.Subtitle
                            className="!text-[12px] !absolute !top-1 !px-4 !right-0 !text-center !bg-[#00B67A] !text-white"
                            style={{ borderRadius: " 0px 7px 0px 7px" }}
                          >
                            {card.card_label}
                          </Card.Subtitle>
                        )}
                        <LazyLoad offset={300}>
                          <ProgressiveImage
                            src={`${card.path}thumbnails/${card.file_name}`}
                            placeholder={`${card.path}blures/${card.file_name}`}
                          >
                            {(src) => (
                              <img
                                src={src}
                                alt={card.cards_name}
                                name="lgCardDesign"
                                onClick={() => {
                                  imageChangeHandler(
                                    `${card.path}thumbnails/${card.file_name}`,
                                    card.cards_name,
                                    card.font_color,
                                    card.id,
                                    card.font_family
                                  );
                                }}
                                className="mt-2"
                              />
                            )}
                          </ProgressiveImage>
                        </LazyLoad>
                        <Card.Body className="!text-center !h-20 !max-h-24 !pt-3">
                          <Card.Title className="!text-sm !text-[#000000] !font-black">
                            {card.cards_name}
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                </div>
              </Row>
            )}
            {!cardStyleLoading && !cardStyle && (
              <h1 className="!text-center !mt-[50%]">
                No Cards Found Here Yet
              </h1>
            )}
          </Col>
        }
        {
          <Col
            className={activeCol === "col2" ? "col1Show pt-9" : "col1Hidden"}
          >
            <div className="flex flex-col justify-center items-center">
              <div>
                <canvas
                  ref={canvasRef}
                  height="379"
                  width="239"
                  className="absolute !top-4 inset-0 flex items-center justify-center"
                />
              </div>
              <Row
                className={
                  activeRow === "row1" ? "col1Show !w-[100%]" : "col1Hidden"
                }
              >
                <Row>
              <div className="!fixed !top-0 !left-0 flex items-center justify-between py-3 text-[#fff] !bg-[#FF733A] !z-50">
                <div className="!flex !items-center" onClick={backHomeHandler}>
                  <img src={backArrow} alt="Back" className="!pr-2 !inline" />
                  <p className="!m-0">Home</p>
                </div>
                <div>
                  <p className="m-0 font-medium sm:!text-xs !px-3 text-left">
                  Edit Your Card
                  </p>
                </div>
                <div>
                  <p className="!bg-white !text-[#FF733A] !rounded-lg !px-3 !m-0">
                  3 / 8
                  </p>
                </div>
              </div>
            </Row>
                <div className="flex items-center pb-2">
                  <img src={icon1} alt=".." />
                  <label
                    className="!font-bold !text-[#000] !m-0 !px-2"
                    htmlFor="nameCanvasSmall"
                  >
                    Name
                  </label>
                </div>
                <InputGroup as={Col} controlid="formGridEmail" size="sm">
                  <FloatingLabel
                    controlid="floatingInput"
                    label="Enter Your Name"
                    // className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Enter Your Name"
                      value={clientName}
                      onChange={nameChangeHandler}
                      className="!rounded-[15px]"
                    />
                  </FloatingLabel>
                </InputGroup>
                <div className="flex justify-between pt-3">
                  <div className="flex items-center">
                    <img src={icon5} alt=".." />
                    <label
                      className="!font-bold !text-[#000] !m-0 !px-2"
                      htmlFor="imageCanvasSmall"
                    >
                      Image
                    </label>
                  </div>
                  <div
                    onClick={() => {
                      dispatch(stylesActions.showImageGuideModalSmall());
                    }}
                  >
                    <label
                      htmlFor="imgGuide"
                      className="!text-[#999999] flex items-center justify-around cursor-pointer"
                    >
                      Image Guide
                      <img
                        className="pl-1"
                        src={icon2}
                        alt=".."
                        id="imgGuide"
                      />
                    </label>
                  </div>
                </div>
                <div className="mt-1 flex flex-col">
                  <div>
                    <Form.Group
                      className="!border-dashed !border-[#FF733A] !border-2 !rounded-[15px] !py-4 !px-3"
                      onClick={playerImageInputHandler}
                    >
                      <Form.Label
                        className={
                          !imgUploaderContent
                            ? "!m-0 !flex !flex-col !items-center !justify-center !cursor-pointer !text-[#000] !font-bold"
                            : "!m-0 !flex !items-center !justify-center !cursor-pointer !text-[#000] !font-bold"
                        }
                      >
                        {
                          <div
                            className={
                              !imgUploaderContent
                                ? "!flex !flex-col !items-center !justify-center"
                                : "col1Hidden"
                            }
                          >
                            <img src={icon3} alt="..." />
                            Drag or upload image
                          </div>
                        }
                        {imgUploaderContent && (
                          <div className="!flex !items-center !justify-between">
                            <div>
                              <h5 className="!mb-0 muted !mx-2">uploaded</h5>
                            </div>
                            <div>
                              <img
                                src={image74}
                                alt="uploaded"
                                className="!h-7"
                              />
                            </div>
                            {/* <img
                              src={icon11}
                              alt="..."
                              className="!pl-2"
                              onClick={clearUploadedImage}
                            /> */}
                          </div>
                        )}
                      </Form.Label>
                      <Form.Control
                        type="file"
                        size="lg"
                        className="!hidden"
                        accept="image/*"
                        ref={playerImageRef}
                        onChange={playerImageHandler}
                        disabled={imgUploaderContent}
                      />
                    </Form.Group>
                  </div>
                  <div
                    className="flex justify-around items-center !mt-2"
                    id="deleteUploaded"
                    style={{ display: "none" }}
                  >
                    <img
                      src=""
                      alt="..."
                      id="uploaded"
                      className="!h-[79px] !w-[79px] !rounded-[20px] !object-cover"
                    />
                    <Button
                      onClick={clearUploadedImage}
                      className="!rounded-[20px] !bg-[#E6E9EE] !text-[#000] !px-5 !border-none !flex !items-center"
                    >
                      <img src={icon9} alt="..." className="!px-2" />
                      Delete
                    </Button>
                  </div>
                </div>
                <Offcanvas
                  show={showImageGuide}
                  onHide={handleCloseImageGuide}
                  placement="bottom"
                  className={`${classes.pp} !h-[80vh] !rounded-t-[20px]`}
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="!flex !flex-col">
                      Propack image guide
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body
                    dangerouslySetInnerHTML={{ __html: imageGuide[0]?.text }}
                  ></Offcanvas.Body>
                </Offcanvas>
                {/* <Offcanvas
                  show={extrasGuideModal}
                  onHide={() => {
                    setExtrasGuideModal(false);
                  }}
                  placement="bottom"
                  className={`${classes.pp} !h-[80vh] !rounded-t-[20px]`}
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="!flex !flex-col">
                      Propack extras guide
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  {extrasGuide.content && (
                    <Offcanvas.Body
                      dangerouslySetInnerHTML={{
                        __html: extrasGuide.content[0]?.text,
                      }}
                    ></Offcanvas.Body>
                  )}
                </Offcanvas> */}
              </Row>
              <Row
                className={
                  activeRow === "row3"
                    ? "col1Show !z-50 !mt-10 !mx-3"
                    : "col1Hidden"
                }
              >
                <Row>
              <div className="!fixed !top-0 !left-0 flex items-center justify-between py-3 text-[#fff] !bg-[#FF733A] !z-50">
                <div className="!flex !items-center" onClick={backHomeHandler}>
                  <img src={backArrow} alt="Back" className="!pr-2 !inline" />
                  <p className="!m-0">Home</p>
                </div>
                <div>
                  <p className="m-0 font-medium sm:!text-xs !px-3 text-left">
                  Edit Your Card
                  </p>
                </div>
                <div>
                  <p className="!bg-white !text-[#FF733A] !rounded-lg !px-3 !m-0">
                  4 / 8
                  </p>
                </div>
              </div>
            </Row>
                <div className="flex items-center">
                  <img src={icon14} alt=".." />
                  <p className="!font-bold !text-[#000] !m-0 !px-2">
                    Choose a sport & position
                  </p>
                </div>
                <div className="!px-4 !py-2 !bg-[#FFF1EB] !rounded-[20px]">
                  <Tab.Container
                    id="list-group-tabs-example"
                    activeKey={`#${firstSport}`}
                  >
                    <Row>
                      <Row sm={4}>
                        <ListGroup horizontal>
                          {sportS.map((sport, index) => (
                            <ListGroup.Item
                              key={index}
                              action
                              eventKey={`#${sport.sport_name}`}
                              className="bg-transparent !text-[#000] !font-semibold !border-x-0 !border-t-0 !rounded-none !pl-0"
                              onClick={() => {
                                setSport(sport.id);
                                setFirstSport(sport.sport_name);
                              }}
                            >
                              {sport.sport_name}
                            </ListGroup.Item>
                          ))}
                          <ListGroup.Item
                            action
                            eventKey="#custom"
                            className="bg-transparent !text-[#000] !font-semibold !border-x-0 !border-t-0 !rounded-none"
                            onClick={() => {
                              setSport(0);
                              setFirstSport("custom");
                            }}
                          >
                            Custom
                          </ListGroup.Item>
                        </ListGroup>
                      </Row>
                      <Col className="!pl-0">
                        <Tab.Content className="!py-2 !flex !flex-wrap">
                          {sportS.map((sport, index) => (
                            <Tab.Pane
                              eventKey={`#${sport.sport_name}`}
                              key={index}
                            >
                              <div>
                                {sport.positions.map((position, index) => (
                                  <div className="!inline-block" key={index}>
                                    <label
                                      className="cursor-pointer"
                                      key={index}
                                    >
                                      <Form.Check
                                        inline
                                        className="!bg-white !pr-5 !pl-10 !py-1 !rounded-[10px] !border-[black] !border-[0.5px]"
                                        label={position.position}
                                        type="radio"
                                        id={position.position}
                                        name="rdbs"
                                        value={position.position}
                                        onClick={(event) =>
                                          positionChangeHandler(
                                            event,
                                            position.category_id
                                          )
                                        }
                                      />
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </Tab.Pane>
                          ))}
                          <Tab.Pane eventKey="#custom">
                            <Row>
                              <InputGroup
                                as={Col}
                                controlid="formGridEmail"
                                size="sm"
                              >
                                <FloatingLabel
                                  controlid="floatingInput"
                                  label="Enter Custom Position"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Custom Position"
                                    onKeyUp={(event) =>
                                      positionChangeHandler(
                                        event,
                                        sportS[0].positions[0].category_id
                                      )
                                    }
                                    className="!rounded-[15px] !border-[#FF733A]"
                                  />
                                </FloatingLabel>
                              </InputGroup>
                            </Row>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
              </Row>
              <Row
                className={
                  activeRow === "row4"
                    ? "col1Show !z-50 !mt-2 w-[100%]"
                    : "col1Hidden"
                }
              >
                <Row>
              <div className="!fixed !top-0 !left-0 flex items-center justify-between py-3 text-[#fff] !bg-[#FF733A] !z-50">
                <div className="!flex !items-center" onClick={backHomeHandler}>
                  <img src={backArrow} alt="Back" className="!pr-2 !inline" />
                  <p className="!m-0">Home</p>
                </div>
                <div>
                  <p className="m-0 font-medium sm:!text-xs !px-3 text-left">
                  Choose Club and Country
                  </p>
                </div>
                <div>
                  <p className="!bg-white !text-[#FF733A] !rounded-lg !px-3 !m-0">
                  5 / 8
                  </p>
                </div>
              </div>
            </Row>
                <Modal
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  animation={true}
                  show={showCustomFlagCountryModal}
                  onHide={() => {
                    setShowCustomFlagCountryModal(false);
                  }}
                  scrollable={true}
                >
                  <Modal.Header closeButton className="!border-b-0">
                    <Modal.Title
                      id="contained-modal-title-vcenter"
                      className="!text-[#000] !flex !flex-row !items-center"
                    >
                      <img src={icon14} alt=".." />
                      <p className="!text-[#000] !m-0 !px-2">
                        Upload custom flag
                      </p>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="show-grid">
                    <Form>
                      <Row>
                        <Form.Group
                          className="!border-dashed !border-[#FF733A] !border-2 !rounded-[15px] !py-4 !px-3"
                          onClick={customFlagInputhandler}
                        >
                          <Form.Label className="!m-0 !flex !flex-col !items-center !justify-center !cursor-pointer !text-[#000] !font-bold">
                            <img src={icon44} alt="..." />
                            Upload custom flag
                          </Form.Label>
                          <Form.Control
                            type="file"
                            size="lg"
                            className="!hidden"
                            ref={flagInputRef}
                            onChange={customFlagHandler}
                          />
                        </Form.Group>
                      </Row>
                    </Form>
                    <Row className="!my-5">
                      <div className="!flex !items-center ">
                        <img src={icon54} alt="..." />
                        <p className="!text-[#000] !font-medium !m-0 !px-2">
                          Uploaded flag will be fully edited by our design team.
                        </p>
                      </div>
                    </Row>
                  </Modal.Body>
                </Modal>
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <img src={icon13} alt=".." />
                    <p className="!font-bold !text-[#000] !m-0 !pl-1">
                      Country flag
                    </p>
                  </div>
                  <div onClick={customFlagModal}>
                    <Lottie options={defaultOptions} height={50} width={50} />
                  </div>
                </div>
                <Row className="!mx-0">
                  {!countryUploaderContent && (
                    <Button
                      className={
                        "!flex !items-center !justify-center !bg-transparent !border-slate-400 !text-slate-500 !py-4"
                      }
                      disabled={countryUploaderContent}
                      size="lg"
                      onClick={() => setShowCountries(true)}
                    >
                      <div className={"!flex !items-center !justify-center"}>
                        Click here to find country
                      </div>
                    </Button>
                  )}
                  {countryUploaderContent && (
                    <Button
                      className={
                        "!flex !items-center !justify-center !bg-transparent !border-slate-400 !text-slate-500 !py-4"
                      }
                      size="lg"
                      onClick={clearCountryImage}
                    >
                      <div
                        className={
                          "!flex !items-center !justify-center !z-[1000] !h-20 !w-20 !max-h-20 !max-w-20"
                        }
                      >
                        <img
                          src={country}
                          alt="Uploaded Country"
                          className="!w-[40px] !h-[40px] !object-contain"
                        />
                        <h5 className=" !text-slate-800 !mt-[0.5rem] !mx-2">
                          Change country
                        </h5>
                      </div>
                    </Button>
                  )}
                </Row>
                <div>
                  <Offcanvas
                    show={showCountries}
                    onHide={handleCloseCountries}
                    placement="bottom"
                    className="!h-[80vh] !rounded-t-[20px]"
                  >
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title className="!flex !flex-col">
                        Choose Country
                      </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <InputGroup as={Col} controlid="formGridEmail" size="sm">
                        <FloatingLabel
                          controlid={placeholder}
                          label={placeholder}
                          className="mb-3"
                        >
                          <Form.Control
                            type="search"
                            onChange={handleChangeCountry}
                            onKeyUp={handleKeyUpCountry}
                            placeholder={placeholder}
                            className="!rounded-[15px] !border-[#FF733A]"
                          />
                        </FloatingLabel>
                      </InputGroup>
                      <Col>
                        <Row className="!m-auto !w-[90%] !overflow-x-scroll">
                          <Swiper
                            modules={[Scrollbar]}
                            scrollbar={{
                              hide: false,
                              draggable: true,
                              dragSize: 100,
                            }}
                            slidesPerGroupSkip={1}
                            slidesPerView={"auto"}
                            slideToClickedSlide={false}
                            spaceBetween={12}
                          >
                            {continents.map((continent) => (
                              <SwiperSlide
                                className="swiperSlide"
                                key={continent.id}
                                value={continent.id}
                                onClick={() => {
                                  setContinentSelected(continent.id);
                                }}
                              >
                                <p
                                  className={
                                    continentSelected === continent.id
                                      ? "!text-[#FF733A] !font-semibold !cursor-pointer"
                                      : "!text-[#A04723] !font-semibold !cursor-pointer"
                                  }
                                >
                                  {continent.name}
                                </p>
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        </Row>
                        <Row>
                          <div className="!flex !flex-wrap !items-center">
                            {countries?.map((country, index) => (
                              <Button
                                key={index}
                                className={
                                  countryClicked === country.id
                                    ? "!m-1 !text-[#fff] !font-semibold !bg-[#FF733A] !border-2 !border-[#fff] !rounded-[20px] !flex !items-center"
                                    : "!m-1 !text-[#000] !font-semibold !bg-transparent !border-2 !border-[#999] !rounded-[20px] !flex !items-center"
                                }
                                onClick={() => {
                                  countryChangeHandler(
                                    country.id,
                                    country.main_image
                                  );
                                }}
                              >
                                <LazyLoad offset={300}>
                                  <CustomProgressiveImage
                                    src={`${country.path}${country.file_name}`}
                                    thumbnail={`${country.path}thumbnails/${country.file_name}`}
                                    blur={`${country.path}blures/${country.file_name}`}
                                    className="!pr-1 !h-6"
                                  />
                                </LazyLoad>
                                {country.name}
                                <img
                                  src={icon64}
                                  alt="..."
                                  className={
                                    countryClicked === country.id
                                      ? "!pt-1 !px-1"
                                      : "!hidden"
                                  }
                                />
                              </Button>
                            ))}
                          </div>
                        </Row>
                      </Col>
                    </Offcanvas.Body>
                  </Offcanvas>
                </div>
                <Modal
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  animation={true}
                  show={showCustomFlagClubModal}
                  onHide={() => {
                    setShowCustomFlagClubModal(false);
                  }}
                  scrollable={true}
                >
                  <Modal.Header closeButton className="!border-b-0">
                    <Modal.Title
                      id="contained-modal-title-vcenter"
                      className="!text-[#000] !flex !flex-row !items-center"
                    >
                      <img src={icon14} alt=".." />
                      <p className="!text-[#000] !m-0 !px-2">
                        Upload custom badge
                      </p>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="show-grid">
                    <Form>
                      <Row>
                        <Form.Group
                          className="!border-dashed !border-[#FF733A] !border-2 !rounded-[15px] !py-4 !px-3"
                          onClick={customClubInputhandler}
                        >
                          <Form.Label className="!m-0 !flex !flex-col !items-center !justify-center !cursor-pointer !text-[#000] !font-bold">
                            <img src={icon44} alt="..." />
                            Upload custom badge
                          </Form.Label>
                          <Form.Control
                            type="file"
                            size="lg"
                            className="!hidden"
                            accept="image/*"
                            ref={clubInputRef}
                            onChange={customClubHandler}
                          />
                        </Form.Group>
                      </Row>
                    </Form>
                    <Row className="!my-5">
                      <div className="!flex !items-center ">
                        <img src={icon54} alt="..." />
                        <p className="!text-[#000] !font-medium !m-0 !px-2">
                          Uploaded badge will be fully edited by our design
                          team.
                        </p>
                      </div>
                    </Row>
                    <Row className="!px-3">
                      <Form.Check
                        type="checkbox"
                        id={`badgeCheck`}
                        label="I understand that Propack has the right to modify/replace my club badge if it's a breach of copyright."
                      />
                    </Row>
                  </Modal.Body>
                </Modal>
                <div className="flex justify-between !mt-2">
                  <div className="flex items-center">
                    <img src={icon15} alt=".." />
                    <p className="!font-bold !text-[#000] !m-0 !pl-1">
                      Club flag
                    </p>
                  </div>
                  <div onClick={customClubModal}>
                    <Lottie options={defaultOptions} height={50} width={50} />
                  </div>
                </div>
                <Row className="!mx-0">
                  {!clubUploaderContent && (
                    <Button
                      className={
                        "!flex !items-center !justify-center !bg-transparent !border-slate-400 !text-slate-500 !py-4"
                      }
                      disabled={clubUploaderContent}
                      size="lg"
                      onClick={() => setShowClubs(true)}
                    >
                      Click here to find club
                    </Button>
                  )}
                  {clubUploaderContent && (
                    <Button
                      className={
                        "!flex !items-center !justify-center !bg-transparent !border-slate-400 !text-slate-500 !py-4"
                      }
                      size="lg"
                      onClick={clearClubImage}
                    >
                      <div
                        className={
                          "!flex !items-center !justify-center !z-[1000]"
                        }
                      >
                        <img
                          src={club}
                          alt="Uploaded Country"
                          id="uploadedClub"
                          className="!w-[40px] !h-[40px] !object-contain"
                        />
                        <h5 className=" !text-slate-800 !mt-[0.5rem] !mx-2">
                          Change club
                        </h5>
                      </div>
                    </Button>
                  )}
                </Row>
                <div>
                  <Offcanvas
                    show={showClubs}
                    onHide={handleCloseClubs}
                    placement="bottom"
                    className="!h-[80vh] !rounded-t-[20px]"
                  >
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title className="!flex !flex-col">
                        Choose Club
                      </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <InputGroup as={Col} controlid="formGridEmail" size="sm">
                        <FloatingLabel
                          controlid={placeholder2}
                          className="mb-3"
                        >
                          <Form.Control
                            type="search"
                            onChange={handleChangeClub}
                            onKeyUp={handleKeyUpClub}
                            placeholder={placeholder2}
                            className="!rounded-[15px] !border-[#FF733A]"
                          />
                        </FloatingLabel>
                      </InputGroup>
                      <Col>
                        <Row className="!m-auto !w-[90%] !overflow-x-scroll">
                          <Swiper
                            modules={[Scrollbar]}
                            scrollbar={{
                              hide: false,
                              draggable: true,
                              dragSize: 100,
                            }}
                            slidesPerGroupSkip={1}
                            slidesPerView={"auto"}
                            slideToClickedSlide={false}
                            spaceBetween={12}
                          >
                            {leagues.map((league) => (
                              <SwiperSlide
                                className="swiperSlide"
                                key={league.id}
                                value={league.id}
                                onClick={() => {
                                  setLeagueSelected(league.id);
                                }}
                              >
                                <p
                                  className={
                                    leagueSelected === league.id
                                      ? "!text-[#FF733A] !font-semibold !cursor-pointer"
                                      : "!text-[#A04723] !font-semibold !cursor-pointer"
                                  }
                                >
                                  {league.league_name}
                                </p>
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        </Row>
                        <Row>
                          <div className="!flex !flex-wrap !items-center">
                            {clubs?.map((club, index) => (
                              <Button
                                key={index}
                                className={
                                  clubClicked === club.id
                                    ? "!m-1 !text-[#fff] !font-semibold !bg-[#FF733A] !border-2 !border-[#fff] !rounded-[20px] !flex !items-center"
                                    : "!m-1 !text-[#000] !font-semibold !bg-transparent !border-2 !border-[#999] !rounded-[20px] !flex !items-center"
                                }
                                onClick={() => {
                                  clubChangeHandler(club.id, club.main_image);
                                }}
                              >
                                <LazyLoad offset={300}>
                                  <CustomProgressiveImage
                                    src={`${club.path}${club.file_name}`}
                                    thumbnail={`${club.path}thumbnails/${club.file_name}`}
                                    blur={`${club.path}blures/${club.file_name}`}
                                    className="!pr-1 !h-6"
                                  />
                                </LazyLoad>
                                {club.club_name}
                                <img
                                  src={icon64}
                                  alt="..."
                                  className={
                                    clubClicked === club.id
                                      ? "!pt-1 !px-1"
                                      : "!hidden"
                                  }
                                />
                              </Button>
                            ))}
                          </div>
                        </Row>
                      </Col>
                    </Offcanvas.Body>
                  </Offcanvas>
                </div>
              </Row>
              <Row
                className={
                  activeRow === "row6"
                    ? "col1Show !z-50 !mt-2 w-[100%]"
                    : "col1Hidden"
                }
              >
                <Row>
              <div className="!fixed !top-0 !left-0 flex items-center justify-between py-3 text-[#fff] !bg-[#FF733A] !z-50">
                <div className="!flex !items-center" onClick={backHomeHandler}>
                  <img src={backArrow} alt="Back" className="!pr-2 !inline" />
                  <p className="!m-0">Home</p>
                </div>
                <div>
                  <p className="m-0 font-medium sm:!text-xs !px-3 text-left">
                  Choose Attributes
                  </p>
                </div>
                <div>
                  <p className="!bg-white !text-[#FF733A] !rounded-lg !px-3 !m-0">
                  6 / 8
                  </p>
                </div>
              </div>
            </Row>
                <Row>
                  <div className="flex items-center">
                    <img src={icon25} alt=".." />
                    <p className="!font-bold !text-[#000] !m-0 !pl-1">
                      Choose Attributes
                    </p>
                  </div>
                  <Col>
                    <div className="my-2 !flex">
                      <Form.Control
                        min={0}
                        max={99}
                        size="lg"
                        type="number"
                        placeholder="99"
                        className="!w-[49%] !mr-1 !rounded-r-none !rounded-l-[14px] !border-[#FF733A] !text-center"
                        onChange={trait1VHandler}
                        value={trait1V}
                      />
                      <Form.Control
                        size="lg"
                        type="text"
                        className="!uppercase !w-[49%] !rounded-l-none !rounded-r-[14px] !border-[#FF733A] !text-center"
                        defaultValue={trait1}
                        onKeyUp={trait1THandler}
                      />
                    </div>
                    <div className="my-2 !flex">
                      <Form.Control
                        min={0}
                        max={99}
                        size="lg"
                        type="number"
                        placeholder="99"
                        className="!w-[49%] !mr-1 !rounded-r-none !rounded-l-[14px] !border-[#FF733A] !text-center"
                        onChange={trait2VHandler}
                        value={trait2V}
                      />
                      <Form.Control
                        size="lg"
                        type="text"
                        className="!uppercase !w-[49%] !rounded-l-none !rounded-r-[14px] !border-[#FF733A] !text-center"
                        defaultValue={trait2}
                        onKeyUp={trait2THandler}
                      />
                    </div>
                    <div className="my-2 !flex">
                      <Form.Control
                        min={0}
                        max={99}
                        size="lg"
                        type="number"
                        placeholder="99"
                        className="!w-[49%] !mr-1 !rounded-r-none !rounded-l-[14px] !border-[#FF733A] !text-center"
                        onChange={trait3VHandler}
                        value={trait3V}
                      />
                      <Form.Control
                        size="lg"
                        type="text"
                        className="!uppercase !w-[49%] !rounded-l-none !rounded-r-[14px] !border-[#FF733A] !text-center"
                        defaultValue={trait3}
                        onKeyUp={trait3THandler}
                      />
                    </div>
                  </Col>
                  {/* className="!flex !flex-col !justify-center !items-center" */}
                  <Col>
                    <div className="my-2 !flex">
                      <Form.Control
                        min={0}
                        max={99}
                        size="lg"
                        type="number"
                        placeholder="99"
                        className="!w-[49%] !mr-1 !rounded-r-none !rounded-l-[14px] !border-[#FF733A] !text-center"
                        onChange={trait4VHandler}
                        value={trait4V}
                      />
                      <Form.Control
                        size="lg"
                        type="text"
                        className="!uppercase !w-[49%] !rounded-l-none !rounded-r-[14px] !border-[#FF733A] !text-center"
                        defaultValue={trait4}
                        onKeyUp={trait4THandler}
                      />
                    </div>
                    <div className="my-2 !flex">
                      <Form.Control
                        min={0}
                        max={99}
                        size="lg"
                        type="number"
                        placeholder="99"
                        className="!w-[49%] !mr-1 !rounded-r-none !rounded-l-[14px] !border-[#FF733A] !text-center"
                        onChange={trait5VHandler}
                        value={trait5V}
                      />
                      <Form.Control
                        size="lg"
                        type="text"
                        className="!uppercase !w-[49%] !rounded-l-none !rounded-r-[14px] !border-[#FF733A] !text-center"
                        defaultValue={trait5}
                        onKeyUp={trait5THandler}
                      />
                    </div>
                    <div className="my-2 !flex">
                      <Form.Control
                        min={0}
                        max={99}
                        size="lg"
                        type="number"
                        placeholder="99"
                        className="!w-[49%] !mr-1 !rounded-r-none !rounded-l-[14px] !border-[#FF733A] !text-center"
                        onChange={trait6VHandler}
                        value={trait6V}
                      />
                      <Form.Control
                        size="lg"
                        type="text"
                        className="!uppercase !w-[49%] !rounded-l-none !rounded-r-[14px] !border-[#FF733A] !text-center"
                        defaultValue={trait6}
                        onKeyUp={trait6THandler}
                      />
                    </div>
                  </Col>
                  <div>
                    <div className="flex items-center">
                      <img src={icon16} alt=".." />
                      <p className="!font-bold !text-[#000] !m-0 !pl-1">
                        Overall rating
                      </p>
                    </div>
                    <Col className="pt-3">
                      <Row>
                        <Form.Control
                          size="lg"
                          min={0}
                          max={99}
                          type="number"
                          placeholder="99"
                          className="!text-center !rounded-[60px]"
                          defaultValue={overAllRating}
                          onKeyUp={overAllRatingHandler}
                        />
                      </Row>
                      <Row className="!pt-1 !flex !justify-between">
                        <p>Suggested :</p>
                        <div className="!flex !flex-row !justify-between">
                          <Button
                            value="99"
                            onClick={sugg1}
                            size="sm"
                            className={
                              overAllRating === "99"
                                ? "!bg-white !border-[#A04723] !border-2 !w-[30%] !rounded-[14px] !p-2 !text-center !cursor-pointer !text-[#000] !font-medium"
                                : "!bg-white !border-[#FF733A] !w-[30%] !rounded-[14px] !p-2 !text-center !cursor-pointer !text-[#000] !font-medium"
                            }
                          >
                            99
                          </Button>
                          <Button
                            value="93"
                            onClick={sugg2}
                            size="sm"
                            className={
                              overAllRating === "93"
                                ? "!bg-white !border-[#A04723] !border-2 !w-[30%] !rounded-[14px] !p-2 !text-center !cursor-pointer !text-[#000] !font-medium"
                                : "!bg-white !border-[#FF733A] !w-[30%] !rounded-[14px] !p-2 !text-center !cursor-pointer !text-[#000] !font-medium"
                            }
                          >
                            93
                          </Button>
                          <Button
                            value="87"
                            onClick={sugg3}
                            size="sm"
                            className={
                              overAllRating === "87"
                                ? "!bg-white !border-[#A04723] !border-2 !w-[30%] !rounded-[14px] !p-2 !text-center !cursor-pointer !text-[#000] !font-medium"
                                : "!bg-white !border-[#FF733A] !w-[30%] !rounded-[14px] !p-2 !text-center !cursor-pointer !text-[#000] !font-medium"
                            }
                          >
                            87
                          </Button>
                        </div>
                      </Row>
                    </Col>
                  </div>
                </Row>
              </Row>
              <Row
                className={
                  activeRow === "row9"
                    ? "col1Show !z-50 !mt-3 w-[100%]"
                    : "col1Hidden"
                }
              >
                <Row>
              <div className="!fixed !top-0 !left-0 flex items-center justify-between py-3 text-[#fff] !bg-[#FF733A] !z-50">
                <div className="!flex !items-center" onClick={backHomeHandler}>
                  <img src={backArrow} alt="Back" className="!pr-2 !inline" />
                  <p className="!m-0">Home</p>
                </div>
                <div>
                  <p className="m-0 font-medium sm:!text-xs !px-3 text-left">
                  Choose Chemistry Style
                  </p>
                </div>
                <div>
                  <p className="!bg-white !text-[#FF733A] !rounded-lg !px-3 !m-0">
                  7 / 8
                  </p>
                </div>
              </div>
            </Row>
                <Row className="!mt-3">
                  <div className="flex items-center">
                    <img src={nb8} alt=".." />
                    <p className="!font-bold !text-[#000] !m-0 !pl-1">
                      Choose Chemistry Style
                    </p>
                  </div>
                  <Col className="!mt-5">
                    <div className="!h-[20vh] !overflow-y-scroll !flex !flex-wrap !items-center !bg-slate-100 !rounded-[20px]">
                      {chemistryStyle?.map((chemistry) => (
                        <div key={chemistry.id}>
                          <Button
                            className={
                              chemistryClicked === chemistry.id
                                ? `${chemistryName.toLowerCase() === "propack" ? "!bg-[#ece8e8]" : "!bg-[#FF733A]"} !m-1 !text-[#fff] !font-semibold !border-2 !border-[#fff] !rounded-[20px] !flex !items-center`
                                : "!m-1 !text-[#000] !font-semibold !bg-transparent !border-2 !border-[#999] !rounded-[20px] !flex !items-center"
                            }
                            onClick={() =>
                              chemistryStyleHandler(
                                chemistry.id,
                                chemistry.main_image,
                                chemistry.name
                              )
                            }
                          >
                            <LazyLoad offset={300}>
                              <CustomProgressiveImage
                                src={`${chemistry.main_image}`}
                                thumbnail={`${chemistry.path}thumbnails${chemistry.file_name}`}
                                blur={`${chemistry.path}blures/${chemistry.file_name}`}
                                className="!pr-1 !w-10 !h-8 !object-contain"
                              />
                            </LazyLoad>
                            {chemistry.name.toLowerCase() === "propack" ? "" : chemistry.name}
                            <img
                              src={icon64}
                              alt="..."
                              className={
                                chemistryClicked === chemistry.id
                                  ? "!pt-1 !px-1"
                                  : "!hidden"
                              }
                            />
                          </Button>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
              </Row>
            </div>
          </Col>
        }
        {
          <Col
            className={activeCol === "col3" ? "col1Show pt-9" : "col1Hidden"}
          >
            <Row>
              <div className="!fixed !top-0 !left-0 flex items-center justify-between py-3 text-[#fff] !bg-[#FF733A] !z-50">
                <div className="!flex !items-center" onClick={backHomeHandler}>
                  <img src={backArrow} alt="Back" className="!pr-2 !inline" />
                  <p className="!m-0">Home</p>
                </div>
                <div>
                  <p className="m-0 font-medium sm:!text-xs !px-3 text-left">
                  Choose Extras
                  </p>
                </div>
                <div>
                  <p className="!bg-white !text-[#FF733A] !rounded-lg !px-3 !m-0">
                  8 / 8
                  </p>
                </div>
              </div>
            </Row>
            <Row>
              <div className="flex items-center !mt-10">
                <img src={nb9} alt=".." />
                <p className="!font-bold !text-[#000] !m-0 !px-2">
                  Choose extras for your card
                </p>
                {/* <img
                  src={icon2}
                  alt="extras guide"
                  className="!cursor-pointer"
                  onClick={() => {
                    setExtrasGuideModal(true);
                  }}
                /> */}
              </div>
            </Row>
            <Row className="!mt-4">
              <Col>
                <Row>
                  {extras.map((extra, index) => (
                    <div key={index}>
                      <div className="bg-white mb-3 px-3 !border-2">
                      <label htmlFor={`${index}_${extra.id}`} className="!w-full">
                        <Row>
                          <p className="!text-[#000] !text-[20px] !font-bold">
                            {extra.option_name}
                          </p>
                        </Row>
                        <Row>
                          <div className="!flex !items-center !justify-between">
                            <div>
                              <LazyLoad offset={300}>
                                <ProgressiveImage
                                  src={extra.file_path}
                                  placeholder={`${extra.path}thumbnails/${extra.file_name}`}
                                >
                                  {(src) => (
                                    <img
                                      src={src}
                                      alt="..."
                                      className="!max-w-20 !w-20 !h-20 !rounded-md"
                                    />
                                  )}
                                </ProgressiveImage>
                              </LazyLoad>
                            </div>
                            <div className="!flex !flex-col !mt-auto !pl-3">
                              <p className="!text-[#757575] !pb-0 !text-xs !text-left">
                                {extra.short_description}
                              </p>
                              <h5
                                className={
                                  getExtrasSelected[index].quantity
                                    ? "!text-[#FF733A] !font-extrabold !text-2xl"
                                    : "!text-[#000] !font-extrabold !text-2xl"
                                }
                              >
                                ${extra.price}
                              </h5>
                            </div>
                            <div className="flex items-center justify-between">
                              <div>
                                <Form.Check
                                  onChange={(event) => {
                                    handleCheckExtra(
                                      event,
                                      getExtrasSelected[index].id
                                    );
                                  }}
                                  checked={
                                    getExtrasSelected[index].quantity > 0
                                  }
                                  data-note={extra.is_extra_note}
                                  data-bundle={extra.is_bundle}
                                  value={getExtrasSelected[index].id}
                                  id={`${index}_${extra.id}`}
                                  className="!ml-3 !text-[25px]"
                                  disabled={extrasToDisable.includes(
                                    getExtrasSelected[index].id
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </Row>
                        </label>
                      </div>
                      {extra.is_extra_note === "1" &&
                        getExtrasSelected[index].quantity > 0 && (
                          <>
                            <Row className="!grid !grid-cols-3">
                              {extra.extra_images?.map((img, index) => (
                                <LazyLoad offset={300} key={index}>
                                  <CustomProgressiveImage
                                    src={img.file_path}
                                    thumbnail={img.file_path}
                                    blur={img.file_path}
                                    className={
                                      noteImgSelected === img.id
                                        ? "!border-orange-600 !border-4 !rounded-[20px] !h-32 !w-40 !object-contain !cursor-pointer"
                                        : "!border-transparent !border-4 !rounded-[20px] !h-32 !w-40 !cursor-pointer !object-contain"
                                    }
                                    onClick={() => {
                                      handleGiftNoteImage(extra.id, img.id);
                                    }}
                                  />
                                </LazyLoad>
                              ))}
                            </Row>
                            <Row className="mt-3">
                              <FloatingLabel
                                controlId="floatingTextarea2"
                                label="Write your message here or leave this space blank if you want to write it by hand"
                                className={"!text-[#999] !pl-2 !relative !text-[8px] !font-semibold"}
                              >
                                <Form.Control
                                  as="textarea"
                                  style={{ height: "100px" }}
                                  onChange={handleGiftNoteText}
                                  className="!border-4 !border-orange-600"
                                  ref={textAreaRef}
                                  maxLength={120}
                                  disabled={noteImgSelected === null}
                                />
                                {textAreaChars.length > 0 && <div className="!absolute !w-14 !h-5 !bottom-1 !right-5 bg-gray-200 !rounded-md text-sm !select-none">{120 - textAreaChars.length} / 120</div>}
                              </FloatingLabel>
                            </Row>
                          </>
                        )}
                    </div>
                  ))}
                </Row>
              </Col>
            </Row>
            {/* {giftNoteChecked &&
              getExtrasSelected.map(
                (extra, index) =>
                  extra.is_extra_note && (
                    <Row
                      className="divTransition !grid !grid-cols-2 gap-1 !place-items-center"
                      key={index}
                    >
                      {extra.extra_images?.map((img, index) => (
                        <LazyLoad offset={300} key={index}>
                          <CustomProgressiveImage
                            src={img.file_path}
                            thumbnail={img.file_path}
                            blur={img.file_path}
                            className={
                              noteImgSelected === img.id
                                ? "!border-orange-600 !border-4 !rounded-[20px] !h-32 !w-40 !object-cover !cursor-pointer"
                                : "!border-transparent !border-4 !rounded-[20px] !h-32 !w-40 !cursor-pointer !object-cover"
                            }
                            onClick={() => {
                              handleGiftNoteImage(extra.id, img.id);
                            }}
                          />
                        </LazyLoad>
                      ))}
                    </Row>
                  )
              )}
            {giftNoteChecked && (
              <Row className="!mt-3">
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Write your gift note..."
                  className="!text-[#999]"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Write your gift note..."
                    style={{ height: "100px" }}
                    onKeyUp={handleGiftNoteText}
                  />
                </FloatingLabel>
              </Row>
            )} */}
          </Col>
        }
      </Container>
      <footer className="!fixed !bottom-0 !left-0 !right-0 !bg-[#fff] !flex !justify-between !py-2 !px-7 shadow-lg !z-50">
        {!backToStep1 && (
          <Button
            className="!rounded-[60px] !py-2 !px-8 !flex !items-center !justify-between !bg-[#DEDDEB] !border-transparent !text-[#000]"
            onClick={activeBackColHandler}
          >
            <img src={backArrow} alt="Back" className="!pr-2" />
            Back
          </Button>
        )}
        {backToStep1 && (
          <Link
            to="/shopcards"
            className="!rounded-[60px] !py-2 !px-8 !flex !items-center !justify-between !bg-[#DEDDEB] !border-transparent !text-[#000]"
          >
            <img src={backArrow} alt="Back" className="!pr-2" />
            Back
          </Link>
        )}
        {!showCheckOutModal && (
          <Button
            className={
              btnNextStatus
                ? "!rounded-[60px] !py-2 !px-8 !flex !items-center !justify-between !bg-slate-500 !border-transparent !text-[#fff]"
                : "!rounded-[60px] !py-2 !px-8 !flex !items-center !justify-between !bg-[#FF733A] !border-transparent !text-[#fff]"
            }
            onClick={activeNextColHandler}
          >
            Next
            <img src={nextArrow} alt="next" className="!pl-2" />
          </Button>
        )}
        {showCheckOutModal && (
          <Button
            className="!rounded-[60px] !py-2 !px-8 !flex !items-center !justify-between !bg-[#FF733A] !border-transparent !text-[#fff]"
            onClick={insertCard}
            disabled={isAddToCard}
          >
            {isAddToCard ? "Loading.." : "Add to cart"}
            <img src={nextArrow} alt="next" className="!pl-2" />
          </Button>
        )}
      </footer>
    </section>
  );
};

export default Step2;
