import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Dropdown from "react-bootstrap/Dropdown";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { useRef, useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import localforage from "localforage";
import { stylesActions } from "../../../store/Styles-slice";
import {
  getCountryCodes,
  getsignUp,
} from "../../../store/data/Secondary-slice";
import LazyLoad from "react-lazy-load";
import { CustomProgressiveImage } from "../progressiveImage/ProgressiveImage";
import Loader from "../Loader/Loader";
import { checkOutActions } from "../../../store/CheckOut-slice";
const SignUpForm = () => {
  const dispatch = useDispatch();
  const url = useSelector((state) => state.api.domain);
  const history = useHistory();
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [toastMsg, setToastMsg] = useState();
  const notify = (msg) => toast(msg);
  useEffect(() => {
    notify(toastMsg);
  }, [toastMsg]);
  const { countryCodes } = useSelector((state) => state.secondary.countryCode);
  const { isLoading, content } = useSelector((state) => state.secondary.signup);
  const [searchCc, setSerachCc] = useState("");
  const [countryCode, setCountryCode] = useState("country code");
  const filteredCountryCode = countryCodes?.filter((code) =>
    code.name?.toLowerCase().includes(searchCc.toLowerCase())
  );
  useEffect(() => {
    dispatch(getCountryCodes());
    dispatch(getsignUp());
  }, []);
  const userNameRef = useRef();
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const telephoneRef = useRef();
  const birthDateRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const cpasswordRef = useRef();
  const cityRef = useRef();
  const stateRef = useRef();
  const zipRef = useRef();
  const signUpHandler = (e) => {
    e.preventDefault();
    setIsRequestLoading(true);
    setToastMsg("Please wait");
    if (passwordRef.current.value !== cpasswordRef.current.value) {
      setToastMsg("Please confirm your password");
      setIsRequestLoading(false);
    } else if (countryCode === "country code") {
      setToastMsg("Enter your country code");
      setIsRequestLoading(false);
    } else if (countrySelected === "Country") {
      setToastMsg("Enter your country");
      setIsRequestLoading(false);
    } else {
      let formData = new FormData();
      formData.append("userName", userNameRef.current.value);
      formData.append("firstName", firstNameRef.current.value);
      formData.append("lastName", lastNameRef.current.value);
      formData.append(
        "telephone",
        countryCode + "-" + telephoneRef.current.value
      );
      formData.append("birthDate", birthDateRef.current.value);
      formData.append("email", emailRef.current.value);
      formData.append("password", passwordRef.current.value);
      formData.append("country", countrySelected);
      formData.append("city", cityRef.current.value);
      formData.append("state", stateRef.current.value);
      formData.append("zip", zipRef.current.value);
      const url1 = `${url}/wizard/set-client`;
      axios
        .post(url1, formData)
        .then((res) => {
          if (res.data.succeeded) {
            history.push("/shopcards");
            localforage.setItem("user", res.data.user);
            localStorage.setItem("token", res.data.user.token);
            dispatch(stylesActions.trueLoggedIn());
            dispatch(checkOutActions.setCardsCount({ type: "null" }));
          } else {
            setToastMsg(res.data.message);
          }
          setIsRequestLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };
  //country
  const [countrySelected, setCountrySelected] = useState("Country");
  const [countryFilter, setCountryFilter] = useState("");
  const filterCountryHandler = countryCodes?.filter((country) =>
    country.name?.toLowerCase().includes(countryFilter.toLowerCase())
  );
  return (
    <Container className="co1 !mt-20 2xl:!my-[8%]">
      <ToastContainer autoClose={3000} />
      <Col>
        <Row>
          <Col>
            <Form onSubmit={signUpHandler}>
              <Row className="mb-3">
                <Form.Label
                  htmlFor="signUpUn"
                  className="!text-[#000000] !font-bold !text-xl"
                >
                  Sign up
                </Form.Label>
              </Row>
              <Row>
                <InputGroup as={Col} controlid="signUpUn" size="sm">
                  <FloatingLabel
                    controlid="signUpUn"
                    label={
                      <Form.Label className="mb-0" required>
                        User name <span className="text-danger inline">*</span>
                      </Form.Label>
                    }
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="User name"
                      id="signUpUn"
                      className="!rounded-[15px]"
                      ref={userNameRef}
                      required
                    />
                  </FloatingLabel>
                </InputGroup>
              </Row>
              <Row>
                <InputGroup as={Col} controlid="signUpFn" size="sm">
                  <FloatingLabel
                    controlid="floatingInput"
                    label={
                      <Form.Label className="mb-0" required>
                        First name <span className="text-danger inline">*</span>
                      </Form.Label>
                    }
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="First Name"
                      id="signUpFn"
                      className="!rounded-[15px]"
                      ref={firstNameRef}
                      required
                    />
                  </FloatingLabel>
                </InputGroup>
                <InputGroup as={Col} controlid="signUpLn" size="sm">
                  <FloatingLabel
                    controlid="floatingInput"
                    label={
                      <Form.Label className="mb-0" required>
                        Last name <span className="text-danger inline">*</span>
                      </Form.Label>
                    }
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      id="signUpLn"
                      className="!rounded-[15px]"
                      ref={lastNameRef}
                      required
                    />
                  </FloatingLabel>
                </InputGroup>
              </Row>
              <Row className="mb-3">
                <InputGroup className="mb-3">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-custom-components" className="!flex">
                      {countryCode === "country code" && (
                        <div>
                          Country code{" "}
                          <span className="!inline text-red-500">*</span>
                        </div>
                      )}
                      {countryCode !== "country code" && countryCode}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="!h-[40vh] !overflow-y-scroll">
                      <InputGroup>
                        <Form.Control
                          autoFocus
                          className="mx-3 my-2 w-auto"
                          placeholder="Filter by country name"
                          onChange={(e) => setSerachCc(e.target.value)}
                          value={searchCc}
                        />
                      </InputGroup>
                      {filteredCountryCode &&
                        filteredCountryCode.map((code, index) => (
                          <Dropdown.Item
                            key={index}
                            eventKey={index}
                            onClick={() => {
                              setCountryCode(
                                code.phone_code.includes("+")
                                  ? code.phone_code
                                  : `+${code.phone_code}`
                              );
                            }}
                            value={code.phone_code}
                            className="!flex"
                          >
                            <LazyLoad offset={300}>
                              <CustomProgressiveImage
                                src={`${code.path}${code.file_name}`}
                                thumbnail={`${code.path}thumbnails/${code.file_name}`}
                                blur={`${code.path}blures/${code.file_name}`}
                                className="!pr-1 !w-7 !h-7 !object-contain"
                              />
                            </LazyLoad>
                            {code.phone_code.includes("+")
                              ? code.phone_code
                              : `+${code.phone_code}`}
                          </Dropdown.Item>
                        ))}
                      {!filteredCountryCode && (
                        <p className="!text-center !mt-[5%]">Loading...</p>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Form.Control
                    id="phoneContact"
                    placeholder="Phone number *"
                    ref={telephoneRef}
                    type="number"
                    required
                  />
                </InputGroup>
                <InputGroup as={Col} controlid="signUpDb" size="sm">
                  <FloatingLabel
                    controlid="floatingInput"
                    label={
                      <Form.Label className="mb-0" required>
                        Date of birth{" "}
                        <span className="text-danger inline">*</span>
                      </Form.Label>
                    }
                    className="mb-3"
                  >
                    <Form.Control
                      type="date"
                      placeholder="Date of birth"
                      id="signUpDb"
                      className="!rounded-[15px]"
                      ref={birthDateRef}
                      required
                    />
                  </FloatingLabel>
                </InputGroup>
              </Row>
              <Row className="mb-3">
                <InputGroup as={Col} controlid="signUpEmail" size="sm">
                  <FloatingLabel
                    controlid="floatingInput"
                    label={
                      <Form.Label className="mb-0" required>
                        Email <span className="text-danger inline">*</span>
                      </Form.Label>
                    }
                    className="mb-3"
                  >
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      id="signUpEmail"
                      className="!rounded-[15px]"
                      ref={emailRef}
                      required
                    />
                  </FloatingLabel>
                </InputGroup>
              </Row>
              <Row className="mb-3">
                <InputGroup as={Col} controlid="signUpPass" size="sm">
                  <FloatingLabel
                    controlid="floatingInput"
                    label={
                      <Form.Label className="mb-0" required>
                        Password <span className="text-danger inline">*</span>
                      </Form.Label>
                    }
                    className="mb-3"
                  >
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      id="signUpPass"
                      className="!rounded-[15px]"
                      ref={passwordRef}
                      required
                    />
                  </FloatingLabel>
                </InputGroup>
                <InputGroup as={Col} controlid="signUpRpass" size="sm">
                  <FloatingLabel
                    controlid="floatingInput"
                    label={
                      <Form.Label className="mb-0" required>
                        Repeat password{" "}
                        <span className="text-danger inline">*</span>
                      </Form.Label>
                    }
                    className="mb-3"
                  >
                    <Form.Control
                      type="password"
                      placeholder="Repeat Password"
                      id="signUpRpass"
                      className="!rounded-[15px]"
                      ref={cpasswordRef}
                      required
                    />
                  </FloatingLabel>
                </InputGroup>
              </Row>
              <Row className="mb-3">
                <Form.Label
                  htmlFor="formGridShippengAddress"
                  className="!text-[#000000] font-bold"
                >
                  Country <span className="inline text-red-500">*</span>
                </Form.Label>
                <InputGroup className="mb-3">
                  <Dropdown>
                    <Dropdown.Toggle
                      className="!w-full"
                      id="formGridShippengAddress"
                    >
                      {countrySelected}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="!h-[40vh] !w-full !overflow-y-scroll">
                      <InputGroup>
                        <Form.Control
                          autoFocus
                          className="mx-3 my-2 w-auto"
                          placeholder="Filter by country name"
                          onChange={(e) => setCountryFilter(e.target.value)}
                          value={countryFilter}
                        />
                      </InputGroup>
                      {filterCountryHandler &&
                        filterCountryHandler.map((code) => (
                          <Dropdown.Item
                            key={code.id}
                            eventKey={code.id}
                            onClick={() => {
                              setCountrySelected(code.name);
                            }}
                            value={code.name}
                            className="!flex"
                          >
                            <LazyLoad offset={300}>
                              <CustomProgressiveImage
                                src={`${code.path}${code.file_name}`}
                                thumbnail={`${code.path}thumbnails/${code.file_name}`}
                                blur={`${code.path}blures/${code.file_name}`}
                                className="!pr-1 !w-7 !h-7 !object-contain"
                              />
                            </LazyLoad>
                            {code.name}
                          </Dropdown.Item>
                        ))}
                      {!filterCountryHandler && (
                        <p className="!text-center !mt-[5%]">Loading...</p>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </InputGroup>
                <InputGroup as={Col} controlid="signUpCity" size="sm">
                  <FloatingLabel
                    controlid="floatingInput"
                    label={
                      <Form.Label className="mb-0" required>
                        City <span className="text-danger inline">*</span>
                      </Form.Label>
                    }
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="City"
                      id="signUpCity"
                      className="!rounded-[15px]"
                      ref={cityRef}
                      required
                    />
                  </FloatingLabel>
                </InputGroup>
                <InputGroup as={Col} controlid="signUpState" size="sm">
                  <FloatingLabel
                    controlid="floatingInput"
                    label={
                      <Form.Label className="mb-0" required>
                        State <span className="text-danger inline">*</span>
                      </Form.Label>
                    }
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="State"
                      id="signUpState"
                      className="!rounded-[15px]"
                      ref={stateRef}
                      required
                    />
                  </FloatingLabel>
                </InputGroup>
                <InputGroup as={Col} controlid="signUpZip" size="sm">
                  <FloatingLabel
                    controlid="floatingInput"
                    label={
                      <Form.Label className="mb-0" required>
                        Zip <span className="text-danger inline">*</span>
                      </Form.Label>
                    }
                    className="mb-3"
                  >
                    <Form.Control
                      type="number"
                      placeholder="Zip"
                      id="signUpZip"
                      className="!rounded-[15px]"
                      ref={zipRef}
                      required
                    />
                  </FloatingLabel>
                </InputGroup>
              </Row>
              <Row>
                <Button
                  className="!bg-[#FF733A] !border-[#FF733A] hover:!bg-[#A04723] hover:!border-[#A04723] !px-5 !rounded-[60px]"
                  type="submit"
                  disabled={isRequestLoading}
                >
                  {isRequestLoading ? "Loading..." : "Sign Up"}
                </Button>
              </Row>
            </Form>
          </Col>
          <Col className="md:!flex md:!justify-center !hidden">
            {!isLoading && content && (
              <LazyLoad offset={300}>
                <CustomProgressiveImage
                  src={content[0]?.main_image}
                  thumbnail={`${content[0]?.path}thumbnails/${content[0]?.file_name}`}
                  blur={`${content[0]?.path}blures/${content[0]?.file_name}`}
                  className="!mt-[25%] 2xl:!ml-20 !h-96 !object-contain"
                />
              </LazyLoad>
            )}
            {isLoading && <Loader />}
          </Col>
        </Row>
      </Col>
    </Container>
  );
};

export default SignUpForm;
