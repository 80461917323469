import "./ContactUsForm.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import InputGroup from "react-bootstrap/InputGroup";
import cube1 from "../../../images/home/BestSelling/cube1.png";
import cube2 from "../../../images/home/BestSelling/cube2.png";
import mailIcon from "../../../images/contactus/mailIcon.svg";
import sendIcon from "../../../images/contactus/sendIcon.svg";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useState, useEffect } from "react";
import icon3 from "../../../images/shopCards/Step3/galleryIcon.svg";
import icon9 from "../../../images/shopCards/Step3/deleteIcon.svg";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { getCountryCodes } from "../../../store/data/Secondary-slice";
import LazyLoad from "react-lazy-load";
import { CustomProgressiveImage } from "../progressiveImage/ProgressiveImage";
import { getContactUs } from "../../../store/data/Secondary-slice";
const ContactUsForm = () => {
  const url = useSelector((state) => state.api.domain);
  const dispatch = useDispatch();
  const history = useHistory();
  const [toastMsg, setToastMsg] = useState();
  const notify = (msg) =>
    toast(msg, {
      onClose: () => {
        if (isRedirect) {
          history.push("/home");
          setIsRequestLoading(false);
        } else {
          return;
        }
      },
    });
  useEffect(() => {
    notify(toastMsg);
  }, [toastMsg]);
  const { isLoading, content } = useSelector(
    (state) => state.secondary.contactUs
  );
  useEffect(() => {
    dispatch(getCountryCodes());
    if (content === null) {
      dispatch(getContactUs());
    } else {
      return;
    }
  }, []);
  //phone code
  const { countryCodes } = useSelector((state) => state.secondary.countryCode);
  const [searchCc, setSerachCc] = useState("");
  const [countryCode, setCountryCode] = useState("country code");
  const filteredCountryCode = countryCodes?.filter((code) =>
    code.name?.toLowerCase().includes(searchCc.toLowerCase())
  );
  //country
  const [countrySelected, setCountrySelected] = useState("Country");
  const [countryFilter, setCountryFilter] = useState("");
  const filterCountryHandler = countryCodes?.filter((country) =>
    country.name?.toLowerCase().includes(countryFilter.toLowerCase())
  );
  const imageInputRef = useRef();
  const fnRef = useRef();
  const lnRef = useRef();
  const telephoneRef = useRef();
  const emailRef = useRef();
  const subjectRef = useRef();
  const messageRef = useRef();
  const [hasImage, setHasImage] = useState(false);
  const [image, setImage] = useState(null);
  const [isRedirect, setIsRedirect] = useState(false);
  const attachmentChangeHandler = (event) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      var image = new Image();
      image.src = e.target.result;
      image.onload = function () {
        setImage(image.src);
      };
    };
    setHasImage(true);
    reader.readAsDataURL(event.target.files[0]);
  };
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const contactUsFormSubmit = (event) => {
    event.preventDefault();
    setIsRequestLoading(true);
    if (countryCode === "country code") {
      setToastMsg("Enter your country code.");
      setIsRedirect(false);
      setIsRequestLoading(false);
      return;
    } else if (countrySelected === "Country") {
      setToastMsg("Enter your country");
      setIsRedirect(false);
      setIsRequestLoading(false);
      return;
    }
    var formData = new FormData();
    formData.append("first_name", fnRef.current.value);
    formData.append("last_name", lnRef.current.value);
    formData.append(
      "telephone",
      countryCode + "/" + telephoneRef.current.value
    );
    formData.append("email", emailRef.current.value);
    formData.append("country", countrySelected);
    formData.append("subject", subjectRef.current.value);
    formData.append("hasImage", hasImage);
    formData.append("attachment", image);
    formData.append("message", messageRef.current.value);
    const url1 = `${url}/wizard/contact-us`;
    axios
      .post(url1, formData)
      .then((res) => {
        if (res.data.succeeded) {
          setToastMsg(res.data.message);
          setIsRedirect(true);
        } else {
          setToastMsg(res.data.message);
          setIsRedirect(false);
        }
        setHasImage(false);
        setImage(null);
      })
      .catch((err) => console.log(err));
  };
  const deleteImageHandler = () => {
    setHasImage(false);
    setImage(null);
  };
  return (
    <section className="co1 contAll">
      <ToastContainer autoClose={5000} />
      <Container>
        <Row className="md:!pt-[140px] !pt-[90px]">
          <header className="flex flex-row items-center justify-center">
            <img src={cube1} alt="Cube 1" className="p-2 xsm:h-8" />
            <h1 className="text-[#000000] font-extrabold xsm:text-[20px]">
              Contact Us
            </h1>
            <img src={cube2} alt="Cube 2" className="p-2 xsm:h-8" />
          </header>
        </Row>
        <Row className="rowMain !px-5 !py-7 !flex !flex-col md:!flex-row">
          <Col
            md={5}
            lg={4}
            xl={4}
            xxl={4}
            className="col1Contact !rounded-[20px] text-left flex flex-col !items-start"
          >
            <div className="!pl-3">
              <Row className="pt-10">
                {!isLoading && content && (
                  <h1 className="!box-decoration-slice text-[#fff] font-extrabold">
                    {content[0]?.title ? content[0]?.title : "Loading..."}
                  </h1>
                )}
                {isLoading && !content && <h1>Loading...</h1>}
              </Row>
              {!isLoading && content && (
                <Row className="text-[#fff] flex flex-col md:pt-5">
                  {content[0]?.gmail && (
                    <span className="!flex pt-3">
                      <img src={mailIcon} alt="mail" />
                      <a
                        href={`mailto:${content[0]?.gmail}`}
                        className="!px-5 !text-white hover:!text-[#A04723]"
                      >
                        {content[0]?.gmail}
                      </a>
                    </span>
                  )}
                </Row>
              )}
              {!isLoading && content && (
                <Row className="lg:!mt-[100%] pt-4">
                  {content[0]?.bussines_email && (
                    <span className="!flex pt-3">
                      {content[0].business_statement && (
                        <p
                          className="!text-slate-200 !text-sm sm:!text-lg !flex"
                          dangerouslySetInnerHTML={{
                            __html: content[0].business_statement + ":",
                          }}
                        ></p>
                      )}
                      <a
                        href={`mailto:${content[0]?.bussines_email}`}
                        className="!pl-1 sm:!pl-2 !text-white hover:!text-[#A04723] sm:!pt-1 xsm:!mt-[-2.4px]"
                      >
                        {content[0]?.bussines_email}
                      </a>
                    </span>
                  )}
                  <div className="flex !justify-center">
                    <a
                      href="https://facebook.com/propack.store"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="bg-contain bg-no-repeat icon iconC1 xsm:!h-6 xsm:!w-6"></div>
                    </a>
                    <a
                      href="https://instagram.com/propack.store"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="bg-contain bg-no-repeat icon iconC2 xsm:!h-6 xsm:!w-6 !ml-4"></div>
                    </a>
                  </div>
                </Row>
              )}
              {isLoading && <h1>Loading...</h1>}
            </div>
          </Col>
          <Col>
            <Form onSubmit={contactUsFormSubmit}>
              <Row className="mt-3">
                <Col>
                  <Form.Label
                    htmlFor="fNameContact"
                    className="!text-[#000] !font-semibold"
                  >
                    First Name
                  </Form.Label>
                  <InputGroup controlid="formGridEmail" size="sm">
                    <FloatingLabel
                      controlid="floatingInput"
                      label="First Name"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        id="fNameContact"
                        className="!rounded-[15px] !border-[#ff733a]"
                        ref={fnRef}
                        required
                      />
                    </FloatingLabel>
                  </InputGroup>
                </Col>
                <Col>
                  <Form.Label
                    htmlFor="lNameContact"
                    className="!text-[#000] !font-semibold"
                  >
                    Last Name
                  </Form.Label>
                  <InputGroup controlid="formGridEmail" size="sm">
                    <FloatingLabel
                      controlid="floatingInput"
                      label="Last Name"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        id="lNameContact"
                        className="!rounded-[15px] !border-[#ff733a]"
                        ref={lnRef}
                        required
                      />
                    </FloatingLabel>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} xxs={12} md={4} lg={12} xl={12} xxl={12}>
                  <Form.Label
                    htmlFor="emailContact"
                    className="!text-[#000] !font-semibold"
                  >
                    Email
                  </Form.Label>
                  <InputGroup controlid="formGridEmail" size="sm">
                    <FloatingLabel
                      controlid="floatingInput"
                      label="Enter your email"
                      className="mb-3"
                    >
                      <Form.Control
                        type="email"
                        placeholder="Enter your email"
                        id="emailContact"
                        className="!rounded-[15px] !border-[#ff733a]"
                        ref={emailRef}
                        required
                      />
                    </FloatingLabel>
                  </InputGroup>
                </Col>
                <Col>
                  <Form.Label
                    htmlFor="formGridShippengAddress"
                    className="!text-[#000] !font-semibold"
                  >
                    Country
                  </Form.Label>
                  <Dropdown>
                    <Dropdown.Toggle
                      className="!w-full"
                      id="formGridShippengAddress"
                    >
                      {countrySelected}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="!h-[40vh] !w-full !overflow-y-scroll">
                      <InputGroup>
                        <Form.Control
                          autoFocus
                          className="w-auto"
                          placeholder="Filter by country name"
                          onChange={(e) => setCountryFilter(e.target.value)}
                          value={countryFilter}
                        />
                      </InputGroup>
                      {filterCountryHandler &&
                        filterCountryHandler.map((code) => (
                          <Dropdown.Item
                            key={code.id}
                            eventKey={code.id}
                            onClick={() => {
                              setCountrySelected(code.name);
                            }}
                            value={code.name}
                            className="!flex"
                          >
                            <LazyLoad offset={300}>
                              <CustomProgressiveImage
                                src={`${code.path}${code.file_name}`}
                                thumbnail={`${code.path}thumbnails/${code.file_name}`}
                                blur={`${code.path}blures/${code.file_name}`}
                                className="!pr-1 !w-7 !h-7 !object-contain"
                              />
                            </LazyLoad>
                            {code.name}
                          </Dropdown.Item>
                        ))}
                      {!filterCountryHandler && (
                        <p className="!text-center !mt-[5%]">Loading...</p>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col sm={12} md={5}>
                  <Form.Label
                    htmlFor="phoneContact"
                    className="!text-[#000] !font-semibold"
                  >
                    Phone
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-custom-components">
                        {countryCode}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="!h-[40vh] !overflow-y-scroll">
                        <InputGroup>
                          <Form.Control
                            autoFocus
                            className="mx-3 my-2 w-auto"
                            placeholder="Filter by country name"
                            onChange={(e) => setSerachCc(e.target.value)}
                            value={searchCc}
                          />
                        </InputGroup>
                        {filteredCountryCode &&
                          filteredCountryCode.map((code, index) => (
                            <Dropdown.Item
                              key={index}
                              eventKey={index}
                              onClick={() => {
                                setCountryCode(
                                  code.phone_code.includes("+")
                                    ? code.phone_code
                                    : `+${code.phone_code}`
                                );
                              }}
                              value={code.phone_code}
                              className="!flex"
                            >
                              <LazyLoad offset={300}>
                                <CustomProgressiveImage
                                  src={`${code.path}${code.file_name}`}
                                  thumbnail={`${code.path}thumbnails/${code.file_name}`}
                                  blur={`${code.path}blures/${code.file_name}`}
                                  className="!pr-1 !w-7 !h-7 !object-contain"
                                />
                              </LazyLoad>
                              {code.phone_code.includes("+")
                                ? code.phone_code
                                : `+${code.phone_code}`}
                            </Dropdown.Item>
                          ))}
                        {!filteredCountryCode && (
                          <p className="!text-center !mt-[5%]">Loading...</p>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                    <Form.Control
                      id="phoneContact"
                      placeholder="Phone number"
                      ref={telephoneRef}
                      required
                    />
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={7} md={7} lg={7} xl={7} xxl={7}>
                  <Form.Label
                    htmlFor="txtSubjectContact"
                    className="!text-[#000] !font-semibold"
                  >
                    Subject
                  </Form.Label>
                  <FloatingLabel label="Subject">
                    <Form.Control
                      placeholder="Subject"
                      style={{ height: "70px" }}
                      id="txtSubjectContact"
                      className="!border-[#ff733a]"
                      ref={subjectRef}
                      required
                    />
                  </FloatingLabel>
                </Col>
                <Col className="">
                  <Form.Label
                    htmlFor="imgAttachmentContact"
                    className="!text-[#000] !font-semibold"
                  >
                    Attachment
                  </Form.Label>
                  <Form.Group className="!border-dashed !border-[#FF733A] !border-2 !rounded-[15px]">
                    {!hasImage && (
                      <>
                        <Form.Label
                          className="!flex !flex-col !items-center !justify-center !cursor-pointer !text-[#000] !font-bold"
                          onClick={() => {
                            imageInputRef.current.click();
                          }}
                        >
                          <div
                            className={
                              "flex flex-col items-center !justify-center !pt-2"
                            }
                          >
                            <img src={icon3} alt="..." />
                            Upload image
                          </div>
                        </Form.Label>
                        <Form.Control
                          type="file"
                          size="lg"
                          className="!hidden"
                          id="imgAttachmentContact"
                          onChange={attachmentChangeHandler}
                          ref={imageInputRef}
                        />
                      </>
                    )}
                    {hasImage && (
                      <div className="!flex !flex-row !items-center !justify-center !py-2">
                        <img
                          src={image}
                          alt="..."
                          className="!h-[60px] !w-[79px] !rounded-[20px] !object-cover"
                        />
                        <img
                          src={icon9}
                          alt="..."
                          className="!h-[40px] !w-[50px] !rounded-[20px] !cursor-pointer"
                          onClick={deleteImageHandler}
                        />
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="!mt-5">
                <Col>
                  <Form.Label
                    htmlFor="txtAreaContact"
                    className="!text-[#000] !font-semibold"
                  >
                    Message
                  </Form.Label>
                  <FloatingLabel label="Write your message...">
                    <Form.Control
                      as="textarea"
                      placeholder="Write your message..."
                      style={{ height: "200px" }}
                      id="txtAreaContact"
                      className="!border-[#ff733a]"
                      ref={messageRef}
                      required
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Button
                type="submit"
                className="!mt-4 !float-right !no-underline !rounded-full !bg-[#FF733A] !text-[#FFFFFF] !px-7 !py-0 hover:!bg-[#A04723] !border-0"
                disabled={isRequestLoading}
              >
                <span className="!flex !flex-row !items-center">
                  <>
                    <img src={sendIcon} alt="back" className="" />
                    <p className="pt-3 pl-2">Send Message</p>
                  </>
                </span>
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactUsForm;
