import "./NavBar.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Badge from "react-bootstrap/Badge";
import Offcanvas from "react-bootstrap/Offcanvas";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import logoLight from "../../../images/home/navLogoLight.svg";
import logoDark from "../../../images/home/navLogo.svg";
import { stylesActions } from "../../../store/Styles-slice";
import { checkOutActions } from "../../../store/CheckOut-slice";
const NavBar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isHomePage = location.pathname.split("/")[1] === "home";
  const [colorChange, setColorchange] = useState(true);
  const [navBarTransparent, setNavBarTransparent] = useState(true);
  const changeNavbarColor = () => {
    if (window.scrollY >= 0 && window.screen.width > 767) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  var lightLogo;
  if (window.screen.width < 767) {
    lightLogo = logoDark;
  } else {
    lightLogo = logoLight;
  }
  useEffect(() => {
    if (isHomePage && !colorChange) {
      setNavBarTransparent(true);
    } else {
      setNavBarTransparent(false);
    }
  }, [isHomePage, colorChange]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const isLoggedIn = useSelector((state) => state.styles.loggedIn);
  const cards_count = useSelector((state) => state.checkOut.cards_count);
  return (
    <Navbar
      expand="md"
      className={
        navBarTransparent
          ? "md:!bg-transparent !bg-[#FFFFFF] shadow-md !z-50"
          : "bg-[#FFFFFF] opacity-95 shadow-md !z-50"
      }
      fixed="top"
    >
      <Container className="!flex-nowrap" fluid="lg">
        <Navbar.Brand as={NavLink} to="/" className="order-2 md:order-1">
          <img
            src={!navBarTransparent ? logoDark : lightLogo}
            alt="Propack"
            className="xsm:w-[100px] xsm:h-[20px]"
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="offcanvasNavbar-expand-md"
          onClick={handleShow}
        />
        <Navbar.Offcanvas
          aria-labelledby="offcanvasNavbarLabel-expand-md"
          placement="start"
          className="md:order-2 lg:order-2 !w-full px-2"
          show={show}
          onHide={handleClose}
        >
          <Offcanvas.Header
            className="!relative nn border-b-2 !pb-4 border-[#A04723]"
            closeButton
          >
            <Offcanvas.Title
              as={NavLink}
              to="/"
              id="offcanvasNavbarLabel-expand-md"
              onClick={handleClose}
              className="!order-2"
            >
              <img
                src={!navBarTransparent ? logoDark : lightLogo}
                alt="Propack"
                className="!w-[125px] !h-[24px]"
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "10px",
                  transform: "translateX(-50%)",
                }}
              />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="offCanvasSmall">
            <Nav className="justify-content-end flex-grow-1">
              <Nav.Link
                as={NavLink}
                to="/"
                onClick={handleClose}
                className={
                  !navBarTransparent
                    ? "!text-[#FF733A] md:!mr-2 xsm:!mt-3 no-underline !font-bold hover:!text-[#A04723] focus:!text-[#A04723]"
                    : "md:!text-[white] md:!mr-2 xsm:!mt-3 no-underline !text-[#FF733A] !font-bold hover:!text-[#A04723] focus:!text-[#A04723]"
                }
              >
                Home
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/contactus"
                onClick={handleClose}
                className={
                  !navBarTransparent
                    ? "!text-[#FF733A] md:!mr-2 xsm:!mt-3 no-underline !font-bold hover:!text-[#A04723] focus:!text-[#A04723]"
                    : "md:!text-[white] md:!mr-2 xsm:!mt-3 no-underline !text-[#FF733A] !font-bold hover:!text-[#A04723] focus:!text-[#A04723]"
                }
              >
                Contact us
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/aboutus"
                onClick={handleClose}
                className={
                  !navBarTransparent
                    ? "!text-[#FF733A] md:!mr-2 xsm:!mt-3 no-underline !font-bold hover:!text-[#A04723] focus:!text-[#A04723]"
                    : "md:!text-[white] md:!mr-2 xsm:!mt-3 no-underline !text-[#FF733A] !font-bold hover:!text-[#A04723] focus:!text-[#A04723]"
                }
              >
                About us
              </Nav.Link>
              <Nav.Link
                as={NavLink}
                to="/trackorder"
                onClick={handleClose}
                className={
                  !navBarTransparent
                    ? "!text-[#FF733A] xsm:!mt-3 md:!mr-2 no-underline !font-bold hover:!text-[#A04723] focus:!text-[#A04723]"
                    : "md:!text-[white] xsm:!mt-3 md:!mr-2 no-underline !text-[#FF733A] !font-bold hover:!text-[#A04723] focus:!text-[#A04723]"
                }
              >
                Track Your Order
              </Nav.Link>
              <div className="!relative xsm:!mt-4 !flex !items-center">
                <h5
                  className="!absolute !top-2 !left-0 md:!hidden !text-[#FF733A] !text-[17px] !font-bold hover:!text-[#A04723] focus:!text-[#A04723]"
                  onClick={() => {
                    document.getElementById("user-dropdown").click();
                  }}
                >
                  My account
                </h5>
                <DropdownButton
                  id="user-dropdown"
                  title={
                    <i
                      className={
                        !navBarTransparent
                          ? "xsm:ml-[6rem] fa fa-user fa-lg !text-[#FF733A]"
                          : "xsm:ml-[6rem] fa fa-user fa-lg md:!text-[white] !text-[#FF733A]"
                      }
                    />
                  }
                  variant="link"
                >
                  {!isLoggedIn && (
                    <>
                      <Dropdown.Item
                        as={NavLink}
                        to="/account/signin"
                        onClick={handleClose}
                      >
                        Sign In
                      </Dropdown.Item>
                      <Dropdown.Item
                        as={NavLink}
                        to="/account/signup"
                        onClick={handleClose}
                      >
                        Sign Up
                      </Dropdown.Item>
                    </>
                  )}
                  {isLoggedIn && (
                    <>
                      <Dropdown.Item
                        as={NavLink}
                        to="/home"
                        className="!bg-white !text-black"
                        onClick={() => {
                          handleClose();
                          dispatch(stylesActions.falseLoggedIn());
                          dispatch(stylesActions.trueLoggedOut());
                          dispatch(
                            checkOutActions.setCardsCount({ type: "null" })
                          );
                        }}
                      >
                        Log Out
                      </Dropdown.Item>
                    </>
                  )}
                </DropdownButton>
              </div>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
        <div className="!order-3 lg:!order-3 !flex !items-center">
          <Nav.Link
            as={NavLink}
            to="/cart"
            className={
              !navBarTransparent
                ? "iconB1 iconNav !relative !mr-5"
                : "!relative iconB2 iconNav"
            }
            onClick={handleClose}
          >
            {cards_count > 0 && (
              <Badge
                className="bg-warning"
                pill
                style={{
                  fontSize: "0.7rem",
                  position: "absolute",
                  top: -10,
                  right: -10,
                }}
              >
                {cards_count}
              </Badge>
            )}
          </Nav.Link>
        </div>
        <Nav.Link
          as={NavLink}
          to="/shopcards"
          className="order-4 lg:!order-none bg-[#FF733A] !text-[#fff] rounded-[60px] !font-semibold !text-center !px-3 !py-1 hover:!text-[#A04723] !whitespace-nowrap"
        >
          SHOP CARDS
        </Nav.Link>
      </Container>
    </Navbar>
  );
};

export default NavBar;
