import { createSlice } from "@reduxjs/toolkit";

const checkOutSlice = createSlice({
  name: "checkOut",
  initialState: {
    confirmCheckOutModal: false,
    finalCanvasImage: "",
    startInsert: false,
    //
    extraList: [],
    cards_count: 0,
  },
  reducers: {
    showModal(state) {
      state.confirmCheckOutModal = !state.confirmCheckOutModal;
    },
    setCanvasImage(state, action) {
      var canvasSrc = action.payload;
      state.finalCanvasImage = canvasSrc;
    },
    setInsertCard(state) {
      state.startInsert = !state.startInsert;
    },
    //EXTRAS
    setInitialExtras(state, action) {
      const extraObeject = action.payload;
      state.extraList = extraObeject;
    },
    incrementExtra(state, action) {
      const newExtra = action.payload;
      const existingExtra = state.extraList.find(
        (extra) => extra.id === newExtra.id
      );
      if (existingExtra) {
        existingExtra.quantity++;
      } else {
        return;
      }
    },
    decrementExtra(state, action) {
      const id = action.payload;
      const existingExtra = state.extraList.find((extra) => extra.id === id.id);
      if (action.payload.type === "normalDecrement") {
        if (existingExtra.quantity === 0) {
          return;
        } else if (existingExtra.quantity > 0) {
          existingExtra.quantity--;
        } else {
          return;
        }
      } else if (action.payload.type === "notChecked") {
        existingExtra.quantity = 0;
        existingExtra.r_extra_image_id = 0;
        existingExtra.note = null;
      }
    },
    setGiftNoteImage(state, action) {
      const imageObject = action.payload;
      state.extraList = state.extraList.map((extra) => {
        if (extra.id === imageObject.extraId) {
          return {
            ...extra,
            r_extra_image_id: imageObject.imgId,
          };
        }
        return extra;
      });
    },
    setGiftNoteText(state, action) {
      const giftObjects = action.payload;
      state.extraList = state.extraList.map((extra) => {
        if (extra.id === giftObjects.extraId) {
          return {
            ...extra,
            note: giftObjects.extraNote,
          };
        }
        return extra;
      });
    },
    setFinalCanvasImage(state, action) {
      state.finalCanvasImage = action.payload;
    },
    setCardsCount(state, action) {
      if (action.payload.type === "update") {
        state.cards_count += action.payload.count;
      } else if (action.payload.type === "null") {
        state.cards_count = 0;
      }
    },
  },
});

export const checkOutActions = checkOutSlice.actions;
export default checkOutSlice;
