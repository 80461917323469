import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  InputGroup,
  FloatingLabel,
  Container,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import cube1 from "../../../images/home/BestSelling/cube1.png";
import cube2 from "../../../images/home/BestSelling/cube2.png";
import tn from "../../../images/trackingNumber/tn.png";
import { useSelector } from "react-redux";
import localforage from "localforage";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const DhlResponseForm = () => {
  const url = useSelector((state) => state.api.domain);
  //
  const [response, setResponse] = useState("");
  const [loggedIn, setIsloggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const nbRef = useRef(null);
  //
  const notify = (msg) => toast(msg);
  useEffect(() => {
    localforage.getItem("user").then((user) => {
      if (user !== null) {
        setIsloggedIn(true);
      } else {
        setIsloggedIn(false);
      }
    });
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    let formData = new FormData();
    if (
      localStorage.getItem("session_id") &&
      localStorage.getItem("session_id") != null &&
      localStorage.getItem("session_id") !== "null"
    ) {
      formData.append("session_id", localStorage.getItem("session_id"));
    }
    formData.append("is_logged_in", loggedIn);
    formData.append("token", localStorage.getItem("token"));
    formData.append("tracking_number", nbRef.current.value);
    const url1 = `${url}/wizard/dhl-track`;
    axios.post(url1, formData).then((res) => {
      if (res.data.succeeded) {
        setIsLoading(false);
        var t = JSON.parse(res.data.response);
        setResponse(t.detail);
      } else {
        setIsLoading(false);
        notify(res.data.response);
      }
    });
  };

  const inputChangeHandler = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length > 11) {
      nbRef.current.value = inputValue.slice(0, 11); // Truncate input to 11 characters
    }
  };

  return (
    <Container className="contAll" fluid>
      <ToastContainer autoClose={3000} />
      <Container className="!pt-24 !px-10">
        <Col>
          <Row>
            <main className="text-center">
              <header className="flex flex-row items-center justify-center">
                <img src={cube1} alt="Cube 1" className="p-1 xsm:h-8" />
                <h1 className="text-[#000000] font-bold xsm:text-[20px]">
                  Track Your Order
                </h1>
                <img src={cube2} alt="Cube 2" className="p-1 xsm:h-8" />
              </header>
            </main>
          </Row>
          <Row>
            <Col className="!mt-16">
              <Row>
                <label
                  className="!text-[18px] !text-black !mx-0 !px-0"
                  htmlFor="formGridTrackingNumber"
                >
                  Enter Tracking Number
                </label>
                <Form className="!mx-0 !px-0 !mt-4" onSubmit={handleSubmit}>
                  <InputGroup
                    as={Col}
                    controlid="formGridTrackingNumber"
                    size="sm"
                    className="!mx-0 !px-0"
                    id="formGridTrackingNumber"
                  >
                    <FloatingLabel
                      controlid="floatingInput"
                      label="Enter Tracking Number"
                      className="mb-3"
                    >
                      <Form.Control
                        type="number"
                        placeholder="Enter Tracking Number"
                        id="formGridTrackingNumber"
                        className="!rounded-[15px] !border-[#FF733A]"
                        ref={nbRef}
                        maxLength={11}
                        onChange={inputChangeHandler}
                        required
                      />
                    </FloatingLabel>
                  </InputGroup>
                  <Button
                    type="submit"
                    className="!float-right !bg-[#FF733A] hover:!bg-[#A04723] !rounded-[60px] !text-white !border-transparent !px-4"
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading..." : "Get Order Details"}
                  </Button>
                </Form>
              </Row>
              {response && (
                <Row className="!mt-4">
                  <label
                    className="!text-[18px] !text-black !mx-0 !px-0"
                    htmlFor="formGridTrackingNumber"
                  >
                    Order Description
                  </label>
                  <Form.Control
                    as="textarea"
                    style={{ minHeight: "200px" }}
                    className="!border-[#FF733A]"
                    readOnly
                    value={response}
                  />
                </Row>
              )}
            </Col>
            <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
              <img
                src={tn}
                alt="..."
                className="!float-right !object-contain !h-[577px]"
              />
            </Col>
          </Row>
        </Col>
      </Container>
    </Container>
  );
};

export default DhlResponseForm;
