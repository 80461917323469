import "./Section2.css";
import { Card, Row, Col, Container, Placeholder } from "react-bootstrap";
import img2 from "../../../images/home/Star.png";
import cube1 from "../../../images/home/BestSelling/cube1.png";
import cube2 from "../../../images/home/BestSelling/cube2.png";
import LazyLoad from "react-lazy-load";
import ProgressiveImage from "react-progressive-image";
import Loader from "../Loader/Loader";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getOurCustomerSays } from "../../../store/data/Home-slice";
const Section2 = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (content === null) {
      dispatch(getOurCustomerSays());
    } else {
      return;
    }
  }, [dispatch]);
  const { isLoading, content, contentBg } = useSelector(
    (state) => state.home.ourCustomerSays
  );
  return (
    <Container
      className="sm:mt-[100px] mt-[50px] !w-[100%] text-[#FFFFFF] !pl-0 !pr-0"
      fluid
    >
      <Row>
        <main className="text-center">
          <header className="flex flex-row items-center justify-center">
            <img src={cube1} alt="Cube 1" className="p-1 xsm:h-8" />
            {!isLoading && contentBg && content && (
              <h1
                className="font-bold xsm:text-[20px] !pt-2 md:!pt-0 divTransition"
                style={{
                  color: `${content[0].font_color ? content[0].font_color : "#000"}`,
                }}
              >
                {content && content[0].title}
              </h1>
            )}
            <img src={cube2} alt="Cube 2" className="p-1 xsm:h-8" />
          </header>
        </main>
      </Row>
      <Card className="!border-none !bg-transparent">
        {isLoading && <Loader />}
        {!isLoading && contentBg && (
          <LazyLoad offset={300}>
            <ProgressiveImage
              src={`${contentBg[0]?.path}${contentBg[0]?.file_name}`}
              placeholder={`${contentBg[0]?.path}thumbnails/${contentBg[0]?.file_name}`}
            >
              {(src) => <Card.Img src={src} alt="Loading..." loading="lazy" />}
            </ProgressiveImage>
          </LazyLoad>
        )}
        <Card.ImgOverlay>
          <Row>
            <Col
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={5}
              xxl={5}
              className="md:!pl-10"
            >
              {!isLoading && content && (
                <LazyLoad offset={300}>
                  <ProgressiveImage
                    src={`${content[0]?.path}${content[0]?.file_name}`}
                    placeholder={`${content[0]?.path}thumbnails/${content[0]?.file_name}`}
                  >
                    {(src) => (
                      <img
                        src={src}
                        alt="Loading..."
                        loading="lazy"
                        className="!object-contain lg:!float-right"
                      />
                    )}
                  </ProgressiveImage>
                </LazyLoad>
              )}
            </Col>
            <Col className="!flex !justify-start !mt-10 sm:!mt-20 md:!mt-32 xl:!mt-40">
              <div>
                <h3 className="2xl:!text-[37px] lg:!text-[27px] xsm:!text-[10px] !text-[10px] sm:!text-[18px] md:!text-[30px]">
                  {isLoading && (
                    <Placeholder as={Card.Title} animation="glow">
                      <Placeholder
                        xs={6}
                        style={{ backgroundColor: "red" }}
                        className="!ml-32 !w-full"
                      />
                    </Placeholder>
                  )}
                  {!isLoading && content && content[0]?.description}
                </h3>
                <h1 className="2xl:!text-[37px] lg:!text-[30px] xsm:!text-[17px] !text-[15px] sm:!text-[22px] md:!text-[30px] !font-extrabold">
                  {isLoading && (
                    <Placeholder as={Card.Title} animation="glow">
                      <Placeholder
                        xs={6}
                        style={{ backgroundColor: "red" }}
                        className="!ml-32 !w-full"
                      />
                    </Placeholder>
                  )}
                  {!isLoading && "Excellent"}
                  {!isLoading && (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-7 inline-block xsm:!h-3 xsm:w-4"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-7 inline-block xsm:!h-3 xsm:w-4"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-7 inline-block xsm:!h-3 xsm:w-4"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-7 inline-block xsm:!h-3 xsm:w-4"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      <img
                        src={img2}
                        alt="preview"
                        className="h-4 w-6 inline-block pl-1 xsm:!h-2 xsm:w-3 xsm:pl-0"
                      />
                    </>
                  )}
                </h1>
              </div>
            </Col>
          </Row>
        </Card.ImgOverlay>
      </Card>
    </Container>
  );
};

export default Section2;
