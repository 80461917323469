import { configureStore } from "@reduxjs/toolkit";
import apiSlice from "./Api-slice";
import cardSlice from "./CardDesign-slice";
import checkOutSlice from "./CheckOut-slice";
import homeSlice from "./data/Home-slice";
import secondarySlice from "./data/Secondary-slice";
import step1Slice from "./data/Step1-slice";
import stylesSlice from "./Styles-slice";
const store = configureStore({
  reducer: {
    card: cardSlice.reducer,
    checkOut: checkOutSlice.reducer,
    api: apiSlice.reducer,
    styles: stylesSlice.reducer,
    home: homeSlice.reducer,
    stepOne: step1Slice.reducer,
    secondary: secondarySlice.reducer,
  },
});

export default store;
