import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ToggleButton from "react-bootstrap/ToggleButton";
import Card from "react-bootstrap/Card";
import Offcanvas from "react-bootstrap/Offcanvas";
import customizeIcon from "../../../images/shopCards/Step1/customizeButtonIcon.svg";
import checkIcon from "../../../images/shopCards/Step1/checkIcon.svg";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cardActions } from "../../../store/CardDesign-slice";
import { stylesActions } from "../../../store/Styles-slice";
import { Link, useHistory } from "react-router-dom";
import LazyLoad from "react-lazy-load";
import ProgressiveImage from "react-progressive-image";
import backArrow from "../../../images/shopCards/backArrow.svg";
import { getStepOne } from "../../../store/data/Step1-slice";
import icon2 from "../../../images/shopCards/Step3/InfoSquare.svg";
//
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper";
import classes from "../privacyPolicy/PrivacyPolicyForm.module.css";
import localforage from "localforage";
const Step1 = () => {
  const history = useHistory();
  //
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      stepOneContentStyles === null ||
      stepOneContentSizes === null ||
      stepOneContentPrices === null ||
      stepOneContentImages === null
    ) {
      dispatch(getStepOne());
    } else {
      return;
    }
  }, [dispatch]);
  const {
    isLoading,
    stepOneContentStyles,
    stepOneContentSizes,
    stepOneContentPrices,
    stepOneContentImages,
    stepOneContentList,
    stepOneContentStylesGuide,
    error,
  } = useSelector((state) => state.stepOne.stepOne);
  //
  const [styleSelected, setStyleSelected] = useState(null);
  const [sizeSelected, setSizeSelected] = useState(null);
  const [price, setPrice] = useState(0);
  const [imagesToDisplay, setImagesToDisplay] = useState([]);
  useEffect(() => {
    if (styleSelected) {
      dispatch(cardActions.setCardStyleId(styleSelected));
      const styleName = stepOneContentStyles.filter(
        (style) => style.id === styleSelected
      );
      const name = styleName[0].name;
      dispatch(cardActions.setCardStyleName(name));
      localforage.setItem("st", styleSelected);
      localforage.setItem("stn", name);
    }
  }, [styleSelected]);
  useEffect(() => {
    if (sizeSelected) {
      dispatch(cardActions.setCardSizeId(sizeSelected));
      const sizeName = stepOneContentSizes.filter(
        (size) => size.id === sizeSelected
      );
      const name = sizeName[0].size;
      dispatch(cardActions.setCardSizeName(name));
      localforage.setItem("si", sizeSelected);
      localforage.setItem("sin", name);
      setImagesToDisplay(
        stepOneContentImages.filter((image) => image.r_size_id === sizeSelected)
      );
    }
  }, [sizeSelected]);
  useEffect(() => {
    if (styleSelected && sizeSelected) {
      dispatch(stylesActions.enableSmallBtnCustomize());
      const selectedItem = stepOneContentPrices.filter(
        (item) =>
          item.r_style_id === styleSelected && item.r_size_id === sizeSelected
      );
      const price = selectedItem[0].price;
      setPrice(price);
      dispatch(cardActions.setCardPrice(price));
      localforage.setItem("cp", price);
    } else {
      dispatch(stylesActions.disableSmallBtnCustomize());
    }
  }, [styleSelected, sizeSelected]);
  const btnNextStatus = useSelector(
    (state) => state.styles.wizardSmallCustomizeButtonDisabled
  );
  const backHomeHandler = () => {
    history.push("/home");
  };
  useEffect(() => {
    if (stepOneContentSizes) {
      setSizeSelected(stepOneContentSizes[stepOneContentSizes.length - 1]?.id);
    }
  }, [stepOneContentSizes]);
  const [show, setShow] = useState(false);
  return (
    <>
      <Offcanvas
        show={show}
        onHide={() => {
          setShow(false);
        }}
        placement="bottom"
        className={`${classes.pp} !h-[80vh] !rounded-t-[20px]`}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="!flex !flex-col">
            Propack Style Guide
          </Offcanvas.Title>
        </Offcanvas.Header>
        {stepOneContentStylesGuide && (
          <Offcanvas.Body
            dangerouslySetInnerHTML={{
              __html: stepOneContentStylesGuide[0]?.text,
            }}
          ></Offcanvas.Body>
        )}
      </Offcanvas>
      <section className="shopCardsSection">
        <Container>
          <Col>
            <Row>
              <div className="flex items-center justify-between py-3 text-[#fff] !bg-[#FF733A]">
                <div className="!flex !items-center" onClick={backHomeHandler}>
                  <img src={backArrow} alt="Back" className="!pr-2 !inline" />
                  <p className="!m-0">Home</p>
                </div>
                <div>
                  <p className="m-0 font-medium sm:!text-xs !px-3 text-left">
                    Card Style and Size
                  </p>
                </div>
                <div>
                  <p className="!bg-white !text-[#FF733A] !rounded-lg !px-3 !m-0">
                    1 / 8
                  </p>
                </div>
              </div>
            </Row>
            <Row className="mt-2">
              <div className="pt-3">
                <div className="flex items-center">
                  <h5 className="text-[#000000] !font-bold">
                    (A) Select Style
                  </h5>
                  <label
                    className="!cursor-pointer !mb-[0.5rem] !mx-2"
                    onClick={() => {
                      setShow(true);
                    }}
                  >
                    <img src={icon2} alt=".." id="styleGuide" />
                  </label>
                </div>
                <div className="flex items-center justify-center">
                  {!isLoading &&
                    stepOneContentStyles &&
                    stepOneContentStyles?.map((style, index) => (
                      <ToggleButton
                        key={index}
                        className={
                          styleSelected === style.id
                            ? "!bg-[#FF733A] !text-[#fff] !border-[#FF733A] !px-14 !py-1 !font-semibold !mx-1"
                            : "!bg-white !text-[#FF733A] !border-[#FF733A] !px-14 !py-1 !font-semibold !mx-1"
                        }
                        checked={styleSelected === style.id}
                        value={style.name}
                        onClick={() => {
                          setStyleSelected(style.id);
                        }}
                        type="checkbox"
                        name={style.name}
                      >
                        {style.name}
                      </ToggleButton>
                    ))}
                </div>
              </div>
            </Row>
            <Row className="mt-4">
              <div className="pt-3">
                <div>
                  <h5 className="text-[#000000] !font-bold">(B) Select Size</h5>
                </div>
                <div className="flex flex-row">
                  {!isLoading &&
                    stepOneContentSizes &&
                    stepOneContentSizes?.map((size, index) => (
                      <Card
                        key={index}
                        className={
                          size.id === sizeSelected
                            ? "!border-[#FF733A] !bg-white ml-3 !pt-1 cursor-pointer"
                            : "!bg-white ml-3 !pt-1 cursor-pointer"
                        }
                        onClick={() => {
                          setSizeSelected(size.id);
                        }}
                      >
                        <LazyLoad>
                          <ProgressiveImage
                            src={`${size.path}thumbnails/${size.file_name}`}
                            placeholder={`${size.path}blures/${size.file_name}`}
                          >
                            {(src) => (
                              <Card.Img
                                src={src}
                                alt="..."
                                loading="lazy"
                                variant="top"
                                className="!max-w-[160px]"
                              />
                            )}
                          </ProgressiveImage>
                        </LazyLoad>
                        <Card.Body className="!text-center !px-0">
                          <Card.Title className="!text-[#000] !font-semibold lg:text-sm whitespace-nowrap">
                            {size.size}
                          </Card.Title>
                          <Card.Subtitle className="!text-[#000] !text-xs whitespace-nowrap">
                            {size.size_short_description}
                          </Card.Subtitle>
                        </Card.Body>
                      </Card>
                    ))}
                </div>
              </div>
            </Row>
            <Row className="mt-4">
              <Col>
                <ul className="list-group">
                  {!isLoading &&
                    stepOneContentList &&
                    stepOneContentList?.map((list, index) =>
                      index < stepOneContentList.length / 2 ? (
                        <span className="!flex" key={index}>
                          <img src={checkIcon} alt=".." />
                          <li className="!text-[#000] !font-semibold !pl-2 whitespace-nowrap text-sm">
                            {list.name}
                          </li>
                        </span>
                      ) : (
                        ""
                      )
                    )}
                </ul>
              </Col>
              <Col>
                <ul className="list-group">
                  {!isLoading &&
                    stepOneContentList &&
                    stepOneContentList?.map((list, index) =>
                      index > stepOneContentList.length / 2 ? (
                        <span className="!flex" key={index}>
                          <img src={checkIcon} alt=".." />
                          <li className="!text-[#000] !font-semibold !pl-2 whitespace-nowrap text-sm">
                            {list.name}
                          </li>
                        </span>
                      ) : (
                        ""
                      )
                    )}
                </ul>
              </Col>
            </Row>
            <Row className="mt-4 mb-24">
              <Swiper
                slidesPerView={3}
                slidesPerGroup={3}
                spaceBetween={3}
                // loopFillGroupWithBlank={true}
                pagination={{
                  clickable: true,
                }}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: true,
                }}
                loop={true}
                // navigation={true}
                modules={[Pagination, Navigation, Autoplay]}
                className="!z-0"
              >
                {imagesToDisplay?.map((img, index) => {
                  return (
                    <SwiperSlide key={`${img.filename}_${index}`}>
                      <LazyLoad>
                        <ProgressiveImage
                          src={`${img.path}${img.file_name}`}
                          placeholder={`${img.path}blures/${img.file_name}`}
                        >
                          {(src) => (
                            <img
                              src={src}
                              alt="..."
                              className="!object-cover"
                            />
                          )}
                        </ProgressiveImage>
                      </LazyLoad>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Row>
          </Col>
        </Container>
        <footer className="!fixed !bottom-0 !left-0 !right-0">
          <div className="!w-[100%] !h-[100%]">
            <Link
              className={
                btnNextStatus
                  ? "!bg-[#FF733A] !opacity-50 !pointer-events-none !cursor-default !text-[#fff] !flex !justify-center !py-4 !rounded-t-[30px] !border-transparent !font-semibold hover:!bg-[#A04723] !my-0 !text-lg !border-[#A04723] !border-b-0 !border-4"
                  : "!bg-[#FF733A] !text-[#fff] !flex !justify-center !py-4 !rounded-t-[30px] !border-transparent !font-semibold hover:!bg-[#A04723] !my-0 !text-lg !border-[#A04723] !border-b-0 !border-4"
              }
              to="/cardesign"
            >
              <img src={customizeIcon} alt="..." className="px-1" />
              Customize {price > 0 ? "$" + price : ""}
            </Link>
          </div>
        </footer>
      </section>
    </>
  );
};

export default Step1;
