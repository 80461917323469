import React from "react";
import { useHistory } from "react-router-dom";
function NotFound() {
  const fadeInAnimation = {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  };
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      width: "100%",
      fontSize: "2rem",
      color: "#333",
      animation: "fadeIn 1s ease-in-out",
    },
    icon: {
      fontSize: "5rem",
      marginBottom: "1rem",
    },
    "@keyframes fadeIn": fadeInAnimation,
  };
  const history = useHistory();
  const backHandler = () => {
    history.goBack();
  };
  return (
    <div style={styles.container}>
      <i style={styles.icon} className="fas fa-exclamation-triangle"></i>
      <h1>404 - Page Not Found</h1>
      <p className="!text-center">
        Sorry, the page you are looking for could not be found.
      </p>
      <button
        class="transition ease-in-out delay-150 bg-orange-500 hover:-translate-y-1 hover:scale-110 hover:bg-orange-900 hover:text-white duration-300 !rounded-xl !px-20"
        onClick={backHandler}
      >
        Go Back
      </button>
    </div>
  );
}
export default NotFound;
