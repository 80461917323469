import { Container, Row } from "react-bootstrap";
import CartItem from "./CartItem";
import cube1 from "../../../images/home/BestSelling/cube1.png";
import cube2 from "../../../images/home/BestSelling/cube2.png";
import coI from "../../../images/cart/checkOutIcon.svg";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import localforage from "localforage";
import axios from "axios";
import Loader from "../Loader/Loader";
import { checkOutActions } from "../../../store/CheckOut-slice";
import { ToastContainer, toast } from "react-toastify";
const CartList = () => {
  const notify = (msg) => toast(msg);
  const dispatch = useDispatch();
  const url = useSelector((state) => state.api.domain);
  const [subTotal, setSubTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  //get cart
  const [cards, setCards] = useState([]);
  function getCustomizedCrads(isLoggedIn) {
    let formData = new FormData();
    formData.append("is_logged_in", isLoggedIn);
    formData.append("token", localStorage.getItem("token"));
    formData.append("session_id", localStorage.getItem("session_id"));
    const url1 = `${url}/wizard/get-cart`;
    axios
      .post(url1, formData)
      .then((res) => {
        setCards(res.data.cards);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }
  const [loggedIn, setIsloggedIn] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    localforage
      .getItem("user")
      .then((user) => {
        if (user !== null) {
          getCustomizedCrads(true);
          setIsloggedIn(true);
        } else {
          getCustomizedCrads(false);
          setIsloggedIn(false);
        }
      })
  }, []);
  function roundNumber(num) {
    if (num % 1 !== 0 && num.toString().split(".")[1].length >= 3) {
      return num.toFixed(2);
    } else {
      return num;
    }
  }
  const itemDeleteHandler = (id) => {
    let formData = new FormData();
    formData.append("user_card_id", id);
    formData.append("is_logged_in", loggedIn);
    formData.append("token", localStorage.getItem("token"));
    formData.append("session_id", localStorage.getItem("session_id"));
    const url1 = `${url}/wizard/delete-users-customized-card`;
    axios
      .post(url1, formData)
      .then((res) => {
        if (res.data.succeeded) {
          const updatedItems = cards.filter((item) => item.id !== id);
          setCards(updatedItems);
          dispatch(
            checkOutActions.setCardsCount({ type: "update", count: -1 })
          );
          if (updatedItems.length === 0) {
            setSubTotal(0);
          }
        }
        notify(res.data.message);
      })
      .catch((err) => console.log(err));
  };
  return (
    <Container className="lg:!w-[85%] xsm:!w-[90%] sm:!w-[85%] !w-[90%]" fluid>
    <ToastContainer autoClose={3000} />
      <Row>
        <header className="flex flex-row items-center justify-center">
          <img src={cube1} alt="Cube 1" className="p-2 xsm:h-8" />
          <h1 className="text-[#000000] font-extrabold xsm:text-[20px]">
            Your Cart
          </h1>
          <img src={cube2} alt="Cube 2" className="p-2 xsm:h-8" />
        </header>
      </Row>
      <Row className="!pr-0">
        {isLoading && <Loader />}
        {!isLoading && cards.length > 0 && (
          <CartItem cardsItems={cards} setSubTotal={setSubTotal} deleteItem={itemDeleteHandler} />
        )}
        {!isLoading && cards.length === 0 && (
          <h1 className="!text-center !my-[50%] md:!my-[10%]">
            Your cart is empty
          </h1>
        )}
      </Row>
      <Row>
        <div className="flex justify-between items-center my-10">
          <div>
            <h3 className="text-[#000000] font-bold">
              Subtotal:
              <span className="inline-block text-[#FF733A] pl-2">
                ${roundNumber(subTotal)}
              </span>
            </h3>
          </div>
          <div>
            <Link
              to="/checkout"
              className={
                cards.length > 0
                  ? "flex !items-center no-underline !rounded-full !bg-[#FF733A] !text-[#FFFFFF] !px-7 !py-1 hover:!bg-[#A04723] !border-0"
                  : "!pointer-events-none flex !opacity-20 !items-center no-underline !rounded-full !bg-[#FF733A] !text-[#FFFFFF] !px-7 !py-1 hover:!bg-[#A04723] !border-0"
              }
            >
              <img src={coI} alt="Checkout" className="p-1" />
              Checkout
            </Link>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default CartList;
