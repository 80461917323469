import "./BigOccasion.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import cube1 from "../../../images/home/WhyChoose/cube1.svg";
import cube2 from "../../../images/home/WhyChoose/cube2.svg";
import LazyLoad from "react-lazy-load";
import ProgressiveImage from "react-progressive-image";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getBigOccasion } from "../../../store/data/Home-slice";
import Loader from "../Loader/Loader";
const BigOccasion = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (content === null) {
      dispatch(getBigOccasion());
    } else {
      return;
    }
  }, []);
  const { isLoading, content } = useSelector((state) => state.home.bigOccasion);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  return (
    <Container className="!m-auto !mt-20" fluid>
      <Row>
        {isImageLoaded && <div className="!flex !flex-row !items-center !justify-center text-[#000000]">
          <img
            src={cube1}
            alt="Propack Cube"
            className="p-2 xsm:p-0 xsm:h-8 h-10"
          />
          <h1 className="font-extrabold pt-2 xsm:text-[20px] md:text-2xl sm:text-xl text-lg lg:text-4xl">
            {content && content[0]?.title}
          </h1>
          <img
            src={cube2}
            alt="Propack Cube"
            className="p-2 xsm:p-0 xsm:h-8 h-10"
          />
        </div>}
      </Row>
      <Row className="!text-black xsm:!h-[250px]">
        {isImageLoaded && (
          <Col className="col1 !relative xsm:!m-0 xsm:!pt-16">
            <div className="!max-w-[320px] md:!absolute !flex !items-center !justify-between md:!top-[22%] md:!left-[1%] lg:!left-[1%] xl:!left-[4%] 2xl:!left-[7%]">
              <p className="md:text-sm !pb-0 !mb-0 lg:text-xl xl:text-2xl !font-bold">
                {content &&
                  content[0]?.first_option_left &&
                  content[0]?.first_option_left}
                {isLoading && "Loading..."}
              </p>
              {content[0]?.first_icon_left && (
                <div className="!ml-2 md:!w-8 md:!h-8 lg:!w-10 lg:!h-10 !bg-[#A04723] !rounded-[60px] !flex !items-center !justify-center">
                  <i
                    className={`${content[0]?.first_icon_left}`}
                    style={{ color: "white" }}
                  />
                </div>
              )}
            </div>
            <div className="!max-w-[330px] md:!absolute !flex !items-center !justify-between md:!top-[44%] md:!left-[2%] lg:!left-[13%]">
              <p className="md:text-sm !pb-0 !mb-0 lg:text-xl xl:text-2xl !font-bold">
                {content &&
                  content[0]?.second_option_left &&
                  content[0]?.second_option_left}
                {isLoading && "Loading..."}
              </p>
              {content[0]?.second_icon_left && (
                <div className="!ml-2 md:!w-8 md:!h-8 lg:!w-10 lg:!h-10 !bg-[#A04723] !rounded-[60px] !flex !items-center !justify-center">
                  <i
                    className={`${content[0]?.second_icon_left}`}
                    style={{ color: "white" }}
                  />
                </div>
              )}
            </div>
            <div className="!max-w-[320px] md:!absolute !flex !items-center !justify-between md:!top-[66%] md:!left-[1%] lg:!left-[1%] xl:!left-[4%] 2xl:!left-[7%]">
              <p className="md:text-sm !pb-0 !mb-0 lg:text-xl xl:text-2xl !font-bold">
                {content &&
                  content[0]?.thired_option_left &&
                  content[0].thired_option_left}
                {isLoading && "Loading..."}
              </p>
              {content[0]?.third_icon_left && (
                <div className="!ml-2 md:!w-8 md:!h-8 lg:!w-10 lg:!h-10 !bg-[#A04723] !rounded-[60px] !flex !items-center !justify-center">
                  <i
                    className={`${content[0]?.third_icon_left}`}
                    style={{ color: "white" }}
                  />
                </div>
              )}
            </div>
          </Col>
        )}
        <Col>
          {!isLoading && content && (
            <LazyLoad offset={300}>
              <ProgressiveImage
                src={`${content[0]?.path}${content[0]?.file_name}`}
                thumbnail={`${content[0]?.path}thumbnails/${content[0]?.file_name}`}
              >
                {(src) => (
                  <img
                    src={src}
                    alt="Big Occasion"
                    loading="lazy"
                    className="xsm:!w-[100%] xsm:!h-[100%] object-cover xsm:!mt-[100%]"
                    onLoad={() => {
                      setIsImageLoaded(true);
                    }}
                  />
                )}
              </ProgressiveImage>
            </LazyLoad>
          )}
          {isLoading && <Loader />}
        </Col>
        {isImageLoaded && (
          <Col className="col3 !relative xsm:!m-0 xsm:!pt-16">
            <div className="!max-w-[320px] md:!absolute !flex !items-center !justify-center md:!top-[22%] md:!right-[1%] lg:!right-[1%] xl:!right-[4%] 2xl:!right-[7%]">
              {content[0]?.first_icon_right && (
                <div className="!ml-2 md:!w-8 md:!h-8 lg:!w-10 lg:!h-10 !bg-[#A04723] !rounded-[60px] !flex !items-center !justify-center">
                  <i
                    className={`${content[0]?.first_icon_right}`}
                    style={{ color: "white" }}
                  />
                </div>
              )}
              <p className="md:text-sm lg:text-xl xl:text-2xl !font-bold">
                {content &&
                  content[0]?.first_option_right &&
                  content[0].first_option_right}
                {isLoading && "Loading..."}
              </p>
            </div>
            <div className="!max-w-[330px] md:!absolute !flex !items-center !justify-center md:!top-[44%] md:!right-[4%] lg:!right-[13%]">
              {content[0]?.second_icon_right && (
                <div className="!ml-2 md:!w-8 md:!h-8 lg:!w-10 lg:!h-10 !bg-[#A04723] !rounded-[60px] !flex !items-center !justify-center">
                  <i
                    className={`${content[0]?.second_icon_right}`}
                    style={{ color: "white" }}
                  />
                </div>
              )}
              <p className="md:text-sm lg:text-xl xl:text-2xl !font-bold">
                {content &&
                  content[0]?.second_option_right &&
                  content[0].second_option_right}
                {isLoading && "Loading..."}
              </p>
            </div>
            <div className="!max-w-[320px] md:!absolute !flex !items-center !justify-center md:!top-[66%] md:!right-[1%] lg:!right-[1%] xl:!right-[4%] 2xl:!right-[7%]">
              {content[0]?.third_icon_right && (
                <div className="!ml-2 md:!w-8 md:!h-8 lg:!w-10 lg:!h-10 !bg-[#A04723] !rounded-[60px] !flex !items-center !justify-center">
                  <i
                    className={`${content[0]?.third_icon_right}`}
                    style={{ color: "white" }}
                  />
                </div>
              )}
              <p className="md:text-sm lg:text-xl xl:text-2xl !font-bold">
                {content &&
                  content[0]?.thired_option_right &&
                  content[0].thired_option_right}
                {isLoading && "Loading..."}
              </p>
            </div>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default BigOccasion;
