import "./CheckoutForm.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import backImg from "../../../images/checkoutForm/backArrow.svg";
import dhlIcon from "../../../images/checkoutForm/dhlIcon.svg";
import checkImg from "../../../images/checkoutForm/checkout_check.svg";
import colorL from "../../../images/checkoutForm/colors_left.svg";
import colorR from "../../../images/checkoutForm/colors_right.svg";
import visa from "../../../images/checkoutForm/visa.svg";
import master from "../../../images/checkoutForm/master.png";
import cash from "../../../images/checkoutForm/handMoney.jpg";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import localforage from "localforage";
import { useHistory } from "react-router-dom";
import { checkOutActions } from "../../../store/CheckOut-slice";
import { useDispatch, useSelector } from "react-redux";
import {
  getCountryCodes,
  getDhlCountries,
} from "../../../store/data/Secondary-slice";
import LazyLoad from "react-lazy-load";
import { CustomProgressiveImage } from "../progressiveImage/ProgressiveImage";
const CheckoutForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const notify = (msg) => toast(msg);
  const url = useSelector((state) => state.api.domain);
  const [show, setShow] = useState(false);
  const [ipAddress, setIpAddress] = useState();
  const [isCash, setIsCash] = useState(false);
  const [promoCode, setPromoCode] = useState(null);
  const emailRef = useRef(null);
  const fnRef = useRef(null);
  const lnRef = useRef(null);
  const phoneRef = useRef(null);
  const addPhoneRef = useRef(null);
  const addressRef = useRef(null);
  const addDetailRef = useRef(null);
  const birthDateRef = useRef(null);
  const couponRef = useRef(null);
  const cardNbRef = useRef(null);
  const dateRef = useRef(null);
  const cvvRef = useRef(null);
  const cardNameRef = useRef(null);
  const postalRef = useRef(null);
  const formRef = useRef(null);
  function roundNumber(num) {
    if (num % 1 !== 0 && num.toString().split(".")[1].length >= 3) {
      return num.toFixed(2);
    } else {
      return num;
    }
  }
  const handleHide = () => {
    setShow(false);
  };
  const handleCheckOutConfirmModal = () => {
    setShow(false);
    history.push("/home");
  };
  //ip_address
  const getIpAddress = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIpAddress(res.data.IPv4);
  };
  //promo code
  const [promoCodeResponse, setPromoCodeResponse] = useState(false);
  const [discountedBy, setDiscountedBy] = useState(null);
  const [discountedSubTotal, setDiscountedSubTotal] = useState(null);
  const promoCodeHandler = () => {
    notify("Please wait");
    setPromoCode(couponRef.current.value);
    let formData = new FormData();
    formData.append("promo_code", couponRef.current.value);
    const url1 = `${url}/wizard/check-promo-code`;
    axios
      .post(url1, formData)
      .then((res) => {
        if (res.data.succeeded) {
          setPromoCodeResponse(true);
          setDiscountedBy(res.data.discount_amount);
          var newSub = subTotal - subTotal * (res.data.discount_amount / 100);
          setDiscountedSubTotal(newSub);
          // setPromoCode(null);
        } else if (!res.data.succeeded) {
          notify(res.data.message);
          setPromoCodeResponse(false);
          setDiscountedBy(null);
          setPromoCode(null);
          couponRef.current.value = "";
        }
      })
      .catch((err) => console.log(err));
  };
  //payment type
  const paymentTypeHandler = (event) => {
    setIsCash(event.target.checked);
  };
  //
  const [cards, setCards] = useState([]);
  const [loggedIn, setIsloggedIn] = useState(false);
  function getCustomizedCrads(islogged) {
    let formData = new FormData();
    formData.append("is_logged_in", islogged);
    formData.append("token", localStorage.getItem("token"));
    formData.append("session_id", localStorage.getItem("session_id"));
    const url1 = `${url}/wizard/get-cart`;
    axios
      .post(url1, formData)
      .then((res) => {
        setCards(res.data.cards);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    getIpAddress();
    localforage.getItem("user").then((user) => {
      if (user !== null) {
        setIsloggedIn(true);
        getCustomizedCrads(true);
      } else {
        setIsloggedIn(false);
        getCustomizedCrads(false);
      }
    });
    dispatch(getCountryCodes());
    dispatch(getDhlCountries());
  }, []);
  //client ?
  useEffect(() => {
    localforage.getItem("user", function (err, value) {
      if (value) {
        emailRef.current.value = value.email;
        fnRef.current.value = value.first_name;
        lnRef.current.value = value.last_name;
        // cityRef.current.value = value.city;
        phoneRef.current.value = value.telephone.split("-")[1];
        setCountryCode1(value.telephone.split("-")[0]);
      }
    });
  }, []);
  const [confirmIsLoading, setConfirmIsLoading] = useState(false);
  const confirmCheckOutHandler = async () => {
    setConfirmIsLoading(true);
    let formData = new FormData();
    if (
      formRef.current.checkValidity() === false ||
      countryCode1 === "country code" ||
      countrySelected === "Country" ||
      citySelected === "City"
    ) {
      notify("Pleaes fill out all required fields.");
      setConfirmIsLoading(false);
      return;
    }
    if (
      !isCash &&
      (cardNbRef.current.value.length === 0 ||
        dateRef.current.value.length === 0 ||
        cvvRef.current.value.length === 0 ||
        cardNameRef.current.value.length === 0)
    ) {
      notify("Please fill out all card Info.");
      setConfirmIsLoading(false);
      return;
    } else if (
      !isCash &&
      cardNbRef.current.value.length > 0 &&
      dateRef.current.value.length > 0 &&
      cvvRef.current.value.length > 0 &&
      cardNameRef.current.value.length > 0
    ) {
      formData.append("card", JSON.stringify(card));
    }
    if (promoCode) {
      formData.append("promo_code", promoCode);
    }
    formData.append("is_logged_in", loggedIn);
    formData.append("token", localStorage.getItem("token"));
    formData.append("session_id", localStorage.getItem("session_id"));
    formData.append("ip_address", ipAddress);
    formData.append("email", emailRef.current.value);
    formData.append("shipping_address", countrySelectedId);
    formData.append("shipping_cost", shippingCost);
    formData.append(
      "address_detail",
      addressRef.current.value + " / " + addDetailRef.current.value
    );
    formData.append("city", citySelectedId);
    formData.append("postal_code", postalRef.current.value);
    formData.append(
      "phone_number",
      countryCode1 + "/" + phoneRef.current.value
    );
    formData.append(
      "addional_phone_number",
      countryCode2 + "/" + addPhoneRef.current.value
    );
    formData.append("first_name", fnRef.current.value);
    formData.append("birthDate", birthDateRef.current.value);
    formData.append("last_name", lnRef.current.value);
    formData.append("subtotal", roundNumber(subTotal));
    const url1 = `${url}/wizard/check-out`;
    axios
      .post(url1, formData)
      .then((res) => {
        if (res.data.succeeded) {
          notify(res.data.message);
          setShow(true);
          dispatch(checkOutActions.setCardsCount({ type: "null", count: 0 }));
        } else {
          notify(res.data.message);
          setShow(false);
        }
        setConfirmIsLoading(false);
      })
      .catch((err) => console.log(err));
  };
  const [subTotal, setSubTotal] = useState(0);
  function calculateTotal(items) {
    let total_card = 0;
    items.forEach((item) => {
      var card_total =
        parseFloat(item.price) +
        item.extras.reduce((acx, extra) => {
          return acx + parseFloat(extra.extra_price) * parseInt(extra.quantity);
        }, 0);
      total_card += card_total * parseInt(item.quantity);
    });
    setSubTotal(total_card);
  }
  useEffect(() => {
    calculateTotal(cards);
  }, [cards]);
  //shipping address
  const { countryCodes } = useSelector((state) => state.secondary.countryCode);
  const dhlCountries = useSelector((state) => state.secondary.dhlCountries);
  const [countrySelected, setCountrySelected] = useState("Country");
  const [countrySelectedId, setCountrySelectedId] = useState(null);
  const [countryFilter, setCountryFilter] = useState("");
  const filterCountryHandler = dhlCountries.content?.filter((country) =>
    country.name?.toLowerCase().includes(countryFilter.toLowerCase())
  );
  const [isCashDisabled, setIsCashDisabled] = useState(false);
  useEffect(() => {
    if (countrySelected.toLowerCase() !== "lebanon") {
      setIsCashDisabled(true);
      setIsCash(false);
    } else {
      setIsCashDisabled(false);
    }
  }, [countrySelected]);
  const [shippingCost, setShippingCost] = useState(null);
  const countrySelectedChangeHandler = (country_name, country_id) => {
    // if (country_name.toLowerCase() === "lebanon") {
    //   setShippingCost(3);
    //   setCountrySelected(country_name);
    //   setCitySelected("City");
    //   setCountrySelectedId(country_id);
    // } else {
    setCountrySelected(country_name);
    setCitySelected("City");
    setPage(0);
    setCountrySelectedId(country_id);
    setShippingCost(null);
    // }
  };
  //
  const [card, setCard] = useState({
    name: "",
    number: "",
    exp_month: "",
    exp_year: "",
    cvc: "",
  });
  function updateCard(newProperties) {
    setCard((prevProperties) => ({
      ...prevProperties,
      ...newProperties,
    }));
  }
  function cardNameHandler(event) {
    updateCard({
      name: event.target.value,
    });
  }
  function cardNumberHandler(event) {
    let value = event.target.value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(?=\d)/g, "$1 ");
    updateCard({
      number: value,
    });
    event.target.value = value;
  }
  function cardCvvHandler(event) {
    updateCard({
      cvc: event.target.value,
    });
  }
  const cardDateHandler = (event) => {
    const value = event.target.value;
    const newValue = value.replace(/^(\d{2})(\d)/, "$1 / $2");
    dateRef.current.value = newValue;
    updateCard({
      exp_month: newValue.substring(0, 2),
      exp_year: newValue.substring(newValue.length - 2),
    });
  };
  //country code
  const [searchCc1, setSerachCc1] = useState("");
  const [searchCc2, setSerachCc2] = useState("");
  const filteredCountryCode1 = countryCodes?.filter((code) =>
    code.name?.toLowerCase().includes(searchCc1.toLowerCase())
  );
  const filteredCountryCode2 = countryCodes?.filter((code) =>
    code.name?.toLowerCase().includes(searchCc2.toLowerCase())
  );
  const [countryCode1, setCountryCode1] = useState("+1");
  const [countryCode2, setCountryCode2] = useState("+1");
  //cities
  const [dhlCities, setDhlCities] = useState([]);
  const [citySelected, setCitySelected] = useState("City");
  const [citySelectedId, setCitySelectedId] = useState(null);
  const citySelectedChangeHandler = (city_name, city_id) => {
    let formData = new FormData();
    // if (countrySelected.toLocaleLowerCase() === "lebanon") {
    //   setCitySelected(city_name);
    //   setCitySelectedId(city_id);
    // } else {
    formData.append("cards", JSON.stringify(cards));
    formData.append("shipping_address_id", countrySelectedId);
    formData.append("city_id", city_id);
    const url1 = `${url}/wizard/get-shipping-cost`;
    axios
      .post(url1, formData)
      .then((res) => {
        if (res.data.succeeded) {
          setShippingCost(parseFloat(res.data.shippment_cost));
          setCitySelected(city_name);
          setCitySelectedId(city_id);
        } else {
          notify(res.data.message);
          setShippingCost(null);
          setCitySelected("City");
        }
      })
      .catch((err) => console.log(err));
    // }
  };
  //search city
  const [page, setPage] = useState(0);
  const [qSearch, setQSearch] = useState("");
  const [loadMore, setLoadMore] = useState(false);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  async function searchCities(countryId, q, page) {
    setLoadMoreLoading(true);
    await axios
      .get(
        `${url}/wizard/dhl-cities?country_id=${countryId}&q=${q}&page=${page}`
      )
      .then((res) => {
        if (res.data.length > 0) {
          if (page > 0) {
            setDhlCities((prevCities) => [...prevCities, ...res.data]);
            setLoadMoreLoading(false);
          } else if (page === 0) {
            setDhlCities("");
            setDhlCities(res.data);
            setLoadMoreLoading(false);
          }
          if (res.data.length < 20) {
            setLoadMore(false);
            setLoadMoreLoading(false);
          } else {
            setLoadMore(true);
          }
        }
      });
  }

  const searchCitiesHandler = (e) => {
    setPage(0);
    setQSearch(e.target.value);
  };

  const pageChangeHandler = (e) => {
    e.preventDefault();
    setPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    if (countrySelectedId || qSearch || page) {
      searchCities(countrySelectedId, qSearch, page);
    } else {
      return;
    }
  }, [countrySelectedId, qSearch, page]);
  return (
    <section className="ch1 contAll">
      <ToastContainer autoClose={3000} />
      <Modal
        show={show}
        onHide={handleHide}
        size="md"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="!text-center !rounded-[60px] !border-none"
      >
        <Modal.Header className="modalHeader !border-b-0 !flex !flex-col !justify-center !align-top !relative">
          <img
            src={checkImg}
            alt="checkOut"
            className="!w-[70px] !h-[70px] !absolute"
          />
        </Modal.Header>
        <Modal.Body className="!pt-16">
          <img
            src={colorL}
            alt="congrats"
            className="!absolute !right-2/3 !top-0.5"
          />
          <img
            src={colorR}
            alt="congrats"
            className="!absolute !left-2/3 !top-0.5"
          />
          <h4 className="!text-black !font-bold !capitalize">
            Congratulations!
          </h4>
          <p className="!text-black !font-medium !capitalize">
            Your order has been submitted. You will receive an e-mail shortly
            with the tracking number.
          </p>
        </Modal.Body>
        <Modal.Footer className="!border-t-0 !flex !justify-center !items-center">
          <Button
            className="!border-none !bg-[#FF733A] !rounded-[20px] !text-white !px-20 !font-semibold"
            onClick={handleCheckOutConfirmModal}
          >
            Done
          </Button>
        </Modal.Footer>
      </Modal>
      <Container className="!relative sm:!flex">
        <Container className="cont1 !mt-20 !h-min" fluid>
          <Form className="!mt-3" ref={formRef}>
            <Row className="mt-3">
              <Form.Label
                htmlFor="formGridEmail"
                className="!text-[#000000] font-semibold"
              >
                Contact Information
              </Form.Label>
              <Col>
                <InputGroup controlid="formGridEmail" size="sm">
                  <FloatingLabel
                    controlid="floatingInput"
                    label={
                      <div className="!flex">
                        First Name <span className="!text-[red] !pl-1">*</span>
                      </div>
                    }
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="First Name"
                      id="fName"
                      className="!rounded-[15px]"
                      ref={fnRef}
                      required
                    />
                  </FloatingLabel>
                </InputGroup>
              </Col>
              <Col>
                <InputGroup controlid="formGridEmail" size="sm">
                  <FloatingLabel
                    controlid="floatingInput"
                    label={
                      <div className="!flex">
                        Last Name <span className="!text-[red] !pl-1">*</span>
                      </div>
                    }
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      id="lName"
                      className="!rounded-[15px]"
                      ref={lnRef}
                      required
                    />
                  </FloatingLabel>
                </InputGroup>
              </Col>
            </Row>
            <Row className="mb-3">
              <InputGroup as={Col} controlid="formGridEmail" size="sm">
                <FloatingLabel
                  controlid="floatingInput"
                  label={
                    <div className="!flex">
                      Email <span className="!text-[red] !pl-1">*</span>
                    </div>
                  }
                  className="mb-3"
                >
                  <Form.Control
                    type="email"
                    placeholder="name@example.com"
                    id="email"
                    className="!rounded-[15px]"
                    onKeyUp={(e) => (emailRef.current.value = e.target.value)}
                    ref={emailRef}
                    required
                  />
                </FloatingLabel>
              </InputGroup>
            </Row>
            <Row>
              <Col sm={12} xs={12} md={6} lg={6}>
                <InputGroup className="mb-3">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-custom-components">
                      {countryCode1}{" "}
                      <span className="text-danger !inline">*</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="!h-[40vh] !overflow-y-scroll">
                      <InputGroup>
                        <Form.Control
                          autoFocus
                          className="mx-3 my-2 w-auto"
                          placeholder="Filter by country name"
                          onChange={(e) => setSerachCc1(e.target.value)}
                          value={searchCc1}
                        />
                      </InputGroup>
                      {filteredCountryCode1 &&
                        filteredCountryCode1.map((code) => (
                          <Dropdown.Item
                            key={code.id}
                            eventKey={code.id}
                            onClick={() => {
                              setCountryCode1(
                                code.phone_code.includes("+")
                                  ? code.phone_code
                                  : `+${code.phone_code}`
                              );
                            }}
                            value={code.phone_code}
                            className="!flex"
                          >
                            <LazyLoad offset={300}>
                              <CustomProgressiveImage
                                src={`${code.path}${code.file_name}`}
                                thumbnail={`${code.path}thumbnails/${code.file_name}`}
                                blur={`${code.path}blures/${code.file_name}`}
                                className="!pr-1 !w-7 !h-7 !object-contain"
                              />
                            </LazyLoad>
                            {code.phone_code.includes("+")
                              ? code.phone_code
                              : `+${code.phone_code}`}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Form.Control
                    placeholder="Phone number *"
                    ref={phoneRef}
                    required
                  />
                </InputGroup>
              </Col>
              <Col>
                <InputGroup className="mb-3">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-custom-components">
                      {countryCode2}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="!h-[40vh] !overflow-y-scroll">
                      <InputGroup>
                        <Form.Control
                          autoFocus
                          className="mx-3 my-2 w-auto"
                          placeholder="Filter by country name"
                          onChange={(e) => setSerachCc2(e.target.value)}
                          value={searchCc2}
                        />
                      </InputGroup>
                      {filteredCountryCode1 &&
                        filteredCountryCode2.map((code, index) => (
                          <Dropdown.Item
                            key={index}
                            eventKey={index}
                            value={code.phone_code}
                            onClick={() => {
                              setCountryCode2(
                                code.phone_code.includes("+")
                                  ? code.phone_code
                                  : `+${code.phone_code}`
                              );
                            }}
                            className="!flex"
                          >
                            <LazyLoad offset={300}>
                              <CustomProgressiveImage
                                src={`${code.path}${code.file_name}`}
                                thumbnail={`${code.path}thumbnails/${code.file_name}`}
                                blur={`${code.path}blures/${code.file_name}`}
                                className="!pr-1 !w-7 !h-7 !object-contain"
                              />
                            </LazyLoad>
                            {code.phone_code.includes("+")
                              ? code.phone_code
                              : `+${code.phone_code}`}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Form.Control
                    placeholder="Additional Phone number"
                    ref={addPhoneRef}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <InputGroup controlid="formGridBirthDate" size="sm">
                  <FloatingLabel
                    controlid="floatingInput"
                    label={<div className="!flex">Date of Birth</div>}
                    className="mb-3"
                  >
                    <Form.Control
                      type="date"
                      placeholder="BirthDate"
                      id="BirthDate"
                      className="!rounded-[15px]"
                      ref={birthDateRef}
                    />
                  </FloatingLabel>
                </InputGroup>
              </Col>
            </Row>
            <Form.Label
              htmlFor="formGridShippengAddress"
              className="!text-[#000000] font-bold mt-2"
            >
              Shipping Address
            </Form.Label>
            <InputGroup className="mb-3">
              <Dropdown>
                <Dropdown.Toggle
                  className="!w-full"
                  id="formGridShippengAddress"
                >
                  {countrySelected}
                </Dropdown.Toggle>
                <Dropdown.Menu className="!h-[40vh] !w-full !overflow-y-scroll !pt-0">
                  <div className="sticky top-0 bg-white">
                    <InputGroup>
                      <Form.Control
                        autoFocus
                        className="mx-3 my-2 w-auto"
                        placeholder="Filter by country name"
                        onChange={(e) => setCountryFilter(e.target.value)}
                        value={countryFilter}
                      />
                    </InputGroup>
                  </div>
                  {filterCountryHandler &&
                    filterCountryHandler.map((code) => (
                      <Dropdown.Item
                        key={code.id}
                        eventKey={code.id}
                        onClick={() => {
                          countrySelectedChangeHandler(code.name, code.id);
                        }}
                        value={code.name}
                        className="!flex"
                      >
                        {code.name}
                      </Dropdown.Item>
                    ))}
                  {!filterCountryHandler && <p>No Countries Found</p>}
                </Dropdown.Menu>
              </Dropdown>
            </InputGroup>
            <InputGroup>
              <Dropdown>
                <Dropdown.Toggle
                  className="!w-full"
                  id="formGridCity"
                  disabled={dhlCities.length === 0}
                >
                  {citySelected}
                </Dropdown.Toggle>
                <Dropdown.Menu className="!h-[40vh] !w-full !overflow-y-scroll !pt-0">
                  <div className="sticky top-0 bg-white">
                    <InputGroup>
                      <Form.Control
                        autoFocus
                        className="mx-3 w-auto"
                        placeholder="Filter by city name"
                        onChange={searchCitiesHandler}
                      />
                    </InputGroup>
                  </div>
                  {dhlCities &&
                    dhlCities.map((city) => (
                      <Dropdown.Item
                        key={city.id}
                        eventKey={city.id}
                        onClick={() => {
                          citySelectedChangeHandler(city.name, city.id);
                        }}
                        value={city.name}
                      >
                        {city.name}
                      </Dropdown.Item>
                    ))}
                  <Dropdown.Divider />
                  {dhlCities.length > 4 && (
                    <div className="flex justify-center">
                      <Button
                        className="!text-center !font-medium"
                        onClick={pageChangeHandler}
                        disabled={!loadMore || loadMoreLoading}
                      >
                        {loadMoreLoading ? "Loading" : "Load More"}
                      </Button>
                    </div>
                  )}
                  {dhlCities.length === 0 && <p>No cities found</p>}
                </Dropdown.Menu>
              </Dropdown>
            </InputGroup>
            <InputGroup controlid="formGridAddress" size="sm" className="mt-3">
              <FloatingLabel
                controlid="floatingInput"
                label={
                  <div className="!flex">
                    Address <span className="!text-[red] !pl-1">*</span>
                  </div>
                }
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder="Address"
                  id="address"
                  className="!rounded-[15px]"
                  ref={addressRef}
                  required
                />
              </FloatingLabel>
            </InputGroup>
            <Row className="mt-3">
              <Col>
                <InputGroup controlid="formGridAddressDetail" size="sm">
                  <FloatingLabel
                    controlid="floatingInput"
                    label="Apartment, suite, etc"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Apartment, suite, etc."
                      id="appartment"
                      className="!rounded-[15px]"
                      ref={addDetailRef}
                    />
                  </FloatingLabel>
                </InputGroup>
              </Col>
              <Col>
                <InputGroup controlid="formGridEmail" size="sm">
                  <FloatingLabel
                    controlid="floatingInput"
                    label="Postal code"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Postal code"
                      id="postal"
                      className="!rounded-[15px]"
                      ref={postalRef}
                    />
                  </FloatingLabel>
                </InputGroup>
              </Col>
            </Row>
          </Form>
        </Container>
        <Container className="md:!mx-6 xsm:!my-5 md:!pt-20" fluid>
          <Row className=" border-b-2 !mt-10">
            <div className="!bg-[#fff] !my-3 !pt-3">
              {cards.map((item, index) => {
                var totalPerCard =
                  parseFloat(item.price) +
                  item.extras.reduce((acx, extra) => {
                    return (
                      acx +
                      parseFloat(extra.extra_price) * parseInt(extra.quantity)
                    );
                  }, 0);
                return (
                  <div
                    className="card mb-3 !border-b-1 !border-t-0 !border-x-0 !bg-transparent"
                    key={index}
                  >
                    <Row className="row !flex !justify-between">
                      <Col
                        xxs={12}
                        xs={12}
                        sm={3}
                        md={3}
                        lg={3}
                        xl={3}
                        xxl={3}
                        className="!relative bg-[#ffffff] rounded border-2 border-[#C4C4C4] xsm:!w-[90%] xsm:!m-auto"
                      >
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-[#FF733A] !text-sm">
                          {item.quantity}
                          <span className="visually-hidden">
                            {item.quantity}
                          </span>
                        </span>
                        <img
                          src={item.main_image}
                          className="!m-auto sm:!pt-5 !object-contain xsm:!w-[40%]"
                          alt={item.card_name}
                        />
                      </Col>
                      <Col
                        className="xsm:!mt-2"
                        xxs={4}
                        xs={4}
                        sm={3}
                        md={3}
                        lg={3}
                        xl={3}
                        xxl={3}
                      >
                        <p className="!whitespace-nowrap">{item.card_name}</p>
                        {item.extras.length ? <p>Extra</p> : ""}
                        {item.extras?.map((extra, index) => (
                          <div key={index} className="!whitespace-nowrap">
                            <small className="xsm:!text-xs">
                              {extra.extra_name}
                            </small>
                          </div>
                        ))}
                        <br />
                        <p className="!text-lg !font-bold !whitespace-nowrap">
                          Total : $ {roundNumber(totalPerCard * item.quantity)}
                        </p>
                      </Col>
                      <Col
                        className="xsm:!mt-2 !text-center"
                        xxs={4}
                        xs={4}
                        sm={3}
                        md={3}
                        lg={3}
                        xl={3}
                        xxl={3}
                      >
                        <p className="xsm:text-right">${item.price}</p>
                        {item.extras.length ? (
                          <p className="!whitespace-nowrap">Unit Price</p>
                        ) : (
                          ""
                        )}
                        {item.extras?.map((extra, index) => (
                          <div key={index} className="!whitespace-nowrap">
                            <small className="xsm:!text-xs">
                              $ {extra.extra_price}
                            </small>
                          </div>
                        ))}
                      </Col>
                      <Col
                        className="xsm:!mt-2 !text-center"
                        xxs={4}
                        xs={4}
                        sm={3}
                        md={3}
                        lg={3}
                        xl={3}
                        xxl={3}
                      >
                        <p className="!text-transparent !select-none">
                          $ {item.quantity * item.price}
                        </p>
                        {item.extras.length ? (
                          <p className="!whitespace-nowrap">Total Price</p>
                        ) : (
                          ""
                        )}
                        {item.extras?.map((extra, index) => (
                          <div key={index} className="!whitespace-nowrap">
                            <small className="xsm:!text-xs">
                              $ {extra.extra_price * extra.quantity}
                            </small>
                          </div>
                        ))}
                        <br />
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </div>
            <InputGroup className="mb-3" size="lg">
              <Form.Control
                placeholder="Coupon code"
                aria-label="Coupon code"
                aria-describedby="basic-addon2"
                className="!border-[#FF733A] !border-1"
                ref={couponRef}
              />
              <Button
                className="!bg-[#FF733A] !border-[#FF733A] hover:!bg-[#A04723]"
                onClick={promoCodeHandler}
              >
                Apply
              </Button>
            </InputGroup>
            {promoCodeResponse && (
              <p>Your order has been dicounted by {discountedBy} %</p>
            )}
          </Row>
          <Row className="flex flex-col border-b-2">
            <div className="flex justify-between mt-1">
              <p className="text-[#000000] font-semibold">Subtotal</p>
              <p className="text-[#000000] font-semibold">
                $ {roundNumber(subTotal)}
              </p>
            </div>
            {promoCodeResponse && (
              <div className="flex justify-between">
                <p className="text-[#000000] font-semibold">Discounted By</p>
                <p className="text-[#000000] font-semibold">{discountedBy} %</p>
              </div>
            )}

            {promoCodeResponse && (
              <div className="flex justify-between border-t-2">
                <p className="text-[#000000] font-semibold">
                  Discounted Subtotal
                </p>
                <p className="text-[#000000] font-semibold">
                  $ {roundNumber(discountedSubTotal)}
                </p>
              </div>
            )}
            {shippingCost && (
              <div className="flex justify-between">
                <p className="text-[#000000] font-semibold">
                  Shipping{" "}
                  {isCashDisabled && (
                    <img
                      src={dhlIcon}
                      className="inline h-10 w-10 bg-contain"
                      alt="dhl"
                    />
                  )}
                </p>
                <p className="text-[#000000] font-semibold">$ {shippingCost}</p>
              </div>
            )}
          </Row>
          <Row className="mt-3">
            <div className="flex justify-between">
              <p className="text-[#000000] font-bold">Total</p>
              <p className="text-[#000000] font-bold">
                ${" "}
                {promoCodeResponse
                  ? roundNumber(discountedSubTotal + shippingCost)
                  : roundNumber(subTotal + shippingCost)}
              </p>
            </div>
          </Row>
          <Form className="form2 py-3 px-4 sm:!px-5">
            <p className="!text-[#FF733A] font-semibold !p-0">Payment Method</p>
            {!isCashDisabled && (
              <Row className="border-4 p-2 pt-4 border-orange-500 rounded-xl relative">
                <p className="w-auto absolute !left-2 !-top-6 !bg-white !pt-2 !flex !items-center">
                  Pay Cash{" "}
                  <img
                    src={cash}
                    className="!h-10 !w-10 !object-contain"
                    alt="..."
                  />
                </p>
                <Form.Check
                  reverse
                  label="Cash on Delivery (only inside lebanon)"
                  type="checkbox"
                  id="Cash on Delivery (only inside lebanon)"
                  checked={isCash}
                  onChange={paymentTypeHandler}
                  disabled={isCashDisabled}
                />
              </Row>
            )}
            <Row className="mt-4 border-4 p-2 pt-4 border-orange-500 rounded-xl relative">
              <p className="w-auto absolute !left-2 !-top-6 !bg-white !pt-2 !flex !items-center">
                Pay By Card{" "}
                <img
                  src={visa}
                  alt="..."
                  className="!h-10 !w-10 !object-contain !ml-3"
                />
                <img
                  src={master}
                  alt="..."
                  className="!h-10 !w-10 !object-contain !mx-3"
                />
              </p>
              <p className="text-[#000000] font-semibold !p-0">
                Card Information
              </p>
              <Row className="!px-0">
                <InputGroup controlid="formGridEmail" size="sm">
                  <FloatingLabel
                    controlid="floatingInput"
                    className="form-outline"
                    label="Card number"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Card number"
                      id="cardNumber"
                      className="!rounded-[15px] !mr-0"
                      onKeyUp={cardNumberHandler}
                      ref={cardNbRef}
                      disabled={isCash}
                      maxLength={23}
                    />
                  </FloatingLabel>
                </InputGroup>
              </Row>
              <Row className="mt-3 !px-0">
                <Col>
                  <FloatingLabel controlid="floatingInput" label="MM/YY">
                    <Form.Control
                      type="text"
                      placeholder="MM/YY"
                      className="!rounded-[15px] !mr-0"
                      ref={dateRef}
                      disabled={isCash}
                      onKeyUp={cardDateHandler}
                      maxLength={7}
                    />
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel controlId="floatingInputGrid" label="CVV">
                    <Form.Control
                      type="text"
                      placeholder="CVV"
                      className="!rounded-[15px]"
                      ref={cvvRef}
                      onKeyUp={cardCvvHandler}
                      disabled={isCash}
                      maxLength="3"
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row className="!px-0 mt-3">
                <InputGroup controlid="formGridEmail" size="sm">
                  <FloatingLabel controlid="floatingInput" label="Card Name">
                    <Form.Control
                      type="text"
                      placeholder="Card Name"
                      id="cardName"
                      onKeyUp={cardNameHandler}
                      className="!rounded-[15px]"
                      ref={cardNameRef}
                      disabled={isCash}
                    />
                  </FloatingLabel>
                </InputGroup>
              </Row>
            </Row>
            {/* <CardPaymentInfo /> */}
          </Form>
          <div className="!flex !justify-between !pt-3">
            <Link to="/cart">
              <span className="!flex !flex-row !items-center !no-underline !bg-transparent !text-[#000000]">
                <img src={backImg} alt="back" />
                <p className="pt-3 pl-2">Return to&nbsp;cart</p>
              </span>
            </Link>
            <Button
              className="!flex !items-center !no-underline !rounded-full !bg-[#FF733A] !text-[#FFFFFF] !px-7 !border-0"
              onClick={confirmCheckOutHandler}
              disabled={confirmIsLoading}
            >
              {confirmIsLoading ? "Loading..." : "Confirm Checkout"}
            </Button>
          </div>
        </Container>
      </Container>
    </section>
  );
};

export default CheckoutForm;
