import "./AboutUsForm.css";
import cube1 from "../../../images/home/BestSelling/cube1.png";
import cube2 from "../../../images/home/BestSelling/cube2.png";
import cube12 from "../../../images/home/QualityTrust/QTcube1.png";
import cube22 from "../../../images/home/QualityTrust/QTcube2.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import LazyLoad from "react-lazy-load";
import ProgressiveImage from "react-progressive-image";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../Loader/Loader";
import { getAboutUs } from "../../../store/data/Secondary-slice";
const AboutUsForm = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (content1 && content3) {
      return;
    } else {
      dispatch(getAboutUs());
    }
  }, []);
  const { isLoading, content1, content2, content22, content3 } = useSelector(
    (state) => state.secondary.aboutUs
  );
  return (
    <section className="AU1">
      <Container className="md:!pt-[140px] !pt-[90px]">
        <Row>
          <header className="flex flex-col items-center justify-center">
            <div className="flex flex-row items-center justify-center">
              <img src={cube1} alt="Cube 1" className="p-2 xsm:h-8" />
              <h1 className="text-[#000000] font-extrabold xsm:text-[20px]">
                About Us
              </h1>
              <img src={cube2} alt="Cube 2" className="p-2 xsm:h-8" />
            </div>
            <div>
              <p className="text-[#000000] font-bold xsm:text-[12px] text-center">
                Propack is home to the world’s favourite personalised football
                cards.
              </p>
            </div>
          </header>
        </Row>
        <Row className="md:!mt-10 xsm:!mt-2 !flex">
          {!isLoading &&
            content1 &&
            content1?.map((card, index) => (
              <Card
                style={{ width: "20rem" }}
                border="0"
                className="cardContainer mx-auto !bg-transparent xsm:!my-3"
                key={index}
              >
                <LazyLoad>
                  <ProgressiveImage
                    src={`${card.path}${card.file_name}`}
                    placeholder={`${card.path}blures/${card.file_name}`}
                    className="!h-[296px] !w-[296px]"
                  >
                    {(src) => (
                      <Card.Img
                        src={src}
                        variant="top"
                        className="ImgClass !rounded-t-[16px] !rounded-b-[16px] !h-[296px] !w-[296px] !object-cover"
                      />
                    )}
                  </ProgressiveImage>
                </LazyLoad>
                <Card.Body className="text-left">
                  <Card.Title className="!text-[#000000] !font-bold">
                    {card.title}
                  </Card.Title>
                  <Card.Text className="!text-[#000000] !font-medium">
                    {card.description}
                  </Card.Text>
                </Card.Body>
              </Card>
            ))}
          {isLoading && <Loader />}
        </Row>
      </Container>
      <Card className="AU2 !bg-transparent !border-none">
        <Row className="!pt-3">
          <header className="flex flex-row items-center justify-center">
            <img src={cube12} alt="Cube 1" className="p-2 xsm:h-8" />
            {content2 && (
              <h1 className="text-[#ffffff] font-extrabold xsm:text-[20px]">
                {content2[0]?.title}
              </h1>
            )}
            <img src={cube22} alt="Cube 2" className="p-2 xsm:h-8" />
          </header>
        </Row>
        <Row className="px-5 md:!pb-40 md:!pt-6 !py-5 !flex !flex-col md:!flex-row">
          {content3 && (
            <Col>
              <Row>
                {content3?.map((list, index) => (
                  <Row>
                    <div
                      className="card mb-3 !border-none bg-transparent"
                      style={{ maxwidth: "540px" }}
                      key={index}
                    >
                      <Row>
                        <div className="col-md-2">
                          <LazyLoad>
                            <ProgressiveImage
                              src={`${list.path}${list.file_name}`}
                              placeholder={`${list.path}blures/${list.file_name}`}
                            >
                              {(src) => (
                                <img
                                  src={src}
                                  variant="top"
                                  className="img-fluid rounded-start !m-auto"
                                />
                              )}
                            </ProgressiveImage>
                          </LazyLoad>
                        </div>
                        <div className="col-md-8">
                          <div className="card-body !p-0 !text-[#ffffff]">
                            <h5 className="card-title text-[#ffffff] font-semibold text-[12px] md:!text-[1.25rem] !text-center md:!text-left">
                              {list.title}
                            </h5>
                            <p className="card-text xsm:text-[12px] !text-center md:!text-left">
                              {list.short_description}
                            </p>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </Row>
                ))}
              </Row>
            </Col>
          )}
          {content22 && (
            <Col>
              {content22?.map((img, index) =>
                img.type === "1" ? (
                  <LazyLoad key={index}>
                    <ProgressiveImage
                      src={`${img?.path}${img?.file_name}`}
                      placeholder={`${img?.path}blures/${img?.file_name}`}
                    >
                      {(src) => (
                        <img
                          src={src}
                          className="!rounded-lg !w-[512px] !h-[465px] !object-contain !mt-[-4rem] sm:!mt-0"
                        />
                      )}
                    </ProgressiveImage>
                  </LazyLoad>
                ) : (
                  ""
                )
              )}
            </Col>
          )}
        </Row>
      </Card>
    </section>
  );
};

export default AboutUsForm;
