import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { Link, useHistory } from "react-router-dom";
import { useRef, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { stylesActions } from "../../../store/Styles-slice";
import localforage from "localforage";
import { checkOutActions } from "../../../store/CheckOut-slice";
const SignInForm = () => {
  const dispatch = useDispatch();
  const url = useSelector((state) => state.api.domain);
  const notify = (msg) => toast(msg);
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  let history = useHistory();
  const emailRef = useRef();
  const passwordRef = useRef();
  const rememberMeREf = useRef();
  const logInHandler = (e) => {
    e.preventDefault();
    setIsRequestLoading(true);
    let formData = new FormData();
    formData.append("email", emailRef.current.value);
    formData.append("password", passwordRef.current.value);
    const url1 = `${url}/wizard/login`;
    axios
      .post(url1, formData)
      .then((res) => {
        if (res.data.succeeded) {
          if (rememberMeREf.current.checked) {
            localStorage.setItem("token", res.data.user.token);
          }
          localforage.setItem("user", res.data.user);
          localStorage.setItem("token", res.data.user.token);
          dispatch(stylesActions.trueLoggedIn());
          history.push("/shopcards");
          dispatch(checkOutActions.setCardsCount({ type: "null" }));
          dispatch(
            checkOutActions.setCardsCount({
              type: "update",
              count: res.data.cards_count,
            })
          );
        } else {
          notify("Wrong email or password");
          setIsRequestLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <Container>
      <ToastContainer autoClose={3000} />
      <Col className="!m-auto !mt-20 2xl:!my-44 xsm:!mt-40 xsm:!mb-20 !w-[50%] xsm:!w-[70%]">
        <Row>
          <Form onSubmit={logInHandler}>
            <Row className="mb-3">
              <Form.Label
                htmlFor="formGridEmail"
                className="!text-[#000000] !font-bold !text-xl"
              >
                Login
              </Form.Label>
              <InputGroup as={Col} controlid="signInUserName" size="sm">
                <FloatingLabel
                  controlid="floatingInput"
                  label="Email"
                  className="mb-3"
                >
                  <Form.Control
                    ref={emailRef}
                    type="email"
                    placeholder="Email"
                    id="signInUserName"
                    className="!rounded-[15px] focus:!border-sky-500 focus:!ring-1 focus:!ring-sky-500"
                    required
                  />
                </FloatingLabel>
              </InputGroup>
            </Row>
            <Row>
              <InputGroup as={Col} controlid="signInPass" size="sm">
                <FloatingLabel
                  controlid="floatingInput"
                  label="Password"
                  className="mb-3"
                >
                  <Form.Control
                    ref={passwordRef}
                    type="password"
                    placeholder="Password"
                    id="signInPass"
                    className="!rounded-[15px] focus:!border-sky-500 focus:!ring-1 focus:!ring-sky-500"
                    required
                  />
                </FloatingLabel>
              </InputGroup>
            </Row>
            <Row>
              <Form.Check
                inline
                label="Remember me"
                name="group1"
                type="checkbox"
                id="Remember me"
                className="!whitespace-nowrap !ml-3"
                ref={rememberMeREf}
              />
            </Row>
            <Row className="!mt-10">
              <div className="!text-center">
                <p>
                  Need an account? <Link to="/account/signup">Create one</Link>
                </p>
              </div>
            </Row>
            <Row>
              <Button
                tupe="submit"
                className="!bg-[#FF733A] !border-[#FF733A] hover:!bg-[#A04723]
                 hover:!border-[#A04723] !px-5 !rounded-[60px] !font-bold !text-lg"
                type="submit"
              >
                Login
              </Button>
            </Row>
          </Form>
        </Row>
      </Col>
    </Container>
  );
};

export default SignInForm;
