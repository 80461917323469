import CartList from "./CartList";

const CartMain = () => {
  return (
    <div className="md:!pt-[140px] !pt-[90px]">
      <CartList />
    </div>
  );
};

export default CartMain;
