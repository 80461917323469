import "./WhyChoose.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import cube1 from "../../../images/home/WhyChoose/cube1.svg";
import cube2 from "../../../images/home/WhyChoose/cube2.svg";
import LazyLoad from "react-lazy-load";
import { CustomProgressiveImage } from "../progressiveImage/ProgressiveImage";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getWhyChoose } from "../../../store/data/Home-slice";
import Loader from "../Loader/Loader";
const WhyChoose = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (content1 === null) {
      dispatch(getWhyChoose());
    } else {
      return;
    }
  }, []);
  const { isLoading, content1, content2 } = useSelector(
    (state) => state.home.whyChoose
  );
  return (
    <Container fluid className="xsm:mt-10 mt-20 xsm:!px-0 xsm:!mx-0 sm:!w-[80%]">
      {!isLoading && content1 && (
        <Row>
          <div className="flex items-center justify-center text-[#000000]">
            <img src={cube1} alt="c1" className="p-2 xsm:p-0 xsm:h-8 h-10" />
            <h2 className="font-extrabold pt-2 xsm:text-[20px]">
              {content1 && content1[0]?.title}
            </h2>
            <img src={cube2} alt="c2" className="p-2 xsm:p-0 xsm:h-8 h-10" />
          </div>
        </Row>
      )}
      <Row className="!flex !items-center !justify-center">
        <Col className="!rounded-r-2xl">
          {!isLoading && content1 && (
            <LazyLoad offset={300}>
              <CustomProgressiveImage
                src={`${content1[0]?.path}${content1[0]?.file_name}`}
                thumbnail={`${content1[0]?.path}thumbnails/${content1[0]?.file_name}`}
                blur={`${content1[0]?.path}blures/${content1[0]?.file_name}`}
                className="xsm:h-48 h-60 sm:h-64 md:h-80 lg:h-96 !object-contain"
              />
            </LazyLoad>
          )}
          {isLoading && <Loader />}
        </Col>
        <Col>
          <div>
            <ul className="list-group !bg-transparent">
              {content2 &&
                content2?.map(
                  (feature, index) =>
                    index < content2.length / 2 && (
                      <li
                        key={index}
                        className="list-group-item border-0 !bg-transparent !flex items-center justify-left font-extrabold"
                      >
                        <div className="xsm:!hidden !w-8 !h-8 sm:!w-12 sm:!h-12 !bg-[#A04723] !rounded-[60px] !flex !items-center !justify-center">
                          <i
                            className={feature.feature_icon}
                            style={{ color: "white" }}
                          />
                        </div>
                        <i
                          className={`${feature.feature_icon} xsm:!block !hidden`}
                          style={{ color: "#FF733A" }}
                        />
                        <h4
                          className="text-[#000000] font-extrabold
                      lg:text-[22px] lg:!pt-3
                      md:!pt-2 md:px-2 md:text-[20px]
                      xsm:px-1 xsm:!pt-2 xsm:text-[10px]
                      !px-1 !pt-2 text-[17px]"
                        >
                          {feature.features}
                        </h4>
                      </li>
                    )
                )}
            </ul>
          </div>
        </Col>
        <Col>
          <div>
            <ul className="list-group !bg-transparent">
              {content2 &&
                content2?.map((feature, index) =>
                  index > content2.length / 2 - 1 ? (
                    <li
                      key={index}
                      className="list-group-item border-0 !bg-transparent !flex items-center justify-left font-extrabold"
                    >
                      <div className="xsm:!hidden !w-8 !h-8 sm:!w-12 sm:!h-12 !bg-[#A04723] !rounded-[60px] !flex !items-center !justify-center">
                        <i
                          className={feature.feature_icon}
                          style={{ color: "white" }}
                        />
                      </div>
                      <i
                        className={`${feature.feature_icon} xsm:!block !hidden`}
                        style={{ color: "#FF733A" }}
                      />
                      <h4
                        className="text-[#000000] font-extrabold
                        lg:text-[22px] lg:!pt-3
                        md:!pt-2 md:px-2 md:text-[20px]
                        xsm:px-1 xsm:!pt-2 xsm:text-[10px]
                        !px-1 !pt-2 text-[17px]"
                      >
                        {feature.features}
                      </h4>
                    </li>
                  ) : (
                    ""
                  )
                )}
            </ul>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default WhyChoose;
