import "./BigOccasion.css";
import bg from "../../../images/home/Occasion/BG.png";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import cube1 from "../../../images/home/WhyChoose/cube1.svg";
import cube2 from "../../../images/home/WhyChoose/cube2.svg";
import LazyLoad from "react-lazy-load";
import ProgressiveImage from "react-progressive-image";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getBigOccasion } from "../../../store/data/Home-slice";
import Loader from "../Loader/Loader";
import checkIcon from "../../../images/shopCards/Step1/checkIcon.svg";
const BigOccasionSmall = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (content === null) {
      dispatch(getBigOccasion());
    } else {
      return;
    }
  }, []);
  const { isLoading, content } = useSelector((state) => state.home.bigOccasion);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  return (
    <Container className="!m-auto !mt-20 !px-0" fluid>
      <Row>
        <div className="!flex !flex-row !items-center !justify-center text-[#000000]">
          <img
            src={cube1}
            alt="Propack Cube"
            className="p-2 xsm:p-0 xsm:h-8 h-10"
          />
          <h1 className="font-extrabold pt-2 xsm:text-[20px] sm:text-xl text-lg">
            {content && content[0]?.title}
          </h1>
          <img
            src={cube2}
            alt="Propack Cube"
            className="p-2 xsm:p-0 xsm:h-8 h-10"
          />
        </div>
      </Row>
      <Card className="!border-none">
        {isLoading && <Loader />}
        {!isLoading && content && (
          <LazyLoad offset={300}>
            <ProgressiveImage src={bg} placeholder={bg}>
              {(src) => <Card.Img src={src} alt="Loading..." loading="lazy" />}
            </ProgressiveImage>
          </LazyLoad>
        )}
        <Card.ImgOverlay>
          <Row>
            {isImageLoaded && (
              <Col className="!pl-0">
                <ul className="!pl-0 !text-black xsm:!text-[11px] sm:!text-lg !font-semibold">
                  <li className="xsm:!pt-0 !pt-1 sm:!pt-5 !flex !items-center">
                    <img
                      src={checkIcon}
                      alt=".."
                      className="xsm:!h-[10px] !px-1"
                    />
                    {content &&
                      content[0]?.first_option_left &&
                      content[0]?.first_option_left}
                    {isLoading && "Loading..."}
                  </li>
                  <li className="xsm:!pt-1 !pt-2 sm:!pt-7 !flex !items-center">
                    <img
                      src={checkIcon}
                      alt=".."
                      className="xsm:!h-[10px] !px-1"
                    />
                    {content &&
                      content[0]?.second_option_left &&
                      content[0]?.second_option_left}
                    {isLoading && "Loading..."}
                  </li>
                  <li className="xsm:!pt-1 !pt-2 sm:!pt-7 !flex !items-center">
                    <img
                      src={checkIcon}
                      alt=".."
                      className="xsm:!h-[10px] !px-1"
                    />
                    {content &&
                      content[0]?.thired_option_left &&
                      content[0].thired_option_left}
                    {isLoading && "Loading..."}
                  </li>
                </ul>
              </Col>
            )}
            <Col>
              {!isLoading && content && (
                <LazyLoad offset={300}>
                  <ProgressiveImage
                    src={`${content[0]?.path}${content[0]?.file_name}`}
                    thumbnail={`${content[0]?.path}thumbnails/${content[0]?.file_name}`}
                  >
                    {(src) => (
                      <img
                        src={src}
                        alt="Big Occasion"
                        loading="lazy"
                        onLoad={() => {
                          setIsImageLoaded(true);
                        }}
                      />
                    )}
                  </ProgressiveImage>
                </LazyLoad>
              )}
            </Col>
            {isImageLoaded && (
              <Col className="!pl-0">
                <ul className="!pl-0 !text-black xsm:!text-[11px] sm:!text-lg !font-semibold">
                  <li className="xsm:!pt-0 !pt-1 sm:!pt-5 !flex !items-center">
                    <img
                      src={checkIcon}
                      alt=".."
                      className="xsm:!h-[10px] !px-1"
                    />
                    {content &&
                      content[0]?.first_option_right &&
                      content[0].first_option_right}
                    {isLoading && "Loading..."}
                  </li>
                  <li className="xsm:!pt-0 !pt-2 sm:!pt-5 !flex !items-center">
                    <img
                      src={checkIcon}
                      alt=".."
                      className="xsm:!h-[10px] !px-1"
                    />
                    {content &&
                      content[0]?.second_option_right &&
                      content[0].second_option_right}
                    {isLoading && "Loading..."}
                  </li>
                  <li className="xsm:!pt-0 !pt-2 sm:!pt-5 !flex !items-center">
                    <img
                      src={checkIcon}
                      alt=".."
                      className="xsm:!h-[10px] !px-1"
                    />
                    {content &&
                      content[0]?.thired_option_right &&
                      content[0].thired_option_right}
                    {isLoading && "Loading..."}
                  </li>
                </ul>
              </Col>
            )}
          </Row>
        </Card.ImgOverlay>
      </Card>
    </Container>
  );
};

export default BigOccasionSmall;
